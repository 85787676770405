<template>
  <div>
    <AnDialogComponent :dialog.sync="_dialog"
                       title="Associer un ou plusieurs délégant(s) à ce délégué"
                       :actions="true">
      <template v-slot:card-text>
        <p class="semibold--text primary_dark--text font-size--18 mb-8">Délégué : {{ delegate_name }}</p>
        <p v-if="professionals_delegating && professionals_delegating.length === 0" class="font-italic">Aucun délégant n'est déclaré pour ce protocole.</p>
        <v-checkbox v-for="(item, key) in professionals_delegating"
                    :key="key"
                    v-model="pros_selected"
                    :value="item.id"
                    :label="get_name(item)"
                    hide-details/>
      </template>
      <template v-slot:card-actions>
        <v-btn color="primary" text @click="_dialog=false">
          <span class="normal--text text-decoration-underline">Annuler</span>
        </v-btn>
        <v-btn color="green" class="white--text px-6" elevation="0" :loading="loading" @click="patch_settings_protocol()">
          <span class="semibold--text medium--text">Confirmer</span>
        </v-btn>
      </template>
    </AnDialogComponent>
  </div>
</template>

<script>
import AnDialogComponent from "anam-library/src/components/an-ui/an-dialog/AnDialogComponent";
import {set_txt_author} from "anam-library/src/js/utils";


export default {
  name: "PtManageDuoDialog",
  components: {AnDialogComponent},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    hf_id: {
      type: String,
      required: true
    },
    selected_delegate: {
      type: Object,
      default: null
    },
    selected_protocol: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    pros_selected: [],
    loading: false,
  }),
  computed: {
    _dialog: {
      get: function() {
        return this.dialog;
      },
      set: function(val) {
        this.$emit("update:dialog", val);
      }
    },
    professionals_delegating: function() {
      if(this.selected_protocol) return this.selected_protocol.delegating_professionals;
      return [];
    },
    duo_delegate_delegating: function() {
      if(this.selected_protocol) return this.selected_protocol.duo_delegate_delegating;
      return {};
    },
    delegate_name: function() {
      if(this.selected_delegate) return set_txt_author(this.selected_delegate);
      return null;
    },
    delegate_id: function() {
      if(this.selected_delegate && this.selected_delegate.id) return this.selected_delegate.id.toString();
      return null;
    }
  },
  watch: {
    _dialog: function(val) {
      if(!val) {
        this.loading = false;
        this.pros_selected = [];
      } else {
        if(this.duo_delegate_delegating && this.delegate_id) {
          this.pros_selected = this.duo_delegate_delegating[this.delegate_id];
        }
      }
    }
  },
  mounted() {
    if(this.duo_delegate_delegating && this.delegate_id) {
      this.pros_selected = this.duo_delegate_delegating[this.delegate_id];
    }
  },
  methods: {
    get_name: function(item) {
      return set_txt_author(item);
    },
    patch_settings_protocol: function() {
      this.loading = true;
      let tmp = JSON.parse(JSON.stringify(this.duo_delegate_delegating));
      if(this.delegate_id) tmp[this.delegate_id] = this.pros_selected;
      let data = {duo_delegate_delegating: tmp};

      this.$http.patch(`/api/hf-coordination/${this.hf_id}/settings-protocols/${this.selected_protocol.id}`, data).then(response => {
        this.$emit("update_protocol", response.data);
        this.$store.commit("PostSuccess", `Modifications enregistrées`);
        this._dialog = false;
      }).finally(() => {
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>