<template>
  <div>
    <p class="primary_dark--text font-size--18 semibold--text mb-0">Les soins pris en charge par le service</p>
    <p class="font-italic">Si vous décochez un type de soin, l'administrateur du service ne recevra plus les demandes d'intervention concernant ce type de soin.</p>
    <div class="pl-2">
      <v-switch v-model="avis_diabeto" label="Consultation avis en endocrinologie et diabétologie" hide-details></v-switch>
      <v-switch v-model="pied_diabetique" label="Consultation pied diabétique" hide-details></v-switch>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceSettingsDiabetologie",
  props: {
    settings: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    avis_diabeto: false,
    pied_diabetique: false
  }),
  mounted() {
    this.set_settings(this.settings);
  },
  watch: {
    settings: function(val) {
      this.set_settings(val);
    },
    avis_diabeto: function(val) {
      this.check_update('avis_diabeto', val);
    },
    pied_diabetique: function(val) {
      this.check_update('pied_diabetique', val);
    }
  },
  computed: {
    _settings: {
      get: function() {
        return this.settings
      },
      set: function(val) {
        this.$emit("update:settings", val);
      }
    }
  },
  methods: {
    set_settings: function(data) {
      this.pied_diabetique = data.pied_diabetique;
      this.avis_diabeto = data.avis_diabeto;
    },
    check_update: function(field, value) {
      let tmp = JSON.parse(JSON.stringify(this._settings));
      if(tmp[field] !== value) {
        tmp[field] = value;
        this._settings = tmp;
      }
    }
  }
}
</script>

<style scoped>

</style>