<template>
  <div v-if="!phone_format">
    <div v-if="tag && has_patient_form">
      <span v-if="tag.author.id === item.user_id || tag.author === item.user_id" class="normal--text">
        Patient
      </span>
      <span v-else class="normal--text">
        Pro. de santé
        <br>
        -
        <br>
        {{ get_author_name(tag.author) }}
      </span>
    </div>
    <v-icon v-else small>mdi-minus</v-icon>
  </div>
</template>

<script>
import {get_data_waiting_list} from "coordination-library/src/modules/waiting_list";
import {set_txt_author} from "anam-library/src/js/utils";

export default {
  name: "WaitingListInscriptionItem",
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    variable: {
      type: String,
      default: null
    },
    phone_format: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    data_waiting_list: function() {
      return get_data_waiting_list(this.item.waiting_list_of_type);
    },
    has_patient_form: function() {
      return this.data_waiting_list.has_patient_form;
    },
    tag_name : function() {
      return this.data_waiting_list.tag;
    },
    tag: function() {
      if(this.data_waiting_list.of_type === '334') {
        if(this.item.tags_mt && this.item.tags_mt.length > 0) return this.item.tags_mt[0]
      } else {
        if(this.item.tags && this.item.tags.length > 0) {
          let index = this.item.tags.findIndex(x => x.meta_indicator.of_type === this.tag_name);
          if(index > -1) {
            return this.item.tags[index];
          }
        }
      }
      return null;
    }
  },
  methods: {
    get_author_name: function(user) {
      return set_txt_author(user, true);
    }
  }
}
</script>

<style scoped>

</style>