<template>
  <AnConfirmIdentityViaBirthdate :patient_id="patient_id" :redirection="redirection"/>
</template>

<script>
import AnConfirmIdentityViaBirthdate from "anam-library/src/components/an-confirm-identity/AnConfirmIdentityViaBirthdate";

export default {
  name: "PatientConfirmIdentityView",
  components: {
    AnConfirmIdentityViaBirthdate
  },
  computed: {
    patient_id: function() {
      return this.$route.params.patient_id;
    },
    redirection: function () {
      return this.$route.query.redirection;
    }
  }
}
</script>

<style scoped>

</style>