import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
import axios from "axios";

import createPersistedState from "vuex-persistedstate"

import actes from "anam-library/src/store/modules/actes";
import env from "anam-library/src/store/modules/env";
import treatment from "anam-library/src/store/modules/treatment";
import errors from "anam-library/src/store/modules/snackbar_and_errors";
import permissions from "coordination-library/src/modules/permissions";
import notifications from "anam-library/src/store/modules/notifications";
import calendar from "coordination-library/src/modules/calendar"
import tags from "coordination-library/src/modules/tags";
import utils from "coordination-library/src/modules/utils";
import user_doctor from "anam-library/src/store/modules/user_doctor";
import visio from "anam-library/src/store/modules/visio";

import info_admin_json from "@/data/dpa/info_admin.json"
import action_map_json from "@/data/dpa/action_map.json"

import auth from "anam-library/src/store/modules/auth";

import router from "@/router";

Vue.use(Vuex)


const paths = [
    'patient_id',
    'patient_infos',
    'user_infos',
    'auth_ts',
    'auth.user_permission',
    'auth.agency_name',
    'auth.app_name',
    'env',
    'permissions',
    'tags',
    'utils',
    'user_doctor',
    'hf_coordination_public_template_name',
    'hf_coordination_waiting_list_specifics_tags',
    'gcs_bretagne',
    'firebaseToken',
    'keycloak_anamnese',
    // 'visio.chat_messages',
];

export default new Vuex.Store({
    plugins: [createPersistedState({ paths })],
    state: {
        patient_logged_in: false,
        patient_id: null,
        user_infos: {},
        firebaseToken: null,
        auth_ts: false,
        is_mounted_app: false,
        show_patient_name: false,
        patient_infos: {},
        info_admin: JSON.parse(JSON.stringify(info_admin_json)),
        action_map: JSON.parse(JSON.stringify(action_map_json)),
        discussion_by_patient: [],
        dashboard_search: null,
        patients_tab: null,
        hf_coordination_public_template_name: null,
        hf_coordination_waiting_list_specifics_tags: null,
        calendar_focus: null,
        gcs_bretagne: {
            connexion_from_gcs_bretagne: false,
            redirect_logout: null,
             // Important que les variables keycloak ne soit pas vides par défaut
            keycloak_url: "https://auth.gcs.csm.ovh/",
            keycloak_realm: "gcs",
            keycloak_clientId: "snp-public"
        },
        keycloak_anamnese: {
          url: "",
          realm: "",
          clientId: "",
          redirect_logout: ""
        }
    },
    mutations: {
        resetState(state) {
            state.user_infos = {};
            state.is_mounted_app = false;
            state.snack_model = false;
            state.patient_infos = {};
            state.info_admin = JSON.parse(JSON.stringify(info_admin_json));
            state.action_map = JSON.parse(JSON.stringify(action_map_json));
            this.commit('resetVariablesAnamLib')
        },
        resetVariablesAnamLib() {
            this.commit('cleanActes');
        },
        setUserInfos: function(state, data) {
            state.user_infos = data;
        },
        setSpecificUserInfos: function(state, { info, data }) {
            state.user_infos[info] = data;
        },
        setSpecificPPUserInfos: function(state, { info, data }) {
            state.user_infos.personnephysique[info] = data;
        },
        mapInzeecareDataFromAnamneseSerializer(state, data) {
            state.patient_id = data.patient.id;
            state.patient_infos = {
                patient_id: data.patient.id,
                email: data.email,
                first_name: data.first_name,
                last_name: data.last_name,
                sexe: data.personnephysique.sexe,
                mobile: data.personnephysique.personel_phone,
                fullAddress: data.personnephysique.adresse ? data.personnephysique.adresse.fullAddress : "Non renseigné",
                birthdate: {
                    birthdate: data.personnephysique.birthdate.birthdate,
                    birth_zipcode: data.personnephysique.birthdate.birth_zipcode,
                    birth_country: data.personnephysique.birthdate.birth_country,
                    updated_by: data.personnephysique.birthdate.updated_by,
                }
            };
            this.commit('setAge', data.personnephysique.birthdate);
        },
        SetAdminInfos(state) {
            let map = {
                "sex": "sexe",
                "age": "age",
                "phone": "mobile",
                "email": "email",
                "adresse": "fullAddress"
            }
            for (let i in state.info_admin.primary) {
                for (let j in state.info_admin.primary[i].data) {
                    state.info_admin.primary[i].data[j].value = state.patient_infos[map[j]];
                }
            }
        },

        setAge: function(state, birthdate) {
            let age = null;
            if (birthdate.updated_by !== 'default') {
                let formatted_birthdate = birthdate.birthdate;
                if (!moment(formatted_birthdate, "YYYY-MM-DD", true).isValid()) {
                    formatted_birthdate = moment(formatted_birthdate, "DD/MM/YYYY").format("YYYY-MM-DD");
                }
                age = moment.duration(moment().diff(formatted_birthdate)).years() + ' ans';
            }
            state.patient_infos.age = age;
        },
        setPatientId: function(state, patient_id) {
            state.patient_id = patient_id;
        },
        setPatientInfos: function(state, infos) {
            state.patient_infos = infos;
        },
        setAuthTS: function(state, value) {
            state.auth_ts = value
        },
        setPatientList() {},
        setPatientsTab: function(state, value) {
            state.patients_tab = value;
        },
        setConnexionFromGCSBretagne: function(state, value) {
            state.gcs_bretagne.connexion_from_gcs_bretagne = value;
        },
        setRedirectLogoutGCSBretagne: function(state, value) {
            state.gcs_bretagne.redirect_logout = value;
        },
        setEnvKeycloakCGSBretagne: function(state) {
            if (["localhost", "staging"].some(substring => window.location.href.includes(substring))) {
                state.gcs_bretagne.keycloak_url = "https://auth.gcs.csm.ovh/";
                state.gcs_bretagne.keycloak_realm = "gcs";
                state.gcs_bretagne.keycloak_clientId = "snp-public";
            }
            else if (["preprod"].some(substring => window.location.href.includes(substring))) {
                state.gcs_bretagne.keycloak_url = "https://auth-recette.telesantebretagne.org/";
                state.gcs_bretagne.keycloak_realm = "gcs";
                state.gcs_bretagne.keycloak_clientId = "snp";
            }
            else if(["app.citana.fr"].some(substring => window.location.href.includes(substring))) {
                state.gcs_bretagne.keycloak_url = "https://auth.telesantebretagne.org/";
                state.gcs_bretagne.keycloak_realm = "gcs";
                state.gcs_bretagne.keycloak_clientId = "snp";
            }
        },
        setEnvKeycloakAnamnese: function(state) {
          if (["localhost"].some(substring => window.location.href.includes(substring))) {
            state.keycloak_anamnese.url = "https://auth.preprod.anamnese.care";
            state.keycloak_anamnese.realm = "Anamnese";
            state.keycloak_anamnese.clientId = "citana-coordination";
            state.keycloak_anamnese.redirect_logout = "http://inzeecare.localhost";
          } else if (["staging"].some(substring => window.location.href.includes(substring))) {
            state.keycloak_anamnese.url = "https://auth.preprod.anamnese.care";
            state.keycloak_anamnese.realm = "Anamnese";
            state.keycloak_anamnese.clientId = "citana-coordination";
            state.keycloak_anamnese.redirect_logout = "https://inzeecare.staging.anamnese.care";
          } else if (["app.preprod.citana.fr"].some(substring => window.location.href.includes(substring))) {
            state.keycloak_anamnese.url = "https://auth.preprod.anamnese.care";
            state.keycloak_anamnese.realm = "Anamnese";
            state.keycloak_anamnese.clientId = "citana-coordination";
            state.keycloak_anamnese.redirect_logout = "https://app.preprod.citana.fr";
          } else if (["inzeecare.demo.anamnese.care"].some(substring => window.location.href.includes(substring))) {
            state.keycloak_anamnese.url = "https://auth.anamnese.care";
            state.keycloak_anamnese.realm = "AnamneseDemo";
            state.keycloak_anamnese.clientId = "citana-coordination";
            state.keycloak_anamnese.redirect_logout = "https://inzeecare.demo.anamnese.care";
          } else if(["app.citana.fr"].some(substring => window.location.href.includes(substring))) {
            state.keycloak_anamnese.url = "https://auth.anamnese.care";
            state.keycloak_anamnese.realm = "Anamnese";
            state.keycloak_anamnese.clientId = "citana-coordination";
            state.keycloak_anamnese.redirect_logout = "https://app.citana.fr";
          }
        },
        setHfPublicTemplateName: function(state, value) {
            state.hf_coordination_public_template_name = value;
        },
        setHfWaitingListSpecificsTags: function(state, value) {
            state.hf_coordination_waiting_list_specifics_tags = value;
        },
        setCalendarFocus(state, value) {
            state.calendar_focus = value;
        },
        setFirebaseToken(state, token) {
            state.firebaseToken = token;
        }
    },

    getters: {
        getPatientId(state) {
            return state.patient_id
        },
        getUserInfosElement: (state) => (element) => {
            return state.user_infos[element]
        },
        getCurrentUserFullName(state) {
            return `${state.user_infos.first_name} ${state.user_infos.last_name.toUpperCase()}`;
        },
        getAuthTS: function(state) {
            return state.auth_ts
        },
        getPatientInfosBirthdate: (state) => (element) => {
            return state.patient_infos[element]
        },
        getConnexionFromGCSBretagne(state) {
            return state.gcs_bretagne.connexion_from_gcs_bretagne;
        },
        getRedirectLogoutGCSBretagne(state) {
            return state.gcs_bretagne.redirect_logout;
        },
        getEnvKeycloakCGSBretagne: (state) => (element) => {
            return state.gcs_bretagne[element];
        },
        getEnvKeycloakAnamnese: (state) => (element) => {
          return state.keycloak_anamnese[element];
        },
        getHfPublicTemplateName: function(state) {
            return state.hf_coordination_public_template_name;
        },
        getHfWaitingListSpecificsTags: function(state) {
            return state.hf_coordination_waiting_list_specifics_tags;
        },
        getCalendarFocus(state) {
            return state.calendar_focus;
        },
        getPatientsTab: function(state) {
            return state.patients_tab || 0;
        }
    },

    actions: {
        async connexionInzeecare(context, { data, from_gcs_bretagne = false }) {
            if (!from_gcs_bretagne) context.commit('setRedirectLogoutGCSBretagne', null);
            context.commit('ResetSnackBar');
            let query = { role: data.role };
            if (router.currentRoute.query.redirection) {
                query["redirection"] = router.currentRoute.query.redirection;
            }

            router.push({ name: "RedirectionView", query: query });
        },
        logoutInzeecare: async function(context) {
            axios.get("/api/deconnexion").then(() => {
                context.commit('setUserPermission', 0);
                context.commit('setAuthTS', false);
                context.commit('resetAllPermissions');

                if(context.rootGetters.getConnexionFromGCSBretagne) {
                    if (context.rootGetters.getRedirectLogoutGCSBretagne) {
                        try {
                            window.location.href = context.rootGetters.getRedirectLogoutGCSBretagne;
                        } catch(e) {
                            router.push({name: "ConnexionBrLayout"});
                        }
                    } else {
                        router.push({name: "ConnexionBrLayout"});
                    }
                }
                else router.push({name: "ConnexionLayout"});
            })
        },
        setPatientAccess: function (context, infos) {
            context.commit('setPatientId', infos['patient_id']);
            context.commit('setPatientInfos', JSON.parse(JSON.stringify(infos)));
            // context.commit('setAge', infos['birthdate'])
        }
    },
    modules: {
        actes: actes,
        auth: auth,
        calendar: calendar,
        env: env,
        errors: errors,
        permissions: permissions,
        tags: tags,
        treatment: treatment,
        notifications: notifications,
        utils: utils,
        user_doctor: user_doctor,
        visio: visio
    }
})
