<template>
  <AnDialogComponent :dialog.sync="_dialog" title="Modifier le statut" actions>
    <template v-slot:card-text>
      <v-select v-model="status_account"
                :items="items_status_account"
                :item-text="item => `${item.text} (${item.subtext})`"
                filled>
      </v-select>
    </template>
    <template v-slot:card-actions>
      <v-btn class="px-6" color="primary" text @click="_dialog=false; status_account=init_status_account">
        <span class="medium--text text-decoration-underline">Annuler</span>
      </v-btn>
      <v-btn class="px-6" color="primary" elevation="0" :loading="loading" :disabled="!can_update" @click="update_status_account()">
        <span class="medium--text">Enregistrer</span>
      </v-btn>
    </template>
  </AnDialogComponent>
</template>

<script>
import AnDialogComponent from "anam-library/src/components/an-ui/an-dialog/AnDialogComponent";
import {get_items_status_account} from "anam-library/src/js/onboarding";

export default {
  name: "DialogUpdateStatusAccount",
  components: {
    AnDialogComponent
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    user_id: {
      type: String,
      required: true
    },
    init_status_account: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    loading: false,
    status_account: null,
  }),
  computed: {
    _dialog: {
      get: function() {
        return this.dialog;
      },
      set: function(val) {
        this.$emit("update:dialog", val);
      }
    },
    can_update: function() {
      return this.status_account !== this.init_status_account;
    },
    items_status_account: function() {
      return get_items_status_account();
    }
  },
  watch: {
    init_status_account: function(val) {
      this.status_account = val;
    }
  },
  created() {
    if(this.init_status_account) this.status_account = this.init_status_account;
  },
  methods: {
    update_status_account: function() {
      this.loading = true;
      let data = {status_account: this.status_account};
      this.$http.patch(`/api/coordination/bo/professionals/${this.user_id}`, data).then(response => {
        this.$emit("update_user", response.data);
        this._dialog = false;
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>