<template>
  <NotFoundTemplate color_txt="white--text"
                    color_btn="primary_pastel"
                    color_btn_text="white"
                    color_txt_inside_btn="white"
                    :specific_logout="true"
                    :img="img"
                    v-on:logout="logout()"/>
</template>

<script>
import NotFoundTemplate from "anam-library/src/components/an-error-pages/NotFoundTemplate";
import img_404 from "anam-library/src/assets/error-pages/new-charte/404_illustration.png";

export default {
  name: "DeconnexionView",
  components: {NotFoundTemplate},
  data: () => ({
    img: img_404
  }),
  methods: {
    logout: function() {
      this.$store.dispatch("logoutInzeecare");
      if(this.$keycloak) this.$keycloak.logout({redirectUri: this.$store.getters.getEnvKeycloakAnamnese('redirect_logout')});
    }
  }
}
</script>

<style scoped>

</style>