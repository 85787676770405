<template>
  <div style="z-index: 10">
    <DoctorDrawer :drawer_value.sync="drawer_value"/>
    <AnHeader :drawer="true"
              :elevation="0"
              height="45"
              :display_col_logo="$vuetify.breakpoint.smAndUp"
              :logo="logo"
              :height_logo="42"
              :col_logo_sm="3"
              :col_logo_md="2"
              color_menu="primary"
              v-on:menu_icon_click="drawer_value=true">
      <template v-slot:actions>
        <div class="mr-sm-3">
          <NotificationsMenu/>

          <AnHeaderMenuButton :navigation_buttons="navigation_buttons" :name="full_name" :optional_info="profession" color_sub_button="primary" color="primary">
            <template v-slot:specific-actions>
              <v-divider class="mt-3"></v-divider>
              <v-list-item @click="logout()">
                <v-icon class="mr-2" color="red">mdi-logout</v-icon>
                <span class="font-size--14 semibold--text red--text">Déconnexion</span>
              </v-list-item>
            </template>
          </AnHeaderMenuButton>
        </div>
      </template>
    </AnHeader>
  </div>
</template>

<script>
import AnHeader from "anam-library/src/components/an-header/AnHeader";
import AnHeaderMenuButton from "anam-library/src/components/an-header/AnHeaderMenuButton";
import DoctorDrawer from "./DoctorDrawer";
import NotificationsMenu from "./NotificationsMenu";

import {get_profession_name} from "anam-library/src/data/professions";

export default {
  name: "DoctorHeader",
  components: {
    AnHeader,
    AnHeaderMenuButton,
    DoctorDrawer,
    NotificationsMenu,
  },
  data: () => ({
    menu: false,
    drawer_value: false,
    logo: "https://www.anamnese.care/hubfs/config/Citana/inzeecare/logo/logo-citana-couleur-BL.png",
  }),
  computed: {
    full_name: function() {
      return this.$store.getters.getCurrentUserFullName;
    },
    profession: function() {
      return get_profession_name(this.$store.getters.getProfessionnelDmp);
    },
    _has_subscription() {
      return this.$store.getters.getPermission('has_subscription');
    },
    navigation_buttons: function() {
      let temp = [
          {text: "Mes préférences", icon: "mdi-cog", url: "/professional/settings", primary_action: false}
      ];
      if(this._has_subscription) temp.push({text: "Mes exports", icon: "mdi-download", url: "/professional/exports", primary_action: false});
      temp.push({text: "Aide", icon: "mdi-help", href: "https://aide.anamnese.care/inzee-care-coordination", primary_action: false});
      return temp;
    }
  },
  created() {
    if(!this.$vuetify.breakpoint.xs) this.drawer_value = true;
  },
  methods: {
    logout: function() {
      this.$store.dispatch('logoutInzeecare');
      if(this.$keycloak) this.$keycloak.logout({redirectUri: this.$store.getters.getEnvKeycloakAnamnese('redirect_logout')});
    }
  }
}
</script>

<style scoped>

</style>