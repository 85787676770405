<template>
  <v-col cols="5" class="align-self-center">
    <p class="mb-0 medium--text primary_dark--text">{{ txt }} :</p>
    <p v-if="information" class="font-italic font-size--10 primary_dark--text mb-0">{{ information }}</p>
  </v-col>
</template>

<script>
export default {
  name: "LegendItem",
  props: {
    txt: {
      type: String,
      required: true
    },
    information: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>

</style>