import { render, staticRenderFns } from "./DoctorSettingsInfirmier.vue?vue&type=template&id=60b8a6f3&scoped=true"
import script from "./DoctorSettingsInfirmier.vue?vue&type=script&lang=js"
export * from "./DoctorSettingsInfirmier.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../inzeecare/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60b8a6f3",
  null
  
)

export default component.exports