<template>
  <v-app>
    <AnOverlayLoading :is_mounted="$store.state.is_mounted_app"/>
    <ManagerHeader></ManagerHeader>
    <v-main class="bg_primary">
      <CoAlert :show="$store.state.errors.snack_model" :snackbar_info="$store.state.errors.snack_info"/>
      <router-view/>
    </v-main>
  </v-app>

</template>

<script>
import AnOverlayLoading from "anam-library/src/components/an-ui/loading/AnOverlayLoading";
import CoAlert from "coordination-library/src/components/doctor/alert/CoAlert";
import ManagerHeader from "./header/ManagerHeader";

export default {
  name: "ManagerLayout",
  components: {ManagerHeader, AnOverlayLoading, CoAlert},
  computed: {
    show_snackbar: {
      get: function() {
        return this.$store.state.errors.snack_model;
      },
      set: function(val) {
        if(!val) this.$store.state.errors.snackbar = false
      }
    }
  }
}
</script>