<template>
  <v-footer color="bg_primary" inset absolute app>
		<v-row no-gutters>
			<v-col cols="12" style="text-align: center;" class="my-3">
				<a target="_blank" class="font-size--12 medium--text primary_dark--text mx-5" :href="contact_url">Nous contacter</a>
        <a target="_blank" class="font-size--12 medium--text primary_dark--text mx-5" :href="cgu_url">CGU</a>
        <a target="_blank" class="font-size--12 medium--text primary_dark--text mx-5" :href="help_url">Aide</a>
			</v-col>
			<v-col cols="12" style="text-align: center">
				<span class="primary_dark--text font-weight-bold`">{{ new Date().getFullYear() }} - &copy; <span class="semibold--text"> Anamnèse</span></span>
			</v-col>
		</v-row>
	</v-footer>
</template>

<script>
export default {
  name: "CommonFooter",
  data: () => ({
    contact_url: "https://www.citana.care/inzee-contact",
    cgu_url: "https://www.citana.care/inzee-cgu",
    help_url: "https://www.citana.care/inzee-aide"
  })
}
</script>