<template>
  <div>
    <v-btn elevation="0" color="red lighten-5" class="mb-1 red--text" block small :disabled="disabled_actions" @click="dialog_cancel=true">
      <v-icon class="mr-sm-1" small color="red">mdi-cancel</v-icon>
      <span class="medium--text">Annuler le protocole</span>
    </v-btn>

    <ManagerProtocolsCancelDialog :dialog.sync="dialog_cancel"
                                  :protocol="item"
                                  v-on:update_protocol="$emit('update_item', $event)"/>
  </div>
</template>

<script>
import ManagerProtocolsCancelDialog from "./ManagerProtocolsCancelDialog.vue";

export default {
  name: "ManagerProtocolsActionsItem",
  components: {
    ManagerProtocolsCancelDialog
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    dialog_cancel: false
  }),
  computed: {
    disabled_actions: function() {
      return this.item.closed;
    }
  }
}
</script>

<style scoped>

</style>