<template>
  <v-switch v-model="is_admin" color="green" @change="patch_professional()"></v-switch>
</template>

<script>
import axios from "axios";
import getCookie from "anam-library/src/js/common";

export default {
  name: "MembersStructureItemAdmin",
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    service: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    is_admin: false
  }),
  created() {
    this.is_admin = this.is_admin_service();
  },
  methods: {
    is_admin_service: function() {
      return this.service.managers.includes(this.item.technical_account_id) || this.item.is_manager_service === true;
    },
    patch_professional: function() {
      if(this.is_admin) this.post_service_manager();
      else this.delete_service_manager();
    },
    post_service_manager: function() {
      let data = {
        technical_account: {
          service: this.service.id,
        }
      }
      axios.patch(`/api/user/${this.item.user_id}/service-manager`, data, {headers: {"X-CSRFToken": getCookie("csrftoken")}}).then(() => {
        this.$store.commit('PostSuccess', 'Modification enregistrée');
      }).catch(error => {
        if(error.response) {
          if(error.response.status === 417) {
            this.is_admin = false;
            this.$store.commit('PostInformation', 'Cet utilisateur est déjà administrateur d\'une structure');
            return;
          }
          this.$store.dispatch('ManageError', error.response.status);
        }
        this.$store.commit('PostCommonError');
      })
    },
    delete_service_manager: function() {
      this.$http.delete(`/api/user/${this.item.user_id}/service-manager`).then(() => {
        this.$store.commit('PostSuccess', 'Modification enregistrée');
      })
    }
  }
}
</script>

<style scoped>

</style>