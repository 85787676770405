<template>
  <v-btn color="green" elevation="0" small class="white--text" :disabled="!item.active" @click="$emit('action')">
    <span class="medium--text">Associer un délégant</span>
  </v-btn>
</template>

<script>

export default {
  name: "ActionAssociateDelegatingTableItem",
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped>

</style>