<template>
  <div v-if="item.email || item.phone">
    <span v-if="item.email" class="normal--text">{{ item.email }}</span>
    <v-icon v-else small>mdi-minus</v-icon>
    <br>
    <span v-if="item.phone" class="normal--text">{{ item.phone }}</span>
    <v-icon v-else small>mdi-minus</v-icon>
  </div>
  <v-icon v-else small>mdi-minus</v-icon>
</template>

<script>

export default {
  name: "ContactItem",
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
}
</script>

<style scoped>

</style>