<template>
  <v-autocomplete v-model="_value"
                  :items="items"
                  :item-value="item_value"
                  :item-text="item_text"
                  :filter="filter_search"
                  :rules="rules"
                  :multiple="multiple"
                  :chips="multiple"
                  deletable-chips
                  :menu-props="{closeOnContentClick: true}"
                  :loading="loading"
                  :placeholder="placeholder"
                  solo
                  dense
                  class="textfield-solo-elevation-0"
                  :no-data-text="no_data_txt"/>
</template>

<script>
import rules from "anam-library/src/js/rules";
import {clean_txt} from "anam-library/src/js/utils";

export default {
  name: "RegistrySelectField",
  props: {
    value: {
      type: [String, Array],
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    item_value: {
      type: String,
      default: "value"
    },
    item_text: {
      type: [String, Function],
      default: "text"
    },
    of_type: {
      type: String,
      default: "text"
    },
    multiple: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: "Sélectionnez"
    },
    loading: {
      type: Boolean,
      default: false
    },
    no_data_txt: {
      type: String,
      default: "Aucun résultat"
    }
  },
  computed: {
    _value: {
      get: function() {
        return this.value;
      },
      set: function(val) {
        this.$emit("update:value", val);
      }
    },
    rules: function() {
      if(this.required) return [rules.required];
      return []
    }
  },
  methods: {
    filter_search(item, queryText, itemText) {
      if (clean_txt(itemText).includes(clean_txt(queryText))) return item;
    },
  }
}
</script>

<style scoped>

</style>