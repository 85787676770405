<template>
  <div>
    <p class="text-center font-size--18 semibold--text my-10">Il n'y a aucune préférence à configurer pour ce type de service.</p>
  </div>
</template>

<script>
export default {
  name: "ServiceSettingsDefault"
}
</script>

<style scoped>

</style>