<template>
  <v-container fluid class="bg_primary pa-3">
    <v-row class="mx-sm-3 white my-3 border_radius_container pa-sm-4">
      <v-col cols="12">
        <p class="semibold--text primary--text font-size--24 mb-0 mb-sm-4">
          <v-icon color="primary" class="mr-1 hidden-xs-only" large>mdi-cog</v-icon>
          Paramètres de la structure : {{ hf_name }} ({{ hf_id }})
        </p>
        <v-progress-linear v-if="loading" indeterminate color="primary_pastel"></v-progress-linear>
      </v-col>
      <v-col cols="12">
        <v-form v-model="valid_form" autocomplete="chrome-off">
          <p class="semibold--text font-size--18 mb-1 primary--text">Général & Options</p>
          <v-divider class="primary"></v-divider>
          <v-row class="mx-0 mt-2">
            <v-col cols="12" sm="6">
              <span>Nom de la structure</span>
              <v-text-field v-model="commercial_name" filled dense :rules="[rules.required]"></v-text-field>
              <span>Numéro FINESS</span>
              <v-text-field v-model="finess_number" :rules="[rules.finess_number]" filled dense ></v-text-field>

              <span>Adresse de la structure</span>
              <p class="mb-0 font-size--12 font-italic">Adresse actuellement renseignée : {{ address_str }}</p>
              <AnSearchAddressGoogleAPI ref="search_address" placeholder="Adresse" v-on:update:address="set_address($event)" class="filled-input mb-8"/>

              <span>Région de la structure</span>
              <v-autocomplete v-model="region" :items="all_regions" item-text="libelle" item-value="reg" filled dense no-data-text="Aucune donnée" :rules="[rules.required]"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" offset-sm="1">
              <div class="d-flex">
                <v-switch v-model="is_published" label="Publié" color="green"></v-switch>
                <v-spacer></v-spacer>
                <v-switch v-model="is_client" label="Client" color="green"></v-switch>
              </div>

              <div class="my-6 pb-6">
                <p class="semibold--text primary--text font-size--18">OPTIONS</p>
                <div class="d-flex">
                  <v-switch v-model="option_snp" label="Module SNP" color="green" class="mt-0 pt-0" hide-details></v-switch>
                  <span v-if="option_snp_date" class="font-italic align-self-center ml-2 font-size--10">(mise à jour le {{ option_snp_date|datetime }})</span>
                </div>
                <div class="d-flex mt-4">
                  <v-switch v-model="option_ddi" label="Module DDI" color="green" class="mt-0 pt-0" hide-details></v-switch>
                  <span v-if="option_ddi_date" class="font-italic align-self-center ml-2 font-size--10">(mise à jour le {{ option_ddi_date|datetime }})</span>
                </div>
                <div class="d-flex mt-4">
                  <v-switch v-model="option_pt" label="Module Protocoles" color="green" class="mt-0 pt-0" hide-details></v-switch>
                  <span v-if="option_pt_date" class="font-italic align-self-center ml-2 font-size--10">(mise à jour le {{ option_pt_date|datetime }})</span>
                </div>
                <div class="d-flex mt-4">
                  <v-switch v-model="option_waiting_list" label="Module Liste d'attente" color="green" class="mt-0 pt-0" hide-details></v-switch>
                  <span v-if="option_waiting_list_date" class="font-italic align-self-center ml-2 font-size--10">(mise à jour le {{ option_waiting_list_date|datetime }})</span>
                </div>
                <div class="d-flex mt-4">
                  <v-switch v-model="option_tlc" label="Module TLC" color="green" class="mt-0 pt-0" hide-details></v-switch>
                  <span v-if="option_tlc_date" class="font-italic align-self-center ml-2 font-size--10">(mise à jour le {{ option_tlc_date|datetime }})</span>
                </div>
                <div class="d-flex mt-4">
                  <v-switch v-model="option_medical_record" label="Module Dossier médical" color="green" class="mt-0 pt-0" hide-details></v-switch>
                  <span v-if="option_medical_record_date" class="font-italic align-self-center ml-2 font-size--10">(mise à jour le {{ option_medical_record_date|datetime }})</span>
                </div>
                <div class="d-flex mt-4">
                  <v-switch v-model="option_communication" label="Module Communication" color="green" class="mt-0 pt-0" hide-details></v-switch>
                  <span v-if="option_communication_date" class="font-italic align-self-center ml-2 font-size--10">(mise à jour le {{ option_communication_date|datetime }})</span>
                </div>
                <div class="d-flex mt-4">
                  <v-switch v-model="option_traceability_home_survey" label="Module Suivi à domicile" color="green" class="mt-0 pt-0" hide-details></v-switch>
                  <span v-if="option_traceability_home_survey_date" class="font-italic align-self-center ml-2 font-size--10">(mise à jour le {{ option_home_survey_date|datetime }})</span>
                </div>
              </div>
            </v-col>
          </v-row>

          <p class="semibold--text font-size--18 mb-1 mt-6 mt-sm-10 primary--text">Demandes Interventions</p>
          <v-divider class="primary"></v-divider>
          <v-row class="mx-0 mt-2">
            <v-col cols="12">
              <div class="d-flex">
                <p class="primary_dark--text semibold--text font-size--16 mb-0">Codes Postaux pour la prise en charge des demandes d'intervention :</p>
                <v-spacer></v-spacer>
                <v-btn color="primary" small elevation="0" @click="add_city=!add_city">
                  <v-icon class="mr-1" small>mdi-map-marker-plus</v-icon>
                  <span class="medium--text font-size--14">Ajouter un code postal</span>
                </v-btn>
              </div>
              <div>
                <v-expand-transition>
                  <AnSearchAddressGoogleAPI v-if="add_city"
                                            ref="search_cities"
                                            :search_only_cities="true"
                                            class_input="filled-input"
                                            placeholder="Entrez un code postal"
                                            v-on:address_selected="add_city_to_hf($event)"/>
                </v-expand-transition>
                <v-alert v-model="alert_zipcode" type="warning" text dense class="mt-2" transition="scale-transition">
                  Ajouter la ville via le code postal
                </v-alert>
              </div>
            </v-col>
            <v-col cols="12">
              <p v-if="!cities || Object.keys(cities).length === 0" class="font-italic">Aucune ville n'est renseignée</p>
              <div style="max-height: 200px; overflow-y: auto">
                <v-list dense>
                  <v-list-item v-for="(item, key) in cities" :key="key" style="min-height: 35px">
                    <v-list-item-icon class="mr-2 my-0 align-self-center">
                      <v-icon color="primary">mdi-circle-small</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="py-0 align-self-center">
                      <v-list-item-title class="primary_dark--text item_list">{{ key }} ({{ item }})</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="my-0 align-self-center">
                      <v-btn icon small color="pastel_red" @click="remove_city_to_hf(key)">
                        <v-icon small>mdi-close-circle</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </div>
            </v-col>
          </v-row>

          <p class="semibold--text font-size--18 mb-1 mt-6 mt-sm-10 primary--text">Liste d'attente</p>
          <v-divider class="primary"></v-divider>
          <v-row class="mx-0 my-2">
            <v-col cols="12">
              <div class="d-flex">
                <p class="primary_dark--text semibold--text font-size--16 mb-0 align-self-center">Régions ({{ regions_managed ? regions_managed.length : "0" }})</p>
                <v-spacer></v-spacer>
                <v-btn elevation="0" small color="primary" @click="show_add_region = !show_add_region">
                  <v-icon small class="mr-1">mdi-map-marker-plus</v-icon>
                  <span class="medium--text">Ajouter une région</span>
                </v-btn>
              </div>
              <v-select v-if="show_add_region"
                        :items="all_regions"
                        item-text="libelle" item-value="reg"
                        filled dense placeholder="Sélectionner une région"
                        @change="add_region($event); show_add_region=false">
              </v-select>
              <p v-if="!regions_managed || Object.keys(regions_managed).length === 0" class="font-italic">Aucune région n'est renseignée</p>
              <div>
                <v-chip v-for="(item, key) in regions_managed" :key="key"
                        small close outlined
                        class="ma-1"
                        @click:close="remove_region(item)">
                  {{ item }} - {{ get_region_name(item) }}
                </v-chip>
              </div>

              <div class="d-flex mt-4 mb-2">
                <p class="primary_dark--text semibold--text font-size--16 mb-0 align-self-center">Codes communes ({{ communes_managed ? communes_managed.length : "0" }})</p>
                <v-spacer></v-spacer>
                <v-btn elevation="0" small color="primary" @click="show_add_communes = !show_add_communes">
                  <v-icon small class="mr-1">mdi-map-marker-plus</v-icon>
                  <span class="medium--text">Ajouter une commune</span>
                </v-btn>
              </div>
              <v-autocomplete v-if="show_add_communes"
                              :items="all_communes"
                              :item-text="(item) => (`${item.com} - ${item.libelle}`)" item-value="com"
                              filled dense placeholder="Sélectionner une commune"
                              @change="add_commune($event); show_add_communes=false">
              </v-autocomplete>

              <p v-if="!communes_managed || Object.keys(communes_managed).length === 0" class="font-italic">Aucun code commune n'est renseigné</p>

              <div style="max-height: 200px; overflow-y: auto">
                <v-chip v-for="(item, key) in communes_managed_sorted" :key="key"
                        small close outlined
                        class="ma-1"
                        @click:close="remove_commune(item); show_add_communes = false">
                  {{ item }} - {{ get_commune_name(item) }}
                </v-chip>
              </div>

              <div class="mt-6">
                <p class="primary_dark--text semibold--text font-size--16 mb-0 align-self-center">Lien accès public :</p>
                <a :href="url_waiting_list" target="_blank"
                   class="primary_dark--text medium--text font-size--14 text-decoration-underline">
                  {{ url_waiting_list }}
                </a>
                <v-btn icon color="primary" class="ml-2" @click="copy_link()" small>
                  <v-icon small>mdi-content-copy</v-icon>
                </v-btn>
                <v-snackbar v-model="success_copy">
                  <v-icon color="green" class="mr-4">mdi-check</v-icon>
                  <span class="medium--text font-size--16">Lien copié</span>
                </v-snackbar>
              </div>
            </v-col>
          </v-row>

          <p class="semibold--text font-size--18 mb-1 mt-6 mt-sm-10 primary--text">Protocoles</p>
          <v-divider class="primary"></v-divider>
          <v-row class="mx-0 mt-2">
            <v-col cols="12" v-if="!hf_data.settings_protocols || hf_data.settings_protocols.length === 0">
              <p class="font-italic">Aucun protocole pour cette structure</p>
            </v-col>
            <v-col cols="12" sm="3" v-for="(item, key) in hf_data.settings_protocols" :key="key">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="primary_dark--text medium--text">{{ item.meta_protocol.commercial_name }}</v-list-item-title>
                    <v-list-item-subtitle>Nb. délégués : {{ item.delegate_professionals.length }}</v-list-item-subtitle>
                    <v-list-item-subtitle>Nb. délégants : {{ item.delegating_professionals.length }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="12" class="text-center mt-10">
        <v-alert v-model="alert_error" type="error" text dense class="mt-3" transition="scale-transition">
          {{ msg_error }}
        </v-alert>
        <v-btn elevation="0" large :disabled="!valid_form" color="green" class="white--text px-10" :loading="loading_save" @click="patch_hf_coordination()">
          <v-icon class="mr-4">mdi-content-save</v-icon>
          <span class="semibold--text font-size--18">Enregistrer</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AnSearchAddressGoogleAPI from "anam-library/src/components/an-ui/an-address/AnSearchAddressGoogleAPI";

import {mixin_dates} from "anam-library/src/js/dates";
import rules from "anam-library/src/js/rules";
import {get_dict_formated_address_from_google_search, get_formated_address} from "anam-library/src/js/address";
import {get_url_waiting_list} from "coordination-library/src/modules/waiting_list";

export default {
  name: "ManagerSettingsCPTSView",
  components: {AnSearchAddressGoogleAPI},
  mixins: [mixin_dates],
  data: () => ({
    loading: false,
    hf_data: {},
    valid_form: false,
    rules: rules,
    commercial_name: null,
    finess_number: null,
    address: {},
    address_str: null,
    region: null,
    is_client: false,
    is_published: false,
    option_snp: false,
    option_snp_date: null,
    option_ddi: false,
    option_ddi_date: null,
    option_pt: false,
    option_pt_date: null,
    option_waiting_list: false,
    option_waiting_list_date: null,
    option_tlc: false,
    option_tlc_date: null,
    option_traceability_home_survey: false,
    option_traceability_home_survey_date: null,
    option_medical_record: false,
    option_medical_record_date: null,
    option_communication: false,
    option_communication_date: null,
    cities: {},
    add_city: false,
    alert_zipcode: false,
    regions_managed: [],
    communes_managed: [],
    show_add_region: false,
    all_regions: [],
    show_add_communes: false,
    all_communes: [],
    success_copy: false,
    loading_save: false,
    alert_error: false,
    msg_error: null
  }),
  created() {
    this.$store.state.is_mounted_app = true;
    this.loading = true;
    this.$http.get(`/api/hf_coordination/${this.hf_id}?settings_protocols=true`).then(response => {
      this.hf_data = response.data;
      this.set_data_back_to_front();
    }).finally(() => {
      this.loading = false;
    });

    this.all_regions = [];
    this.$http.get("/api/regions-insee/").then(response => {
      this.all_regions = response.data
    })
  },
  computed: {
    hf_id: function() {
      return this.$route.params.hf_id;
    },
    hf_name: function() {
      return this.hf_data.commercial_name
    },
    communes_managed_sorted: function() {
      let tmp = JSON.parse(JSON.stringify(this.communes_managed));
      if(tmp) return tmp.sort((a, b) => a.localeCompare(b))
      else return [];
    },
    url_waiting_list: function() {
      return get_url_waiting_list(this.hf_id);
    }
  },
  watch: {
    regions_managed: function(val) {
      this.all_communes = [];
      if(val && val.length > 0) {
        for(let el of val) {
          this.$http.get(`/api/communes-insee?reg=${el}`).then(response => {
            this.all_communes = this.all_communes.concat(response.data);
          })
        }
      }
    }
  },
  methods: {
    set_data_back_to_front: function() {
      let data = JSON.parse(JSON.stringify(this.hf_data));
      this.commercial_name = data.commercial_name;
      this.address_str = get_formated_address(data.address);
      this.region = data.region;
      this.finess_number = data.id_structure;
      this.is_client = data.is_client;
      this.is_published = data.is_published;
      this.option_snp = data.option_snp;
      this.option_snp_date = data.option_snp_date_update;
      this.option_ddi = data.option_ddi;
      this.option_ddi_date = data.option_ddi_date_update;
      this.option_pt = data.option_protocoles;
      this.option_pt_date = data.option_protocoles_date_update;
      this.option_waiting_list = data.option_waiting_list;
      this.option_waiting_list_date = data.option_waiting_list_date_update;
      this.option_tlc = data.option_tlc;
      this.option_tlc_date = data.option_tlc_date_update;
      this.option_traceability_home_survey = data.option_traceability_home_survey;
      this.option_traceability_home_survey_date = data.option_traceability_home_survey_date_update;
      this.option_medical_record = data.option_medical_record;
      this.option_medical_record_date = data.option_medical_record_date_update;
      this.option_communication = data.option_communication;
      this.option_communication_date = data.option_communication_date_update;
      this.cities = data.cities_caring_for;
      this.regions_managed = data.regions_managed;
      this.communes_managed = data.communes_managed;
    },
    set_address: function(val) {
      this.address = get_dict_formated_address_from_google_search(val);
    },
    add_city_to_hf: function(place) {
      this.alert_zipcode = false;
      let infos = place.address_components;
      if(infos) {
        let index_zipcode = infos.findIndex(x => x.types.includes('postal_code'));
        if(index_zipcode > -1) {
          let temp = JSON.parse(JSON.stringify(this.cities));
          let zipcode = infos[index_zipcode].long_name;
          let city = "";
          let index_city = infos.findIndex(x => x.types.includes('locality'));
          if(index_city > -1) {
            city = infos[index_city].long_name;
          }
          temp[zipcode] = city;
          this.cities = temp;
          this.$refs.search_cities.reset_search();
        } else {
          this.alert_zipcode = true;
        }
      }
    },
    remove_city_to_hf: function(key) {
      let temp = JSON.parse(JSON.stringify(this.cities));
      delete temp[key];
      this.cities = temp;
    },
    add_region: function(val) {
      if(!this.regions_managed) this.regions_managed = [val];
      else {
        let index = this.regions_managed.findIndex(x => x === val.toString());
        if(index === -1) this.regions_managed.push(val);
      }
    },
    remove_region: function(val) {
      let index = this.regions_managed.findIndex(x => x === val.toString());
      if(index > -1) this.regions_managed.splice(index, 1);
    },
    add_commune: function(val) {
      if(!this.communes_managed) this.communes_managed = [val];
      else {
        let index = this.communes_managed.findIndex(x => x === val.toString());
        if(index === -1) this.communes_managed.push(val);
      }
    },
    remove_commune: function(val) {
      let index = this.communes_managed.findIndex(x => x === val.toString());
      if(index > -1) this.communes_managed.splice(index, 1);
    },
    get_region_name: function(val) {
      let index = this.all_regions.findIndex(x => x.reg === val);
      if(index > -1) return this.all_regions[index].libelle;
      else return "";
    },
    get_commune_name: function(val) {
      let index = this.all_communes.findIndex(x => x.com === val);
      if(index > -1) return this.all_communes[index].libelle;
      else return "";
    },
    copy_link: function() {
      navigator.clipboard.writeText(this.url_waiting_list);
      this.success_copy = true;
    },
    patch_hf_coordination: function() {
      this.loading_save = true;
      this.alert_error = false;
      this.msg_error = null;

      let data = {
        of_type: "cpts",
        commercial_name: this.commercial_name,
        is_published: this.is_published,
        is_client: this.is_client,
        region: this.region,
        id_structure: this.finess_number,
        ...(this.finess_number ? {typeIdNat_structure:'1'}: {}),
        cities_caring_for: this.cities,
        communes_managed: this.communes_managed,
        regions_managed: this.regions_managed,
        option_snp: this.option_snp,
        option_ddi: this.option_ddi,
        option_protocoles: this.option_pt,
        option_waiting_list: this.option_waiting_list,
        option_tlc: this.option_tlc,
        option_medical_record: this.option_medical_record,
        option_communication: this.option_communication,
        option_traceability_home_survey: this.option_traceability_home_survey
      };

      if (this.address) data.address = this.address;

      this.$http.patch(`/api/hf_coordination/${this.hf_id}`, data).then(() => {
        this.$store.commit("PostSuccess", "Modifications enregistrées")
      }).catch(error => {
        if(error.response.status === 422) {
          this.alert_error = true;
          if(Object.keys(error.response.data).includes('name')) {
            this.msg_error = "Le nom de la structure existe déjà, merci d'en renseigner un nouveau";
          } else if(Object.keys(error.response.data).includes('cities_caring_for')) {
            this.msg_error = error.response.data["cities_caring_for"].join();
          } else {
            this.msg_error = "L'un des champs est invalide, nous ne pouvons pas enregistrer votre demande";
          }
          return;
        }
      }).finally(() => {
        this.loading_save = false;
      })
    }
  }
}
</script>

<style scoped>

</style>