<template>
  <v-col cols="12" class="mt-8">
    <div :class="$vuetify.breakpoint.smAndUp ? 'my-3 d-flex' : 'mb-3'">
      <p :class="`mb-0 semibold--text primary_dark--text font-size--24 align-self-center`">
        {{ protocol_commercial_name }} - {{ title }}
      </p>
      <div class="align-self-center mx-2">
         <v-menu location="end" offset-y open-on-hover>
           <template v-slot:activator="{ on, attrs }">
             <v-btn text color="primary" v-bind="attrs" v-on="on">
               <span class="medium--text font-size--16 text-decoration-underline">Actions</span>
             </v-btn>
           </template>
           <v-list dense>
             <v-list-item v-if="is_has_protocol" @click="dialog_send_questionnaire=true" :loading="loading_send_questionnaire">
               <v-list-item-title>Envoyer un questionnaire de satisfaction</v-list-item-title>
             </v-list-item>
             <v-list-item @click="generate_xlsx()" :loading="loading_csv">
               <v-list-item-title>Exporter en .XLSX</v-list-item-title>
             </v-list-item>
             <v-list-item @click="generate_csv()" :loading="loading_csv">
               <v-list-item-title>Exporter en .CSV</v-list-item-title>
             </v-list-item>
           </v-list>
         </v-menu>
      </div>
      <slot name="append-action"></slot>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" hide-details dense clearable color="primary" label="Rechercher">
        <template v-slot:prepend-inner>
          <v-icon color="primary">mdi-magnify</v-icon>
        </template>
      </v-text-field>
    </div>
    <slot name="subtitle"></slot>
    <AnDataTable :headers="headers"
                 :items="items"
                 :loading="loading"
                 :search="search"
                 :table_items_mapping="table_items_mapping"
                 :option_selection="is_has_protocol"
                 :items_selected.sync="items_selected"
                 elevation="0"
                 no_data_txt="Aucun professionnel" class="mt-4 mt-sm-6"
                 v-on:action="$emit('action', $event)"/>

    <AnDialogComponent v-if="is_has_protocol" :dialog.sync="dialog_send_questionnaire" title="Envoyer un questionnaire de satisfaction" :actions="true">
      <template v-slot:card-text>
        <p v-if="disabled_send_questionnaire">Vous devez au préalable sélectionner les professionnels auxquels vous souhaitez envoyer un questionnaire.</p>
        <p v-else class="primary_dark--text font-size--16">Souhaitez vous envoyer un questionnaire de satisfaction aux <span class="font-size--16 semibold--text">{{ items_selected.length }}</span> professionnel(s) sélectionné(s) ?</p>
      </template>
      <template v-slot:card-actions>
        <v-btn class="px-6 white--text" text rounded color="pastel_red" elevation="0" large @click="dialog_send_questionnaire=false;items_selected=[]">
          <span class="font-size--16 medium--text text-decoration-underline">Annuler</span>
        </v-btn>
        <v-btn :disabled="disabled_send_questionnaire" class="px-6 white--text" rounded color="green" elevation="0" large @click="send_questionnaire()" :loading="loading_send_questionnaire">
          <span class="font-size--16 medium--text">Valider</span>
        </v-btn>
      </template>
    </AnDialogComponent>
  </v-col>
</template>

<script>
import AnDialogComponent from "anam-library/src/components/an-ui/an-dialog/AnDialogComponent"
import AnDataTable from "anam-library/src/components/an-data-table/AnDataTable";
import CheckItem from "anam-library/src/components/an-dashboard/an-dashboard-table-items/CheckItem";
import DmpProItem from "anam-library/src/components/an-data-table/an-table-items-components/DmpProItem";
import NbDelegatingTableItem from "./table-items/NbDelegatingTableItem";
import ActionAssociateDelegatingTableItem from "./table-items/ActionAssociateDelegatingTableItem";

import {get_period_text_for_export} from "coordination-library/src/js/utils";
import {get_profession_name} from "anam-library/src/data/professions";
import {generate_csv} from "anam-library/src/js/export_file";
import {generateXlsx} from "anam-library/src/js/export_xlsx";

export default {
  name: "ManagerProtocolsProfessionalsTable",
  components: {
    AnDataTable,
    AnDialogComponent,
  },
  props: {
    app_name: {
      type: String,
      required: true
    },
    protocol_name: {
      type: String,
      default: null
    },
    protocol_commercial_name: {
      type: String,
      default: null
    },
    is_has_protocol: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    filter_date_start: {
      type: String,
      default: null
    },
    filter_date_end: {
      type: String,
      default: null
    },
    questionnaire_type: {
      type: String,
      required: true,
    }
  },
  data: () => ({
    items_selected: [],
    loading_send_questionnaire: false,
    dialog_send_questionnaire: false,
    loading_csv: false,
    search: null,
    table_items_mapping: {
      "nb_delegating_associated": NbDelegatingTableItem,
      "nb_delegate_associated": NbDelegatingTableItem,
      "actions": ActionAssociateDelegatingTableItem,
      "dmp_type": DmpProItem,
      "active": CheckItem,
    }
  }),
  computed: {
    file_name_export: function(){
      return `protocoles_${this.title.toLowerCase()} ${get_period_text_for_export(this.filter_date_start, this.filter_date_end)}`
    },
    headers_xlsx_csv: function(){
      let headers = [];
      for (let header of this.headers.filter(x => x.value !== "actions")) {
        headers.push(header.text);
      }
      return headers
    },
    disabled_send_questionnaire: function() {
      return !this.items_selected || this.items_selected.length === 0;
    }
  },
  methods: {
    download_xlsx_csv: function() {
      this.loading_csv = true;
      let data_xlsx = [];
      for (let item of this.items) {
        let rowData = [];
        for (let header of this.headers.filter(x => x.value !== "actions")) {
          let txt = "";
          if (item[header.value] !== null) {
            if(header.value === "active") {
              if(item[header.value] ===  true) txt = "Oui";
              else if(item[header.value] === false) txt = "Non";
            } else if(header.value === "dmp_type") {
              txt = get_profession_name(item[header.value]);
            } else {
              if(item[header.value]) txt = item[header.value].toString();
              else txt = item[header.value]
            }
          } else {
            txt = "N/A";
          }
          rowData.push(txt)
        }
        data_xlsx.push(rowData);
      }
      this.loading_csv = false;
      return data_xlsx
    },
    generate_csv: function() {
      generate_csv(this.download_xlsx_csv(), this.headers_xlsx_csv, this.file_name_export, false);
    },
    generate_xlsx: function() {
      generateXlsx(this.download_xlsx_csv(), this.headers_xlsx_csv, this.file_name_export, this.file_name_export);
    },
    send_questionnaire: function() {
      this.loading_send_questionnaire = true;
      let users_id = []
      for(let el of this.items_selected) {
        users_id.push(el.user_id);
      }
      let data = {
        app_name: this.app_name,
        questionnaire: this.questionnaire_type,
        ids_selected: users_id,
        additional_data: {
          protocol_name: this.protocol_name
        }
      }
      this.$http.post("/api/mass-send-questionnaires-pro", data).then(() => {
        this.$store.commit("PostSuccess", "Email(s) envoyé(s)");
        this.items_selected = [];
      }).finally(() => {
        this.loading_send_questionnaire = false;
        this.dialog_send_questionnaire = false;
      })
    }
  }
}
</script>

<style scoped>

</style>