<template>
  <v-btn @click="go_to_historic_protocoles()" color="bg_primary" class="primary--text" elevation="0" rounded>
    <span class="semibold--text">Accès</span>
    <v-icon class="ml-1" small>mdi-arrow-right</v-icon>
  </v-btn>
</template>

<script>

export default {
  name: "AccessProtocolItem",
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    variable: {
      type: String,
      default: null
    }
  },
  methods: {
    go_to_historic_protocoles: function() {
      this.$router.push({name: "DoctorProtocolesHistory", params: {patient_id: this.item.patient_id.toString()}})
    }
  }
}
</script>

<style scoped>

</style>