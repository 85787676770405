<template>
  <v-menu v-model="menu_notif" :close-on-content-click="false" offset-y :max-width="$vuetify.breakpoint.smAndUp ? 400 : 250" z-index="20">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon text color="primary" class="mr-0 mr-sm-2" :small="$vuetify.breakpoint.xsOnly">
        <v-badge color="pastel_red" bordered overlap
                 :content="$store.getters.getNumberUnreadNotifications"
                 :value="$store.getters.getNumberUnreadNotifications">
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-list v-if="$store.getters.getNotifications.length > 0" style="max-height: 600px; overflow-y: auto;">
      <v-list-item class="font-italic">
        <v-btn text @click="mark_all_as_read()" class="pr-2">Marquer toutes les notifications comme lues</v-btn>
        <v-divider vertical class="my-3 mr-2 ml-1"></v-divider>
        <v-btn text icon @click="menu_notif = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-list-item>
      <v-list-item v-for="(notif, key) in $store.getters.getNotifications" :key="key" :class="get_notif_class(notif)">
        <v-tooltip bottom v-if="notif.is_unread">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" small color="blue" @click="mark_read(notif, true)">mdi-circle</v-icon>
          </template>
          <span>Marquer comme lue</span>
        </v-tooltip>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" small @click="mark_read(notif, false)">mdi-circle-outline</v-icon>
          </template>
          <span>Marquer comme non lue</span>
        </v-tooltip>
        <v-list-item-content>
          <v-list-item-title
              style="cursor:pointer; overflow-wrap: anywhere;"
              :class="get_notif_class(notif) + ' mx-2 font-weight-bold'"
              @click="notification_link(notif)"
          >
            {{notif.data.main_text}}
          </v-list-item-title>
          <v-list-item-subtitle
              style="cursor:pointer; overflow-wrap: anywhere;"
              :class="get_notif_class(notif) + ' mx-2'"
              @click="notification_link(notif)"
          >
            {{notif.data.subtext}}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list v-else>
      <v-list-item>
        <v-list-item-content>
          Aucune notification
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>

export default {
  name: "NotificationsMenu",
  data: () => ({
    menu_notif: false,
  }),
  methods: {
    get_notif_class(notif) {
      if (notif.is_unread) return "primary white--text";
      return "white";
    },
    notification_link(notif) {
      let previous_route_name = this.$router.currentRoute.name;
      if (notif.data.url) {
        if (notif.is_unread) this.mark_read(notif, true);
        this.$router.push(notif.data.url);
        if (this.$router.currentRoute.name === previous_route_name) window.location.reload();
      }
    },
    mark_read(notif, is_read) {
      this.$http.patch("/api/notifications/" + notif.id, { is_unread: !is_read }).then(() => {
        notif.is_unread = !is_read;
      })
    },
    mark_all_as_read() {
      this.$http.patch("/api/notifications").then(response => {
        this.$store.commit("setNotifications", response.data);
      })
    }
  }
}
</script>