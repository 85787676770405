<template>
  <CoNotesLayout></CoNotesLayout>
</template>

<script>
import CoNotesLayout from "coordination-library/src/components/doctor/notes/CoNotesLayout";

export default {
        name: "NotesLayout",
        components: {
            CoNotesLayout
        }
    }
</script>