<template>
  <div v-if="!phone_format">
    <div v-if="tag">
      <span class="normal--text">{{ tag.creation_date|date }}</span>
      <br>
      <span class="normal--text">Depuis {{ duration }} jours</span>
    </div>
    <v-icon v-else small>mdi-minus</v-icon>
  </div>
</template>

<script>
import {mixin_dates} from "anam-library/src/js/dates";
import moment from "moment-timezone";
import {get_data_waiting_list} from "coordination-library/src/modules/waiting_list";

export default {
  name: "WaitingListInscriptionItem",
  mixins: [mixin_dates],
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    variable: {
      type: String,
      default: null
    },
    phone_format: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    data_waiting_list: function() {
      return get_data_waiting_list(this.item.waiting_list_of_type);
    },
    tag_name : function() {
      return this.data_waiting_list.tag;
    },
    duration: function() {
      let now = moment(moment().format("YYYY-MM-DD"));
      let date_tag = moment(moment(this.tag.creation_date).format("YYYY-MM-DD"));
      return now.diff(date_tag, 'days');
    },
    tag: function() {
      if(this.data_waiting_list.of_type === '334') {
        if(this.item.tags_mt && this.item.tags_mt.length > 0) return this.item.tags_mt[0]
      } else {
        if(this.item.tags && this.item.tags.length > 0) {
          let index = this.item.tags.findIndex(x => x.meta_indicator.of_type === this.tag_name);
          if(index > -1) return this.item.tags[index];
        }
      }
      return null;
    }
  }
}
</script>

<style scoped>

</style>