<template>
  <v-app>
    <v-app-bar color="white" elevation="2" app>
      <v-row class="align-center">
        <v-col cols="8" sm="4" md="2">
          <img width="150" :src="logo" alt="Logo"/>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-app-bar>
    <v-main class="bg_primary">
      <v-progress-linear :value="progression" height="8" color="secondary"></v-progress-linear>
      <v-window v-model="onboarding">
        <RegistryStepProfession :value="0"
                                v-on:update_profession="profession_selected=$event"
                                v-on:next="next_profession()"/>

        <RegistryStepIDPP :value="1"
                          :profession="profession_selected"
                          v-on:professional_cat18="professional_cat18=$event"
                          v-on:set_idpp="idpp=$event"
                          v-on:back="back_rpps()"
                          v-on:next="next_rpps()"/>

        <RegistryStepForm :value="2"
                          :professional_cat18="professional_cat18"
                          :profession_selected="profession_selected"
                          :idpp="idpp"
                          v-on:back="back_form()"
                          v-on:next="next_form()"/>
      </v-window>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import Footer from "@/components/footer/Footer.vue";
import RegistryStepProfession from "@/components/public/registry/steps/RegistryStepProfession.vue";
import RegistryStepIDPP from "@/components/public/registry/steps/RegistryStepIDPP.vue";
import RegistryStepForm from "@/components/public/registry/steps/RegistryStepForm.vue";

import {PROFESSIONS_WITH_RPPS, PROFESSIONS_WITH_ADELI} from "anam-library/src/data/professions";

export default {
  name: "RegistryProfessionalView",
  components: {RegistryStepForm, Footer, RegistryStepProfession, RegistryStepIDPP},
  data: () => ({
    onboarding: 0,
    profession_selected: null,
    professional_cat18: {},
    idpp: null,
    logo: "https://www.anamnese.care/hubfs/config/Citana/inzeecare/logo/logo-2022-citana-couleur-BL.png"
  }),
  created() {
    this.go_to_top_page();
  },
  computed: {
    progression: function() {
      let total_steps = 3;
      let current_step = this.onboarding + 1;
      return (current_step/total_steps)*100;
    },
    is_profession_with_idpp: function() {
      return PROFESSIONS_WITH_RPPS.includes(this.profession_selected) || PROFESSIONS_WITH_ADELI.includes(this.profession_selected);
    },
  },
  watch: {
    onboarding: function(val) {
      if(val === 0) this.reset_data();
    }
  },
  methods: {
    go_to_top_page: function() {
      this.$vuetify.goTo(0, {'duration': 500, 'offset': 60, 'easing': 'easeOutQuad'});
    },
    next_profession: function() {
      if(this.is_profession_with_idpp) this.onboarding = 1;
      else this.onboarding = 2;
      this.go_to_top_page();
    },
    back_rpps: function() {
      this.onboarding = 0;
      this.go_to_top_page();
    },
    next_rpps: function() {
      this.onboarding = 2;
      this.go_to_top_page();
    },
    back_form: function() {
      if(this.is_profession_with_idpp) this.onboarding = 1;
      else this.onboarding = 0;
      this.go_to_top_page();
    },
    next_form: function() {
      this.$store.commit("setAgencyName", "inzeecare");
      this.$store.commit("setAppName", "inzeecare");
      this.$keycloak.login({redirectUri: `${window.location.origin}/redirection`});
    },
    reset_data: function() {
      this.profession_selected = null;
      this.professional_cat18 = {};
      this.idpp = null;
    }
  }
}
</script>

<style>
.textfield-solo-elevation-0 .v-input__slot{
  box-shadow: unset !important;
  border-radius: 10px !important;
}
</style>