<template>
  <v-row class="mx-0">
    <v-col cols="12">
      <DashboardDemandsAdminComponent :hf_coordination_id="hfcoordination_id"
                                      :items="_items"
                                      :app_name="app_name"
                                      :loading="loading"
                                      :admin_view="true"
                                      :color_txt="color_txt"
                                      :loading_members="loading_members"
                                      :hf_name="hf_name"
                                      v-on:open_expand_dialog="open_expand_dialog($event)"
                                      v-on:open_search_pro="open_search_pro($event)"
                                      v-on:open_close_dialog="open_close_dialog($event)"
                                      v-on:open_settings_demands="dialog_settings_demands=true"/>
    </v-col>

    <CoDialogSearchPro :dialog.sync="dialog_search_pro"
                       dialog_title="Affecter la demande à un professionnel"
                       :map_professions="profession_to_search"
                       :search_all_professions="false"
                       :emit="true"
                       v-on:doctor_clicked="check_if_pds_in_cpts($event)"
                       v-on:close="close_dialog_search_pro()"/>

    <DialogExpandDemand :dialog.sync="dialog_expand_demand"
                        :hf_id="hfcoordination_id"
                        :members="members"
                        :demand="demand_selected"
                        :color_txt="color_txt"
                        v-on:expand_demand="expand_demand($event)"/>

    <AnDialogConfirmation :dialog.sync="dialog_add_pds_to_cpts"
                          icon="mdi-account-plus"
                          title="Ajouter le professionnel à la structure ?"
                          question=""
                          txt_btn_confirm="Oui"
                          txt_btn_cancel="Non"
                          title_color="primary_dark"
                          question_color="primary_dark"
                          v-on:cancel="dialog_add_pds_to_cpts = false;"
                          v-on:confirm="add_pro_in_cpts()">
      <template v-slot:additional-content>
        <p :class="`${color_txt}--text font-size--16 medium--text`">Pour affecter la demande d’intervention à ce praticien, il doit être rattaché à votre structure.</p>
        <p :class="`${color_txt}--text font-size--16`">
          En cliquant sur oui, le professionnel sera
          <span class="semibold--text font-size--16">affecté à la demande d'intervention</span> et automatiquement
          <span class="semibold--text font-size--16">ajouté à votre structure</span> afin qu’il puisse consulter cette demande d’intervention.
        </p>
      </template>
    </AnDialogConfirmation>

    <CoDDIClosedDialog :dialog.sync="dialog_close_ddi"
                       :demand="demand_selected ? demand_selected : {}"
                       :app_name="app_name"
                       :color_txt="color_txt"
                       v-on:update_demand="update_demand($event)"/>

    <DialogSettingsDemands :dialog.sync="dialog_settings_demands"
                           :hfcoordination_id="hfcoordination_id"
                           :hf_cities_caring_for="hf_cities_caring_for"
                           :color_title="color_title"
                           :color_txt="color_txt"/>
  </v-row>
</template>

<script>
import CoDialogSearchPro from "coordination-library/src/components/doctor/common/CoDialogSearchPro";
import DialogExpandDemand from "./DialogExpandDemand";
import DashboardDemandsAdminComponent from "coordination-library/src/components/common/demand-intervention/DashboardDemandsAdminComponent";
import AnDialogConfirmation from "anam-library/src/components/an-dialogs/AnDialogConfirmation";
import DialogSettingsDemands from "./DialogSettingsDemands";
import CoDDIClosedDialog from "coordination-library/src/components/doctor/demand-intervention/common/CoDDIClosedDialog";

import axios from "axios";
import {MAP_ALL_PROFESSIONS, get_profession_name, MAP_PROFESSIONS_COORDINATION} from "anam-library/src/data/professions";
import {MAP_SPECIALITES} from "anam-library/src/data/professionnal_specialities";
import getCookie from "anam-library/src/js/common";

export default {
  name: "HistoricDemands",
  components: {
    CoDialogSearchPro,
    DialogExpandDemand,
    DashboardDemandsAdminComponent,
    AnDialogConfirmation,
    DialogSettingsDemands,
    CoDDIClosedDialog
  },
  props: {
    app_name: {
      type: String,
      required: true
    },
    hf_name: {
      type: String,
      default: null
    },
    members: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    loading_members: {
      type: Boolean,
      default: false
    },
    hf_cities_caring_for: {
      type: Object,
      default: () => {}
    },
    color_title: {
      type: String,
      default: 'primary'
    },
    color_txt: {
      type: String,
      default: 'primary_dark'
    }
  },
  data: () => ({
    dialog_search_pro: false,
    profession_to_search: [],
    professional_selected: {},
    demand_selected: null,
    dialog_expand_demand: false,
    dialog_add_pds_to_cpts: false,
    dialog_settings_demands: false,
    dialog_close_ddi: false,
  }),
  computed: {
    hfcoordination_id() {
      return this.$route.params.id
    },
    _items: {
      get: function() {
        return this.items;
      },
      set: function(val) {
        this.$emit("update:items", val);
      }
    },
  },
  watch: {
    dialog_add_pds_to_cpts: function(val) {
      if(!val) {
        this.professional_selected = {};
      }
    }
  },
  methods: {
    expand_demand: function(users_to_expand) {
      let data = {state: 2, users_to_expand: users_to_expand};
      this.patch_demand(this.demand_selected.id, data, 'Demande d\'intervention élargie aux professionnels sélectionnés');
    },
    close_dialog_search_pro: function() {
      this.dialog_search_pro = false;
      this.demand_selected = null;
      this.profession_to_search = []
    },
    check_if_pds_in_cpts: function(event) {
      this.professional_selected = event.professionnel
      let index = this.members.findIndex(x =>  x.professionnel.id === this.professional_selected.id && x.status === 2);
      if (index === -1) {
        this.dialog_add_pds_to_cpts = true;
      } else {
        this.link_pds_to_demand();
      }
    },
    add_pro_in_cpts: function() {
      let data = {
        id: this.professional_selected.id,
        action: "add",
        app_name: this.app_name
      };
      this.$http.put(`/api/hf_coordination/${this.hfcoordination_id}/health-practitioners`, data).then(() => {
        this.$emit('reload_data');
        this.link_pds_to_demand();
      }).catch(error => {
        if(error.response) {
          if(error.response.status === 417) {
            this.$store.commit('PostInformation', 'Professionnel déjà présent au sein de la structure');
            this.link_pds_to_demand();
          }
        }
      }).finally(() => {
        this.dialog_add_pds_to_cpts = false;
        this.dialog_search_pro = false;
      })
    },
    link_pds_to_demand: function() {
      this.dialog_search_pro = false;
      let data = {
        state: 3,
        professional: this.professional_selected.id
      }
      this.patch_demand(this.demand_selected.id, data, 'Professionnel affecté à la demande d\'intervention');
    },
    patch_demand: function(demand_id, data, msg_success) {
      axios.patch(`/api/demand-intervention/${demand_id}`, data, {
        params: {app_name: this.app_name},
        headers: {"X-CSRFToken": getCookie('csrftoken')}
      }).then(response => {
        this.update_demand(response.data)
        this.$store.commit('PostSuccess', msg_success);
      }).catch(error => {
        if(error.response.status) {
          this.$store.dispatch('ManageErrorLight', error.response.status);
          if(error.response.status === 422) {
            let msg = "";
            for (let el in error.response.data) {
              let temp = error.response.data[el];
              if(msg.length > 0) msg = `${msg} ; ${temp}`
              else msg = `${temp}`;
            }
            this.$store.commit('PostWarning', msg);
          } else {
            this.$store.commit('PostCommonError');
          }
        } else {
          this.$store.commit('PostCommonError');
        }
      }).finally(() => {
        this.dialog_expand_demand = false;
        this.close_dialog_search_pro();
      });
    },
    open_expand_dialog(demand) {
      this.demand_selected = demand;
      this.dialog_expand_demand = true;
    },
    open_search_pro: function(item) {
      this.demand_selected = item;
      let profession = null;
      if(item.speciality_code && Object.keys(MAP_SPECIALITES).includes(item.speciality_code)) profession = [MAP_SPECIALITES[item.speciality_code]];
      if(!profession && Object.keys(MAP_ALL_PROFESSIONS).includes(item.dmp_type)) profession = [MAP_ALL_PROFESSIONS[item.dmp_type]];
      if (!profession) profession = MAP_PROFESSIONS_COORDINATION();
      this.profession_to_search = profession;
      this.dialog_search_pro = true;
    },
    open_close_dialog(demand) {
      this.demand_selected = demand;
      this.dialog_close_ddi = true;
    },
    update_demand: function(data) {
      let temp = JSON.parse(JSON.stringify(this._items));
      let index = temp.findIndex(x => x.id === data.id);
      if (index > -1) temp[index] = data;
      this._items = temp;
    }
  },
  filters: {
    dmp_name: function(val) {
      return get_profession_name(val);
    }
  }
}
</script>

<style scoped>

</style>