<template>
  <v-card class="rounded-lg">
    <v-card-text>
      <v-row class="mx-0">
        <v-col cols="12" sm="8">
          <div>
            <v-icon color="primary">mdi-tune-variant</v-icon>
            <span class="align-self-center primary--text semibold--text font-size--16 ml-2 mr-6">Filtrer par période :</span>
          </div>
          <div :class="$vuetify.breakpoint.smAndUp ? 'd-flex' : ''">
            <AnDate :date="filter_date_start"
                    :filled="false"
                    reset_icon
                    label="Début période"
                    v-on:formatted_date="filter_date_start = $event"
                    v-on:date_reset="filter_date_start = null"/>

            <AnDate :date="filter_date_end"
                    :filled="false"
                    reset_icon
                    class="ml-sm-8"
                    label="Fin période"
                    v-on:formatted_date="filter_date_end = $event"
                    v-on:date_reset="filter_date_end = null"/>
          </div>
        </v-col>
        <v-progress-circular v-if="loading_stats" indeterminate color="primary_pastel"></v-progress-circular>
        <v-col cols="12" v-else>
          <v-alert v-if="error_stats" type="error" text transition="scale-transition">Une erreur est survenue au chargement des statistiques, veuillez nous excuser pour la gêne occasionnée.</v-alert>
          <apex-chart type="bar" height="400" :options="chartOptions" :series="data_stats"/>
        </v-col>
        <v-col cols="12">
          <div :class="$vuetify.breakpoint.smAndUp ? 'my-3 d-flex' : 'mb-3'">
            <p :class="`mb-0 semibold--text ${color_title}--text font-size--24 align-self-center`">
              <v-icon :color="color_title" class="mr-3" large>mdi-account-multiple</v-icon>
              Professionnels
              <br>
              <span class="font-italic font-size--12">Correspond aux tags ajoutés et retirés par les professionnels</span>
            </p>
            <v-spacer></v-spacer>
            <div class="align-self-center mr-0 mr-sm-6">
              <v-btn text color="primary" rounded small @click="export_csv()" :loading="loading_export">
                <v-icon small class="mr-2">mdi-download</v-icon>
                <span class="medium--text text-decoration-underline">Exporter en csv</span>
              </v-btn>
            </div>
            <v-text-field  v-model="search_table" hide-details clearable color="primary" label="Rechercher">
              <template v-slot:prepend-inner>
                <v-icon color="primary">mdi-magnify</v-icon>
              </template>
            </v-text-field>
          </div>
          <v-data-table :headers="headers_table"
                        :items="in_members"
                        :search="search_table"
                        :class="`elevation-2 data-table-stats ${color_txt}--text`"
                        fixed-header
                        :loading="loading_stats"
                        loading-text="Chargement en cours ..."
                        no-data-text="Aucun professionnel"
                        no-results-text="Aucun résultat"
                        :footer-props="{
                          itemsPerPageText: 'Lignes par page',
                           itemsPerPageAllText: 'Tous',
                           itemsPerPageOptions: [20, 100, 500, -1]
                        }"
                        :header-props="{sortByText: 'Trier par'}">

            <template v-slot:item="{ item }">
              <tr>
                <th>{{ item.last_name}}</th>
                <th>{{ item.first_name}}</th>
                <th>{{ item.profession }}</th>
                <th>{{ item.nb_tags_add }}</th>
                <th>{{ item.nb_tags_remove }}</th>
                <th v-for="(tag, key) in headers_tags" :key="key">
                  <div v-if="item[tag.value]" style="display: inline-grid">
                    <span>
                      <v-icon small>mdi-plus</v-icon>
                      {{ item[tag.value]["add"] }}
                    </span>
                    <span>
                      <v-icon small>mdi-minus</v-icon>
                      {{ item[tag.value]["remove"] }}
                    </span>
                  </div>
                </th>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import AnDate from "anam-library/src/components/an-date/AnDate";
import axios from "axios";
import moment from "moment-timezone";
import {downloadContentFront} from "anam-library/src/js/export_file";
import {get_profession_name} from "anam-library/src/data/professions";
import {get_period_text_for_export} from "coordination-library/src/js/utils";

export default {
  name: "ManagerStatisticsTags",
  components: {AnDate},
  props: {
    load_data: {
      type: Boolean,
      default: false
    },
    hf_coordination_id: {
      type: String,
      required: true
    },
    members: {
      type: Array,
      default: () => []
    },
    color_title: {
      type: String,
      default: 'primary'
    },
    color_txt: {
      type: String,
      default: 'primary_dark'
    }
  },
  data: () => ({
    is_loaded: false,
    filter_date_start: null,
    filter_date_end: null,
    headers_table: [
      {text: "Nom", value: "last_name"},
      {text: "Prénom", value: "first_name"},
      {text: "Profession", value: "profession"},
      {text: "Total ajouté", value: "nb_tags_add"},
      {text: "Total supprimé", value: "nb_tags_remove"},
    ],
    headers_tags: [],
    search_table: null,
    in_members: [],
    error_stats: false,
    loading_stats: false,
    chartOptions: {
      chart: {toolbar: {show: false}},
      xaxis: {categories: [['Nombre de patients', 'concernés sur la période'], ['Nombre de patients', 'cumulés sur la période']]},
      title: {text: '', align: 'center'}
    },
    loading_export: false,
    data_from_api: null,
    data_stats: [],
  }),
  watch: {
    load_data: function(val) {
      if(val && !this.is_loaded) this.get_statistics();
    },
    hf_coordination_id: function(val) {
      if(val) this.get_statistics();
    },
    filter_date_start: function() {
      this.display_data()
    },
    filter_date_end: function() {
      this.display_data();
    }
  },
  methods: {
    get_statistics: function () {
      this.loading_stats = true;
      this.error_stats = false;

      axios.get(`/api/hf-coordination/${this.hf_coordination_id}/tags-statistics`).then(response => {
        this.data_from_api = response.data;
        this.display_data();
      }).catch(error => {
        if (error.response) this.$store.dispatch('ManageErrorLight', error.response.status);
        this.error_stats = true;
      }).finally(() => {
        this.loading_stats = false;
        this.is_loaded = true;
      })
    },
    display_data() {
      this.chartOptions.colors = this.data_from_api.map(item => {
        return item.color
      });
      let data_stats = [];
      let temp = JSON.parse(JSON.stringify(this.members));
      for (let el of temp) {
        el.profession = get_profession_name(el.professionnel.dmp_type, el.professionnel.speciality_code);
        el.nb_tags_add = 0;
        el.nb_tags_remove = 0;
      }

      for (let meta_tag of this.data_from_api) {
        let type_tag = meta_tag.of_type;
        let index = this.headers_table.findIndex(x => x.value === type_tag);
        if (index === -1) {
          this.headers_table.push({'text': `Nb. tags ${meta_tag.commercial_name}`, 'value': type_tag})
          this.headers_tags.push({'text': `Nb. tags ${meta_tag.commercial_name}`, 'value': type_tag})
        }

        let mt_stats = meta_tag.statistics;
        if (this.filter_date_end) {
          mt_stats = mt_stats.filter(x => moment(x.start_date, "YYYY-MM-DD").isSameOrBefore(moment(this.filter_date_end, "DD/MM/YYYY")));
        }

        if (this.filter_date_start) {
          mt_stats = mt_stats.filter(x => {
            if (x.deletion_date) return moment(x.deletion_date, "YYYY-MM-DD").isSameOrAfter(moment(this.filter_date_start, "DD/MM/YYYY"));
            else return true;
          })
        }

        for (let tag of mt_stats) {
          let index = data_stats.findIndex(x => x.name === meta_tag.commercial_name);
          if (index === -1) {
            data_stats.push({
              name: meta_tag.commercial_name,
              data: [tag.deletion_date ? 0 : 1, 1]
            })
          } else {
            data_stats[index].data[0] += tag.deletion_date ? 0 : 1;
            data_stats[index].data[1] += 1;
          }

          let index_author = temp.findIndex(x => x.id === tag.author);
          if (index_author > -1) {
            temp[index_author].nb_tags_add += 1;
            if (!Object.keys(temp[index_author]).includes(type_tag)) temp[index_author][type_tag] = {add: 0, remove: 0};
            temp[index_author][type_tag].add += 1;
          }

          if (tag.author_deletion) {
            let index_deletion = temp.findIndex(x => x.id === tag.author_deletion);
            if (index_deletion > -1) {
              temp[index_deletion].nb_tags_remove += 1;
              if (!Object.keys(temp[index_deletion]).includes(type_tag)) temp[index_deletion][type_tag] = {
                add: 0,
                remove: 0
              };
              temp[index_deletion][type_tag].remove += 1;
            }
          }
        }
      }
      this.data_stats = data_stats;
      this.in_members = temp;
    },
    // Fonction pour exporter les donées du tableau professionnels/tags en .csv
    export_csv: function() {
      this.loading_export = true;

      let headers = JSON.parse(JSON.stringify(this.headers_table));
      let csvContent = "data:text/csv;charset=utf-8,";
      for (let header of headers) {
        csvContent += `${header.text},`;
      }
      csvContent += "\r\n";

      let items = JSON.parse(JSON.stringify(this.in_members));
      for (let item of items) {
        for (let header of headers) {
          let pro = item.professionnel;
          if(["profession"].includes(header.value) && pro.dmp_type) {
            item[header.value] = get_profession_name(pro.dmp_type, pro.speciality_code);
          } else if(header.text.includes("Nb. tags") && item[header.value]) {
            item[header.value] = `Ajouté : ${item[header.value].add} ; Supprimé : ${item[header.value].remove}`;
          }

          if (typeof item[header.value] === "string") item[header.value] = item[header.value].replace(/[,\n]/g, " ");
          if (item[header.value] === null || item[header.value] === undefined) item[header.value] = "";
          csvContent += item[header.value] + ",";
        }
        csvContent += "\r\n";
      }

      downloadContentFront(`export-professionnels-tags ${get_period_text_for_export(this.filter_date_start, this.filter_date_end)}`, csvContent, false);
      this.loading_export = false;
    }
  },
}
</script>

<style scoped>

</style>