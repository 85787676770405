<template>
  <AnDialogComponent :dialog.sync="_dialog"
                     title="Lancer un protocole"
                     :icon="$vuetify.breakpoint.smAndUp ? 'mdi-flag' : ''"
                     width="830">
    <template v-slot:card-text>

      <v-form v-model="valid_form_start_protocol" ref="form_protocol">
        <NewPtSelectProtocols :mt_protocol_selected.sync="mt_protocol_selected"/>
      </v-form>

      <NewPtAlertUserStart :mt_protocol_selected="mt_protocol_selected"
                           v-on:check_user_can_start_pt="current_user_can_start_pt=$event"/>

      <v-expand-transition>
        <div v-if="mt_protocol_selected && current_user_can_start_pt && !selected_patient">
          <v-expand-transition>
            <FormSearchPatient v-if="!new_patient"
                               ref="form_search_patient"
                               class="mt-6 mt-sm-0"
                               :option_search_ddn="true"
                               :option_create_patient="true"
                               v-on:selected_patient="set_dict_patient_selected($event)"
                               v-on:new_patient="new_patient=true"/>
          </v-expand-transition>

          <v-expand-transition>
            <div v-if="new_patient && !selected_patient">
              <p class="semibold--text primary_dark--text font-size--16 text-decoration-underline mb-10">
                Champs à remplir pour la création du patient :
              </p>
              <v-form v-model="valid_form_creation_patient" ref="form_creation_patient">
                <CoPatientEditComponent :create.sync="create_patient"
                                        :service_to_add_patient="services_to_add_patient"
                                        app_name="inzeecare"
                                        :min_infos="true"
                                        :show_missing_informations="true"
                                        :from_home_action="true"
                                        :show_address="true"
                                        :show_birth_location="true"
                                        v-on:patient_created="set_dict_patient_selected($event)"
                                        v-on:actions_finally_creation_patient="actions_finally_creation()"/>
              </v-form>
              <div class="text-center mt-10">
                <v-btn text rounded color="primary_dark" class="mx-2" :disabled="loading_creation_patient" @click="new_patient=false">
                  <span class="normal--text text-decoration-underline">Retour</span>
                </v-btn>
                <v-btn rounded elevation="0" color="green" class="mx-2 white--text px-6"
                       :loading="loading_creation_patient"
                       :disabled="!valid_form_creation_patient"
                       @click="create_patient=true">
                  <span class="medium--text">Suivant</span>
                </v-btn>
              </div>
            </div>
          </v-expand-transition>
        </div>
      </v-expand-transition>

      <v-expand-transition>
        <div v-if="selected_patient">
          <p class="normal--text font-size--16">
            Patient sélectionné :
            <span class="medium--text font-size--16 text-capitalize">{{ selected_patient.first_name }} </span>
            <span class="medium--text font-size--16 text-uppercase">{{ selected_patient.last_name }} </span>
            <span class="font-italic">
              ({{ selected_patient|sexe }} - {{ selected_patient|age }})
            </span>
          </p>

          <NewPtAlertPatientCondition :mt_protocol_selected="mt_protocol_selected"
                                      :patient_data="selected_patient"
                                      v-on:close_dialog="_dialog=false"
                                      v-on:check_patient_can_start_pt="can_start_pt=$event"/>

          <v-form v-model="valid_form_specifics_fields" ref="form_fields_protocol">
            <NewPtSpecificFields :mt_protocol_selected="mt_protocol_selected"
                                 :taille.sync="taille"
                                 :poids.sync="poids"/>
          </v-form>

          <div class="text-center">
            <v-btn color="primary_dark" text rounded class="mx-2" @click="selected_patient=null">
              <span class="text-decoration-underline normal--text">Retour</span>
            </v-btn>
            <NewPtBtnStart v-if="can_start_pt"
                           :mt_protocol_selected="mt_protocol_selected"
                           :patient_id="selected_patient.patient_id"
                           :valid_form="valid_form_start_protocol && valid_form_specifics_fields"
                           :can_start_pt="can_start_pt"
                           :current_user_can_start_pt="current_user_can_start_pt"
                           :taille="taille"
                           :poids="poids"
                           class="mx-2"/>
          </div>
        </div>
      </v-expand-transition>
    </template>
  </AnDialogComponent>
</template>

<script>
import AnDialogComponent from "anam-library/src/components/an-ui/an-dialog/AnDialogComponent";
import FormSearchPatient from "coordination-library/src/components/doctor/home/dialog/FormSearchPatient";
import CoPatientEditComponent from "coordination-library/src/components/doctor/patient-edit/CoPatientEditComponent";
import NewPtSelectProtocols from "coordination-library/src/components/doctor/protocoles/components-new-pt/NewPtSelectProtocols";
import NewPtAlertPatientCondition from "coordination-library/src/components/doctor/protocoles/components-new-pt/NewPtAlertPatientCondition";
import NewPtAlertUserStart from "coordination-library/src/components/doctor/protocoles/components-new-pt/NewPtAlertUserStart";
import NewPtBtnStart from "coordination-library/src/components/doctor/protocoles/components-new-pt/NewPtBtnStart";
import NewPtSpecificFields from "coordination-library/src/components/doctor/protocoles/components-new-pt/NewPtSpecificFields";

import rules from "anam-library/src/js/rules";
import {get_age} from "anam-library/src/js/dates";

export default {
  name: "DoctorHomeNewProtocol",
  components: {
    AnDialogComponent,
    FormSearchPatient,
    CoPatientEditComponent,
    NewPtSelectProtocols,
    NewPtAlertUserStart,
    NewPtAlertPatientCondition,
    NewPtBtnStart,
    NewPtSpecificFields
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    selected_patient: null,
    valid_form_start_protocol: false,
    mt_protocol_selected: null,
    taille: null,
    poids: null,
    current_user_can_start_pt: false,
    can_start_pt: false,
    create_patient: false,
    new_patient: false,
    valid_form_creation_patient: false,
    loading_creation_patient: false,
    valid_form_specifics_fields: false,
    rules: rules
  }),
  computed: {
    _dialog: {
      get: function() {
        return this.dialog;
      },
      set: function(val) {
        this.$emit("update:dialog", val);
      }
    },
    services_to_add_patient: function() {
      let tmp = [null];
      for (let service of this.$store.getters.getAllServices) {
        tmp.push(service.name);
      }
      return tmp;
    },
  },
  watch: {
    _dialog: function(val) {
      if(!val) {
        let ref_search_form = this.$refs.form_search_patient;
        let ref_creation_form = this.$refs.form_creation_patient;
        let ref_protocol_form = this.$refs.form_protocol;
        let ref_fields_protocol_form = this.$refs.form_fields_protocol;
        if(ref_search_form) ref_search_form.reset_form();
        if(ref_creation_form) ref_creation_form.reset();
        if(ref_protocol_form) ref_protocol_form.reset();
        if(ref_fields_protocol_form) ref_fields_protocol_form.reset();
        this.new_patient = false;
        this.create_patient = false;
        this.mt_protocol_selected = null;
        this.selected_patient = null;
        this.current_user_can_start_pt = false;
        this.can_start_pt = false;
      }
    }
  },
  methods: {
    set_dict_patient_selected: function(data) {
      if(!data) this.selected_patient = null;
      this.selected_patient = {
        patient_id: data.patient_id,
        first_name: data.first_name,
        last_name: data.last_name,
        personnephysique: {
          birthdate: data.birthdate,
          sexe: data.sexe
        }
      }
    },
    actions_finally_creation: function() {
      this.loading_creation_patient = false;
    }
  },
  filters: {
    sexe: function(val) {
      if(val && val.personnephysique && val.personnephysique.sexe) {
        if(val.personnephysique.sexe === 'F') return "Femme";
        else if(val.personnephysique.sexe === 'M') return "Homme";
      }
      return "";
    },
    age: function(val) {
      if(val && val.personnephysique && val.personnephysique.birthdate) {
        return get_age(val.personnephysique.birthdate, false, true);
      }
      return "";
    }
  }
}
</script>

<style scoped>

</style>