<template>
  <v-text-field v-model="$store.state.dashboard_search"
                :disabled="loading"
                solo dense hide-details clearable
                prepend-inner-icon="mdi-magnify"
                placeholder="Rechercher"/>
</template>

<script>
export default {
  name: "DoctorDashboardSearch",
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>