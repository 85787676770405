<template>
  <v-window-item class="pb-5">
    <v-container fluid>
      <v-row class="ma-0 pt-sm-4">
        <v-col cols="12" sm="8" md="4" offset-sm="2" offset-md="4">
          <RegistryTitle title="Remplissez vos informations ci-dessous" class="mb-10"/>

          <v-form v-model="valid_form" ref="registry_form">
            <div class="mb-4">
              <RegistryLabel label="Civilité"/>
              <RegistryChoiceField :value.sync="civility"
                                   :items="items_civility"/>
            </div>
            <div class="mb-4">
              <RegistryLabel label="Sexe"/>
              <RegistryChoiceField :value.sync="sex"
                                   :items="items_sexe"/>
            </div>
            <div>
              <RegistryLabel label="Nom"/>
              <RegistryTextField :value.sync="last_name"
                                 placeholder="Ex: DURAND"
                                 of_type="last_name"/>
            </div>
            <div>
              <RegistryLabel label="Prénom"/>
              <RegistryTextField :value.sync="first_name"
                                 placeholder="Ex: Jean"/>
            </div>
            <div class="mb-4">
              <RegistryLabel label="Mode d'exercice"/>
              <RegistryChoiceField :value.sync="mode_exercice"
                                   :items="items_mode_exercice"/>
            </div>
            <div>
              <RegistryLabel label="Profession"/>
              <RegistrySelectField :value.sync="profession"
                                   :items="items_professions"/>
            </div>
            <div v-if="profession==='10'">
              <RegistryLabel label="Spécialité"/>
              <RegistrySelectField :value.sync="speciality"
                                   :items="items_specialities"/>
            </div>
            <div class="mb-4">
              <RegistryLabel label="Adresse d'exercice"/>
              <div v-if="initial_address" class="pl-1 mt-1 mb-2">
                <template v-if="incomplete_address && !address">
                  <p class="font-size--14 normal--text red--text mb-0">Votre adresse semble incomplète.</p>
                  <p class="font-size--14 mb-0">Veuillez la saisir ci-dessous afin de la mettre à jour.</p>
                </template>
                <p v-if="!incomplete_address" class="mb-0 normal--text font-size--14 mb-0">Adresse : {{ initial_address }}</p>
              </div>
              <AnSearchAddressGoogleAPI ref="address_form"
                                        :address.sync="address"
                                        :required="required_address"
                                        class_input="dense-solo-input"
                                        placeholder="Saisissez votre adresse d'exercice"
                                        street_number_required/>
            </div>
            <div class="mb-4">
              <RegistryLabel label="Numéro de téléphone professionnel"/>
              <RegistryPhoneField :initial_value="txt_phone_1"
                                  v-on:update="phone_1=$event"/>
            </div>
            <div class="mb-4">
              <RegistryLabel label="Numéro de téléphone additionnel" :required="false"/>
              <RegistryPhoneField :initial_value="txt_phone_2"
                                  :required="false"
                                  v-on:update="phone_2=$event"/>
            </div>
            <div>
              <RegistryLabel label="Adresse email professionnelle"/>
              <RegistryTextField :value.sync="email"
                                 placeholder="Ex: jean.durand@gmail.com"
                                 of_type="email"/>
            </div>
            <div>
              <RegistryLabel label="Créez votre mot de passe"/>
              <RegistryPasswordField :value.sync="password"
                                     placeholder="Entrez un mot de passe"/>
              <p class="pl-2 primary--text">
                <span class="font-size--12 normal--text pr-2" style="border-right: solid 1px var(--v-primary-base)">9 caractères</span>
                <span class="font-size--12 normal--text px-2" style="border-right: solid 1px var(--v-primary-base)">1 minuscule</span>
                <span class="font-size--12 normal--text px-2" style="border-right: solid 1px var(--v-primary-base)">1 majuscule</span>
                <span class="font-size--12 normal--text px-2" style="border-right: solid 1px var(--v-primary-base)">1 chiffre</span>
                <span class="font-size--12 normal--text pl-2">1 caractère spécial</span>
              </p>
            </div>
            <div class="mt-6">
              <RegistryLabel label="Confirmez votre mot de passe"/>
              <RegistryPasswordField :value.sync="password_confirm"
                                     :is_confirm_field="true"
                                     :init_password="password"
                                     placeholder="Confirmez votre mot de passe"/>
            </div>
            <div>
              <RegistryLabel label="Structure de rattachement (CPTS)" :required="false"/>
              <RegistrySelectField :value.sync="structures"
                                   :items="items_structures"
                                   :item_text="(item) => `${item.commercial_name} ${item.id_structure ? `(FINESS : ${item.id_structure})` : ''}`"
                                   item_value="id"
                                   :loading="loading_structures"
                                   :required="false"
                                   :multiple="true"
                                   no_data_txt="Nous ne trouvons pas de résultats correspondant à votre recherche. Si vous ne trouvez pas votre structure, vous pouvez tout de même valider votre inscription."/>
            </div>

            <div class="mb-15">
              <v-checkbox v-model="cgu" :rules="[rules.required]" color="primary" hide-details>
                <template v-slot:label>
                  <span class="normal--text font-size--14">
                    J'accepte les
                    <a :href="link_cgu" target="_blank" @click.stop class="text-decoration-underline medium--text font-size--14 text-decoration-underline">Conditions générales d'utilisation</a>
                  </span>
                </template>
              </v-checkbox>
              <v-checkbox v-model="confidentiality" :rules="[rules.required]" color="primary" hide-details>
                <template v-slot:label>
                  <span class="normal--text font-size--14">
                    J'accepte la
                    <a :href="link_confidentiality" target="_blank" @click.stop class="text-decoration-underline medium--text font-size--14 text-decoration-underline">Politique de confidentialité</a>
                  </span>
                </template>
              </v-checkbox>
            </div>
          </v-form>

          <v-expand-transition>
            <v-alert v-if="access_not_allowed" type="info">
              <span class="medium--text font-size--16">
                Cette fonctionnalité n'est pas encore disponible.
              </span>
            </v-alert>
          </v-expand-transition>

          <v-expand-transition>
            <v-alert v-if="error_email" type="error">
              <span class="normal--text font-size--16">
                Cette adresse email est déjà associée à un compte utilisateur. Vous pouvez vous connecter en vous rendant sur la
                <a class="white--text text-decoration-underline medium--text" @click="go_to_connexion()">page de connexion</a>.
              </span>
            </v-alert>
          </v-expand-transition>

          <v-expand-transition>
            <v-alert v-if="error_fields" type="error">
              <span class="normal--text font-size--16">
                Un ou plusieurs champs du formulaire sont invalides. Vérifiez les informations renseignées ci-dessus.
              </span>
            </v-alert>
          </v-expand-transition>

          <v-expand-transition>
            <v-alert v-if="error_support" type="error">
              <span class="medium--text font-size--16">
                Une erreur est survenue, si le problème persiste contactez
                <a class="white--text medium--text font-size--16 text-decoration-underline" href="mailto:support@anamnese.care">support@anamnese.care</a>
              </span>
            </v-alert>
          </v-expand-transition>

          <div class="mt-6 text-center">
            <div>
              <RegistryBtnAction txt_btn="Continuer"
                                 :disabled="!can_valid_form"
                                 small
                                 :loading="loading_create_pro"
                                 @click="post_professional()"/>
            </div>
            <div>
              <RegistryBtnBack small
                               @click="back()"/>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-window-item>
</template>

<script>
import RegistryTitle from "@/components/public/registry/components/RegistryTitle.vue";
import RegistryLabel from "@/components/public/registry/components/RegistryLabel.vue";
import RegistryTextField from "@/components/public/registry/components/RegistryTextField.vue";
import RegistrySelectField from "@/components/public/registry/components/RegistrySelectField.vue";
import RegistryChoiceField from "@/components/public/registry/components/RegistryChoiceField.vue";
import AnSearchAddressGoogleAPI from "anam-library/src/components/an-ui/an-address/AnSearchAddressGoogleAPI.vue";
import RegistryPhoneField from "@/components/public/registry/components/RegistryPhoneField.vue";
import RegistryBtnBack from "@/components/public/registry/components/RegistryBtnBack.vue";
import RegistryBtnAction from "@/components/public/registry/components/RegistryBtnAction.vue";
import RegistryPasswordField from "@/components/public/registry/components/RegistryPasswordField.vue";

import {ITEMS_PROFESSIONS_FOR_COORDINATION_ONBOARDING} from "anam-library/src/data/professions";
import {capitalize_str, get_items_civility, get_items_mode_exercice, get_items_sexe} from "anam-library/src/js/utils";
import {get_items_specialities_med, manage_med_ge_speciality} from "anam-library/src/data/professionnal_specialities";
import {get_dict_formated_address_from_google_search, get_formated_address} from "anam-library/src/js/address";
import getCookie from "anam-library/src/js/common";
import rules from "anam-library/src/js/rules";
import {mixin_cgu} from "anam-library/src/js/utils";

import axios from "axios";

export default {
  name: "RegistryStepForm",
  mixins: [mixin_cgu],
  components: {
    RegistryPasswordField,
    RegistryBtnAction,
    RegistryBtnBack,
    RegistryPhoneField,
    RegistryChoiceField,
    RegistrySelectField,
    RegistryTextField,
    RegistryLabel,
    RegistryTitle,
    AnSearchAddressGoogleAPI
  },
  props: {
    professional_cat18: {
      type: Object,
      default: () => {}
    },
    profession_selected: {
      type: String,
      default: null
    },
    idpp: {
      type: String,
      default: null,
    }
  },
  data: () => ({
    valid_form: false,
    civility: null,
    sex: null,
    last_name: null,
    first_name: null,
    mode_exercice: null,
    profession: null,
    speciality: null,
    initial_address: null,
    address: null,
    incomplete_address: false,
    phone_1: null,
    txt_phone_1: null,
    phone_2: null,
    txt_phone_2: null,
    email: null,
    password: null,
    password_confirm: null,
    structures: [],
    items_structures: [],
    cgu: false,
    confidentiality: false,
    loading_structures: false,
    loading_create_pro: false,
    rules: rules,
    error_support: false,
    access_not_allowed: false,
    error_fields: false,
    error_email: false
  }),
  computed: {
    items_professions: function() {
      return ITEMS_PROFESSIONS_FOR_COORDINATION_ONBOARDING()
    },
    items_specialities: function() {
      return get_items_specialities_med();
    },
    items_civility: function() {
      return get_items_civility();
    },
    items_mode_exercice: function() {
      return get_items_mode_exercice();
    },
    items_sexe: function() {
      return get_items_sexe();
    },
    required_address: function() {
      if(!this.initial_address) return true;
      else return this.incomplete_address;
    },
    can_valid_form: function() {
      return this.valid_form && this.civility && this.sex && this.phone_1 && this.address;
    },
    link_cgu: function() {
      return this.get_mentions_legales_url("citana", "coordination", "anamnese");
    },
    link_confidentiality: function() {
      return this.get_politique_confidentialite_url("citana", "coordination", "anamnese");
    }
  },
  watch: {
    professional_cat18: function(val) {
      if(val && Object.keys(val).length > 0) {
        this.civility = val.code_civilite;
        if(val.code_civilite === "MME") this.sex = "F";
        else if(val.code_civilite === "M") this.sex = "M"
        this.first_name = val.prenom_exercice;
        this.mode_exercice = val.code_mode_exercice;
        this.last_name = val.nom_exercice;
        this.profession = val.code_profession;
        this.speciality = manage_med_ge_speciality(val.code_savoir_faire);
        this.txt_phone_2 = val.telephone;
        this.email = val.email;
        this.initial_address = get_formated_address(val);

        let address_obligatory_fields = ["numero_voie", "libelle_voie", "code_postal", "code_commune", "latitude", "longitude", "libelle_commune"];
        for(let el in address_obligatory_fields) {
          if(!val[address_obligatory_fields[el]]) {
            this.incomplete_address = true;
            break;
          }
        }
      }
    },
    profession_selected: function(val) {
      if(val) {
        this.profession = val;
      }
    }
  },
  created() {
    this.loading_structures = true;
    this.$http.get('/api/hf-coordinations/public-infos').then(response => {
      this.items_structures = response.data;
    }).finally(() => {
      this.loading_structures = false;
    })
  },
  methods: {
    post_professional: function() {
      this.reset_errors();
      this.loading_create_pro = true;
      let data = this.set_data_to_back();
      axios.post("/api/coordination/inscription-professional", data, {headers: {"X-CSRFToken": getCookie("csrftoken")}}).then(() => {
        this.next();
      }).catch(error => {
        if(error.response) {
          if(error.response.status === 422) {
            for(let el in error.response.data) {
              if (el === "email") this.error_email = true;
            }
            if(!this.error_email) this.error_fields = true;
          } else if(error.response.status === 404) {
            this.error_support = true;
          } else if(error.response.status === 403) {
            this.access_not_allowed = true;
          } else {
            this.error_support = true;
          }
        } else {
          this.error_support = true;
        }
      }).finally(() => {
        this.loading_create_pro = false;
      });
    },
    set_data_to_back: function() {
      let tmp_last_name = this.last_name.toUpperCase();
      let tmp_first_name = capitalize_str(this.first_name);
      let tmp = {
        password: this.password,
        status_account: 1,
        email: this.email,
        last_name: tmp_last_name,
        first_name: tmp_first_name,
        personnephysique: {
          sexe: this.sex,
          civilite: this.civility,
          adresse: get_dict_formated_address_from_google_search(this.address),
          personel_phone: this.phone_1,
          additional_phone: this.phone_2
        },
        professionnel: {
          dmp_type: this.profession,
          mode_exercice: this.mode_exercice
        },
        structures: this.structures
      }
      if(this.address) tmp.personnephysique["adresse"] = get_dict_formated_address_from_google_search(this.address);
      else {
        tmp.personnephysique["adresse"] = {
          numeroVoie: this.professional_cat18.numero_voie,
          libelleVoie: this.professional_cat18.libelle_voie,
          codePostal: this.professional_cat18.code_postal,
          codeCity: this.professional_cat18.code_commune,
          localite: this.professional_cat18.libelle_commune,
          latitude: this.professional_cat18.latitude,
          longitude: this.professional_cat18.longitude
        }
      }
      if(this.idpp) tmp.professionnel["idpp"] = this.idpp;
      if(this.speciality) tmp.professionnel["speciality_code"] = this.speciality;
      return tmp;
    },
    next: function() {
      this.$emit('next')
    },
    back: function() {
      this.$emit('back');
      this.reset_data();
    },
    reset_errors: function() {
      this.error_support = false;
      this.access_not_allowed = false;
      this.error_fields = false;
      this.error_email = false;
    },
    reset_data: function() {
      this.civility = null;
      this.sex = null;
      this.last_name = null;
      this.first_name = null;
      this.mode_exercice = null;
      this.profession = null;
      this.speciality = null;
      this.initial_address = null;
      this.address = null;
      this.incomplete_address = false;
      this.phone_1 = null;
      this.txt_phone_1 = null;
      this.phone_2 = null;
      this.txt_phone_2 = null;
      this.email = null;
      this.password = null;
      this.password_confirm = null;
      this.structures = [];
      this.loading_structures = false;
      this.loading_create_pro = false;
      this.cgu = false;
      this.confidentiality = false;
      this.reset_errors();
    },
    go_to_connexion: function() {
      this.$router.push({name: "ConnexionLayout"});
    }
  }
}
</script>

<style scoped>

</style>