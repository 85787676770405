<template>
  <AnTextField :value.sync="_value"
               :required="required"
               :type_field="of_type"
               :show_title="false"
               :placeholder="placeholder"
               :solo="true"
               :dense="true"
               :show_validation_icon="false"
               :validate_on_blur="true"
               class="textfield-solo-elevation-0"/>
</template>

<script>
import AnTextField from "anam-library/src/components/an-ui/textfield/AnTextField.vue";

export default {
  name: "RegistryTextField",
  components: {AnTextField},
  props: {
    value: {
      type: String,
      default: null
    },
    of_type: {
      type: String,
      default: "text"
    },
    required: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      required: true
    }
  },
  computed: {
    _value: {
      get: function() {
        return this.value;
      },
      set: function(val) {
        this.$emit("update:value", val);
      }
    }
  }
}
</script>

<style scoped>

</style>