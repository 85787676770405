import FooterFr from 'anam-library/src/i18n/footer/fr.json'
import ErrorPagesFr from 'anam-library/src/i18n/error-pages/fr.json'
import DashboardFr from 'anam-library/src/i18n/dashboard/fr.json'
import CalendarFr from 'anam-library/src/i18n/calendar/fr.json'
import DpaFr from 'anam-library/src/i18n/dpa/fr.json'
import RulesFr from 'anam-library/src/i18n/rules/fr.json'
import NotesFr from 'anam-library/src/i18n/notes/fr.json'
import HeaderFr from "anam-library/src/i18n/header/fr.json"
import AuthFr from "anam-library/src/i18n/auth/fr.json"
import MedicalFormFr from 'anam-library/src/i18n/MedicalForm/fr.json'
import CommonFr from "anam-library/src/i18n/common/fr.json"


export const defaultLocale = 'fr'

export const languages = {
	fr: {
		auth: AuthFr,
		calendar: CalendarFr,
		common: CommonFr,
		dashboard: DashboardFr,
		dpa: DpaFr,
		error_pages: ErrorPagesFr,
		footer: FooterFr,
		header: HeaderFr,
		notes: NotesFr,
		rules: RulesFr,
		MedicalForm: MedicalFormFr,

	}
}