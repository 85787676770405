<template>
  <v-card class="rounded-lg">
    <v-card-text>
      <v-row class="mx-0">
        <v-col cols="12" class="d-flex">
          <p class="mb-0 semibold--text primary_dark--text font-size--24 align-self-center">
            Consultations vidéo assistées
          </p>
          <v-spacer></v-spacer>
          <AnExportBtn :loading="loading_export"
                       v-on:generate_csv="generate_csv()"
                       v-on:generate_xlsx="generate_xlsx()"/>
        </v-col>
        <v-col cols="12">
          <AnDataTable
              :items="items"
              :headers="headers"
              :loading="loading"
              elevation="0"
              :table_items_mapping="table_items_mapping"
              class="data-table-stats primary_dark--text"
          >
          </AnDataTable>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import AnDataTable from "anam-library/src/components/an-data-table/AnDataTable";
import DateTimeItem from "anam-library/src/components/an-data-table/an-table-items-components/DateTimeItem";
import axios from "axios";
import {get_formatted_date} from "anam-library/src/js/dates";
import {set_txt_author} from "anam-library/src/js/utils";
import AnExportBtn from "anam-library/src/components/an-export/AnExportBtn"
import {generate_csv} from "anam-library/src/js/export_file";
import {generateXlsx} from "anam-library/src/js/export_xlsx";

export default {
  name: "ManagerStatisticsTelemedecine",
  components: {AnDataTable, AnExportBtn},
  props: {
    load_data: {
      type: Boolean,
      default: false
    },
    hf_coordination_id: {
      type: String,
      required: true
    }
  },
  data: () => ({
    is_loaded: false,
    loading: false,
    items: [],
    headers: [
      {text: "Date", value: "start_slot", class: "primary_dark--text"},
      {text: "Effecteur", value: "health_practitioner_name", class: "primary_dark--text"},
      {text: "Orienteur", value: "author_name", class: "primary_dark--text"},
    ],
    loading_export: false,
    table_items_mapping: {
      "start_slot": DateTimeItem
    },
    headers_export: ["Date", "Effecteur", "Orienteur", "Patient"]
  }),
  watch: {
    load_data: function(val) {
      if(val && !this.is_loaded) this.get_statistics();
    },
    hf_coordination_id: function(val) {
      if(val) this.get_statistics();
    }
  },
  methods: {
    get_statistics: function() {
      this.loading = true;
      axios.get(`/api/hf-coordination/${this.hf_coordination_id}/telemedecine-statistics`).then(response => {
        for (let data of response.data) {
          this.items.push({
            start_slot: data.slot.start_slot,
            health_practitioner_name: data.health_practitioner_name,
            author_name: set_txt_author(data.author),
            last_name: data.last_name,
            first_name: data.first_name

          })
        }
      }).catch(error => {
        if (error.response) this.$store.dispatch('ManageErrorLight', error.response.status);
      }).finally(() => {
        this.loading = false;
        this.is_loaded = true;
      })
    },
    export_data() {
      this.loading_export = true;
      let data_xlsx = [];
      for (let item of this.items) {
        let full_name = `${item.first_name} ${item.last_name}`
        data_xlsx.push([
          get_formatted_date(item.start_slot),
          item.health_practitioner_name,
          item.author_name,
          full_name
        ]);
      }
      this.loading_export = false;
      return data_xlsx
    },
    generate_csv: function() {
      generate_csv(this.export_data(), this.headers_export, "Export télémédecine", false);
    },
    generate_xlsx: function() {
      generateXlsx(this.export_data(), this.headers_export, "Export télémédecine", "Export télémédecine");
    }
  }
}
</script>

<style scoped>

</style>