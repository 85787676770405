<template>
  <v-app>
    <v-main class="light_blue_inzee">
      <v-container fluid class="text-center">
        <template v-if="access">
          <p class="mt-12 font-size--34 semibold--text primary--text">Connexion en cours</p>
          <v-progress-circular indeterminate size="100" class="mt-10" color="tertiary"></v-progress-circular>
          <div style="position: absolute; bottom: 0; right: 0; left: 0">
            <p class="font-italic font-size--12" @click="go_to_connexion()">
              Si la redirection ne s'effectue pas correctement cliquez
              <a class="bold--text primary--text text-decoration-underline">ici</a>
              pour retourner sur la page de connexion.
            </p>
          </div>
        </template>
        <template v-else-if="user_unknow">
          <p class="mt-12 font-size--34 semibold--text primary--text">Vous n'avez pas encore de compte pour accéder à Citana.</p>
          <p class="font-italic normal--text font-size--24 secondary--text">Cliquez sur la bouton ci-dessous pour accéder au formulaire d'inscription.</p>
          <v-btn :href="url_form_inscription" class="mt-sm-6 px-sm-6" color="primary" large>
            <v-icon class="mr-2">mdi-account-plus</v-icon>
            <span class="medium--text font-size--16">Créer mon compte</span>
          </v-btn>
        </template>
        <template v-else-if="not_actif">
          <p class="mt-12 font-size--34 semibold--text primary--text">Votre compte semble inactif.</p>
          <p class="font-italic normal--text font-size--24 secondary--text">Si vous pensez que c'est une erreur, n'hésitez pas à nous contacter.</p>
        </template>
        <template v-else-if="common_error">
          <p class="mt-12 font-size--34 semibold--text primary--text">Une erreur est survenue lors du chargement.</p>
          <p class="font-italic normal--text font-size--24 secondary--text">Si le problème persiste, n'hésitez pas à nous contacter.</p>
          <v-btn color="primary" class="mt-6" text @click="go_to_connexion()" :loading="loading_deconnexion">
            <span class="text-decoration-underline font-size--16 normal--text">Accès à la page de connexion</span>
          </v-btn>
        </template>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import axios from "axios";
import getCookie from "anam-library/src/js/common";

export default {
  name: "RedirectionBrView",
  props: {
    access: {
      type: Boolean,
      default: true
    },
    user_unknow: {
      type: Boolean,
      default: false
    },
    not_actif: {
      type: Boolean,
      default: false
    },
    common_error: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading_deconnexion: false
  }),
  computed: {
    url_form_inscription: function() {
      return "https://app.citana.fr/inscription";
    }
  },
  created() {
    this.$store.commit('setEnvKeycloakCGSBretagne');
    this.$store.commit('setConnexionFromGCSBretagne', true);
    this.$keycloak
        .init({onLoad: "check-sso"})
        .then(authenticated => {
          if(authenticated && this.$store.getters.getUserPermission === 0 && this.$store.getters.getConnexionFromGCSBretagne ) {
            axios.post("/api/inzeecare/connexion_gcs/", {id_token: this.$keycloak_gcs.idToken}, {headers: {"X-CSRFToken": getCookie('csrftoken')}}).then(response => {
                this.$store.commit('setRedirectLogoutGCSBretagne', this.$keycloak_gcs.tokenParsed.redirect_logout);
                this.$store.dispatch("connexionInzeecare", {data: response.data, from_gcs_bretagne: true});
            }).catch(error => {
                if(error.response) {
                    if(error.response.status === 404) this.$router.push({name: 'UnknownErrorRedirectionBrView'});
                    else if(error.response.status === 401) this.$router.push({name: 'InactiveErrorRedirectionBrView'});
                    else {
                        this.$router.push({name: 'CommonErrorRedirectionBrView'});
                    }
                } else {
                     this.$router.push({name: 'CommonErrorRedirectionBrView'});
                }
            })
          }
        });
  },
  methods: {
    go_to_connexion: function(){
      this.loading_deconnexion = true;
      this.$store.dispatch('logoutInzeecare');
    }
  }
}
</script>

<style scoped>

</style>