<template>
  <v-app>
    <AnOverlayLoading :is_mounted="$store.state.is_mounted_app"/>
    <DoctorHeader/>
    <v-main class="bg_primary pb-sm-6">
      <v-banner v-model="banner_validation" single-line color="#FDEFEA">
        <span class="font-size--18 bold--text secondary--text">Vous êtes en attente de validation du support.</span>
        <template v-slot:actions>
          <v-btn text color="secondary" @click="banner_validation=false">
            <span class="semibold--text">Fermer</span>
            <v-icon class="ml-2" small>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-banner>
      <v-banner v-model="banner" single-line color="#FDEFEA">
        <span class="font-size--18 bold--text orange--text">Vous êtes en attente d'acceptation de votre structure.</span>
        <template v-slot:actions>
          <v-btn text color="orange" @click="banner=false">
            <span class="semibold--text">Fermer</span>
            <v-icon class="ml-2" small>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-banner>
      <slot name="alert"></slot>
      <CoAlert :show.sync="show_snackbar" :snackbar_info="$store.getters.getSnackInfos"/>
      <router-view/>
    </v-main>
    <CommonFooter/>
  </v-app>
</template>

<script>
import AnOverlayLoading from "anam-library/src/components/an-ui/loading/AnOverlayLoading";
import CoAlert from "coordination-library/src/components/doctor/alert/CoAlert";
import DoctorHeader from "@/components/doctor/header/DoctorHeader.vue";
import CommonFooter from "@/components/footer/Footer.vue";

export default {
  name: "DoctorLayout",
  components: {
    CommonFooter,
    DoctorHeader,
    AnOverlayLoading,
    CoAlert
  },
  data: () => ({
    banner: false,
    banner_validation: false,
  }),
  created() {
    this.$http.get("/api/notifications").then(response => {
      this.$store.commit("setNotifications", response.data);
    })
  },
  mounted() {
    this.banner_validation = this.$store.getters.getPermission("waiting_for_validation");
    this.banner = this.$store.getters.getPermission("waiting_for_cpts");
  },
  computed: {
    show_snackbar: {
      get: function() {
        return this.$store.getters.getSnackModel
      },
      set: function (val) {
        if(!val) this.$store.commit('ResetSnackBar');
      }
    },
  }
}
</script>

<style scoped>

</style>