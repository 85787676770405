<template>
  <v-container fluid class="bg_primary pa-3 pa-sm-6 pb-10 mb-10">
    <AnBackButton v-on:back="$router.push({name: 'ManagerHome'})" color="primary" :x_large="false"/>
    <v-row class="mx-0">
      <v-col cols="12" class="pb-0">
        <p class="font-size--24 primary--text bold--text">Utilisateurs à valider</p>
      </v-col>
      <v-col cols="12" sm="4" offset-sm="8" class="pt-0">
        <v-text-field v-model="search"
                      hide-details
                      clearable
                      placeholder="Rechercher"/>
      </v-col>
      <v-col cols="12">
        <v-expand-transition>
          <AnDataTable :headers="headers"
                       :items="users"
                       :loading="loading"
                       :search="search"
                       :table_items_mapping="table_items_mapping"
                       elevation="0"
                       rounded="rounded-lg"
                       v-on:action="go_to_detail_user($event)"/>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AnBackButton from "anam-library/src/components/an-ui/button/AnBackButton";
import AnDataTable from "anam-library/src/components/an-data-table/AnDataTable";
import ActionItem from "./components/ActionItem.vue";
import StatusAccountItem from "./components/StatusAccountItem.vue";
import StructuresItem from "./components/StructuresItem.vue";
import BasicItem from "anam-library/src/components/an-data-table/an-table-items-components/BasicItem";
import FirstNameItem from "anam-library/src/components/an-data-table/an-table-items-components/FirstNameItem";
import LastNameItem from "anam-library/src/components/an-data-table/an-table-items-components/LastNameItem";

import {get_profession_name} from "anam-library/src/data/professions";

export default {
  name: "ManagerUsersToValidateListingView",
  components: {AnDataTable, AnBackButton},
  data: () => ({
    headers: [
      {text: 'Nom', value: 'last_name', class: 'primary rounded-tl-lg white--text'},
      {text: 'Prénom', value: 'first_name', class: 'primary white--text'},
      {text: 'Téléphone', value: 'phone', class: 'primary white--text'},
      {text: 'Email', value: 'email', class: 'primary white--text'},
      {text: 'Profession', value: 'profession', class: 'primary white--text'},
      {text: 'RPPS/ADELI', value: 'idpp', class: 'primary white--text'},
      {text: 'Structure(s)', value: 'structures', class: 'primary white--text'},
      {text: 'Statut', value: 'status_account', class: 'primary white--text', align: "center"},
      {text: 'Actions', value: 'actions', class: 'primary white--text rounded-tr-lg', align: "center"},
    ],
    users: [],
    loading: false,
    search: null,
    table_items_mapping: {
      first_name: FirstNameItem,
      last_name: LastNameItem,
      phone: BasicItem,
      email: BasicItem,
      structures: StructuresItem,
      profession: BasicItem,
      idpp: BasicItem,
      status_account: StatusAccountItem,
      actions: ActionItem
    },
  }),
  created() {
    this.$store.state.is_mounted_app = true;
    this.$vuetify.goTo(0);
    this.get_pros();
  },
  methods: {
    get_pros: function() {
      this.loading = true;
      this.users = [];
      let params = {"agency": "inzeecare", "to-validate": true};

      this.$http.get("/api/coordination/bo/professionals", {params: params}).then(response => {
        this.users = response.data.map(el => {
          el.profession = get_profession_name(el.dmp_type, el.speciality_code);
          return el
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    go_to_detail_user: function(user) {
      let route_data = this.$router.resolve({name: "ManagerUsersDetails", params: {user_id: user.id}});
      window.open(route_data.href, "_blank")
    }
  }
}
</script>

<style scoped>

</style>