import {get_formatted_date} from "anam-library/src/js/dates";

export function get_app_name_by_host() {
  let app_name = 'ecoordination'
  if(window.location.hostname.includes('inzee')){
    app_name = 'inzeecare'
  }
  return app_name
}

export async function before_download_pdf(pdf, append_signature_text, document_type, logo) {
  try {
    let totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      if (append_signature_text) {
        pdf.setFontSize(8);
        pdf.setFontStyle("italic");
        pdf.setTextColor(120);
        pdf.text(append_signature_text, 7, pdf.internal.pageSize.getHeight() - 25, {align: 'left'});
      }
      pdf.setTextColor(0, 0, 0);
      pdf.setFontSize(8);
      pdf.setFontStyle("normal");
      let edited_text = document_type === "Délivrance" ? "éditée" : "édité";
      pdf.text(`${document_type} ${edited_text} sur`, 50, pdf.internal.pageSize.getHeight() - 8, {align: 'left'});
      pdf.setTextColor(51, 116, 119);
      pdf.text('Citana', document_type === "Délivrance" ? 80 : 75, pdf.internal.pageSize.getHeight() - 8, {align: 'left'});
      pdf.addImage(logo, 'PNG', 130, 283, 25, 0, '', 'FAST', 0);
      pdf.setLineWidth(0.02);
      pdf.roundedRect(45, 283, 120, 10, 5, 5, 'S');
      if(document_type === "Protocole"){
        pdf.setTextColor(0, 0, 0);
        pdf.text(` ${i} / ${totalPages}`, (pdf.internal.pageSize.getWidth() * 0.9), pdf.internal.pageSize.getHeight() - 8 );
      }

    }
  } catch (e) {}
}

export function get_period_text_for_export(start, end) {
  let filename = "";
  if (start && end) filename = `du ${start} au ${end}`;
  else if (start) filename = `depuis le ${start}`;
  else if (end) filename = `jusqu'au ${end}`;
  return filename
}

