<template>
  <CoPatientEndVisioView logo="https://www.anamnese.care/hubfs/config/Citana/inzeecare/logo/logo-2022-citana-couleur-BL.png" />
</template>

<script>
import CoPatientEndVisioView from "coordination-library/src/components/patient/visio/CoPatientEndVisioView";

export default {
  name: "PatientEndVisioView",
  components: {CoPatientEndVisioView}
}
</script>

<style scoped>

</style>