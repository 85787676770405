<template>
  <v-app>
    <v-main class="bg_primary">
      <v-app-bar color="white" elevation="2" app>
        <v-row class="align-center">
          <v-col cols="8" sm="4" md="2">
            <img class="pl-5" width="200" src="@/assets/logo.png" alt="Logo Citana"/>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-app-bar>
      <v-container fluid class="text-center">
        <v-row class="mx-0 mt-4">
          <v-col cols="12" sm="8" md="6" offset-sm="2" offset-md="3">
            <p class="font-size--30 normal--text primary--text mb-2">Dernière étape !</p>
            <p class="font-size--30 semibold--text primary--text">Importer vos pièces justificatives</p>

            <p class="font-size--14 primary_dark--text normal--text text-left mb-0">
              Via Citana, vous allez accéder à des données patients.<br>
              Pour nous assurer que vous êtes bien le professionnel de santé qui en a le droit, nous avons besoin de valider votre identité. Pour cela nous avons besoin des pièces suivantes :
            </p>
            <ul style="list-style: decimal" class="text-left primary_dark--text normal--text mb-2">
              <li class="font-size--14 semibold--text">une pièce d'identité (carte d'identité ou permis de conduire ou passeport)</li>
              <li class="font-size--14 semibold--text">une photo nette et individuelle (autoportrait/selfie)</li>
            </ul>
            <p class="font-size--14 primary_dark--text normal--text text-left">
              Ces documents ne sont utilisés qu’uniquement à des fins de validation de votre compte.<br>
              C'est à vous!
            </p>
          </v-col>
          <v-col cols="12" class="pa-0"></v-col>
          <v-col cols="12" sm="3" offset-sm="3">
            <CardAddPJ :document.sync="identity_card"
                       title="Justificatif d'identité"
                       text="mon justificatif d'identité"
                       icon="mdi-card-account-details"/>
          </v-col>
          <v-col cols="12" sm="3">
            <CardAddPJ :document.sync="photo"
                       title="Photo"
                       text="ma photo"
                       icon="mdi-account"/>
          </v-col>
          <v-col cols="12" sm="8" md="4" offset-sm="2" offset-md="4" class="my-8">
            <v-expand-transition>
              <v-alert v-if="invalid" type="warning" class="text-left">
                <span class="medium--text font-size--16">
                  Un ou plusieurs fichiers sont invalides (format, taille du fichier, etc.). Essayez avec un autre fichier.
                </span>
              </v-alert>
            </v-expand-transition>
            <v-expand-transition>
              <v-alert v-if="not_allowed" type="error" class="text-left">
                <span class="normal--text font-size--16">
                  Vous n'avez pas l'autorisation d'effectuer cette action.
                  <a class="white--text medium--text text-decoration-underline" @click="go_to_connexion()">Retour à la connexion.</a>
                </span>
              </v-alert>
            </v-expand-transition>
            <v-expand-transition>
              <v-alert v-if="error" type="error" class="text-left">
                <span class="normal--text font-size--16">
                  Une erreur est survenue. Si le problème persiste contactez
                  <a class="white--text medium--text text-decoration-underline" href="mailto:support@anamnese.care">support@anamnese.care</a>
                </span>
              </v-alert>
            </v-expand-transition>
            <RegistryBtnAction txt_btn="Valider et accéder à mon espace"
                               :loading="loading"
                               :disabled="disabled_btn"
                               @click="patch_user()"/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>


<script>
import RegistryBtnAction from "@/components/public/registry/components/RegistryBtnAction.vue";
import CardAddPJ from "@/components/doctor/onboarding/components/CardAddPJ.vue";

import axios from "axios";
import getCookie from "anam-library/src/js/common";

export default {
  name: "DoctorOnboardingPJView",
  components: {CardAddPJ, RegistryBtnAction},
  data: () => ({
    loading: false,
    identity_card: null,
    photo: null,
    error: false,
    not_allowed: false,
    invalid: false
  }),
  computed: {
    disabled_btn: function() {
      return !this.identity_card || !this.photo;
    }
  },
  created() {
    this.$store.state.is_mounted_app = true;
  },
  methods: {
    patch_user: function() {
      this.error = false;
      this.not_allowed = false;
      this.invalid = false;

      this.loading = true;
      let formdata = new FormData();
      formdata.append("identity_card", this.identity_card);
      formdata.append("photo", this.photo);
      axios.patch(`/api/users/0/inscription-pj`, formdata, {headers: { "X-CSRFToken": getCookie("csrftoken")}}).then(() => {
        this.$router.push({name: "DoctorHome"})
      }).catch(error => {
        if(error.response) {
          if(error.response.status === 403 || error.response.status === 401) this.not_allowed = true;
          else if (error.response.status === 422) this.invalid = true;
          else this.error = true;
        } else this.error = true;
      }).finally(() => {
        this.loading = false;
      })
    },
    go_to_connexion: function() {
      this.$store.dispatch("axiosLogout");
      this.$router.push({name: "ConnexionLayout"});
    }
  },
}
</script>


<style scoped>

</style>