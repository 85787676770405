<template>
  <div>
    <div class="text-center">
      <v-chip v-if="user_desactive" color="red lighten-4" small>
        <span class="red--text darken-4--text semibold--text">Désactivé</span>
      </v-chip>
      <v-chip v-else-if="user_validate" color="light-green lighten-3" small>
        <span class="green--text darken-4--text semibold--text">Validé</span>
      </v-chip>
      <v-chip v-else-if="user_waiting" color="amber lighten-4" small>
        <span class="orange--text darken-4--text semibold--text">En attente</span>
      </v-chip>
      <v-icon v-else small>mdi-minus</v-icon>
      <p v-if="user_is_etab_account" :class="`${color_table_txt}--text mb-0 mt-1 font-italic font-size--10`">Compte établissement</p>
      <div>
         <v-btn v-if="!user_validate && item.status_account" x-small text color="primary" rounded @click="dialog=true">
          <span class="font-size--10 medium--text text-decoration-underline">Voir</span>
        </v-btn>
      </div>
    </div>

    <v-dialog v-model="dialog" width="600">
      <v-card>
        <div class="text-right pr-3 pt-2">
          <v-btn text icon color="primary" @click="dialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text class="pt-3 pt-sm-4 pb-15">
          <div class="text-center">
            <p class="mb-0 primary--text semibold--text text-break font-size--18">
              {{ title }}
              <br>
              {{ subtitle }}
            </p>
            <p class="font-size--14 normal--text primary_dark--text mb-0">Statut du compte : <span :class="`font-size--14 semibold--text ${status_color}`">{{ status_txt }}</span></p>
          </div>
          <v-divider class="mt-3 mt-sm-5"></v-divider>
          <v-row class="mx-0 mt-3 mt-sm-5">
            <LegendCol txt="Adresse mail" information="Cliquer sur le lien reçu par mail"/>
            <StatusCol>
              <template v-slot:status-item>
                <StatusItem v-if="user_email_validate" status="ok" text="Validé"/>
                <StatusItem v-else-if="item.email" status="wait" text="Non vérifié par le professionnel"/>
                <StatusItem v-else status="ko" text="Non renseigné par le professionnel"/>
              </template>
            </StatusCol>
            <DividerCol/>

            <LegendCol txt="Validation support" information="Les pièces justificatives doivent être renseignées par le professionnel à la première connexion"/>
            <StatusCol>
              <template v-slot:status-item>
                <StatusItem v-if="user_inscrit" status="ko" text="L'email doit être vérifié au préalable"/>
                <StatusItem v-else-if="user_email_validate" status="wait" text="En attente du support"/>
              </template>
            </StatusCol>

            <DividerCol/>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import StatusItem from "./members-status-agency/StatusItem";
import LegendCol from "./members-status-agency/LegendCol";
import StatusCol from "./members-status-agency/StatusCol";
import DividerCol from "./members-status-agency/DividerCol";

import {get_profession_name} from "anam-library/src/data/professions";
import {status_account_is_validate} from "anam-library/src/js/onboarding";

export default {
  name: "StatusAgencyItem",
  components: {StatusItem, LegendCol, StatusCol, DividerCol},
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    color_table_txt: {
      type: String,
      default: 'secondary'
    }
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    title: function() {
      return `${this.item.first_name} ${this.item.last_name.toUpperCase()}`;
    },
    subtitle: function() {
      if(this.item.professionnel) {
        let tmp =  `${get_profession_name(this.item.professionnel.dmp_type)}`;
        if(this.item.professionnel.status) tmp += ` . ${this.item.professionnel.status}`;
        return tmp;
      }
      return "";
    },
    user_validate: function() {
      return status_account_is_validate(this.item.status_account);
    },
    user_desactive: function() {
      return !this.item.is_active;
    },
    user_waiting: function() {
      return this.user_inscrit || this.user_email_validate;
    },
    user_inscrit: function() {
      return this.item.status_account === 1;
    },
    user_email_validate: function() {
      return this.item.status_account === 2;
    },
    user_is_etab_account: function() {
      return this.item.status_account === 6;
    },
    user_is_old_account: function() {
      return this.item.status_account === 4 || this.item.status_account === 5;
    },
    status_color: function() {
      if(this.item.professionnel) {
        if(this.user_validate) return "green--text darken-4--text";
        else if(this.user_desactive) return "red--text darken-4--text";
        else if(this.user_waiting) return "orange--text darken-4--text";
      }
      return null;
    },
    status_txt: function() {
      if(this.item.professionnel) {
        if(this.user_validate) return "Validé";
        else if(this.user_desactive) return "Désactivé";
        else if(this.user_waiting) return "En attente";
      }
      return null;
    },
    hf_id() {
      return this.$route.params.id;
    }
  }
}
</script>

<style scoped>

</style>