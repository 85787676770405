<template>
  <v-dialog v-model="dialog_add_cpts" width="500" persistent>
    <v-card>
      <v-card-title class="primary white--text medium--text justify-center">Créer une structure</v-card-title>
      <v-card-text class="pt-6">
        <v-form ref="form_cpts" v-model="valid_form" autocomplete="chrome-off">

          <v-row class="mx-0">
            <v-col cols="12">
              <span>Nom de la structure</span>
              <v-text-field v-model="commercial_name" filled dense :rules="[rules.required]"></v-text-field>

              <span>Adresse de la structure</span>
              <AnSearchAddressGoogleAPI ref="search_address" placeholder="Adresse" v-on:update:address="set_address($event)" class="filled-input mb-8"/>

              <span>Région de la structure</span>
              <v-autocomplete v-model="region" :items="items_regions" filled dense no-data-text="Aucune donnée" :rules="[rules.required]"></v-autocomplete>
              <v-row class="mx-0">
                <v-col cols="6">
                  <v-switch v-model="is_published" label="Publié" color="green"></v-switch>
                </v-col>
                <v-col cols="6">
                  <v-switch v-model="is_client" label="Client" color="green"></v-switch>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>

        <v-alert v-model="alert_error" type="error" text dense class="mt-3" transition="scale-transition">
          {{ msg_error }}
        </v-alert>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn outlined color="pastel_red" @click="dialog_add_cpts = false;">Annuler</v-btn>
        <v-btn :disabled="!valid_form" color="green" class="white--text px-6" :loading="loading" @click="create_cpts()">Valider</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "anam-library/src/js/rules";
import {mixin_dates} from "anam-library/src/js/dates";
import AnSearchAddressGoogleAPI from "anam-library/src/components/an-ui/an-address/AnSearchAddressGoogleAPI";
import {TRE_R30_REGIONNOM_SELECT} from "coordination-library/src/data/region";

export default {
  name: "DialogCreateCPTS",
  components: {AnSearchAddressGoogleAPI},
  mixins: [mixin_dates],
  data: () => ({
    dialog_add_cpts: false,
    commercial_name: null,
    is_published: false,
    is_client: false,
    valid_form: false,
    address: null,
    region: null,
    items_regions: TRE_R30_REGIONNOM_SELECT(),
    rules: rules,
    loading: false,
    alert_error: false,
    msg_error: null
  }),
  methods: {
    open_dialog() {
      this.dialog_add_cpts = true;
      this.address = null;
      this.alert_error = false;
      this.msg_error = null;

      this.commercial_name = null;
      this.is_published = false;
      this.is_client = false;
      this.region = null;

      setTimeout(() => {
        this.$refs.search_address.search = null;
      })
    },
    create_cpts() {
      this.loading = true;
      this.alert_error = false;
      this.msg_error = null;

      let data = {
        of_type: "cpts",
        commercial_name: this.commercial_name,
        is_published: this.is_published,
        is_client: this.is_client,
        region: this.region
      };
      if (this.address) data.address = this.address;

      this.$http.post("/api/hf_coordination", data).then(response => {
        this.dialog_add_cpts = false;
        this.$emit("update_data", response.data);
      }).catch(error => {
        if(error.response && error.response.status === 422) {
          this.alert_error = true;
          if(Object.keys(error.response.data).includes('name')) {
            this.msg_error = "Le nom de la structure existe déjà, merci d'en renseigner un nouveau";
          } else if(Object.keys(error.response.data).includes('cities_caring_for')) {
            this.msg_error = error.response.data["cities_caring_for"].join();
          } else {
            this.msg_error = "L'un des champs est invalide, nous ne pouvons pas enregistrer votre demande";
          }
          return;
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    set_address(event) {
      if (event && typeof event === 'object') {
        let address = {};
        let infos = event.address_components;
        for (let el in infos) {
          if (infos[el].types.includes('street_number')) {
            address.numeroVoie = infos[el].long_name
          } else if (infos[el].types.includes('route')) {
            address.libelleVoie = infos[el].long_name
          } else if (infos[el].types.includes('locality')) {
            address.localite = infos[el].long_name
          } else if (infos[el].types.includes('postal_code')) {
            address.codePostal = infos[el].long_name
          }
        }
        this.address = address;
      }
    }
  }
}
</script>