<template>
  <v-row class="mx-0">
    <v-col cols="12" sm="2" class="align-self-end">
      <DoctorDashboardCreateBtn v-on:move_to_create="$emit('move_to_create')"/>
    </v-col>
    <v-col cols="12" sm="10" class="align-self-end py-0">
      <p class="font-italic font-size--14 mb-0">
        Vous pouvez effectuer une recherche des patients via le nom, le prénom, la date de naissance ou le numéro de téléphone du patient.
        <br>
        <span class="font-size--14 semibold--text">Pensez à appuyer sur {{$vuetify.breakpoint.xsOnly? 'Rechercher': '"entrée"'}} une fois la saisie effectuée pour afficher les patients</span>.
      </p>
    </v-col>
    <v-col cols="12" sm="2">
      <AdvancedSearchComponent :search.sync="search_last_name" label="Nom" v-on:key_enter="search_patients()"/>
    </v-col>
    <v-col cols="12" sm="2">
      <AdvancedSearchComponent :search.sync="search_first_name" label="Prénom" v-on:key_enter="search_patients()"/>
    </v-col>
    <v-col cols="12" sm="2">
      <AdvancedSearchComponent :search.sync="search_phone" label="Téléphone" v-on:key_enter="search_patients()"/>
    </v-col>
    <v-col cols="12" sm="3">
       <AnDate :date="search_birthdate"
               label="Date de naissance" icon=""
               :filled="false"
               hide_details :validate="false"
               reset_icon solo dense
               v-on:date_reset="search_birthdate=null"
               v-on:formatted_date="search_birthdate=$event; search_patients()"/>
    </v-col>
    <v-col cols="12" sm="3">
      <DoctorDashboardTagsFilter :filter_tags.sync="search_tags"
                                 :loading="loading"
                                 v-on:change="search_patients()"/>
    </v-col>
    <v-col v-if="$vuetify.breakpoint.xsOnly" cols="12"   class="text-center align-self-end">
      <v-btn elevation="0" rounded color="primary" class="px-6" :loading="loading" :disabled="disabled_search" @click="search_patients()">
         <v-icon class="mr-1">mdi-magnify</v-icon>
         <span class="medium--text font-size--16">Rechercher</span>
       </v-btn>
    </v-col>
    <v-col cols="12">
      <AnDataTable :headers="headers"
                   :items="patients"
                   :loading="loading"
                   :table_items_mapping="table_items_mapping"
                   :mobile_card_format="true"
                   :patient_table="true"
                   no_data_txt="Aucun résultat"
                   v-on:move_to_dpa="$emit('move_to_dpa', $event)"/>
    </v-col>

  </v-row>
</template>

<script>
import AdvancedSearchComponent from "coordination-library/src/components/common/AdvancedSearchComponent";
import AnDate from "anam-library/src/components/an-date/AnDate";
import AnDataTable from "anam-library/src/components/an-data-table/AnDataTable";
import DoctorDashboardCreateBtn from "../buttons/DoctorDashboardCreateBtn";
import DoctorDashboardTagsFilter from "../filters/DoctorDashboardTagsFilter";

import {get_formatted_date} from "anam-library/src/js/dates";

export default {
  name: "DoctorAdvancedSearchPatientsView",
  components: {
    AdvancedSearchComponent,
    AnDataTable,
    AnDate,
    DoctorDashboardCreateBtn,
    DoctorDashboardTagsFilter
  },
  props: {
    table_items_mapping: {
      type: Object,
      default: () => {}
    },
    class_headers: {
      type: String,
      default: null
    }
  },
  data: () => ({
    search_last_name: null,
    search_first_name: null,
    search_phone: null,
    search_birthdate: null,
    search_tags: [],
    loading: false,
    patients: [],
  }),
  computed: {
    disabled_search: function() {
      return !this.search_last_name && !this.search_first_name && !this.search_phone && !this.search_birthdate && this.search_tags.length === 0;
    },
    headers: function() {
      let class_headers = this.class_headers;
      return [
        {text: "Sexe", value: "sexe", class: `${class_headers} rounded-tl-lg`},
        {text: "Nom / Prénom", value: "full_name", class: class_headers},
        {text: "Âge", value: "birthdate", class: class_headers},
        {text: "Adresse", value: "fullAddress", class: class_headers},
        {text: "Téléphone", value: "mobile", class: class_headers},
        {text: "Tags", value: "tags", class: class_headers},
        {text: "Fiche Patient", value: "dpa", class: `${class_headers} rounded-tr-lg`, align: "center"},
      ]
    }
  },
  created() {
    this.$store.state.is_mounted_app = true;
  },
  methods: {
    search_patients: function() {
      if(this.disabled_search) return;

      this.loading = true;
      let data = {
        "last_name": this.search_last_name,
        "first_name": this.search_first_name,
        "phone": this.search_phone
      };

      if(this.search_birthdate) data["birthdate"] = get_formatted_date(this.search_birthdate, {input_format: "DD/MM/YYYY", output_format: "YYYY-MM-DD"});
      if(this.search_tags.length > 0) data["tags"] = this.search_tags;

      this.$http.get(`/api/coordination/patients/advanced-search`, {params: data}).then(response => {
        this.patients = response.data;
      }).finally(() => {
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>