<template>
  <v-app>
    <v-main class="bg_primary pb-sm-6">
      <v-container>
        <v-row>
          <v-col cols="12" md="10" offset-md="1" class="mt-sm-15">
            <AnQuestionBloc agency_name="inzeecare"
                            app_name="inzeecare"
                            :questionnaire_uid="questionnaire_uid"
                            :is_pro_questionnaire="true"
                            progressbar_color="primary_pastel"
                            :nb_question="1"
                            :flat="true"
                            :show_back_button="false"
                            text_next_question="Suivant"/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AnQuestionBloc from "anam-library/src/components/an-questionnaire/patient/AnQuestionBloc.vue";
export default {
  name: "ProQuestionnaireView",
  components: {AnQuestionBloc},
  computed: {
    questionnaire_uid: function() {
      return this.$route.params.questionnaire_uid;
    }
  }
}
</script>

<style scoped>

</style>