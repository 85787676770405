<template>
  <v-row class="mx-0">
    <v-col cols="12" sm="8" offset-sm="2">
      <v-card class="rounded-lg elevation-0">
        <v-card-title class="white--text medium--text font-size--24" :style="`background-color: ${color_template}`">
          Demande de médecin traitant
        </v-card-title>
        <v-card-text class="mt-4 mt-sm-8">
          <p class="black--text font-size--18 medium--text mb-6">Veuillez remplir vos informations ci-dessous afin d'être recontacté par notre structure.</p>

          <v-form v-model="valid_form">
            <p class="semibold--text font-size--18 mb-1" :style="`color: ${color_template}`">Identité</p>
            <v-divider :style="`background-color: ${color_template}`"></v-divider>
            <v-row class="mx-0 mt-2">
              <v-col cols="12" ref="civilite">
                <p class="mb-0 black--text medium--text font-size--16">Civililté<v-icon color="red" size="8" class="mb-3">mdi-asterisk</v-icon></p>
                <v-radio-group v-model="civilite" row dense class="mt-0">
                  <v-radio value="MME" label="Madame"></v-radio>
                  <v-radio value="M" label="Monsieur"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="6" ref="last_name">
                <AnTextField :value.sync="last_name" required dense icon="" title="Nom de naissance" title_color="black" placeholder="Entrez votre nom" type_field="last_name"/>
              </v-col>
              <v-col cols="12" sm="6">
                <AnTextField :value.sync="last_name_used" dense icon="" title="Nom d'usage" title_color="black" placeholder="Entrez votre nom"/>
              </v-col>
              <v-col cols="12" sm="6" ref="first_name">
                <AnTextField :value.sync="first_name" required dense icon="" title="Prénom" title_color="black" placeholder="Entrez votre prénom"/>
              </v-col>
              <v-col cols="12" sm="6" ref="sex">
                <AnTextField :value.sync="sex" required dense icon="" title="Sexe" title_color="black" type_field="sexe" placeholder="Sélectionnez"/>
              </v-col>
              <v-col cols="12" sm="6" ref="birthdate">
                <AnDate :data="birthdate"
                        required
                        dense
                        is_birthdate
                        title="Date de naissance" show_title icon="" title_color="black"
                        v-on:formatted_date="birthdate=$event"/>
              </v-col>
              <v-col cols="12" sm="6" ref="birth_place">
                <AnBirthLocation :birth_city.sync="birth_city"
                                 :birth_country.sync="birth_country"
                                 title_zipcode="Lieu de naissance"
                                 title_country="Lieu de naissance"
                                 placeholder_country="Entrez un pays"
                                 placeholder_zipcode="Entrez une ville"
                                 label_foreign_checkbox="Je ne suis pas né(e) en France"
                                 icon=""
                                 title_color="black"
                                 required
                                 dense/>
              </v-col>
            </v-row>

            <p class="semibold--text font-size--18 mb-1 mt-4 mt-sm-8" :style="`color: ${color_template}`">Coordonnées de contact</p>
            <v-divider :style="`background-color: ${color_template}`"></v-divider>
            <v-row class="mx-0 mt-2">
              <v-col cols="12" sm="6">
                <AnTextField :value.sync="email" dense icon="" title="Adresse email" title_color="black" type_field="email" placeholder="Entrez une adresse email"/>
              </v-col>
              <v-col cols="12" sm="6" ref="phone">
                <AnTextField :value.sync="phone" required dense icon="" title="N˚ de téléphone" title_color="black" type_field="phone" placeholder="Entrez un numéro"/>
              </v-col>
              <v-col cols="12" ref="address">
                <p class="semibold--text mb-0 font-size--16 black--text">Adresse postale<v-icon color="red" size="8" class="mb-3">mdi-asterisk</v-icon></p>
                <AnSearchAddressGoogleAPI :address.sync="address"
                                          class_input="filled-input"
                                          placeholder="Entrez une adresse"
                                          :street_number_required="street_number_required"
                                          v-on:force_update="address=$event"/>
              </v-col>
              <v-col cols="12" sm="6">
                <AnTextField :value="localite_initial" title="Ville" dense icon="" required title_color="black" disabled/>
              </v-col>
            </v-row>

            <p class="medium--text font-size--18 mb-1 mt-4 mt-sm-8" :style="`color: ${color_template}`">Informations complémentaires</p>
            <v-divider :style="`background-color: ${color_template}`"></v-divider>
            <v-row class="mx-0 mt-2">
              <v-col cols="12">
                <p class="black--text medium--text font-size--16">
                  Pour vous accompagner de la manière la plus adaptée à votre situation, cochez les informations qui vous concernent :
                </p>

                <v-checkbox v-model="tag_css" dense hide-details label="Je bénéficie de la couverture santé solidaire"></v-checkbox>

                <div v-if="hf_specifics_tags && hf_specifics_tags.length > 0">
                  <v-checkbox v-for="(item, key) in hf_specifics_tags" :key="key"
                              v-model="specifics_tags[item].value"
                              :label="specifics_tags[item].label"
                              dense hide-details>
                  </v-checkbox>
                </div>

                <v-checkbox v-model="tag_malvoyant" dense hide-details label="Je suis malvoyant"></v-checkbox>
                <v-checkbox v-model="tag_malentendant" dense hide-details label="Je suis malentendant"></v-checkbox>
                <v-checkbox v-model="tag_incapacitesmotrices" dense hide-details label="J'ai des difficultés pour me mobiliser (marcher, me lever, sortir de chez moi)"></v-checkbox>
                <v-checkbox v-model="tag_vad" dense hide-details label="J'ai des difficultés pour me déplacer (moins de 2km en voiture par exemple) "></v-checkbox>
                <v-checkbox v-model="tag_ald" dense hide-details label="J'ai une pathologie chronique (que l'on appelle parfois Affection de longue durée)"></v-checkbox>
                <v-checkbox v-model="tag_aldpoly" dense hide-details label="J'ai plusieurs pathologies chroniques (que l'on appelle parfois Affection de longue durée)"></v-checkbox>

                <v-expand-transition>
                  <div v-if="tag_ald || tag_aldpoly" class="mt-6">
                    <v-checkbox v-model="tag_aldnonstabilisee" dense hide-details label="J’ai besoin d’aller régulièrement chez le médecin pour le suivi de ma/mes pathologie(s) chronique(s) (plus de 4 fois par an)"></v-checkbox>
                    <v-checkbox v-model="tag_hospitalisation_ald" dense hide-details label="J’ai été hospitalisé dans l’année pour un motif en rapport avec ma/mes pathologie(s) chronique(s)"></v-checkbox>
                  </div>
                </v-expand-transition>
              </v-col>
            </v-row>

            <v-row class="mx-0 mt-6">
              <v-col cols="12" ref="cgu">
                <v-checkbox v-model="cgu" :rules="[rules.required]" hide-details>
                  <template v-slot:label>
                    <span class="black--text font-size--14 pl-1 pl-sm-3">
                      Je donne mon consentement pour que les informations que j'ai renseignées servent à la création
                      et à l'alimentation d'un dossier patient sur la plateforme Citana. Ces données pourront être
                      partagées aux professionnels qui participent à ma prise en charge dans le respect de ma vie
                      privée et du secret professionnel.
                      <br>
                      {{ cgu_specific_txt }}
                    </span>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>

            <v-expand-transition>
              <v-row v-if="alert_missing_fields || alert_address || missing_fields.length > 0" class="mx-0">
                <v-col cols="12">
                  <v-alert v-if="alert_missing_fields && missing_fields.length > 0" type="error" text transition="scale-transition">
                    Le formulaire est invalide.
                  </v-alert>
                  <v-alert v-if="alert_address" type="error" text transition="scale-transition">
                    Le format de l'adresse saisie est incorrect ou votre adresse n'est pas reconnue.<br>
                    Vérifiez dans le champs ci-dessus que votre adresse comprend bien un numéro de voie, un nom de voie et un code postal.<br>
                    Si c'est le cas cliquez sur <a class="text-decoration-underline semibold--text red--text text--darken-3" @click="street_number_required=false; alert_address=false;">Je confirme qu'il s'agit bien de mon adresse</a> puis validez le formulaire.
                  </v-alert>
                  <v-alert v-if="missing_fields.length > 0" type="info" text transition="scale-transition">
                    <p class="mb-2 semibold--text">Un ou plusieurs champs sont manquants ou invalides :</p>
                    <ul>
                      <li v-for="(item, key) in missing_fields" :key="key" class="text-decoration-underline mb-2" style="cursor: pointer" @click="scroll_to_field(item.ref)">
                        {{ item.text }}
                      </li>
                    </ul>
                  </v-alert>
                </v-col>
              </v-row>
            </v-expand-transition>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center py-3 py-sm-6">
          <v-btn rounded :color="color_template" class="white--text px-12" elevation="0" large :loading="loading" @click="manage_valid_form()">
            <span class="font-size--16 medium--text">Valider</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import AnTextField from "anam-library/src/components/an-ui/textfield/AnTextField";
import AnDate from "anam-library/src/components/an-date/AnDate";
import AnBirthLocation from "anam-library/src/components/an-ui/autocomplete/AnBirthLocation";
import AnSearchAddressGoogleAPI from "anam-library/src/components/an-ui/an-address/AnSearchAddressGoogleAPI";

import axios from "axios";
import rules from "anam-library/src/js/rules"
import {get_dict_formated_address_from_google_search} from "anam-library/src/js/address";
import getCookie from "anam-library/src/js/common";
import {get_element_waiting_list} from "coordination-library/src/modules/waiting_list";

export default {
  name: "PatientWaitingListFormView",
  components: {AnTextField, AnDate, AnBirthLocation, AnSearchAddressGoogleAPI},
  data: () => ({
    loading: false,
    valid_form: false,
    last_name: null,
    last_name_used: null,
    first_name: null,
    sex: null,
    civilite: null,
    birthdate: null,
    birth_city: null,
    birth_country: null,
    email: null,
    phone: null,
    address: null,
    tag_css: false,
    tag_malvoyant: false,
    tag_malentendant: false,
    tag_incapacitesmotrices: false,
    tag_vad: false,
    tag_ald: false,
    tag_aldpoly: false,
    tag_aldnonstabilisee: false,
    tag_hospitalisation_ald: false,
    specifics_tags: {
      tag_ame: {value: false, label: 'Je bénéficie de l\'Aide Médicale de l\'État (AME)'},
      tag_misas: {value: false, label: 'Je suis accompagné par le dispositif MisAS de l\'assurance maladie'},
      tag_vad: {value: false, label: 'J\'ai besoin de visites à domicile'},
    },
    cgu: false,
    rules: rules,
    required_fields: [
      {field: "civilite", text: "Civilité", ref: "civilite"},
      {field: "last_name", text: "Nom de naissance", ref: "last_name"},
      {field: "first_name", text: "Prénom", ref: "first_name"},
      {field: "sex", text: "Sexe", ref: "sex"},
      {field: "birthdate", text: "Date de naissance", ref: "birthdate"},
      {field: "phone", text: "N˚ de téléphone", ref: "phone"},
      {field: "address", text: "Adresse postale", ref: "address"},
      {field: "cgu", text: "Consentement", ref: "cgu"},
    ],
    alert_missing_fields: false,
    alert_address: false,
    street_number_required: true
  }),
  computed: {
    hf_id: function() {
      return this.$route.params.id_health_facility;
    },
    localite_initial: function() {
      return this.$route.query.localite;
    },
    missing_fields: function() {
      let tmp = []
      if(!this.valid_form) {
        for(let el of this.required_fields) {
          if(!this[el.field]) tmp.push(el);
        }
        if(!this.birth_city && !this.birth_country) tmp.push({field: "birth_place", text: "Lieu de naissance", ref: "birth_place"})
      }
      return tmp;
    },
    color_template: function() {
      return get_element_waiting_list("color", this.$store.getters.getHfPublicTemplateName);
    },
    cgu_specific_txt: function() {
      return get_element_waiting_list("cgu_specific_txt", this.$store.getters.getHfPublicTemplateName);
    },
    hf_specifics_tags: function() {
      return this.$store.getters.getHfWaitingListSpecificsTags;
    }
  },
  methods: {
    scroll_to_field: function(ref) {
      this.$vuetify.goTo(this.$refs[ref], {'duration': 500, 'offset': 60, 'easing': 'easeOutQuad'})
    },
    manage_valid_form: function() {
      this.alert_missing_fields = false;
      this.alert_address = false;
      if(!this.valid_form) {
        this.alert_missing_fields = true;
      } else if(!this.address){
        this.alert_address = true;
        this.street_number_required = true;
      } else {
        this.create_patient()
      }
    },
    create_patient: function() {
      this.loading = true;

      let data = {
        "last_name": this.last_name,
        "last_name_used": this.last_name_used,
        "first_name": this.first_name,
        "email": this.email ? this.email : "",
        "personnephysique": {
          "civilite": this.civilite,
          "sexe": this.sex,
          "personel_phone": this.phone,
          "birthdate": {
            "birthdate": this.birthdate,
            "updated_by": "patient"
          },
          "adresse": get_dict_formated_address_from_google_search(this.address)
        },
        "tags": ["tag_mt"]
      }

      if(this.birth_city) data.personnephysique.birthdate.birth_zipcode = this.birth_city;
      if(this.birth_country) data.personnephysique.birthdate.birth_country = this.birth_country;


      let tags_fields = ["tag_css", "tag_malvoyant", "tag_malentendant", "tag_incapacitesmotrices", "tag_vad", "tag_aldnonstabilisee", "tag_hospitalisation_ald"];
      for(let el of tags_fields) {
        if(this[el]) data["tags"].push(el);
      }

      if(this.tag_aldpoly) data["tags"].push("tag_aldpoly");
      else if(this.tag_ald) data["tags"].push("tag_ald");

      for(let el in this.hf_specifics_tags) {
        let tag_type = this.hf_specifics_tags[el];
        if(this.specifics_tags[tag_type].value === true) data["tags"].push(tag_type);
      }

      axios.post(`/api/hf-coordination/${this.hf_id}/waiting-list/patients`, data, {headers: {"X-CSRFToken": getCookie('csrftoken')}}).then(() => {
        this.$router.push({name: "PatientWaitingListEnd"});
      }).catch(error => {
        if(error.response) {
          if(error.response.status === 417) {
            this.$router.push({name: "PatientWaitingListHome", query: {"invalid_commune": true}})
          } else {
            this.$store.dispatch("ManageError", error.response.status);
          }
        } else {
          this.$store.commit('PostCommonError');
        }
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>