<template>
  <v-autocomplete v-model="_filter_tags" :items="tags"
                  item-text="commercial_name" item-value="id"
                  clearable multiple solo dense
                  :disabled="loading"
                  label="Filtrer par tag" no-data-text="Aucun tag"
                  hide-details prepend-inner-icon="mdi-tag-outline"
                  @change="$emit('change')">
    <template v-slot:item="{item}">
      <v-icon :color="item.color" class="mr-3">mdi-circle</v-icon>
      <span class="normal--text font-size--14">{{ item.commercial_name }}</span>
    </template>
    <template v-slot:selection="{item}">
      <v-chip :color="item.color" text-color="white" small>
        <strong>{{ item.commercial_name }}</strong>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "DoctorDashboardTagsFilter",
  props: {
    filter_tags: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    _filter_tags: {
      get: function() {
        return this.filter_tags;
      },
      set: function(val) {
        this.$emit("update:filter_tags", val);
      }
    },
    tags: function() {
      return this.$store.getters.getAllTagsSelect;
    }
  }
}
</script>

<style scoped>

</style>