<template>
  <v-col :cols="12" :md="4">
    <p class="primary_dark--text medium--text">{{ label }}</p>
    <v-img v-if="image_url" :src="image_url" @click="open_image(image_url)" class="mb-5" contain min-height="200" max-height="200" :alt="label"/>
    <p v-else class="font-italic">non renseigné</p>
    <v-divider/>
    <div class="d-flex">
      <p class="mb-0 primary_dark--text normal--text align-self-center mr-3">ajouter/modifier {{ label }} :</p>
      <v-file-input v-model="file"
                    @change="handle_file_change()"
                    accept="image/*"
                    :rules="[rules.max_file_size, rules.accept_image]"
                    prepend-icon="mdi-image-edit"
                    hide-input/>
    </div>
  </v-col>
</template>

<script>
import rules from "anam-library/src/js/rules";

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    image_url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      file: null,
      rules: rules,
    };
  },
  methods: {
    handle_file_change() {
      this.$emit('file-change', this.file);
    },
    open_image(image_url) {
      this.$emit('open-image', image_url);
    }
  }
}
</script>

<style scoped>
</style>
