import { render, staticRenderFns } from "./CoDialogTagsHistory.vue?vue&type=template&id=f8fb7816&scoped=true"
import script from "./CoDialogTagsHistory.vue?vue&type=script&lang=js"
export * from "./CoDialogTagsHistory.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../inzeecare/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8fb7816",
  null
  
)

export default component.exports