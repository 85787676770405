<template>
  <CoProgramVisioLayout
      :paymed_option="true"
      :sms_option="true"
      api_url_email="/api_inzeecare/send_email"
      api_url_sms="/api_inzeecare/send_sms"
  ></CoProgramVisioLayout>
</template>

<script>
import CoProgramVisioLayout from "coordination-library/src/components/doctor/teleconsultation/CoProgramVisioLayout";

export default {
        name: "ProgramVisioLayout",
        components: {CoProgramVisioLayout}
    }
</script>
