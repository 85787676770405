<template>
  <v-hover v-slot="{ hover }">
    <v-card rounded :elevation="hover ? 6 : 0" class="white" @click="$emit('change_display')">
      <v-card-text class="text-center">
        <img :src="img" width="50%" alt="Image"/>
        <p class="primary_dark--text semibold--text font-size--18">{{ title }}</p>
        <v-btn color="primary" elevation="0" rounded class="px-sm-6">
          <span class="medium--text">Accéder</span>
          <v-icon class="ml-2">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-hover>
</template>


<script>
export default {
  name: "AccessStatsCard",
  props: {
    img: {
      type: String,
      default: null
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>


<style scoped>

</style>