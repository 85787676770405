<template>
  <v-col cols="7" class="align-self-center">
    <slot name="status-item"></slot>
    <slot name="status-action"></slot>
  </v-col>
</template>

<script>
export default {
  name: "StatusCol"
}
</script>

<style scoped>

</style>