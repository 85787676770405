<template>
  <v-text-field v-model="_value"
                :solo="true"
                :dense="true"
                color="primary"
                class="textfield-solo-elevation-0"
                :type="show_password ? 'text' : 'password'"
                autocomplete="new-password"
                :placeholder="placeholder"
                counter
                :rules="rules"
                :error-messages="error_messages"
                :validate-on-blur="true">
    <template v-slot:append>
      <v-avatar class="mr-2" size="20" @click="show_password = !show_password">
        <v-icon small>{{ show_password ? "mdi-eye-off" : "mdi-eye" }}</v-icon>
      </v-avatar>
    </template>
  </v-text-field>
</template>

<script>
import rules from "anam-library/src/js/rules";

export default {
  name: "RegistryPasswordField",
  props: {
    value: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      required: true
    },
    is_confirm_field: {
      type: Boolean,
      default: false
    },
    init_password: {
      type: String,
      default: null
    }
  },
  data: () => ({
    show_password: false
  }),
  computed: {
    _value: {
      get: function() {
        return this.value;
      },
      set: function(val) {
        this.$emit("update:value", val);
      }
    },
    rules: function() {
      if(!this._value) return [rules.required];
      return [rules.required, rules.password.min_length, rules.password.lower, rules.password.upper, rules.password.number, rules.password.unauthorized_characters, rules.password.special_characters];
    },
    error_messages: function() {
      if(this.is_confirm_field && this.init_password && this._value) {
        if(this.init_password !== this._value) return "Les mots de passe ne correspondent pas."
      }
      return null;
    }
  }
}
</script>

<style scoped>

</style>