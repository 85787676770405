export const HYDRATATION = {
  "of_type": "hydratation_survey",
  "title": "Surveillance apports hydriques",
  "chart_title": "Consommation hydrique en ml",
  "subtitle": "Apports hydriques",
  "fields": ["morning", "midday", "afternoon", "diner", "evening"],
  "has_stats": "Total apports hydriques du ",
  "legend": [
    {"icon": "mdi-cup-off-outline", "text": "Aucune prise hydrique", "display_in_survey": true},
    {"icon": "mdi-beer-outline", "text": "1 demi-verre = 50 ml", "display_in_survey": true},
    {"icon": "mdi-cup", "text": "1 verre = 1 eau gélifiée = 1 yaourt nature = 1 compote = 100 ml", "display_in_survey": true},
    {"icon": "mdi-cup", "text": "1 bol = 2 verres = 200ml", "twice": true, "display_in_survey": true},
  ],
  "results": [0, 50 , 100, 200],
  "questions": [
    {
      "field": "morning",
      "name": "Matin",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "midday",
      "name": "Midi",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "afternoon",
      "name": "Goûter",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "diner",
      "name": "Soir",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "evening",
      "name": "Nuit",
      "format": "radio",
      "range": 4,
      "result": false
    }
  ],
};

export const ALIMENTATION = {
  "of_type": "alimentation_survey",
  "title": "Surveillance apports alimentaires",
  "subtitle": "Apports alimentaires",
  "fields": ["morning_1", "morning_2", "midday_1", "midday_2", "midday_3","midday_4", "midday_5",
    "afternoon", "diner_1", "diner_2", "diner_3", "diner_4", "diner_5"],
  "has_stats": false,
  "legend": [
    {"icon": "mdi-close-circle-outline", "text": "La personne n'a rien consommé de la portion servie", "display_in_survey": true},
    {"icon": "mdi-circle-slice-2", "text": "Consommation de moins de 50% de la portion servie", "display_in_survey": true},
    {"icon": "mdi-circle-slice-4", "text": "Consommation de plus de 50% de la portion servie", "display_in_survey": true},
    {"icon": "mdi-circle-slice-8", "text": "Consommation de la totalité de la portion servie", "display_in_survey": true},
  ],
  "results": [],
  "questions": [
    {
      "field": null,
      "name": "Matin",
      "format": "radio",
      "range": 0,
      "result": false
    },
    {
      "field": "morning_1",
      "name": "Pain / biscotte beurée",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "morning_2",
      "name": "Bouillie",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": null,
      "name": "Déjeuner",
      "format": "radio",
      "range": 0,
      "result": false
    },
    {
      "field": "midday_1",
      "name": "Entrée",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "midday_2",
      "name": "Viande / poisson",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "midday_3",
      "name": "Légumes",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "midday_4",
      "name": "Fromage / Laitage",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "midday_5",
      "name": "Dessert",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "",
      "name": "Goûter",
      "format": "radio",
      "range": 0,
      "result": false
    },
    {
      "field": "afternoon",
      "name": "",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": null,
      "name": "Diner",
      "format": "radio",
      "range": 0,
      "result": false
    },
    {
      "field": "diner_1",
      "name": "Potage",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "diner_2",
      "name": "Viande / poisson",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "diner_3",
      "name": "Légumes",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "diner_4",
      "name": "Fromage / Laitage",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "diner_5",
      "name": "Dessert",
      "format": "radio",
      "range": 4,
      "result": false
    }
  ],
};

export const SELLES = {
  "of_type": "selle_survey",
  "title": "Surveillance selles",
  "subtitle": "Selles",
  "fields": ["morning_quantity", "morning_quality", "midday_quantity", "midday_quality", "evening_quantity", "evening_quality"],
  "has_stats": false,
  "bidimensionnal_survey": true,
  "legend": [
    {"icon": "mdi-alpha-t-circle", "text": "Qualité : Trace", "display_in_survey": true},
    {"icon": "mdi-alpha-l-circle", "text": "Qualité : Liquide", "display_in_survey": true},
    {"icon": "mdi-alpha-n-circle", "text": "Qualité : Normal", "display_in_survey": true},
    {"icon": "mdi-alpha-d-circle", "text": "Qualité : Dure", "display_in_survey": true},
    {"icon": "mdi-alpha-o-circle", "text": "Quantité : Nul"},
    {"icon": "mdi-plus-circle", "text": "Quantité : Normal"},
    {"icon": "$double_plus", "text": "Quantité : Abondante", "large": true},
    {"icon": "$triple_plus", "text": "Quantité : Très abondante", "large": true},
  ],
  "results": [],
  "questions": [
    {
      "fields": ["morning_quantity", "morning_quality"],
      "icon": ["mdi-alpha-o-circle", "mdi-plus-circle", "$double_plus", "$triple_plus"],
      "name": "Matin",
      "format": "radio",
      "range": 4,
      "range_x": 4,
      "result": ""
    },
    {
      "fields": ["midday_quantity", "midday_quality"],
      "icon": ["mdi-alpha-o-circle", "mdi-plus-circle", "$double_plus", "$triple_plus"],
      "name": "Midi",
      "format": "radio",
      "range": 4,
      "range_x": 4,
      "result": ""
    },
    {
      "fields": ["evening_quantity", "evening_quality"],
      "icon": ["mdi-alpha-o-circle", "mdi-plus-circle", "$double_plus", "$triple_plus"],
      "name": "Soir",
      "format": "radio",
      "range": 4,
      "range_x": 4,
      "result": ""
    },
  ],
};

export const DIURESE = {
  "of_type": "diurese_survey",
  "title": "Surveillance Diurèse",
  "subtitle": "Diurèse",
  "fields": ["morning_quantity", "morning_quality", "midday_quantity", "midday_quality", "evening_quantity", "evening_quality"],
  "has_stats": false,
  "bidimensionnal_survey": true,
  "legend": [
      {"icon": "mdi-alpha-t-circle", "text": "Qualité : Trouble", "display_in_survey": true},
      {"icon": "mdi-alpha-n-circle", "text": "Qualité : Normal", "display_in_survey": true},
      {"icon": "mdi-alpha-m-circle", "text": "Qualité : Malodorante", "display_in_survey": true},
      {"icon": "mdi-alpha-o-circle", "text": "Quantité : Nul"},
      {"icon": "mdi-plus-circle", "text": "Quantité : Normal"},
      {"icon": "$double_plus", "text": "Quantité : Abondante", "large": true},
      {"icon": "$triple_plus", "text": "Quantité : Très abondante", "large": true},
  ],
  "results": [],
  "questions": [
    {
      "fields": ["morning_quantity", "morning_quality"],
      "icon": ["mdi-alpha-o-circle", "mdi-plus-circle", "$double_plus", "$triple_plus"],
      "name": "Matin",
      "format": "radio",
      "range_x": 3,
      "range": 4,
      "result": ""
    },
    {
      "fields": ["midday_quantity", "midday_quality"],
      "icon": ["mdi-alpha-o-circle", "mdi-plus-circle", "$double_plus", "$triple_plus"],
      "name": "Midi",
      "format": "radio",
      "range_x": 3,
      "range": 4,
      "result": ""
    },
    {
      "fields": ["evening_quantity", "evening_quality"],
      "icon": ["mdi-alpha-o-circle", "mdi-plus-circle", "$double_plus", "$triple_plus"],
      "name": "Soir",
      "format": "radio",
      "range_x": 3,
      "range": 4,
      "result": ""
    },
  ],
};


export const PAIN = {
  "of_type": "pain_survey",
  "title": "Surveillance Douleur",
  "subtitle": "Evaluation Douleur",
  "fields": ["pain_now", "pain_before", "pain_highest"],
  "has_stats": false,
  "description": "Cotation de l'intensité de la douleur et de son soulagement à partir du choix d'un adjectif parmi cinq\n",
  "legend": [
    {"icon": "mdi-numeric-0-circle", "text": "Absente", "display_in_survey": true},
    {"icon": "mdi-numeric-1-circle", "text": "Faible", "display_in_survey": true},
    {"icon": "mdi-numeric-2-circle", "text": "Modérée", "display_in_survey": true},
    {"icon": "mdi-numeric-3-circle", "text": "Intense", "display_in_survey": true},
    {"icon": "mdi-numeric-4-circle", "text": "Extrêmement intense", "display_in_survey": true},
  ],
  "results": [],
  "questions": [
    {
      "field": "pain_now",
      "name": "Douleur au moment présent",
      "format": "radio",
      "range": 5,
      "result": false
    },
    {
      "field": "pain_before",
      "name": "Douleur habituelle depuis les 8 derniers jours",
      "format": "radio",
      "range": 5,
      "result": false
    },
    {
      "field": "pain_highest",
      "name": "Douleur la plus intense depuis les 8 derniers jours",
      "format": "radio",
      "range": 5,
      "result": false
    }
  ],
};

export const HEAL = {
  "of_type": "heal_survey",
  "title": "Traçabilité soins et accompagnements",
  "subtitle": "Soins",
  "fields": ["toilette_lavabo", "toilette_lit", "douche", "shampoing", "pediluve", "ongle", "dent", "rasage",
    "effleurage", "escarre", "pansement", "lavage_occulaire", "collyre", "instal_toilette", "protection", "habillage",
    "contention", "coucher", "aide_marche", "aide_medicament", "draps", "deshabillage", "lever", "temp_echange",
    "temp_activite", "course", "toilette_intime", "aide_prise_repas", "prepare_repas", "transfer_manuel",
    "transfer_disque", "transfer_verticalisateur", "transfer_leve_personne", "change_vetement_haut", "change_vetement_bas",
    "change_sous_vetement", "aliment_prescription", "contention_prescription", "tele_assistance", "auditif_droite",
    "auditif_gauche", "prothese_dentaire_haut", "prothese_dentaire_bas"
  ],
  "has_stats": false,
  "legend": [
      {"icon": "mdi-clock-time-three", "text": "Matin", "display_in_survey": true},
      {"icon": "mdi-clock-time-six", "text": "Midi", "display_in_survey": true},
      {"icon": "mdi-clock-time-nine", "text": "Après-midi", "display_in_survey": true},
      {"icon": "mdi-clock-time-twelve", "text": "Soir", "display_in_survey": true},
  ],
  "results": [],
  "questions": [
    {
      "field": "toilette_lavabo",
      "name": "Toilette lavabo",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "toilette_lit",
      "name": "Toilette lit",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "douche",
      "name": "Douche",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "shampoing",
      "name": "Capiluve / Shampoing",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "pediluve",
      "name": "Pédiluve",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "ongle",
      "name": "Ongles",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "dent",
      "name": "Hygiène bucco-dentaire",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "rasage",
      "name": "Rasage",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "effleurage",
      "name": "Effleurage",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "escarre",
      "name": "Prévention d'escarre",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "pansement",
      "name": "Pansement préventif",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "lavage_occulaire",
      "name": "Lavage occulaire",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "collyre",
      "name": "Collyres sur prescription médicale",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "instal_toilette",
      "name": "Installation chaise pot / WC",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "protection",
      "name": "Change de protection",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "habillage",
      "name": "Habillage",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "deshabillage",
      "name": "Deshabillage",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "contention",
      "name": "Bas de contention",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "lever",
      "name": "Lever",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "coucher",
      "name": "Coucher",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "aide_marche",
      "name": "Aide à la marche",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "aide_medicament",
      "name": "Aide à la prise de médicament",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "draps",
      "name": "Chgt de draps",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "temp_echange",
      "name": "Temps d'échange relationnel et soutien",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "temp_activite",
      "name": "Accompagnt aux activités",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "course",
      "name": "Courses",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "toilette_intime",
      "name": "Toilette intime",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "aide_prise_repas",
      "name": "Aide à la prise des repas",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "prepare_repas",
      "name": "Préparation des repas",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "transfer_manuel",
      "name": "Transfert avec aide manuelle et sécurisation",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "transfer_disque",
      "name": "Transfert avec disque ou guidon de transfert",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "transfer_verticalisateur",
      "name": "Transfert avec verticalisateur",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "transfer_leve_personne",
      "name": "Transfert avec lève-personne",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "change_vetement_haut",
      "name": "Chgt de vêtements haut",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "change_vetement_bas",
      "name": "Chgt de vêtements bas",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "change_sous_vetement",
      "name": "Chgt de sous-vêtements",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "aliment_prescription",
      "name": "Complément alimentaire sur prescription médicale",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "contention_prescription",
      "name": "Contention sur prescription médicale (ceinture de maintien, barrière de lit)",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "tele_assistance",
      "name": "Port téléassistance (montre ou collier)",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "auditif_droite",
      "name": "Appareil auditif (droite)",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "auditif_gauche",
      "name": "Appareil auditif (gauche)",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "prothese_dentaire_haut",
      "name": "Prothèse dentaire (haut)",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "prothese_dentaire_bas",
      "name": "Prothèse dentaire (bas)",
      "format": "radio",
      "range": 4,
      "result": false
    },
  ],
};

export const ENTRETIEN = {
  "of_type": "entretien_survey",
  "title": "Traçabilité entretien",
  "subtitle": "Entretien",
  "fields": ["sol_aspirateur", "sol_balai", "sol_serpillere", "vitre", "poussiere", "sanitaire", "cuisine", "frigo_tri",
    "linge", "poubelle_vider", "litiere", "frigo_conservation", "frigo_peremption", "frigo_nettoyage", "poubelle_sortir",
    "relationnel_soutien", "activite", "course"
  ],
  "has_stats": false,
  "legend": [
      {"icon": "mdi-numeric-0-circle", "text": "Matin", "display_in_survey": true},
      {"icon": "mdi-numeric-1-circle", "text": "Midi", "display_in_survey": true},
      {"icon": "mdi-numeric-2-circle", "text": "Après-midi", "display_in_survey": true},
      {"icon": "mdi-numeric-3-circle", "text": "Soir", "display_in_survey": true},
  ],
  "results": [],
  "questions": [
    {
      "field": "sol_aspirateur",
      "name": "Entretien sol (aspirateur)",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "sol_balai",
      "name": "Entretien sol (balai)",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "sol_serpillere",
      "name": "Entretien sol (serpillère)",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "vitre",
      "name": "Vitres",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "poussiere",
      "name": "Poussières",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "sanitaire",
      "name": "Entretien sanitaire",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "cuisine",
      "name": "Entretien cuisine",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "frigo_tri",
      "name": "Gestion frigo (tri)",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "frigo_conservation",
      "name": "Gestion frigo (conservation des aliments)",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "frigo_peremption",
      "name": "Gestion frigo (date de péremption)",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "frigo_nettoyage",
      "name": "Gestion frigo (nettoyage)",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "linge",
      "name": "Gestion du linge",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "poubelle_vider",
      "name": "Vider les poubelles dans le conteneur",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "poubelle_sortir",
      "name": "Sortir le conteneur à poubelles",
      "format": "radio",
      "range": 4,
      "result": false
    },
    {
      "field": "litiere",
      "name": "Litière",
      "format": "radio",
      "range": 4,
      "result": false
    }
  ],
};

export const QUESTIONNAIRES_DOMICILE = [
  {"of_type": "hydratation_survey", "data": HYDRATATION},
  {"of_type": "alimentation_survey", "data": ALIMENTATION},
  {"of_type": "selle_survey", "data": SELLES},
  {"of_type": "diurese_survey", "data": DIURESE},
  {"of_type": "pain_survey", "data": PAIN},
  {"of_type": "heal_survey", "data": HEAL},
  {"of_type": "entretien_survey", "data": ENTRETIEN},
]
