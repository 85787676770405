<template>
  <p :class="`${color}--text medium--text text-center mb-0`">
    <v-icon :color="color" class="mr-2">{{ icon }}</v-icon>
    {{ text }}
  </p>
</template>

<script>
export default {
  name: "StatusItem",
  props: {
    status: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  computed: {
    color: function() {
      if(this.status === 'ok') return 'green';
      else if(this.status === 'ko') return 'red';
      else if(this.status === 'wait') return 'orange';
      else return ""
    },
    icon: function() {
      if(this.status === 'ok') return 'mdi-check';
      else if(this.status === 'ko') return 'mdi-close';
      else if(this.status === 'wait') return 'mdi-timer-sand-empty';
      else return ""
    }
  }
}
</script>

<style scoped>

</style>