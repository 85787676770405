<template>
  <AnTelInputVuetify :initial_number="initial_value"
                     :required="required"
                     :countries_restrictions="['FR']"
                     :filled="false"
                     dense
                     bg_color="white"
                     :label="null"
                     specific_class="solo-tel-input"
                     placeholder="Ex: 0658866775"
                     v-on:update_phone="$emit('update', $event)"/>
</template>

<script>
import AnTelInputVuetify from "anam-library/src/components/an-ui/phone/AnTelInputVuetify.vue";

export default {
  name: "RegistryPhoneField",
  components: {AnTelInputVuetify},
  props: {
    initial_value: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>