<template>
  <v-card class="rounded-lg ma-1">
    <v-card-text>
      <v-row class="mx-0">
        <v-col cols="12" sm="4">
          <p class="align-self-center primary--text semibold--text font-size--16 mb-0">
            <v-icon color="tertiary" class="mr-1">mdi-tune-variant</v-icon>
            Choisir le protocole :
          </p>
          <v-autocomplete v-model="filter_protocol"
                          :items="protocols_items"
                          :loading="loading_pros"
                          no-data-text="Aucun résultat"
                          hide-details
                          @change="set_statistics()"/>
        </v-col>
      </v-row>
      <ManagerProtocolsProfessionalsTable :protocol_commercial_name="selected_protocol_name"
                                          :protocol_name="filter_protocol"
                                          :is_has_protocol="selected_protocol_is_has"
                                          title="Délégués"
                                          :loading="loading_pros"
                                          :headers="headers_delegated"
                                          :items="pds_delegated"
                                          :app_name="app_name"
                                          questionnaire_type="coordination-pt-satisfaction-delegues"
                                          v-on:action="open_dialog_duo($event)">
        <template v-slot:append-action>
          <div v-if="selected_protocol_is_has" class="align-self-center ml-3">
            <v-btn color="primary" text @click="dialog_professionals_delegates=true">
              <span class="medium--text text-decoration-underline font-size--16">Modifier la liste des délégués</span>
            </v-btn>
          </div>
        </template>
        <template v-slot:subtitle v-if="selected_protocol_is_has">
          <p class="font-italic font-size--12 normal--text mb-0">Délégant(s) associé(s) : Indiquer le(s) délégant(s) que le délégué pourra désigner pour ce protocole.</p>
        </template>
      </ManagerProtocolsProfessionalsTable>

      <ManagerProtocolsProfessionalsTable v-if="selected_protocol_is_has"
                                          :protocol_commercial_name="selected_protocol_name"
                                          :protocol_name="filter_protocol"
                                          :is_has_protocol="true"
                                          title="Délégants"
                                          :loading="loading_pros"
                                          :headers="headers_delegating"
                                          :items="pds_delegating"
                                          :app_name="app_name"
                                          questionnaire_type="coordination-pt-satisfaction-delegants">
        <template v-slot:append-action>
          <div class="align-self-center ml-3">
            <v-btn color="primary" text @click="dialog_professionals_delegating=true">
              <span class="medium--text text-decoration-underline font-size--16">Modifier la liste des délégants</span>
            </v-btn>
          </div>
        </template>
      </ManagerProtocolsProfessionalsTable>

      <ManagerProtocolsManageDelegatingDialog v-if="selected_protocol_is_has"
                                              :dialog.sync="dialog_professionals_delegating"
                                              :protocol="selected_protocol"
                                              :hf_id="hf_coordination_id"
                                              of_type="delegating"
                                              v-on:update_protocol="update_protocol($event)"/>

      <PtManageDuoDialog v-if="selected_protocol_is_has"
                         :dialog.sync="dialog_manage_duo"
                         :hf_id="hf_coordination_id"
                         :selected_delegate="selected_delegate"
                         :selected_protocol="selected_protocol"
                         v-on:update_protocol="update_protocol($event)"/>

      <ManagerProtocolsManageDelegatingDialog v-if="selected_protocol_is_has"
                                              :dialog.sync="dialog_professionals_delegates"
                                              :protocol="selected_protocol"
                                              :hf_id="hf_coordination_id"
                                              of_type="delegates"
                                              v-on:update_protocol="update_protocol($event)"/>

      <v-divider class="my-10"></v-divider>
      <ManagerProtocolsTable :hf_coordination_id="hf_coordination_id"
                             :protocols="protocols_filtered"
                             :loading="loading"
                             v-on:update_protocols="$emit('update:protocols', $event)"/>
    </v-card-text>
  </v-card>

</template>

<script>
import ManagerProtocolsTable from "./components/ManagerProtocolsTable.vue";
import ManagerProtocolsProfessionalsTable from "./components/ManagerProtocolsProfessionalsTable.vue";
import ManagerProtocolsManageDelegatingDialog from "./components/ManagerProtocolsManageDelegatingDialog.vue";
import PtManageDuoDialog from "./components/PtManageDuoDialog.vue";

export default {
  name: "ManagerHistoricProtocolsView",
  components: {
    PtManageDuoDialog,
    ManagerProtocolsManageDelegatingDialog,
    ManagerProtocolsProfessionalsTable,
    ManagerProtocolsTable,
  },
  props: {
    app_name: {
      type: String,
      required: true
    },
    protocols: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading_pros: false,
    filter_protocol: null,
    dialog_professionals_delegates: false,
    dialog_professionals_delegating: false,
    dialog_manage_duo: false,
    selected_delegate: null,
    authors_questionnaires: [],
    delegating_questionnaires: [],
    headers_delegating: [
      {text: 'Nom', value: 'last_name', class: 'primary_dark--text'},
      {text: 'Prénom', value: 'first_name', class: 'primary_dark--text'},
      {text: 'Profession', value: 'dmp_type', class: 'primary_dark--text'},
      {text: 'Nombre de fois désigné délégant', value: 'nb_protocoles', class: 'primary_dark--text', align: "center"},
      {text: 'Délégué(s) associé(s)', value: 'nb_delegate_associated', class: 'primary_dark--text', align: "center"},
      {text: "Actuellement autorisé", value: "active", class:"primary_dark--text", align: "center"}
    ],
    settings_protocols: [],
  }),
  computed: {
    hf_coordination_id: function() {
      return this.$route.params.id
    },
    protocols_filtered: function() {
      let tmp = JSON.parse(JSON.stringify(this.protocols));
      return tmp.filter(x => x.protocol === this.filter_protocol);
    },
    protocols_open: function() {
      return JSON.parse(JSON.stringify(this.protocols_filtered.filter(x => !x.closed)));
    },
    protocols_items: function() {
      let tmp = []
      for(let el of this.settings_protocols) {
        tmp.push({text: el.meta_protocol.commercial_name, value: el.meta_protocol.name});
      }
      return tmp;
    },
    selected_protocol: function() {
      if(this.filter_protocol) {
        let index = this.settings_protocols.findIndex(x => x.meta_protocol.name === this.filter_protocol);
        if(index > -1) return this.settings_protocols[index];
      }
      return null
    },
    selected_protocol_name: function() {
      if(this.selected_protocol) return this.selected_protocol.meta_protocol.commercial_name;
      return null;
    },
    selected_protocol_duo: function() {
      if(this.selected_protocol) return this.selected_protocol.duo_delegate_delegating;
      else return {};
    },
    selected_protocol_is_has: function() {
      if(this.selected_protocol) return this.selected_protocol.meta_protocol.is_has_protocol;
      return false;
    },
    headers_delegated: function() {
      let tmp = [
        {text: 'Nom', value: 'last_name', class: 'primary_dark--text'},
        {text: 'Prénom', value: 'first_name', class: 'primary_dark--text'},
        {text: 'Profession', value: 'dmp_type', class: 'primary_dark--text'},
        {text: 'Nombre de protocoles lancés', value: 'nb_protocoles', class: 'primary_dark--text', align: "center"}
      ]
      if(this.selected_protocol_is_has) {
        tmp.push({text: 'Délégant(s) associé(s)', value: 'nb_delegating_associated', class: 'primary_dark--text', align: "center"});
        tmp.push({text: 'Action', value: 'actions', class: 'primary_dark--text', sortable: false, align: "center"});
        tmp.push({text: "Actuellement autorisé", value: "active", class:"primary_dark--text", align: "center"});
      }
      return tmp;
    },
    pds_delegated: function() {
      let tmp = [];
      if(this.selected_protocol) {
        tmp = this.selected_protocol.delegate_professionals.map(el => {
          el.nb_protocoles = 0;
          if(this.selected_protocol_is_has) {
            el.active = true;
            let pro_id = el.id ? el.id.toString() : null;
            // TODO charlotte : a reprendre
            try{
              if(this.selected_protocol_duo && Object.keys(this.selected_protocol_duo).length > 0 && Object.keys(this.selected_protocol_duo).includes(pro_id)) {
                el.nb_delegating_associated = this.selected_protocol_duo[pro_id].length;
              }
            } catch {
              // pass
            }
          }
          return el;
        });
      }

      for(let el of this.authors_questionnaires) {
        let index = tmp.findIndex(x => x.user_id === el.author);
        if(index > -1) tmp[index]["nb_protocoles"] = el.nb_protocoles;
        else {
          tmp.push({
            last_name: el.author_last_name,
            first_name: el.author_first_name,
            dmp_type: el.author_dmp_type,
            user_id: el.author,
            nb_protocoles: el.nb_protocoles,
            active: false
          });
        }
      }
      return tmp.sort((a, b) => {return b.nb_protocoles - a.nb_protocoles});
    },
    pds_delegating: function() {
      let tmp = [];
      if(this.selected_protocol) {
        tmp = this.selected_protocol.delegating_professionals.map(el => {
          el.nb_protocoles = 0;
          el.active = true;

          let tmp_nb = 0;
          for(let item in this.selected_protocol_duo) {
            // TODO charlotte : a reprendre
            try{
              if(this.selected_protocol_duo[item].includes(el.id)) tmp_nb += 1;
            } catch {
              // pass
            }

          }
          el.nb_delegate_associated = tmp_nb;
          return el;
        });
      }

      for(let el of this.delegating_questionnaires) {
        let index = tmp.findIndex(x => x.id === el.professional_delegating);
        if(index > -1) tmp[index]["nb_protocoles"] = el.nb_protocoles;
        else {
          tmp.push({
            last_name: el.delegating_last_name,
            first_name: el.delegating_first_name,
            dmp_type: el.delegating_dmp_type,
            id: el.professional_delegating,
            nb_protocoles: el.nb_protocoles,
            active: false
          });
        }
      }
      return tmp.sort((a, b) => {return b.nb_protocoles - a.nb_protocoles});
    },
  },
  watch: {
    hf_coordination_id: function(val) {
      if(val) this.get_settings_protocols();
    },
    protocols: function(val) {
      if(val) this.set_statistics();
    }
  },
  created() {
    this.get_settings_protocols();
  },
  methods: {
    get_settings_protocols: function() {
      this.loading_pros = true;
      this.$http.get(`/api/hf-coordination/${this.hf_coordination_id}/settings-protocols`).then(response => {
        this.settings_protocols = response.data;
        if(this.settings_protocols.length > 0) this.filter_protocol = this.settings_protocols[0].meta_protocol.name;
        else this.filter_protocol = null;
        this.set_statistics();
      }).finally(() => {
        this.loading_pros = false;
      })
    },
    set_statistics: function() {
      let tmp_author = [];
      let tmp_delegating = [];

      for(let el of this.protocols_open) {
        let index_author = tmp_author.findIndex(x => x.author === el.author);
        if(index_author > -1) tmp_author[index_author]["nb_protocoles"] += 1;
        else tmp_author.push({...el, ...{"nb_protocoles": 1}});

        if(el.professional_delegating) {
          let index_delegating = tmp_delegating.findIndex(x => x.professional_delegating === el.professional_delegating);
          if(index_delegating > -1) tmp_delegating[index_delegating]["nb_protocoles"] += 1;
          else tmp_delegating.push({...el, ...{"nb_protocoles": 1}});
        }
      }

      this.authors_questionnaires = tmp_author;
      this.delegating_questionnaires = tmp_delegating;
    },
    update_protocol: function(data) {
      let tmp = JSON.parse(JSON.stringify(this.protocols));
      let index = tmp.findIndex(x => x.id === data.id);
      if(index > -1) {
        tmp[index] = data;
      }
      this.$emit("update:protocols", tmp);
    },
    open_dialog_duo: function(data_pro) {
      this.dialog_manage_duo = true;
      this.selected_delegate = data_pro;
    }
  },
}
</script>

<style scoped>

</style>