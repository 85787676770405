<template>
  <v-row class="mx-0">
    <v-col cols="12" sm="4">
      <v-card rounded elevation="0">
        <v-card-text>
          <div  v-if="loading_members" class="text-center">
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
          </div>
          <v-expand-transition>
            <div v-if="!loading_members">
              <p :class="`${color_title}--text semibold--text font-size--18 mb-0`">Répartition des professionnels de santé</p>
              <v-btn x-small class="px-0" :readonly="true" color="white" elevation="0"></v-btn>
              <apex-chart v-if="members && members.length"
                          type="pie"
                          class="mt-5"
                          height="500"
                          :series="Object.values(repartition_doctors)"
                          :options="{
                            labels: Object.keys(repartition_doctors),
                            legend: {position: 'bottom', height: 100},
                            noData: {text: 'Aucune donnée'},
                            dataLabels: {
                              formatter: function(val ,opts) {
                                return opts.w.config.series[opts.seriesIndex];
                              }
                            }
                          }"/>
              <p v-else class="text-center mt-5 semibold--text">Aucune donnée</p>
            </div>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="8" class="pt-0">
      <v-row class="ma-0">
        <v-col v-if="show_snp" cols="6" sm="4">
          <AccessStatsCard title="Planning" :img="img_snp" v-on:change_display="$emit('change_display', 1)"/>
        </v-col>
        <v-col v-if="show_ddi" cols="6" sm="4">
          <AccessStatsCard title="Demandes d'intervention" :img="img_ddi" v-on:change_display="$emit('change_display', 2)"/>
        </v-col>
        <v-col v-if="show_protocoles" cols="6" sm="4">
          <AccessStatsCard title="Protocoles" :img="img_pt" v-on:change_display="$emit('change_display', 3)"/>
        </v-col>
        <v-col cols="6" sm="4">
          <AccessStatsCard title="Tags" :img="img_patients" v-on:change_display="$emit('change_display', 4)"/>
        </v-col>
        <v-col v-if="show_wt" cols="6" sm="4">
          <AccessStatsCard title="Liste d'attente MT" :img="img_patients" v-on:change_display="$emit('change_display', 5)"/>
        </v-col>
        <v-col cols="6" sm="4">
          <AccessStatsCard title="Télémédecine" :img="img_tlc" v-on:change_display="$emit('change_display', 6)"/>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import img_patients from "@/assets/patients.png";
import img_protocols from "../../../../../assets/protocoles.png";
import img_snp from "@/assets/snp.png";
import img_tlc from "@/assets/tlc.png";
import img_all_professions from "../../../../../assets/all_professions.png";


import {get_profession_name} from "anam-library/src/data/professions";
import AccessStatsCard from "./components/AccessStatsCard.vue";

export default {
  name: "ManagerStatisticsCardGeneral",
  components: {AccessStatsCard},
  props: {
    hf_coordination_id: {
      type: String,
      default: null
    },
    color_title: {
      type: String,
      default: 'primary'
    },
    show_snp: {
      type: Boolean,
      default: false
    },
    show_ddi: {
      type: Boolean,
      default: false
    },
    show_wt: {
      type: Boolean,
      default: false
    },
    show_protocoles: {
      type: Boolean,
      default: false
    },
    show_tlc: {
      type: Boolean,
      default: false
    },
    members: {
      type: Array,
      default: () => []
    },
    loading_members: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    img_snp: img_snp,
    img_ddi: img_all_professions,
    img_patients: img_patients,
    img_tlc: img_tlc,
    img_pt: img_protocols,
  }),
  computed: {
    repartition_doctors() {
      let data = {};
      for (let pds of this.members) {
        let profession = get_profession_name(pds.professionnel.dmp_type, pds.professionnel.speciality_code);
        if (profession in data) data[profession] += 1;
        else data[profession] = 1;
      }
      return data;
    }
  }
}
</script>

<style scoped>

</style>