<template>
  <div>
    <v-btn color="primary" text rounded small @click="dialog=true">
      <span class="normal--text text-decoration-underline">Voir</span>
    </v-btn>

    <AnDialogComponent :dialog.sync="dialog" title="Accès aux listes d'attente" :actions="true">
      <template v-slot:card-text>
        <div v-for="(item, key) in access_waiting_list" :key="key">
          <v-checkbox v-model="access_waiting_list[key]"
                      hide-details
                      :label="get_name_service(key)">
          </v-checkbox>
        </div>
        <p class="font-size--16 mt-5 mt-sm-10">
          Si vous cochez une case, cela donnera accès à ce professionnel aux patients inscrits sur la liste concernée.
        </p>
      </template>
      <template v-slot:card-actions>
        <v-btn text class="px-6" @click="dialog=false">
          <span class="text-decoration-underline">Annuler</span>
        </v-btn>
        <v-btn class="px-6" elevation="0" color="green" :loading="loading" @click="manage_pro_in_waiting_list()">
          <span class="white--text medium--text font-size--16">Enregister</span>
        </v-btn>
      </template>
    </AnDialogComponent>
  </div>
</template>

<script>
import AnDialogComponent from "anam-library/src/components/an-ui/an-dialog/AnDialogComponent";

import {get_name_waiting_list} from "coordination-library/src/modules/waiting_list";

export default {
  name: "WaitingListItem",
  components: {AnDialogComponent},
  props: {
    hf_id: {
      type: String,
      required: true
    },
    hf_data: {
      type: Object,
      default: () => {}
    },
    user: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    dialog: false,
    loading: false,
    has_access: false,
    access_waiting_list: {}
  }),
  watch: {
    user: function(val) {
      if(val) this.access_waiting_list = val.waiting_list;
    },
    dialog: function(val) {
      if(!val) this.access_waiting_list = this.user.waiting_list;
    }
  },
  created() {
    this.access_waiting_list = this.user.waiting_list;
  },
  methods: {
    get_name_service: function(of_type) {
      return get_name_waiting_list(of_type);
    },
    manage_pro_in_waiting_list: function() {
      for(let of_type in this.access_waiting_list) {
        this.loading = true;
        let action = null;
        if(this.user.waiting_list[of_type]) action = "add";
        else action = "remove"
        let data = {
          action: `${action}_pds`,
          id_pds: this.user.professionnel.id
        }
        this.$http.put(`/api/hf-coordination/${this.hf_id}/services/${this.hf_data.services_waiting_list[of_type]}`, data).then(() => {
          this.$store.commit('PostSuccess', 'Modification enregistrée');
        }).finally(() => {
          this.loading = false;
          this.dialog = false;
        })
      }
    },
  }
}
</script>

<style scoped>

</style>