<template>
  <div>
    <div v-for="(item, key) in item.structures" :key="`structure_${key}`">
      <span class="normal--text">{{ item }}</span>
      <br>
    </div>
  </div>
</template>

<script>
export default {
  name: "StructuresItem",
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped>

</style>