<template>
  <v-container fluid class="pt-3 pb-15">
    <v-row class="mx-0">
      <v-col cols="12" sm="11" class="pl-sm-6 align-self-center">
        <p class="semibold--text mb-1 font-size--24 primary--text">Pilotage opérationnel - {{ hf_name }}</p>
        <v-tabs v-model="tabs" show-arrows background-color="bg_primary" color="primary_dark" active-class="bg_primary_2 rounded-xl primary--text semibold--text" hide-slider>
          <v-tab class="font-size--16 semibold--text" style="text-transform: none" href="#members">Membres</v-tab>
          <v-tab v-if="_has_access_to_ddi" class="font-size--16 semibold--text" style="text-transform: none" href="#etablissements">Établissements</v-tab>
          <v-tab class="font-size--16 semibold--text" style="text-transform: none" href="#statistics">Statistiques</v-tab>
          <v-tab class="font-size--16 semibold--text" style="text-transform: none" href="#tags">Tags</v-tab>
          <v-tab v-if="_has_access_to_ddi" class="font-size--16 semibold--text" style="text-transform: none" href="#ddi">Demandes d'interventions</v-tab>
          <v-tab v-if="_has_access_to_protocols" class="font-size--16 semibold--text" style="text-transform: none" href="#protocols">Protocoles</v-tab>
          <v-tab v-if="_has_access_to_communication" class="font-size--16 semibold--text" style="text-transform: none" href="#communication">Communication</v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="1" class="pb-0 hidden-xs-only">
        <img :src="img" alt="Logo" width="100%"/>
      </v-col>
      <v-col cols="12" class="px-0 px-sm-3">
        <v-tabs-items v-model="tabs" style="width: 100%" class="bg_primary pb-10">
          <v-tab-item value="members">
            <HealthPractitionersCPTS app_name="inzeecare"
                                     color_create_button="green"
                                     url_send_email_rpps="/api/send_inzeecare_invitation_email"
                                     :members="members"
                                     :managers_users="managers_users"
                                     :hf_name="hf_name"
                                     :hf_data="hf_data"
                                     :loading.sync="loading"
                                     v-on:reload_data="get_cpts_data()"/>
          </v-tab-item>
          <v-tab-item v-if="_has_access_to_ddi" value="etablissements">
            <StructuresView :hf_name="hf_name"
                            :app_name="app_name"
                            :color_title="color_title"
                            :color_btn_actions="color_btn_actions"
                            :color_txt="color_txt"/>
          </v-tab-item>
          <v-tab-item value="statistics">
            <StatisticsCPTS :members="members"
                            :loading_members="loading"
                            :hf_name="hf_name"
                            :hf_finess="hf_finess"
                            :protocols.sync="protocols"
                            :loading_protocols="loading_protocols"
                            :color_title="color_title"
                            :color_txt="color_txt"/>
          </v-tab-item>
          <v-tab-item value="tags">
            <AdminTagsTab :color_title="color_title"
                          :color_txt="color_txt"/>
          </v-tab-item>
          <v-tab-item v-if="_has_access_to_ddi" value="ddi">
            <HistoricDemands :app_name="app_name"
                             :hf_name="hf_name"
                             :members="members"
                             :items.sync="ddi"
                             :loading="loading_ddi"
                             :loading_members="loading"
                             :hf_cities_caring_for="hf_cities_caring_for"
                             :color_title="color_title"
                             :color_txt="color_txt"
                             v-on:reload_data="get_cpts_data()"/>
          </v-tab-item>
          <v-tab-item v-if="_has_access_to_protocols" value="protocols">
            <ManagerHistoricProtocolsView :app_name="app_name"
                                          :protocols.sync="protocols"
                                          :loading="loading_protocols"/>
          </v-tab-item>
          <v-tab-item v-if="_has_access_to_communication" value="communication">
            <ManagerCommunicationTab :members="members"
                                     :loading="loading"
                                     :hf_name="hf_name"/>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StatisticsCPTS from "./statistics/StatisticsCPTS";
import AdminTagsTab from "./tags/AdminTagsTab";
import HistoricDemands from "./ddi/HistoricDemands";
import StructuresView from "./etablissements/StructuresView";
import ManagerHistoricProtocolsView from "./protocoles/ManagerHistoricProtocolsView.vue";
import HealthPractitionersCPTS from "@/components/manager/structures/details/members/HealthPractitionersCPTS.vue";
import ManagerCommunicationTab from "@/components/manager/structures/details/communication/ManagerCommunicationTab.vue";

import axios from "axios";
import img_admin from "@/assets/admin.png";

export default {
  name: "ManageCPTSView",
  components: {
    ManagerCommunicationTab,
    StatisticsCPTS,
    AdminTagsTab,
    HistoricDemands,
    StructuresView,
    ManagerHistoricProtocolsView,
    HealthPractitionersCPTS,
  },
  props: {
    app_name: {
      type: String,
      default: "inzeecare"
    },
    color_title: {
      type: String,
      default: 'primary'
    },
    color_btn_actions: {
      type: String,
      default: 'primary'
    },
    color_txt: {
      type: String,
      default: 'primary_dark'
    }
  },
  data: () => ({
    img: img_admin,
    members: [],
    managers_users: [],
    hf_name: null,
    hf_finess: null,
    loading: false,
    hf_cities_caring_for: {},
    hf_data: {},
    loading_ddi: false,
    ddi: [],
    ddi_loaded: false,
    loading_protocols: false,
    protocols: [],
    protocols_loaded: false,
  }),
  mounted() {
    this.$store.state.is_mounted_app = true;
    this.get_cpts_data();
    if(this._has_access_to_ddi && this.tabs === "ddi") this.get_ddi();
    if(this._has_access_to_protocols && (this.tabs === "statistics" || this.tabs === "protocols") && !this.protocols_loaded) this.get_protocols();
  },
  computed: {
    tabs: {
      get: function() {
        return this.$store.getters.getTabSelectedInManage;
      },
      set: function(val) {
        this.$store.commit('setTabSelectedInManage', val);
      }
    },
    hf_id: function() {
      return this.$route.params.id;
    },
    _has_access_to_ddi: function() {
      return this.$store.getters.getPermission("has_access_to_ddi");
    },
    _has_access_to_protocols: function() {
      return this.$store.getters.getPermission("has_access_to_protocoles");
    },
    _has_access_to_communication: function() {
      return this.$store.getters.getPermission("has_access_to_communication");
    }
  },
  watch: {
    hf_id(val) {
      if(val) {
        this.get_cpts_data();

        this.ddi_loaded = false;
        this.ddi = [];
        if(this._has_access_to_ddi && this.tabs === "ddi") this.get_ddi();

        this.protocols_loaded = false;
        this.protocols = [];
        if (this._has_access_to_protocols && (this.tabs === "statistics" || this.tabs === "protocols")) this.get_protocols();
      }
    },
    tabs: function(val) {
      if (this._has_access_to_ddi && val === "ddi" && !this.ddi_loaded) this.get_ddi();
      if (this._has_access_to_protocols && (val === "statistics" || val === "protocols") && !this.protocols_loaded) this.get_protocols();
    }
  },
  methods: {
    get_cpts_data() {
      this.members = [];
      this.loading = true;
      this.$http.get("/api/hf_coordination/" + this.hf_id).then(response => {
        this.members = response.data.doctors;
        this.managers_users = response.data.managers_users;
        this.hf_name = response.data.commercial_name;
        this.hf_finess = response.data.id_structure;
        this.hf_cities_caring_for = response.data.cities_caring_for;
        this.hf_data = response.data;
      }).finally(() => {
        this.loading = false;
      })
    },
    get_ddi: function() {
      this.loading_ddi = true;
      this.ddi = [];
      axios.get(`/api/hf-coordination/${this.hf_id}/demand-intervention`).then(response => {
        this.ddi = response.data;
      }).catch(error => {
        if(error.response) {
          this.$store.dispatch('ManageErrorLight', error.response.status);
        }
        this.$store.commit('PostCommonError');
      }).finally(() => {
        this.loading_ddi = false;
        this.ddi_loaded = true;
      })
    },
    get_protocols: function() {
      this.loading_protocols = true;
      this.protocols = [];
      this.$http.get(`/api/hf-coordination/${this.hf_id}/statistics-protocoles`).then(response => {
        this.protocols = response.data["questionnaires"];
      }).finally(() => {
        this.loading_protocols = false;
        this.protocols_loaded = true;
      })
    }
  }
}
</script>