<template>
  <v-container fluid class="pa-3 pa-sm-6 pb-10 mb-15">
    <div class="px-sm-3">
      <p :class="`${$vuetify.breakpoint.smAndUp ? 'font-size--24' : 'font-size--18'} primary--text bold--text`">Bonjour {{ first_name_user }}</p>
    </div>

    <v-row class="mx-0">
      <v-col v-if="is_admin_cpts" cols="12" sm="6" md="4" order="1" class="px-0 px-sm-3">
        <HomeCardComponent title="Pilotage opérationnel"
                           :img="img_admin"
                           :action="false"
                           :redirections="redirections_admin"/>
      </v-col>

      <v-col cols="12" sm="6" md="4" order="2" class="px-0 px-sm-3">
        <HomeCardComponent title="Patients"
                           :img="img_patients"
                           action
                           action_icon="mdi-account-plus"
                           action_txt="Ajouter un patient"
                           :redirections="redirections_patients"
                           v-on:action="$router.push({name: 'PatientEditLayout'})"/>
      </v-col>

      <v-col cols="12" sm="6" md="4" order="4" order-sm="3" class="px-0 px-sm-3">
        <HomeCardComponent title="Agenda / Planning"
                           :img="img_snp"
                           :disabled="!has_access_to_calendar"
                           action
                           url_iframe="https://www.citana.care/module/activer-acces-soins"
                           action_icon="mdi-calendar-cursor"
                           action_txt="Prendre RDV pour un patient"
                           :redirections="redirections_snp"
                           v-on:action="redirection_to_snp('CoMyCalendarLayout', 0)"
                           v-on:go_to_my_rdv="redirection_to_snp('CoMyCalendarLayout', 0)"
                           v-on:go_to_inscription="redirection_to_snp('CalendarEvenementLayout', 1)"
                           v-on:go_to_manage="redirection_to_snp('CalendarManageEventsLayout', 2)"/>
      </v-col>

      <v-col cols="12" sm="6" md="4" order="3" order-sm="4" class="px-0 px-sm-3">
        <HomeCardComponent title="Demandes d'intervention"
                           action
                           :disabled="!has_access_to_ddi"
                           action_icon="mdi-file-move"
                           action_txt="Faire une demande"
                           :redirections="redirections_ddi"
                           url_iframe="https://www.citana.care/module/activer-acces-soins"
                           v-on:action="dialog_create_ddi=true">
          <template v-slot:img>
            <v-carousel cycle height="100%" hide-delimiters :show-arrows="false" interval="3000" class="hidden-xs-only">
              <v-carousel-item v-for="(slide, key) in slides_ddi" :key="key" class="text-center">
                <img :src="slide" alt="Logo" width="100%"/>
              </v-carousel-item>
            </v-carousel>
          </template>
        </HomeCardComponent>
      </v-col>

      <v-col cols="12" sm="6" md="4" order="5" class="px-0 px-sm-3">
        <HomeCardComponent title="Protocoles / Parcours"
                           :img="img_protocols"
                           :disabled="!has_access_to_protocoles"
                           action
                           url_iframe="https://www.citana.care/module/activer-protocole"
                           action_icon="mdi-flag"
                           action_txt="Lancer un protocole"
                           v-on:action="dialog_new_protocole=true"/>
      </v-col>
      <v-col cols="12" sm="6" md="4" order="6" class="px-0 px-sm-3">
        <HomeCardComponent title="Vidéo consultation"
                           :img="img_tlc"
                           :disabled="!has_access_to_tlc"
                           url_iframe="https://www.citana.care/module/activer-teleconsultation"
                           :action="is_orthophonist || !has_access_to_tlc"
                           action_txt="Lancer la vidéo consultation"
                           action_icon="mdi-laptop-account"
                           :redirections="redirections_tlc"
                           v-on:action="tlc_action='start'; dialog_new_tlc=true"
                           v-on:program_visio="tlc_action='program'; dialog_new_tlc=true"
                           v-on:invitation_visio="tlc_action='invitation'; dialog_new_tlc=true"
                           v-on:go_to_my_rdv="redirection_to_snp('CoMyCalendarLayout', 0)"/>
      </v-col>
    </v-row>

    <DoctorHomeNewDDI :dialog.sync="dialog_create_ddi"/>

    <DoctorHomeNewProtocol :dialog.sync="dialog_new_protocole"/>

    <DoctorHomeNewTlc :dialog.sync="dialog_new_tlc"
                      :action="tlc_action"/>
  </v-container>
</template>

<script>
import HomeCardComponent from "coordination-library/src/components/doctor/home/HomeCardComponent";
import DoctorHomeNewDDI from "./dialog/DoctorHomeNewDDI";
import DoctorHomeNewProtocol from "./dialog/DoctorHomeNewProtocol";
import DoctorHomeNewTlc from "./dialog/DoctorHomeNewTlc";

import img_patients from "@/assets/patients.png";
import img_protocols from "@/assets/protocoles.png";
import img_snp from "@/assets/snp.png";
import img_admin from "@/assets/admin.png";
import img_tlc from "@/assets/tlc.png";

import img_ddi from "@/assets/kinesitherapeute.png";
import img_all_professions from "@/assets/all_professions.png";
import img_inf from "@/assets/infirmier.png";
import img_labo from "@/assets/labo.png";
import img_ortho from "@/assets/orthophoniste.png";
import img_radio from "@/assets/radio.png";
import img_sf from "@/assets/sage_femme.png";

export default {
  name: "DoctorHomeView",
  components: {HomeCardComponent, DoctorHomeNewDDI, DoctorHomeNewProtocol, DoctorHomeNewTlc},
  data: () => ({
    img_patients: img_patients,
    img_protocols: img_protocols,
    img_tlc: img_tlc,
    img_ddi: img_ddi,
    img_snp: img_snp,
    img_admin: img_admin,
    slides_ddi: [img_ddi, img_all_professions, img_inf, img_labo, img_ortho, img_radio, img_sf],
    dialog_create_ddi: false,
    dialog_new_protocole: false,
    dialog_new_tlc: false,
    tlc_action: ""
  }),
  computed: {
    display_web_only: function() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    is_admin_cpts() {
      return this.$store.getters.getPermission('is_admin_cpts');
    },
    all_cpts: function() {
      return this.$store.getters.getUserInfosElement('all_cpts');
    },
    has_access_to_coordination() {
      return this.$store.getters.getPermission('has_access_to_coordination');
    },
    has_access_to_calendar() {
      return this.$store.getters.getPermission('has_access_to_calendar');
    },
    has_access_to_snp() {
      return this.$store.getters.getPermission('has_access_to_snp') && this.has_access_to_coordination;
    },
    has_access_to_protocoles() {
      return this.$store.getters.getPermission('has_access_to_protocoles') && this.has_access_to_coordination;
    },
    has_access_to_ddi() {
      return this.$store.getters.getPermission('has_access_to_ddi') && this.has_access_to_coordination;
    },
    has_access_to_ddi_etab: function() {
      let structures = this.$store.getters.getServicesStructures;
      return !!(structures && structures.length > 0);
    },
    has_access_to_waiting_list() {
      return this.$store.getters.getPermission('has_access_to_waiting_list')["334"] && this.has_access_to_coordination;
    },
    has_access_to_tlc() {
      return this.$store.getters.getPermission('has_access_to_tlc');
    },
    first_name_user: function() {
      return this.$store.getters.getUserInfosElement("first_name");
    },
    redirections_patients: function() {
      let tmp = [
        {txt: "Patients récents", redirection: {name: "DoctorDashboard", query: {tab: 0}}},
        {txt: "Rechercher un patient", redirection: {name: "DoctorDashboard", query: {tab: 1}}}
      ];
      if(this.has_access_to_waiting_list && this.display_web_only) tmp.push({txt: "Liste d'attente MT", redirection: {name: "DoctorDashboard", query: {tab: 2}}});
      return tmp;
    },
    redirections_snp: function() {
      let tmp = [];
      if(this.has_access_to_snp) tmp.push({txt: "M'inscrire à un planning", emit: "go_to_inscription"});
      tmp.push({txt: "Mes rendez-vous", emit: "go_to_my_rdv"});
      if(this.is_admin_cpts) tmp.push({txt: "Gestionnaire de planning", emit: "go_to_manage"});
      return tmp;
    },
    redirections_ddi: function() {
      let tmp = [
        {txt: "Mes demandes reçues", redirection: {name: "MyDemandsView", query: {tab: 0}}},
        {txt: "Mes demandes émises", redirection: {name: "MyDemandsView", query: {tab: 1}}}
      ];
      if(this.has_access_to_ddi_etab && this.display_web_only) tmp.push({txt: "Demandes établissements", redirection: {name: "MyDemandsView", query: {tab: 2}}});
      return tmp;
    },
    redirections_admin: function() {
      let tmp = [];
      for(let cpts of this.all_cpts) {
        tmp.push({txt: cpts.commercial_name, redirection: {name: "ManageCPTS", params: {id: cpts.id.toString()}}})
      }
      return tmp;
    },
    redirections_tlc() {
      let tmp = [];
      if (this.is_orthophonist) {
        tmp = [
          {txt: "Envoyer un lien de vidéo consultation", emit: "invitation_visio"},
          {txt: "Programmer une vidéo consultation", emit: "program_visio"}
        ];
      }
      else {
        tmp = [{txt: "Programmer une vidéo consultation", emit: "program_visio"}]
      }
      tmp.push({txt: "Mes vidéo-consultations", emit: "go_to_my_rdv"});
      return tmp;
    },
    is_orthophonist() {
      return this.$store.getters.isOrthophonist;
    }
  },
  created() {
    this.$store.state.is_mounted_app = true;
  },
  methods: {
    redirection_to_snp: function(name, tab) {
      this.$store.commit('setTabSelectedInCalendar', tab);
      this.$router.push({name: name});
    }
  }
}
</script>

<style scoped>

</style>