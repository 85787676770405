<template>
  <CoProEndVisioView app_name="inzeecare" />
</template>

<script>
import CoProEndVisioView from "coordination-library/src/components/doctor/visio/CoProEndVisioView";

export default {
  name: "ProEndVisioView",
  components: {CoProEndVisioView}
}
</script>

<style scoped>

</style>