<template>
  <div>
    <p class="primary_dark--text font-size--18 semibold--text mb-0">Les soins pris en charge par le service</p>
    <p class="font-italic">Si vous décochez un type de soin, l'administrateur du service ne recevra plus les demandes d'intervention concernant ce type de soin.</p>
    <div class="pl-2">
      <v-switch v-for="(item, key) in cares" :key="key" v-model="item.value" :label="item.label" hide-details @click="update_settings()"></v-switch>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceSettingsDiabetologie",
  props: {
    settings: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    cares: [
      {"id": "doppler", "value": false, "label": "Doppler"},
      {"id": "echographie", "value": false, "label": "Echographie"},
      {"id": "irm", "value": false, "label": "IRM"},
      {"id": "mammographie", "value": false, "label": "Mammographie"},
      {"id": "osteodensitometrie", "value": false, "label": "Ostéodensitométrie"},
      {"id": "radio", "value": false, "label": "Radiologie"},
      {"id": "radio_dentaire_beam", "value": false, "label": "Radiologie dentaire avec cône Beam"},
      {"id": "scanner", "value": false, "label": "Scanner"},
      {"id": "telos", "value": false, "label": "Télos (radiographie dynamique)"},
    ],
  }),
  mounted() {
    this.set_settings(this.settings);
  },
  watch: {
    settings: function(val) {
      this.set_settings(val);
    }
  },
  computed: {
    _settings: {
      get: function() {
        return this.settings
      },
      set: function(val) {
        this.$emit("update:settings", val);
      }
    }
  },
  methods: {
    set_settings: function(data) {
      for(let care of this.cares) {
        care.value = data[care.id];
      }
    },
    update_settings: function() {
      let tmp = JSON.parse(JSON.stringify(this._settings));
      for(let care of this.cares) {
        tmp[care.id] = care.value;
      }
      this._settings = tmp;
    }
  }
}
</script>

<style scoped>

</style>