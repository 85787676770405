<template>
  <div>
    <AnVisioLayout logo="https://www.anamnese.care/hubfs/config/Citana/inzeecare/logo/logo-2022-citana-couleur-BL.png" />
    <CoAlert :show.sync="show_snackbar" :snackbar_info="$store.getters.getSnackInfos"/>
  </div>
</template>

<script>
import AnVisioLayout from "anam-library/src/components/an-visio/AnVisioLayout";
import CoAlert from "coordination-library/src/components/doctor/alert/CoAlert.vue";

export default {
  name: "VisioLayout",
  components: {CoAlert, AnVisioLayout},
  computed: {
    show_snackbar: {
      get: function() {
        return this.$store.getters.getSnackModel
      },
      set: function (val) {
        if(!val) this.$store.commit('ResetSnackBar');
      }
    }
  }
}
</script>

<style scoped>

</style>