import { render, staticRenderFns } from "./ItemDDIPhoneFormat.vue?vue&type=template&id=339dd268&scoped=true"
import script from "./ItemDDIPhoneFormat.vue?vue&type=script&lang=js"
export * from "./ItemDDIPhoneFormat.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../inzeecare/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "339dd268",
  null
  
)

export default component.exports