<template>
  <div>
    <v-dialog v-model="_dialog" width="1000" persistent>
      <v-card-title :class="`medium--text ${color_title}--text justify-center`">
        <v-icon :color="color_title" class="mr-4">mdi-cog</v-icon>
        Paramètres
        <v-spacer></v-spacer>
        <v-btn icon text :color="color_title" @click="_dialog=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-10">
        <p :class="`semibold--text font-size--18 ${color_title}--text mt-sm-2 mb-sm-8`">Étendre automatiquement les demandes d'intervention</p>
        <v-data-table :headers="headers"
                      :items="settings"
                      :items-per-page="-1"
                      :loading="loading"
                      hide-default-footer
                      :class="`${color_txt}--text`"
                      no-data-text="Aucune règle n'est paramétrée">
          <template v-slot:[`item.profession`]="{item}">
            <span class="font-size--14 semibold--text">{{ get_profession(item) }}</span>
          </template>
          <template v-slot:[`item.delay`]="{item}">
            <span class="font-size--14 normal--text">{{ item.delay_value }} {{ item.delay_type|delay_type_name }}</span>
          </template>
          <template v-slot:[`item.number_pds`]="{item}">
            <span class="font-size--14 normal--text">{{ item.number_pds }}</span>
          </template>
          <template v-slot:[`item.distance_max`]="{item}">
            <span class="font-size--14 normal--text">{{ item.distance_max }} km</span>
          </template>
          <template v-slot:[`item.actions`]="{item}">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon text color="primary" small @click="edit_setting(item)">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Modifier</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon text small color="pastel_red" @click="selected_setting=item.id;dialog_confirm_delete=true">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Supprimer</span>
            </v-tooltip>
          </template>
        </v-data-table>

        <v-btn text rounded color="primary" class="mt-sm-6" @click="new_setting=true;edition=false">
          <v-icon class="mr-2">mdi-plus-circle</v-icon>
          <span class="normal--text font-size--14 text-decoration-underline">Ajouter une règle</span>
        </v-btn>

        <v-expand-transition>
          <v-row v-if="new_setting || edition" class="my-8">
            <v-col cols="12" sm="8" offset-sm="2" class="bg px-sm-10" style="border-radius: 15px">
              <p :class="`semibold--text font-size--18 ${color_title}--text mt-sm-2 mb-sm-8 text-center`">{{ edition ? 'Modifier' : 'Nouvelle' }} règle</p>
              <v-form v-model="valid_form">
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-select v-model="profession" :items="professions_items" :rules="[rules.required]" color="primary" label="Sélectionner la profession" :disabled="edition"></v-select>
                  </v-col>
                  <v-expand-transition>
                    <v-col v-if="profession === '10'" cols="12" class="py-0">
                      <v-select v-model="speciality" :items="specialities_items" :rules="[rules.required]" color="primary" label="Sélectionner la spécialité" :disabled="edition"></v-select>
                    </v-col>
                  </v-expand-transition>
                  <v-col cols="3" class="align-self-center py-0">
                    <span class="primary_dark--text medium--text font-size--14">Au bout de</span>
                  </v-col>
                  <v-col cols="3" class="align-self-center py-0">
                    <v-text-field v-model="delay_value" :rules="[rules.required, rules.number, rules.max_value_100]" placeholder="X" color="primary"></v-text-field>
                  </v-col>
                  <v-col cols="6" class="align-self-center py-0">
                    <v-select v-model="delay_type" :items="delay_type_items" :rules="[rules.required]" placeholder="Sélectionner le type de durée" color="primary"></v-select>
                  </v-col>
                  <v-col cols="3" class="align-self-center py-0">
                    <span class="primary_dark--text medium--text font-size--14">Aux</span>
                  </v-col>
                  <v-col cols="3" class="align-self-center py-0">
                    <v-text-field v-model="number_pds" :rules="[rules.required, rules.number, rules.max_value_30]" placeholder="X" color="primary"></v-text-field>
                  </v-col>
                  <v-col cols="6" class="align-self-center py-0">
                    <span class="primary_dark--text medium--text font-size--14">premiers professionnels</span>
                  </v-col>
                  <v-col cols="3" class="align-self-center py-0">
                    <span class="primary_dark--text medium--text font-size--14">Dans un rayon de</span>
                  </v-col>
                  <v-col cols="3" class="align-self-center py-0">
                    <v-text-field v-model="distance_max" :rules="[rules.required, rules.number, rules.max_value_100]" placeholder="X" color="primary"></v-text-field>
                  </v-col>
                  <v-col cols="6" class="align-self-center py-0">
                    <span class="primary_dark--text medium--text font-size--14">km</span>
                  </v-col>
                  <v-col cols="12" class="text-center mt-sm-6">
                    <v-alert v-model="error" class="text-left" type="error" text transition="scale-transition">
                      <span style="white-space: pre-wrap" class="font-size--14 medium--text">{{ error_txt }}</span>
                    </v-alert>
                    <v-btn text color="primary" class="mx-3" @click="reset_new_setting()">
                      <v-icon class="mr-2" small>mdi-close</v-icon>
                      Annuler
                    </v-btn>
                    <v-btn color="green" elevation="0" class="px-sm-6 mx-3 white--text" @click="save_setting()" :disabled="!valid_form" :loading="loading_save">
                      <v-icon class="mr-2" small>mdi-content-save</v-icon>
                      <span class="medium--text font-size--14">Enregistrer</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-expand-transition>

        <div class="my-5 my-sm-10">
          <p :class="`semibold--text font-size--18 ${color_title}--text`">Demandes d'interventions supervisées pour les codes postaux suivants :</p>
          <div class="ml-4">
            <span v-for="(item, key) in hf_cities_caring_for" :key="key" :class="`normal--text ${color_txt}--text`">
              {{ key }} ({{item}}),
            </span>
            <span v-if="no_cities">Aucune ville n'est déclarée. Demandez à votre administrateur si vous souhaitez en ajouter une.</span>
          </div>
        </div>
      </v-card-text>
    </v-dialog>
    <AnDialogConfirmation :dialog.sync="dialog_confirm_delete"
                          :loading="loading_delete"
                          icon="mdi-delete"
                          title="Supprimer la régle ?"
                          title_color="primary_dark"
                          question_color="primary_dark"
                          v-on:cancel="dialog_confirm_delete=false"
                          v-on:confirm="delete_setting()"/>
  </div>
</template>

<script>
import AnDialogConfirmation from "anam-library/src/components/an-dialogs/AnDialogConfirmation";

import {ITEMS_PROFESSIONS_FOR_COORDINATIONS_DEMANDS, get_profession_name} from "anam-library/src/data/professions";
import {ITEMS_SPECIALITIES_FOR_DDI} from "anam-library/src/data/professionnal_specialities";
import axios from "axios"
import getCookie from "anam-library/src/js/common";
import rules from "anam-library/src/js/rules";

export default {
  name: "DialogSettingsDemands",
  components: {
    AnDialogConfirmation
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    hfcoordination_id: {
      type: String,
      required: true
    },
    hf_cities_caring_for: {
      type: Object,
      default: () => {}
    },
    color_title: {
      type: String,
      default: 'primary'
    },
    color_txt: {
      type: String,
      default: 'primary_dark'
    }
  },
  data: () => ({
    headers: [
      {text: 'Profession', value: 'profession', class: "primary--text"},
      {text: 'Délais', value: 'delay', align: "center", class: "primary--text"},
      {text: 'Nombre de pros', value: 'number_pds', align: "center", class: "primary--text"},
      {text: 'Rayon d\'intervention', value: 'distance_max', align: "center", class: "primary--text"},
      {text: 'Actions', value: 'actions', sortable: false, align: "center", class: "primary--text"},
    ],
    settings: [],
    loading: false,
    new_setting: false,
    edition: false,
    valid_form: false,
    rules: rules,
    loading_save: false,
    profession: null,
    speciality: null,
    delay_value: null,
    delay_type: null,
    delay_type_items: [
      {text: 'minutes', value: 2},
      {text: 'heures', value: 1}
    ],
    distance_max: null,
    number_pds: null,
    selected_setting: null,
    dialog_confirm_delete: false,
    loading_delete: false,
    error: false,
    error_txt: null
  }),
  computed: {
    _dialog: {
      get: function() {
        return this.dialog;
      },
      set: function(val) {
        this.$emit('update:dialog', val);
      }
    },
    professions_items: function() {
      return ITEMS_PROFESSIONS_FOR_COORDINATIONS_DEMANDS();
    },
    specialities_items: function() {
      return ITEMS_SPECIALITIES_FOR_DDI();
    },
    no_cities: function() {
      if(!this.hf_cities_caring_for) return true;
      return Object.keys(this.hf_cities_caring_for).length === 0;
    }
  },
  watch: {
    _dialog: function(val) {
      if(!val) this.reset_new_setting();
    },
    hfcoordination_id: function(val) {
      if(val) this.get_settings();
    }
  },
  created() {
    this.get_settings()
  },
  methods: {
    get_settings: function() {
      this.loading = true;
      this.$http.get(`/api/hf-coordination/${this.hfcoordination_id}/settings-expand-ddi`).then(response => {
        this.settings = response.data;
      }).finally(() => {
        this.loading = false;
      })
    },
    save_setting: function() {
      if(this.new_setting) this.post_setting();
      else if (this.edition) this.patch_setting();
    },
    post_setting: function() {
      this.loading_save = true;
      this.error = false;
      this.error_txt = null;

      let data = {
        hf_coordination: this.hfcoordination_id,
        dmp_type: this.profession,
        delay_value: this.delay_value,
        delay_type: this.delay_type,
        number_pds: this.number_pds,
        distance_max: this.distance_max
      }

      if(this.speciality) data["speciality_code"] = this.speciality;

      axios.post(`/api/hf-coordination/${this.hfcoordination_id}/settings-expand-ddi`, data, {headers: {"X-CSRFToken": getCookie('csrftoken')}}).then(response => {
        this.settings.push(response.data);
        this.reset_new_setting();
      }).catch(error => {
        if(error.response) {
          this.$store.dispatch('ManageErrorLight', error.response.status);
          if(error.response.status === 422) {
            this.manage_error_422(error.response.data)
            return
          } else if (error.response.status === 417) {
            this.error = true;
            this.error_txt = "Cette profession est déjà associée à une règle (une seule règle par profession possible).\nSi vous le souhaitez, vous pouvez la modifier en cliquant sur le bouton éditer de la profession correponsdante dans le tableau ci-dessus.";
            return
          }
        }
        this.$store.commit('PostCommonError');
      }).finally(() => {
        this.loading_save = false;
      })
    },
    patch_setting: function() {
      this.loading_save = true;
      let data = {
        delay_value: this.delay_value,
        delay_type: this.delay_type,
        number_pds: this.number_pds,
        distance_max: this.distance_max
      }
      axios.patch(`/api/hf-coordination/${this.hfcoordination_id}/settings-expand-ddi/${this.selected_setting}`, data, {headers: {"X-CSRFToken": getCookie('csrftoken')}}).then(response => {
        let tmp = JSON.parse(JSON.stringify(this.settings));
        let index = tmp.findIndex(x => x.id === response.data.id);
        if(index > -1) {
          tmp[index] = response.data;
          this.settings = tmp;
        }
        this.reset_new_setting();
      }).catch(error => {
        if(error.response) {
          this.$store.dispatch('ManageErrorLight', error.response.status);
          if(error.response.status === 422) {
            this.manage_error_422(error.response.data)
            return;
          }
        }
        this.$store.commit('PostCommonError');
      }).finally(() => {
        this.loading_save = false;
      })
    },
    edit_setting: function(setting) {
      this.profession = setting.dmp_type;
      this.speciality = setting.speciality_code;
      this.delay_value = setting.delay_value;
      this.delay_type = setting.delay_type;
      this.number_pds = setting.number_pds;
      this.distance_max = setting.distance_max;
      this.selected_setting = setting.id;
      this.edition = true;
      this.new_setting = false;
    },
    delete_setting: function() {
      this.loading_delete = true;
      axios.delete(`/api/hf-coordination/${this.hfcoordination_id}/settings-expand-ddi/${this.selected_setting}`, {headers: {"X-CSRFToken": getCookie('csrftoken')}}).then(() => {
        let index = this.settings.findIndex(x => x.id === this.selected_setting);
        if(index > -1) this.settings.splice(index, 1);
        this.$store.commit('PostSuccess', 'Règle supprimée avec succès');
      }).catch(error => {
        if(error.response) {
          this.$store.dispatch('ManageErrorLight', error.response.status);
        }
        this.$store.commit('PostCommonError');
      }).finally(() => {
        this.selected_setting = null;
        this.loading_delete = false;
        this.dialog_confirm_delete = false;
      })
    },
    reset_new_setting: function() {
      this.error = false;
      this.error_txt = null;
      this.selected_setting = null;
      this.profession = null;
      this.speciality = null;
      this.delay_value = null;
      this.delay_type = null;
      this.number_pds = null;
      this.distance_max = null;
      this.new_setting = false;
      this.edition = false;
    },
    manage_error_422: function(error) {
      let map_keys = {
        dmp_type: "Profession",
        delay_value: "Délai",
        delay_type: "Délai",
        number_pds: "Nombre de professionnels",
        distance_max: "Rayon d'intervention",
        author: "Auteur"
      }
      let error_txt = "Champs invalides";
      for (let el in error) {
        if(Object.keys(map_keys).includes(el)) {
          error_txt = `${error_txt}\n${map_keys[el]} : ${error[el][0]}`;
        }
      }
      this.error = true;
      this.error_txt = error_txt;
    },
    get_profession: function(item) {
      return get_profession_name(item.dmp_type, item.speciality_code);
    }
  },
  filters: {
    delay_type_name: function(val) {
      if(val === 1) return 'heures';
      else if(val === 2) return 'minutes'
      return ''
    }
  }
}
</script>

<style scoped>

</style>