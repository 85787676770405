import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueI18n from 'vue-i18n'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts';

import {defaultLocale, languages} from './i18n/index.js'
import api from 'anam-library/src/services/api.js'
import {default_api_error, default_api_response} from 'anam-library/src/services/api'
import PluginKeycloakGCS from "./plugins/keycloak-gcs";
import PluginKeycloakAnamnese from "anam-library/src/plugins/keycloak-anamnese";


Vue.component('apex-chart', VueApexCharts);
Vue.use(VueTelInputVuetify, {vuetify});
Vue.use(VueI18n);
Vue.use(VueApexCharts);
Vue.use(moment);
Vue.use(PluginKeycloakGCS);
Vue.use(PluginKeycloakAnamnese);

const messages = Object.assign(languages)

Vue.config.productionTip = false

export const i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: 'fr',
    messages,
    silentTranslationWarn: true
});

Vue.prototype.$http = api;
api.interceptors.response.use(response => {
        return default_api_response(response);
    }, error => {
        return default_api_error(error, api, store.dispatch);
    }
);

navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);

function handleServiceWorkerMessage(event) {
    if (event.data.action == 'navigate') {
        window.location.href = event.data.url;
    } else if (event.data === 'reloadPage') {
        window.location.reload();
    }
}

if ('serviceWorker' in navigator) {

    let file = "/firebase-messaging-sw.js"

    if (window.location.href.includes("app.citana.fr")) {
        file = "/firebase-messaging-sw-prod.js"
    }

    window.addEventListener('load', () => {
        navigator.serviceWorker.register(file).then(registration => {

            registration.onupdatefound = () => {
                const installingWorker = registration.installing;

                installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            installingWorker.postMessage({ action: 'skipWaiting' });
                        }
                    }
                };
            };
        }).catch(error => {
            console.log('Service Worker registration failed:', error);
        });
    });
}

new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');
