<template>
  <ConnexionLayout ref="common_connexion" :from_br_url="true">
    <template v-slot:sso>
      <v-col cols="12" class="text-center pb-6">
          <div class="mt-3 mt-sm-5">
            <v-btn color="secondary" rounded outlined x-large class="white--text" block
                   @click="initKeycloak()">
              <span class="font-size--16 medium--text">Connexion via</span>
              <img src="https://www.anamnese.care/hubfs/GCS-bretagne-LOGO.png" alt="Logo GCS Bretagne" height="45px" class="ml-3"/>
            </v-btn>
          </div>
        </v-col>
    </template>
  </ConnexionLayout>
</template>

<script>
import ConnexionLayout from "@/components/public/ConnexionLayout";
import axios from "axios";
import getCookie from "anam-library/src/js/common";

export default {
  name: "ConnexionBrLayout",
  components: {ConnexionLayout},
  beforeCreate() {
    this.$keycloak_gcs
        .init({onLoad: "check-sso"})
        .then(authenticated => {
          if(authenticated && this.$store.getters.getUserPermission === 0 && this.$store.getters.getConnexionFromGCSBretagne ) {
            axios.post("/api/inzeecare/connexion_gcs/", {id_token: this.$keycloak_gcs.idToken}, {headers: {"X-CSRFToken": getCookie('csrftoken')}}).then(response => {
                this.$store.commit('setRedirectLogoutGCSBretagne', this.$keycloak_gcs.tokenParsed.redirect_logout);
                this.$store.dispatch("connexionInzeecare", {data: response.data, from_gcs_bretagne: true});
            }).catch(error => {
                if(error.response) {
                    if(error.response.status === 404) this.$router.push({name: 'UnknownErrorRedirectionBrView'});
                    else if(error.response.status === 401) this.$router.push({name: 'InactiveErrorRedirectionBrView'});
                    else {
                        this.$router.push({name: 'CommonErrorRedirectionBrView'});
                    }
                } else {
                     this.$router.push({name: 'CommonErrorRedirectionBrView'});
                }
            })
          }
        });
  },
  methods: {
    initKeycloak() {
      this.$keycloak_gcs.login({redirectUri: `${window.location.origin}/br/connexion-gcs`});
    },
  }
}
</script>

<style scoped>

</style>
