<template>
  <v-dialog v-model="_dialog" persistent width="600">
    <v-card>
      <v-card-title :class="`medium--text ${color_title}--text justify-center`">
        <v-icon :color="color_title" class="mr-4">mdi-cog</v-icon>
        Paramètres du service
        <v-spacer></v-spacer>
        <v-btn icon text :color="color_title" @click="_dialog=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <p :class="`pb-5 font-size--16 semibold--text ${color_txt}--text`">{{ service.commercial_name }}</p>
        <v-form v-model="valid_form">
          <v-progress-linear v-if="loading" indeterminate color="primary_pastel"></v-progress-linear>
          <v-expand-transition>
            <component v-if="!loading" :is="component_settings" :settings.sync="settings"></component>
          </v-expand-transition>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="has_settings" class="justify-center py-8">
        <v-btn text color="primary" @click="_dialog=false">
          <v-icon small class="mr-2">mdi-close</v-icon>
          Annuler
        </v-btn>
        <v-btn elevation="0" color="green" class="white--text px-6" @click="update_settings()" :disabled="!valid_form" :loading="loading_save">
          <v-icon class="mr-sm-4">mdi-content-save</v-icon>
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import {get_component_settings_for_service, service_has_settings} from "coordination-library/src/data/interventions";

export default {
  name: "DialogSettingsService",
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    service: {
      type: Object,
      default: () => {}
    },
    color_title: {
      type: String,
      default: 'primary'
    },
    color_txt: {
      type: String,
      default: 'primary_dark'
    }
  },
  data: () => ({
    valid_form: false,
    loading: false,
    loading_save: false,
    settings: {},
  }),
  computed: {
    _dialog: {
      get: function() {
        return this.dialog;
      },
      set: function(val) {
        this.$emit('update:dialog', val);
      }
    },
    of_type_service: function() {
      return this.service.of_type;
    },
    has_settings: function() {
      return service_has_settings(this.of_type_service);
    },
    component_settings: function() {
      return get_component_settings_for_service(this.of_type_service);
    },
  },
  watch: {
    _dialog: function(val) {
      if(!val) {
        this.loading = false;
        this.loading_save = false;
        this.settings = {}
      } else {
        if(this.has_settings && this.service && this.service.id) this.get_settings();
      }
    },
  },
  methods: {
    get_settings: function() {
      this.loading = true;
      this.$http.get(`/api/services/${this.service.id}/settings-ddi`).then(response => {
        this.settings = response.data;
      }).finally(() => {
        this.loading = false;
      })
    },
    update_settings: function() {
      this.loading_save = true;
      this.$http.patch(`/api/services/${this.service.id}/settings-ddi`, this.settings).then(response => {
        this.settings = response.data;
        this.$store.commit('PostSuccess', 'Modifications enregistrées');
      }).finally(() => {
        this.loading_save = false;
        this._dialog = false;
      })
    }
  }
}
</script>

<style scoped>

</style>