<template>
  <v-container fluid class="bg_primary pa-3 pa-sm-6 pb-10 mb-10">
    <div class="px-sm-3 mb-15">
      <p class="font-size--24 primary--text bold--text">Bienvenue sur votre interface administrateur</p>
    </div>
    <v-row class="mx-0">
      <v-col cols="12" sm="6" md="4" order="1" class="px-0 px-sm-3">
        <HomeCardComponent title="Gestion des structures"
                           :img="img_admin"
                           action
                           action_icon="mdi-arrow-right"
                           action_txt="Accès"
                           :redirections="[]"
                           v-on:action="$router.push({name: 'ManagerStructures'})"/>
      </v-col>
      <v-col cols="12" sm="6" md="4" order="1" class="px-0 px-sm-3">
        <HomeCardComponent title="Gestion des utilisateurs"
                           :img="img_phonebook"
                           action
                           action_icon="mdi-arrow-right"
                           action_txt="Accès"
                           :redirections="redirections_users"
                           v-on:action="$router.push({name: 'ManagerUsers'})"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HomeCardComponent from "coordination-library/src/components/doctor/home/HomeCardComponent.vue";

import img_admin from "@/assets/admin.png";
import img_phonebook from "../../../assets/phonebook.png"

export default {
  name: "ManagerHomeView",
  components: {HomeCardComponent},
  data: () => ({
    img_admin: img_admin,
    img_phonebook: img_phonebook
  }),
  computed: {
    redirections_users: function () {
      return [
        {txt: "Rechercher un utilisateur", redirection: {name: 'ManagerUsers'}},
        {txt: "Utilisateurs à valider", redirection: {name: "ManagerUsersToValidateListing"}},
      ]
    }
  },
  created() {
    this.$store.state.is_mounted_app = true;
    this.$vuetify.goTo(0);
  },
}
</script>

<style scoped>

</style>