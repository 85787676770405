<template>
  <v-container fluid class="bg_primary pa-3 pa-sm-6 pb-10 mb-10">
    <AnBackButton v-on:back="$router.push({name: 'ManagerHome'})" color="primary" :x_large="false"/>
    <v-row class="mx-0">
      <v-col cols="12">
        <p class="font-size--24 primary--text bold--text">Rechercher un utilisateur</p>
      </v-col>
      <v-col cols="2">
        <v-text-field v-model="search_last_name"
                      hide-details
                      clearable
                      placeholder="Entrez votre texte"
                      label="Par nom"
                      @keyup.enter="search_pros()"/>
      </v-col>
      <v-col cols="2">
        <v-text-field v-model="search_first_name"
                      hide-details
                      clearable
                      placeholder="Entrez votre texte"
                      label="Par prénom"
                      @keyup.enter="search_pros()"/>
      </v-col>
      <v-col cols="2">
        <v-text-field v-model="search_idpp"
                      hide-details
                      clearable
                      placeholder="Entrez votre texte"
                      label="Par RPPS/ADELI"
                      @keyup.enter="search_pros()"/>
      </v-col>
      <v-col cols="2">
        <v-text-field v-model="search_email"
                      hide-details
                      clearable
                      placeholder="Entrez votre texte"
                      label="Par email"
                      @keyup.enter="search_pros()"/>
      </v-col>
      <v-col cols="2">
        <v-text-field v-model="search_zipcode"
                      hide-details
                      clearable
                      placeholder="Entrez votre texte"
                      label="Par code postal"
                      @keyup.enter="search_pros()"/>
      </v-col>
      <v-col cols="2">
        <v-autocomplete v-model="search_hf"
                        :items="items_structures"
                        item-text="commercial_name"
                        item-value="id"
                        hide-details
                        clearable
                        placeholder="Sélectionnez"
                        label="Par Structure"/>
      </v-col>
      <v-col cols="12" class="text-center mt-4">
        <v-hover v-slot="{ hover }">
          <v-btn color="primary" large :elevation="hover ? 4 : 0" rounded class="px-8" :disabled="!valid_search" :loading="loading" @click="search_pros()">
            <v-icon class="mr-2">mdi-magnify</v-icon>
            <span class="font-size--16 medium--text">Lancer la recherche</span>
          </v-btn>
        </v-hover>
      </v-col>
      <v-col v-if="has_search" cols="12">
        <v-expand-transition>
          <AnDataTable v-if="has_search"
                       :headers="headers"
                       :items="users"
                       :loading="loading"
                       :table_items_mapping="table_items_mapping"
                       elevation="0"
                       rounded="rounded-lg"
                       v-on:action="go_to_detail_user($event)"/>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AnBackButton from "anam-library/src/components/an-ui/button/AnBackButton";
import AnDataTable from "anam-library/src/components/an-data-table/AnDataTable";
import ActionItem from "./components/ActionItem.vue";
import StatusAccountItem from "./components/StatusAccountItem.vue";
import StructuresItem from "./components/StructuresItem.vue";
import BasicItem from "anam-library/src/components/an-data-table/an-table-items-components/BasicItem";
import FirstNameItem from "anam-library/src/components/an-data-table/an-table-items-components/FirstNameItem";
import LastNameItem from "anam-library/src/components/an-data-table/an-table-items-components/LastNameItem";
import DateTimeItem from "anam-library/src/components/an-data-table/an-table-items-components/DateTimeItem.vue";

import {get_profession_name} from "anam-library/src/data/professions";

export default {
  name: "ManagerUsersListingView",
  components: {AnDataTable, AnBackButton},
  data: () => ({
    headers: [
      {text: 'Nom', value: 'last_name', class: 'primary rounded-tl-lg white--text'},
      {text: 'Prénom', value: 'first_name', class: 'primary white--text'},
      {text: 'Téléphone', value: 'phone', class: 'primary white--text'},
      {text: 'Email', value: 'email', class: 'primary white--text'},
      {text: 'Profession', value: 'profession', class: 'primary white--text'},
      {text: 'RPPS/ADELI', value: 'idpp', class: 'primary white--text'},
      {text: 'Structure(s)', value: 'structures', class: 'primary white--text'},
      {text: 'Statut', value: 'status_account', class: 'primary white--text', align: "center"},
      {text: 'Dernière connexion', value: 'last_login', class: 'primary white--text', align: "center"},
      {text: 'Actions', value: 'actions', class: 'primary white--text rounded-tr-lg', align: "center"},
    ],
    users: [],
    loading: false,
    has_search: false,
    table_items_mapping: {
      first_name: FirstNameItem,
      last_name: LastNameItem,
      phone: BasicItem,
      email: BasicItem,
      structures: StructuresItem,
      profession: BasicItem,
      idpp: BasicItem,
      status_account: StatusAccountItem,
      last_login: DateTimeItem,
      actions: ActionItem
    },
    search_last_name: null,
    search_first_name: null,
    search_idpp: null,
    search_email: null,
    search_zipcode: null,
    search_hf: null,
  }),
  computed: {
    items_structures: function() {
      return this.$store.getters.getAllHFCoordinations;
    },
    valid_search: function() {
      return (this.search_last_name && this.search_last_name.length > 3)
          || (this.search_first_name && this.search_first_name.length > 3)
          || this.search_idpp
          || (this.search_zipcode && this.search_zipcode >= 5)
          || (this.search_email && this.search_email.length > 3)
          || this.search_hf;
    }
  },
  created() {
    this.$store.state.is_mounted_app = true;
    this.$vuetify.goTo(0);
  },
  methods: {
    search_pros: function() {
      if(this.valid_search) {
        this.loading = true;
        this.has_search = true;
        this.users = [];
        let params = {"agency": "inzeecare"};

        if(this.search_last_name) params.last_name = this.search_last_name;
        if(this.search_first_name) params.first_name = this.search_first_name;
        if(this.search_idpp) params.idpp = this.search_idpp;
        if(this.search_email) params.email = this.search_email;
        if(this.search_zipcode) params.zipcode = this.search_zipcode;
        if(this.search_hf) params.hf = this.search_hf;

        this.$http.get("/api/coordination/bo/professionals", {params: params}).then(response => {
          this.users = response.data.map(el => {
            el.profession = get_profession_name(el.dmp_type, el.speciality_code);
            return el
          });
        }).finally(() => {
          this.loading = false;
        })
      }
    },
    go_to_detail_user: function(user) {
      let route_data = this.$router.resolve({name: "ManagerUsersDetails", params: {user_id: user.id}});
      window.open(route_data.href, "_blank")
    }
  }
}
</script>

<style scoped>

</style>