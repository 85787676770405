<template>
  <v-container fluid class="pt-3 pb-15">
    <AnBackButton :x_large="false" color="primary" specific_class_text="text-decoration-underline medium--text" v-on:back="goToDpa()"/>
    <v-row class="mx-sm-3 white my-3 border_radius_container pa-sm-4">
      <v-col cols="10">
        <p class="font-size--24 semibold--text primary--text text-break ml-4">
          {{ title }} - &nbsp;
          <a @click="goToDpa()" class="text-decoration-underline">
            <span class="normal--text font-size--24 text-capitalize">{{ patient_first_name }} </span>
            <span class="normal--text font-size--24 text-uppercase">{{ patient_last_name }}</span>
          </a>
        </p>
        <br/>
        <a @click="post_survey()" class="text-decoration-underline ml-6" v-if="can_create_todays_survey()">
          <span class="normal--text font-size--14">Créer le questionnaire d'aujourd'hui</span>
        </a>
      </v-col>
      <v-col cols="12" md="2" :class="`${$vuetify.breakpoint.xsOnly ? 'text-left ml-6' : 'text-right'}`">
        <a @click="display_stats = !display_stats" v-if="has_stats && surveys.length > 0">
          <span class="normal--text font-size--14 text-decoration-underline">{{ display_stats ? `Masquer` : `Afficher`}} l'historique des questionnaires</span>
        </a>
      </v-col>
      <v-col cols="12">
        <v-expand-transition>
          <apex-chart v-if="display_stats" height="250" type="line" :options="options_apex" :series="data_chart"></apex-chart>
        </v-expand-transition>
        <v-expansion-panels v-model="panel" multiple flat>
          <v-expansion-panel v-for="(item, key) in surveys" :key="key">
            <v-expansion-panel-header class="bg_primary mb-4 ml-4">
              <p class="primary--text semibold--text font-size--18 mb-0">
                {{ subtitle }} -
                <span class="black--text font-size--18 font-italic">
                  {{ item.date }}
                </span>
              </p>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mt-3 px-sm-6 panel">
              <div class="rounded-lg" style="border: solid 1px var(--v-primary_dark-base)" v-if="legends.length > 0">
                <p v-if="description" class=" ma-4 medium--text font-size--18 justify-center">{{ description }}</p>
                <div :class="`px-sm-6 pa-3 align-self-center mt-4 ${$vuetify.breakpoint.xsOnly ? '' : 'd-flex flex-row'}` ">
                  <div v-for="(legend, key) in legends" :key="key" class="justify-start">
                    <p class="medium--text font-size--18">
                      <v-icon color="primary" :large="legend.large">{{ legend.icon }}</v-icon>
                      <v-icon color="primary" v-if="legend.twice">{{ legend.icon }}</v-icon>
                      {{ legend.icon === "" ? "" : "=" }} {{ legend.text }} &nbsp;
                    </p>
                  </div>
                </div>
              </div>
              <v-row class="ma-0" no-gutters>
                <v-col cols="12" v-if="has_stats"><p class="primary--text semibold--text font-size--18 mb-0">Total Apports hydriques du {{ item.date }} = {{ item.total }}</p></v-col>
                <v-col cols="4" :md="bidimensionnal_survey ? 1 : 2"></v-col>
                <v-col :cols="`${item.questions[0].range > 4 ? '1': '2'}`" md="1" class="mt-4" v-for="(legend, key) in legends" :key="key">
                  <div v-if="legend.display_in_survey">
                    <v-icon color="primary" :large="legend.large">{{ legend.icon }}</v-icon>
                    <v-icon color="primary" v-if="legend.twice">{{ legend.icon }}</v-icon>
                  </div>
                </v-col>
              </v-row>
              <div v-if="bidimensionnal_survey">
                <div v-for="(question, key) in item.questions" :key="key">
                  <v-row class="ma-0" no-gutters>
                    <v-col cols="4" md="1">
                      <span class="primary--text semibold--text font-size--20">{{ question.name }}</span>
                      <v-icon color="primary" :large="!question.icon.includes('mdi')">{{ question.icon }}</v-icon>
                    </v-col>
                  </v-row>
                  <v-row v-for="(value_one, key) in question.range" :key="key" no-gutters>
                    <v-col cols="4" md="1" class="text-center"><v-icon color="primary" :large="is_large_icon(question.icon[value_one - 1])">{{ question.icon[value_one - 1] }}</v-icon></v-col>
                    <v-col cols="2" v-for="(value_two, key) in question.range_x" :key="key" md="1" class="pb-0">
                      <v-checkbox v-model="question.result" light dense :disabled="!can_edit_survey(item.creation_date)" :value="value_one.toString() + value_two.toString()" @change="patch_survey(item, question.fields, [value_one, value_two])"></v-checkbox>
                    </v-col>
                    <v-col cols="0" md="2"></v-col>
                  </v-row>
                </div>
              </div>
              <v-row v-else v-for="(question, key) in item.questions" :key="key" class="ma-0" no-gutters>
                <v-col :cols="`${question.range === 1 ? '8': '4'}`" md="2">
                  <p v-if="question.range === 0" class="secondary--text semibold--text font-size--20">{{ question.name }}</p>
                  <p v-else class="font-size--18 medium--text primary--text text-left">{{ question.name }}</p>
                </v-col>
                <v-col :cols="`${question.range > 4 ? '1': '2'}`" md="1" v-for="(option, key) in question.range" :key="key" class="pb-0">
                  <v-checkbox v-model="question.result" light dense :disabled="!can_edit_survey(item.creation_date)" :value="option" @change="patch_survey(item, question.field)"></v-checkbox>
                </v-col>
                <v-col cols="0" md="2"></v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import moment from "moment-timezone";
import {get_formatted_date, set_date_utc_to_back} from "anam-library/src/js/dates";
import * as QUESTIONNAIRES from "./data/questionnaire_suivi_domicile";
import AnBackButton from "anam-library/src/components/an-ui/button/AnBackButton.vue";

export default {
  name: "CoDialogManageConstantes",
  components: {
    AnBackButton
  },
  data: () => ({
    patient_first_name: "",
    patient_last_name: "",
    title: "",
    title_icon: "",
    legends: {},
    subtitle: "",
    survey_questions: {},
    patient_id: null,
    surveys: [],
    panel: [0],
    results: [],
    display_stats: false,
    options_apex: null,
    data_chart: [
        {data: []}
    ],
    has_stats: false,
    chart_title: "",
    bidimensionnal_survey: false,
    description: false,
  }),
  created() {
    this.$store.state.is_mounted_app = true;
    this.set_survey_data_to_front();
    this.initalize_chart();
  },
  computed: {
    _dialog: {
      get: function() {
        return this.dialog;
      },
      set: function(val) {
        this.$emit("update:dialog", val);
      }
    },
  },
  methods: {
    get_formatted_date,
    goToDpa: function() {
      this.$router.push({name:'DoctorDpa', params: {patient_id: this.patient_id}})
    },
    patch_survey: function(survey, question_field, values=[]) {
      let data = {
        "traceability": survey["traceability"] ? survey["traceability"] : {},
        "requested_survey": this.$route.params.questionnaire_type,
      }
      if (typeof(question_field) !== "string") {
        data[question_field[0]] = values[0];
        data[question_field[1]] = values[1];
      } else {
        let index = survey["questions"].findIndex(x => x.field === question_field);
        if (index > -1) {
          let field = survey["questions"][index].field;
          data[field] = survey["questions"][index].result;
          data["traceability"][field] = JSON.stringify({
            "author": this.$store.getters.getUserInfos('id'),
            "date": set_date_utc_to_back(moment())
          });
        }
      }
      this.$http.patch(`/api/patients/${this.patient_id}/home-survey/${survey["id"]}`, data).then(() => {
        this.$store.commit('PostSuccess', "Le résultat a bien été enregistré");
      });
    },
    post_survey: function() {
      let data = {
        "of_type": this.$route.params.questionnaire_type,
        "patient": this.patient_id,
      }
      this.$http.post(`/api/patients/${this.patient_id}/home-survey`, data).then(response => {
        let survey = response.data;
        this.patient_first_name = survey["patient"]["first_name"];
        this.patient_last_name = survey["patient"]["last_name"];
        survey["questions"] = JSON.parse(JSON.stringify(this.questionnaire_info["questions"]));
        survey["date"] = get_formatted_date(moment(), {output_format: 'DD MMMM YYYY'}, true)
        this.surveys.unshift(survey);
      });
    },
    set_survey_data_to_front: function() {
      let index = QUESTIONNAIRES.QUESTIONNAIRES_DOMICILE.findIndex(
          x => x.of_type === this.$route.params.questionnaire_type
      );
      this.patient_id = this.$route.params.patient_id;
      if (index > -1) {
        this.questionnaire_info = QUESTIONNAIRES.QUESTIONNAIRES_DOMICILE[index]["data"];
        this.title = this.questionnaire_info["title"];
        this.title_icon = this.questionnaire_info["title_icon"];
        this.subtitle = this.questionnaire_info["subtitle"];
        this.legends = this.questionnaire_info["legend"];
        this.results = this.questionnaire_info["results"];
        this.has_stats = this.questionnaire_info["has_stats"];
        this.chart_title = this.questionnaire_info["chart_title"] ? this.questionnaire_info["chart_title"] : "";
        this.bidimensionnal_survey = this.questionnaire_info["bidimensionnal_survey"] ? this.questionnaire_info["bidimensionnal_survey"] : false;
        this.description = this.questionnaire_info["description"] ? this.questionnaire_info["description"] : false;
        this.$http.get(`/api/patients/${this.patient_id}/home-survey`, {params: {"requested_survey": this.$route.params.questionnaire_type}}).then((response) => {
          for (let survey of response.data) {
            let questions = JSON.parse(JSON.stringify(this.questionnaire_info["questions"]));
            this.patient_first_name = survey["patient"]["first_name"];
            this.patient_last_name = survey["patient"]["last_name"];
            for (let question of this.questionnaire_info["fields"]) {
              if (this.bidimensionnal_survey) {
                let index_question = questions.findIndex(x => x.fields.includes(question));
                if (index_question > -1) {
                  if(survey[question]) questions[index_question]["result"] += (survey[question]).toString();
                }
              } else {
                let index_question = questions.findIndex(x => x.field === question);
                if (index_question > -1) {
                  questions[index_question]["result"] = survey[question];
                }
              }
            }
            survey["questions"] = questions
            survey["date"] = get_formatted_date(survey.creation_date, {input_format: "DD/MM/YYYY HH:mm", output_format: 'DD MMMM YYYY'}, true)
            this.surveys.push(survey);
            if (survey.total) {
              this.data_chart[0].data.push(survey.total);
              this.options_apex.xaxis.categories.push(
                get_formatted_date(survey.creation_date, {input_format: "DD/MM/YYYY HH:mm", output_format: 'DD/MM'}, true))
            }
          }
          if (this.has_stats) {
            this.data_chart[0].data = this.data_chart[0].data.reverse();
            this.options_apex.xaxis.categories = this.options_apex.xaxis.categories.reverse();
          }
        });
      }
    },
    can_create_todays_survey: function() {
      if (this.surveys.length > 0) {
        let date = moment()
        return !date.isSame(moment(this.surveys[0].creation_date, "DD/MM/YYYY"), 'day')
      }
      return true
    },
    can_edit_survey: function(date) {
      let date_now = moment()
      return date_now.isSame(moment(date, "DD/MM/YYYY"), 'day')
    },
    is_large_icon: function(icon_name) {
      return icon_name.includes("$")
    },
    initalize_chart: function() {
      this.options_apex =  {
        chart: {
          height: 20,
          type: 'line',
          toolbar: {
            show: false
          }
        },
        title: {
          text: this.chart_title,
          align: 'center'
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['transparent'],
            opacity: 0.5
          },
        },
        xaxis: {
          categories: [],
        }
      };
    }
  }
}
</script>

<style>
.panel {
  padding-right: 0px;
  padding-left: 0px;
}
</style>
