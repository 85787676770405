<template>
  <div>
    <v-dialog v-model="_dialog" persistent width="1200">
      <v-card>
        <v-card-title :class="`medium--text ${color_title}--text justify-center`">
          <v-icon :color="color_title" class="mr-4">mdi-account-group</v-icon>
          Gestion des membres
          <v-spacer></v-spacer>
          <v-btn icon text :color="color_title" @click="_dialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <p :class="`font-size--16 normal--text ${color_title}--text`">Structure : <span class="font-size--16 semibold--text">{{ service.commercial_name }}</span></p>
          <div class="my-1 my-sm-3">
            <v-btn  elevation="0" color="green" rounded class="white--text" @click="dialog_search_pds=true">
              <v-icon color="white" class="mr-sm-2">mdi-account-plus</v-icon>
              <span class="font-size--14 medium--text">Ajouter un professionnel</span>
            </v-btn>
          </div>
          <p class="font-italic">
            Il se peut que la liste des professionnels ne soit pas à jour si vous avez effectué des actions récentes. Cliquez
            <a @click="$router.go()" class="text-decoration-underline medium--text">ici</a>
            pour actualiser.
          </p>
          <v-alert v-model="alert" type="error" text transition="scale-transition">
            {{ alert_msg }}
          </v-alert>
          <v-data-table :headers="headers"
                        :items="service['health-practitioners']"
                        class="elevation-0"
                        fixed-header
                        loading-text="Chargement en cours..."
                        no-data-text="Aucun professionnel"
                        no-results-text="Aucun résultat"
                        :footer-props="{
                          itemsPerPageText: 'Lignes par page',
                          itemsPerPageAllText: 'Tous',
                          'items-per-page-options': [20, 100, 500, -1]}"
                        :header-props="{sortByText: 'Trier par'}">
            <template v-slot:[`item.profession`]="{item}">
              <span>
                {{ get_profession_txt(item) }}
              </span>
            </template>

            <template v-slot:[`item.admin`]="{item}">
              <MembersStructureItemAdmin :item="item" :service="service"/>
            </template>

            <template v-slot:[`item.last_login`]="{item}">
              <span v-if="item.last_login">{{ item.last_login|date }}</span>
              <v-icon v-else>mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.actions`]="{item}">
              <v-tooltip bottom v-if="item.anamnese_uid">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="selected_user_reset_password = item.user_id; dialog_confirm_reset_password = true" color="primary">
                    <v-icon>mdi-email-sync</v-icon>
                  </v-btn>
                </template>
                <span>Envoyer un email pour réinitialiser le mot de passe</span>
              </v-tooltip>
<!--              TODO : mise en pause le 16/05/2024 : trop compliqué pour la migration-->
<!--              <v-tooltip bottom v-if="item.id_agency === null">-->
<!--                <template v-slot:activator="{ on }">-->
<!--                  <v-btn v-on="on" icon @click="select_pro_for_edit(item)" color="primary">-->
<!--                    <v-icon>mdi-pencil</v-icon>-->
<!--                  </v-btn>-->
<!--                </template>-->
<!--                <span>Éditer les informations du compte</span>-->
<!--              </v-tooltip>-->

            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <AnDialogConfirmation
        :dialog.sync="dialog_confirm_reset_password"
        icon="mdi-email-send"
        title="Réinitialiser le mot de passe"
        question="Souhaitez-vous envoyer un email pour permettre à l'utilisateur de réinitialiser son mot de passe ?"
        :loading="loading_reset_password"
        title_color="primary_dark"
        question_color="primary_dark"
        v-on:cancel="dialog_confirm_reset_password = false; selected_user_reset_password = null;"
        v-on:confirm="send_email_reset_password()">
    </AnDialogConfirmation>

    <CoDialogSearchPro :dialog.sync="dialog_search_pds"
                       dialog_title="Ajouter un professionnel"
                       :emit="true"
                       :map_professions="map_professions"
                       :search_all_professions="false"
                       :option_selected_first_by_default="false"
                       v-on:close="dialog_search_pds=false"
                       v-on:doctor_clicked="add_pds_to_service($event)">
      <template v-slot:sub-search>
        <div class="my-2 my-sm-5">
          <span class="font-size--12 px-sm-4">Aucun résultat ?</span>
          <v-btn text color="green" small @click="dialog_search_pds=false;dialog_new_pds=true">
            <v-icon class="mr-sm-2" small>mdi-account-plus</v-icon>
            <span class="medium--text text-decoration-underline font-size--12">Créer le professionnel</span>
          </v-btn>
        </div>
      </template>
    </CoDialogSearchPro>

    <DialogNewProfessionnel :dialog.sync="dialog_new_pds"
                            :service="service"
                            :hf_name="hf_name"
                            :pro_to_edit="pro_to_edit"
                            :app_name="app_name"
                            v-on:success_edit="$emit('edit-pds', $event)"
                            v-on:success="$emit('add-pds', $event)"/>
  </div>
</template>

<script>
import DialogNewProfessionnel from "./DialogNewProfessionnel";
import CoDialogSearchPro from "coordination-library/src/components/doctor/common/CoDialogSearchPro";
import MembersStructureItemAdmin from "./MembersStructureItemAdmin";
import AnDialogConfirmation from "anam-library/src/components/an-dialogs/AnDialogConfirmation";

import {get_profession_name, ITEMS_PROFESSIONS_FOR_COORDINATION_NEW_PDS,} from "anam-library/src/data/professions";
import {get_formatted_date} from "anam-library/src/js/dates";
import axios from "axios";
import getCookie from "anam-library/src/js/common";

export default {
  name: "DialogManageMembersService",
  components: {
    DialogNewProfessionnel,
    CoDialogSearchPro,
    AnDialogConfirmation,
    MembersStructureItemAdmin
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    service: {
      type: Object,
      default: () => {}
    },
    hf_name: {
      type: String,
      default: null
    },
    hf_id: {
      type: String,
      default: null
    },
    color_title: {
      type: String,
      default: 'primary'
    },
    app_name: {
      type: String,
      required: true
    }
  },
  data: () => ({
    headers: [
      {text: "Nom", value: "name"},
      {text: "Email", value: "email"},
      {text: "Profession", value: "profession"},
      {text: "Administrateur", value: "admin"},
      {text: "Dernière connexion", value: "last_login"},
      {text: "Actions", value: "actions"},
    ],
    dialog_search_pds: false,
    dialog_new_pds: false,
    alert: false,
    alert_msg: null,
    selected_user_reset_password: null,
    dialog_confirm_reset_password: false,
    loading_reset_password: false,
    pro_to_edit: null
  }),
  computed: {
    _dialog: {
      get: function() {
        return this.dialog;
      },
      set: function(val) {
        this.$emit('update:dialog', val);
      }
    },
    map_professions: function() {
      return ITEMS_PROFESSIONS_FOR_COORDINATION_NEW_PDS(true);
    }
  },
  watch: {
    _dialog: function(val) {
      if(!val) {
        this.alert = false;
        this.alert_msg = null;
        this.dialog_search_pds = false;
        this.dialog_new_pds = false;
      }
    },
    dialog_search_pds: function(val) {
      if(val) {
        this.alert = false;
        this.alert_msg = null;
      }
    },
    dialog_new_pds(val) {
      if (!val) this.pro_to_edit = null;
    }
  },
  filters: {
    date: function(val) {
      return get_formatted_date(val);
    }
  },
  methods: {
    add_pds_to_service: function(user) {
      this.alert = false;
      this.alert_msg = null;

      if(user.professionnel) {
        let data = {
          action: 'add_pds',
          id_pds: user.professionnel.id
        }
        axios.put(`/api/hf-coordination/${this.hf_id}/services/${this.service.id}`, data,{headers: {"X-CSRFToken": getCookie('csrftoken')}}).then(() => {
          this.$emit('add-pds', user);
          this.$store.commit('PostSuccess', 'Professionnel ajouté au service');
        }).catch(error => {
          this.alert_msg = "Une erreur est survenue";
          if (error.response) {
            this.$store.dispatch('ManageErrorLight', error.response.status);
            if(error.response.status === 422) {
              this.alert_msg = error.response.data;
            }
          }
          this.alert = true;
        }).finally(() => {
          this.dialog_search_pds = false;
        })
      } else {
        this.dialog_search_pds = false;
        this.alert = true;
        this.alert_msg = "Nous ne parvenons pas à ajouter ce professionnel à ce service. Si le problème persiste n'hésitez pas à nous contacter.";
      }
    },
    send_email_reset_password() {
      this.loading_reset_password = true;
      this.$http.post(`/api/user/${this.selected_user_reset_password}/sso-actions/update-password`, {}).then(() => {
        this.$store.commit("PostSuccess", "L'email a été envoyé avec succès");
        this.dialog_confirm_reset_password = false;
      }).finally(() => {
        this.loading_reset_password = false;
      })
    },
    select_pro_for_edit(item) {
      this.pro_to_edit = item;
      this.dialog_new_pds = true;
    },
    get_profession_txt: function(item) {
      return get_profession_name(item.dmp_type, item.speciality_code);
    }
  }
}
</script>

<style scoped>

</style>