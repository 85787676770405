<template>
  <v-container fluid class="pt-3 pb-15">
    <v-row class="mx-0">
      <v-col cols="12" class="pl-sm-6 align-self-center">
        <p class="semibold--text mb-1 font-size--24 primary--text">Pilotage opérationnel - {{ hf_name }}</p>
        <p class="primary_dark--text semibold--text font-size--18 mb-0">Recherche avancée des demandes d'interventions</p>
      </v-col>
      <v-col cols="12" class="pb-0 pl-sm-6">
        <p class="font-size--16 primary_dark--text font-italic pb-0">
          Vous pouvez effectuer une recherche directement <span class="font-size--16 medium--text">via le numéro de la demande ou via les champs de recherche</span> ci-dessous.</p>
      </v-col>
      <v-col cols="3" class="py-0 pl-sm-6">
        <AdvancedSearchComponent :search.sync="search_pk" label="Numéro de la demande" v-on:key_enter="get_ddi()"/>
      </v-col>
      <v-col cols="9" class="py-0"></v-col>
      <v-col cols="2 pl-sm-6">
        <AnDate :date="search_date"
                label="Date de création" icon=""
                :filled="false" hide_details :validate="false"
                :dense="true"
                :solo="true"
                reset_icon
                :disabled="search_pk"
                v-on:date_reset="search_date=null"
                v-on:formatted_date="search_date=$event;"/>
      </v-col>
      <v-col cols="2" class="align-self-end">
        <AdvancedSearchComponent :search.sync="search_pro" label="Nom du demandeur" :disabled="search_pk" v-on:key_enter="get_ddi()"/>
      </v-col>
      <v-col cols="2" class="align-self-end">
        <AdvancedSearchComponent :search.sync="search_patient" label="Nom du patient" :disabled="search_pk" v-on:key_enter="get_ddi()"/>
      </v-col>
      <v-col cols="2" class="align-self-end">
        <v-autocomplete v-model="search_profession" :items="professions_items" solo label="Profession" :disabled="search_pk" dense hide-details clearable no-data-text="Aucun résultat" @keydown.enter="get_ddi()"></v-autocomplete>
      </v-col>
      <v-col cols="2" class="align-self-end">
        <AdvancedSearchComponent :search.sync="search_zipcode" label="Code postal" :disabled="search_pk" v-on:key_enter="get_ddi()"/>
      </v-col>
       <v-col cols="2" class="align-self-end text-center">
        <v-btn color="primary" elevation="0" @click="get_ddi()" :loading="loading"  :disabled="!valid_form">
          <v-icon>mdi-magnify</v-icon>
          <span class="medium--text">Rechercher</span>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <DashboardDemandsAdminComponent :hf_coordination_id="hf_id"
                                        :items="items"
                                        :app_name="app_name"
                                        :loading="loading"
                                        :default_toolbar="false"/>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import AnDate from "anam-library/src/components/an-date/AnDate";
import DashboardDemandsAdminComponent from "coordination-library/src/components/common/demand-intervention/DashboardDemandsAdminComponent";
import AdvancedSearchComponent from "coordination-library/src/components/common/AdvancedSearchComponent";
import {ITEMS_PROFESSIONS_FOR_COORDINATIONS_DEMANDS} from "anam-library/src/data/professions";

export default {
  name: "ArchivedDemandsView",
  components: {DashboardDemandsAdminComponent, AnDate, AdvancedSearchComponent},
  props: {
    app_name: {
      type: String,
      required: true
    }
  },
  data: () => ({
    items: [],
    loading: false,
    search_pk: null,
    search_date: null,
    search_pro: null,
    search_patient: null,
    search_profession: null,
    search_zipcode: null
  }),
  computed: {
    hf_id: function() {
      return this.$route.params.hf_id;
    },
    hf_name: function() {
      return this.$route.query.name;
    },
    valid_form: function() {
      return this.search_pk || this.search_date || this.search_pro || this.search_patient || this.search_profession || this.search_zipcode;
    },
    professions_items() {
      return ITEMS_PROFESSIONS_FOR_COORDINATIONS_DEMANDS(true);
    },
  },
  created() {
    this.$store.state.is_mounted_app = true;
  },
  methods: {
    get_ddi: function() {
      this.loading = true;
      let data = {};

      if(this.search_pk) data["search_pk"] = this.search_pk;
      else {
        let search_fields = ["search_date", "search_pro", "search_patient", "search_zipcode"];
        for(let el in search_fields) {
          let field = search_fields[el];
          if(this[field]) data[field] = this[field];
        }

        if(this.search_profession) {
          if(this.search_profession.startsWith("SM")) {
            data["search_dmp"] = "10";
            data["search_speciality"] = this.search_profession;
          } else {
            data["search_dmp"] = this.search_profession;
          }
        }

      }

      this.$http.get(`/api/hf-coordination/${this.hf_id}/demand-intervention`, {params: data}).then(response => {
        this.items = response.data;
      }).finally(() => {
        this.loading = false;
      })
    },
  },
}
</script>

<style scoped>

</style>