import {createCSVContentAndAddHeaders, downloadContentFrontV2} from "anam-library/src/js/export_file";
import {get_formatted_date} from "anam-library/src/js/dates";

export default {
    data: () => ({
        loading_export_history_teleconsultation: false
    }),
    methods: {
        export_history_teleconsultation(patient_id) {
            this.loading_export_history_teleconsultation = true;
            let url = `/api/professionals/${this.$store.getters.getProfessionnalId}/export_teleconsultation_history`;
            if (patient_id) url += `?patient_id=${patient_id}`;
            this.$http.get(url).then(response => {
                let headers = ["Nom", "Prénom", "DDN", "Date TLC", "Heure de début TLC", "Durée TLC"];
                let csvContent = createCSVContentAndAddHeaders(headers);
                for (let item of response.data) {
                    let birthdate = "Non renseignée";
                    if (item.patient_birthdate) birthdate = get_formatted_date(item.patient_birthdate, {input_format: 'YYYY-MM-DD', output_format: 'DD/MM/YYYY'});
                    csvContent += `"${item.patient_last_name}",`;
                    csvContent += `"${item.patient_first_name}",`;
                    csvContent += `"${birthdate}",`;
                    csvContent += `"${get_formatted_date(item.start_slot, {output_format: 'DD/MM/YYYY'})}",`;
                    csvContent += `"${get_formatted_date(item.start_slot, {output_format: 'HH:mm'})}",`;
                    csvContent += `"${item.duration || 'Non renseignée'}",`;
                    csvContent += "\r\n";
                }
                let filename = `historique_teleconsultation`;
                if (patient_id && response.data.length) filename += `_${response.data[0].patient_last_name}_${response.data[0].patient_first_name}`;
                downloadContentFrontV2(filename, csvContent);
            }).finally(() => {
                this.loading_export_history_teleconsultation = false;
            })
        }
    }
}