<template>
  <v-hover v-slot:default="{ hover }">
    <v-btn rounded color="green" :elevation="hover ? 4 : 0" class="white--text" @click="$emit('move_to_create')">
      <v-icon class="mr-2">mdi-account-plus</v-icon>
      <span class="font-size--16 medium--text">Ajouter un patient</span>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: "DoctorDashboardCreateBtn"
}
</script>

<style scoped>

</style>