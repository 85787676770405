<template>
  <div>
    <v-system-bar app fixed color="light-blue" window height="max-content">
      <span class="normal--text white--text font-size--16">
        <span class="white--text bold--text font-size--16">Orthophoniste avec abonnement ?</span> cliquez
        <a class="white--text bold--text text-decoration-underline" href="https://fr.inzee.care/">ici</a> pour vous connecter.
      </span>
    </v-system-bar>
    <AnSSOConnexionView app_name="inzeecare"
                        agency_name="inzeecare"
                        univers="citana"
                        :logo="logo"
                        width_logo="70%">
      <template v-slot:under_card>
        <slot name="sso"></slot>
        <div class="text-center mx-4">
          <div class="d-flex mb-4">
            <v-divider class="align-self-center"></v-divider>
            <span class="align-self-center mx-4 medium--text primary_dark--text">OU</span>
            <v-divider class="align-self-center"></v-divider>
          </div>
          <p class="font-size--18 primary_dark--text semibold--text mb-0">Nouvel utilisateur ?</p>
          <v-btn color="primary" elevation="0" text rounded class="px-10 white--text" @click="go_to_inscription()">
            <span class="font-size--14 medium--text text-decoration-underline">Créer mon compte</span>
          </v-btn>

          <div v-if="pwa_installable" class="mt-2">
            <v-divider class="my-4 my-sm-8"></v-divider>
            <v-btn color="primary" elevation="0" text rounded class="px-10 white--text" @click="prompt_install_PWA">
              <span class="font-size--16 medium--text text-decoration-underline">Installer l’application Citana</span>
            </v-btn>
          </div>

        </div>
      </template>
    </AnSSOConnexionView>
  </div>
</template>


<script>
import AnSSOConnexionView from "anam-library/src/components/an-auth/AnSSOConnexionView.vue";

export default {
  name: "ConnexionLayout",
  components: {
    AnSSOConnexionView,
  },
  props: {
    from_br_url: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    logo: "https://www.anamnese.care/hubfs/config/Citana/inzeecare/logo/logo-2022-citana-couleur-BL.png",
    pwa_installable: false,
    deferred_prompt: null,
  }),
  created() {
    this.$store.commit('setConnexionFromGCSBretagne', this.from_br_url);
    this.$store.commit('setRedirectLogoutGCSBretagne', null);

    this.$store.commit("setAgencyName", "inzeecare");
    this.$store.commit("setAppName", "inzeecare");

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.deferred_prompt = e;
      this.pwa_installable = true;
    });
  },
  methods: {
    prompt_install_PWA: async function () {
      if (this.deferred_prompt) {
        this.deferred_prompt.prompt();
        await this.deferred_prompt.userChoice;
        this.deferred_prompt = null;
        this.pwa_installable = false;
      }
    },
    go_to_inscription: function() {
      this.$router.push({name: "RegistryProfessional"})
    },
  }
}
</script>

<style scoped>

</style>