import { render, staticRenderFns } from "./InterventionPlace.vue?vue&type=template&id=36cb84fa&scoped=true"
import script from "./InterventionPlace.vue?vue&type=script&lang=js"
export * from "./InterventionPlace.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../inzeecare/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36cb84fa",
  null
  
)

export default component.exports