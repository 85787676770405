<template>
  <span class="pl-1 primary--text font-size--14 semibold--text">
    {{ label }}<v-icon v-if="required" color="red" size="8" class="mb-3">mdi-asterisk</v-icon>
  </span>
</template>

<script>
export default {
  name: "RegistryLabel",
  props: {
    label: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>

</style>