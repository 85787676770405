import { render, staticRenderFns } from "./DashboardDemandsAdminComponent.vue?vue&type=template&id=112e42e6&scoped=true"
import script from "./DashboardDemandsAdminComponent.vue?vue&type=script&lang=js"
export * from "./DashboardDemandsAdminComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../inzeecare/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "112e42e6",
  null
  
)

export default component.exports