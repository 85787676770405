import Keycloak from "keycloak-js";
import Vue from 'vue'
import store from "@/store";

// GCS BRETAGNE
// initialise keycloak
store.commit('setEnvKeycloakCGSBretagne');
const _keycloak_gcs = new Keycloak({
    url: store.getters.getEnvKeycloakCGSBretagne("keycloak_url"),
    realm: store.getters.getEnvKeycloakCGSBretagne("keycloak_realm"),
    clientId: store.getters.getEnvKeycloakCGSBretagne("keycloak_clientId")
});

const PluginKeycloakGCS = {
    install: Vue => {
        Vue.$keycloak_gcs = _keycloak_gcs;
    }
}

PluginKeycloakGCS.install = Vue => {
    Vue.$keycloak_gcs = _keycloak_gcs;
    Object.defineProperties(Vue.prototype, {
        $keycloak_gcs: {
            get() {
                return _keycloak_gcs;
            }
        }
    })
}

Vue.use(PluginKeycloakGCS)


// set callback to refresh token if it's expired
Vue.$keycloak_gcs.onTokenExpired = function () {
  _keycloak_gcs.updateToken(70);
};

Vue.$keycloak_gcs.onAuthLogout = function () {
  store.dispatch("logoutInzeecare");
}

export default PluginKeycloakGCS;