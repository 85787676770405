<template>
  <v-card class="rounded-lg">
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="5">
          <p class="align-self-center primary--text semibold--text font-size--16 mb-0">
            <v-icon color="tertiary" class="mr-1">mdi-tune-variant</v-icon>
            Filtrer par période :
          </p>
          <v-form :class="$vuetify.breakpoint.smAndUp ? 'd-flex' : ''">
            <AnDate :date="filter_date_start"
                    :filled="false"
                    reset_icon
                    label="Début période"
                    v-on:formatted_date="filter_date_start = $event"
                    v-on:date_reset="filter_date_start = null"/>

            <AnDate :date="filter_date_end"
                    :filled="false"
                    reset_icon
                    class="ml-sm-8"
                    label="Fin période"
                    v-on:formatted_date="filter_date_end = $event"
                    v-on:date_reset="filter_date_end = null"/>
          </v-form>
        </v-col>

        <v-col cols="12" sm="4">
          <p class="align-self-center primary--text semibold--text font-size--16 mb-0">
            <v-icon color="tertiary" class="mr-1">mdi-tune-variant</v-icon>
            Filtrer par codes postaux (facultatif) :
          </p>
          <v-combobox v-model="postcodes"
                      multiple chips clearable deletable-chips
                      type="number"
                      class="ml-sm-8 pt-2 input_postcodes"
                      label="Entrez un code postal"
          />
        </v-col>
        <v-col cols="12" sm="3" class="text-center align-self-center">
          <v-hover v-slot:default="{ hover }">
            <v-btn :elevation="hover ? 4 : 0" rounded color="primary" class="px-6 ml-sm-4" :disabled="!filter_date_end || !filter_date_start" :loading="loading" @click="get_statistics()">
              <v-icon small class="mr-2">mdi-magnify</v-icon>
              <span class="font-size--16 medium--text">Lancer la recherche</span>
            </v-btn>
          </v-hover>
        </v-col>

        <v-expand-transition>
          <v-col cols="12" v-if="is_loaded" class="text-right">
            <AnExportBtn :loading="loading_export"
                         v-on:generate_csv="generate_csv()"
                         v-on:generate_xlsx="generate_xlsx()"/>
          </v-col>
        </v-expand-transition>
        <v-expand-transition>
          <v-col cols="12" v-if="!is_loaded">
            <v-alert type="info" text transition="scale-transition">
              <span class="font-size--16 medium--text">
                Pour visualiser les statistiques vous devez sélectionner une période puis cliquer sur 'Lancer la recherche'.
              </span>
            </v-alert>
          </v-col>
        </v-expand-transition>

        <v-expand-transition>
          <v-col cols="12" sm="6" v-if="is_loaded">
            <p class="primary--text font-size--18 semibold--text">Volume des demandes d'intervention
              <span>(<span class="secondary--text semibold--text font-size--18">{{total_ddi}}</span> demandes émises sur la période)</span>
            </p>
            <div class="text-center">
              <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
            </div>
            <apex-chart type="pie" width="60%" :options="chart_options_volume" :series="series_volume"></apex-chart>
          </v-col>
        </v-expand-transition>

        <v-expand-transition>
          <v-col cols="12" sm="6" v-if="is_loaded">
            <p class="primary--text font-size--18 semibold--text">Volume des demandes par profession</p>
            <div class="text-center">
              <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
            </div>
            <apex-chart type="pie" width="60%" :options="chart_options_profession" :series="series_profession"></apex-chart>
          </v-col>
        </v-expand-transition>
      </v-row>

      <v-divider v-if="is_loaded" inset class="my-10"></v-divider>

      <v-expand-transition>
        <v-row v-if="is_loaded">
          <v-col cols="12" sm="6">
            <p class="primary--text font-size--18 semibold--text mb-0">Délai de résolution</p>
            <p class="font-size--12 font-italic normal--text">Concerne les demandes prises en charge ou clôturées avec le motif 'PEC hors Citana'</p>
            <div class="text-center">
              <v-progress-circular v-if="loading" indeterminate color="primary_pastel"></v-progress-circular>
            </div>
            <apex-chart width="60%" type="pie" :series="series_delai" :options="chart_options_delai" />
          </v-col>
          <v-col cols="12" sm="6">
            <p class="primary--text font-size--18 semibold--text mb-0">Distance des soins</p>
            <p class="font-size--12 font-italic normal--text">Concerne uniquement les demandes prises en charge au sein de Citana. Attention si une adresse est mal renseignée (sur une demande d'intervention ou sur le profil d'un professionnel), la distance ne pourra pas être calculée.</p>
            <apex-chart width="60%" type="pie" :series="series_distance" :options="chart_options_distance"/>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-card-text>
  </v-card>
</template>

<script>
import AnDate from "anam-library/src/components/an-date/AnDate";
import AnExportBtn from "anam-library/src/components/an-export/AnExportBtn"

import moment from "moment-timezone";

import {get_distance_between_two_coordinates} from "anam-library/src/js/address";
import {get_formatted_date} from "anam-library/src/js/dates";
import {get_profession_name} from "anam-library/src/data/professions";
import {generate_csv} from "anam-library/src/js/export_file";
import {generateXlsx} from "anam-library/src/js/export_xlsx";
import {set_txt_author} from "anam-library/src/js/utils";
import {
  get_list_txt_cares,
  get_sentence_frequencies,
  get_txt_start_date,
  get_txt_status,
  get_ddi_motif_closed_txt,
  get_sentence_address_pec
} from "coordination-library/src/data/interventions";
import {get_period_text_for_export} from "coordination-library/src/js/utils";

import axios from "axios";

export default {
  name: "ManagerStatisticsCardDDI",
  components: {AnDate, AnExportBtn},
  props: {
    hf_coordination_id: {
      type: String,
      required: true
    },
  },
  data: () => ({
    is_loaded: false,
    ddi: [],
    loading: false,
    filter_date_start: null,
    filter_date_end: null,
    postcodes: [],
    headers_export: ["N° demande", "Création de la demande", "Demandeur", "Profession demandeur", "Patient",
      "Profession", "Type(s) de soin", "Pour le", "Fréquences des soins", "Adresse", "État demande", "Date PEC",
      "PEC par", "Date clôture", "Motif clôture", "Code postal (PEC)", "Commune (PEC)"],
    loading_export: false
  }),
  computed: {
    total_ddi() {
      return this.ddi.length;
    },
    ddi_export() {
      return this.ddi.filter(x => this.filter_by_postcode(x.address));
    },
    series_volume: function() {
      if (this.total_ddi === 0) {
        return [];
      }
      let ddi_pec_coord = this.ddi.filter(x => !x.closed && x.professional && this.filter_by_postcode(x.address));
      let ddi_pec_ext = this.ddi.filter(x => x.closed && x.closed_motif === 2 && this.filter_by_postcode(x.address));
      let ddi_en_attente = this.ddi.filter(x => !x.closed && !x.professional  && this.filter_by_postcode(x.address));
      let ddi_closed_cancelled = this.ddi.filter(x => x.closed && x.closed_motif === 1 && this.filter_by_postcode(x.address));
      let ddi_closed_no_ressource = this.ddi.filter(x => x.closed && x.closed_motif === 3 && this.filter_by_postcode(x.address));
      let ddi_closed_other = this.ddi.filter(x => x.closed && (x.closed_motif === 4 || !x.closed_motif) && this.filter_by_postcode(x.address));
      let series = [ddi_pec_coord.length, ddi_pec_ext.length, ddi_en_attente.length, ddi_closed_cancelled.length, ddi_closed_no_ressource.length, ddi_closed_other.length];
      return series;
    },
    series_profession: function() {
      if (this.total_ddi === 0) return [];
      let ddi_sf = this.ddi.filter(x => x.dmp_type === '50' && this.filter_by_postcode(x.address));
      let ddi_inf = this.ddi.filter(x => x.dmp_type === '60' && this.filter_by_postcode(x.address));
      let ddi_other = this.ddi.filter(x => x.dmp_type !== '50' && x.dmp_type !== '60' && this.filter_by_postcode(x.address));
      return [ddi_sf.length, ddi_inf.length, ddi_other.length]
    },
    series_delai: function() {
      let ddi = this.ddi.filter(x => ((!x.closed && x.professional) || (x.closed && x.closed_motif === 2)) && this.filter_by_postcode(x.address));
      if (ddi.length === 0) return [];
      let tmp_under_24 = 0;
      let tmp_between_24_48 = 0;
      let tmp_more_48 = 0;

      for (let el in ddi) {
        let start_date = moment(ddi[el].creation_date);
        let end_date = null;
        if(ddi[el].closed && ddi[el].closed_date) end_date = moment(ddi[el].closed_date);
        if(ddi[el].professional && ddi[el].accept_date) end_date = moment(ddi[el].accept_date);

        if (end_date) {
          let duration = moment.duration(end_date.diff(start_date));
          let hours = duration.asHours();
          if (hours < 24) tmp_under_24 += 1;
          else if(hours <= 48) tmp_between_24_48 += 1;
          else if(hours > 48) tmp_more_48 += 1
        }
      }
      return [tmp_under_24, tmp_between_24_48, tmp_more_48];
    },

    series_distance: function() {
      let ddi = this.ddi.filter(x => !x.closed && x.professional && this.filter_by_postcode(x.address));
      if (ddi.length === 0) return [];
      let tmp_under_1 = 0;
      let tmp_between_1_3 = 0;
      let tmp_between_3_6 = 0;
      let tmp_over_6 = 0;

      for (let demand of ddi) {
        if (demand.address && demand.address.latitude && demand.address.longitude
            && demand.professional && demand.professional.coordinates && demand.professional.coordinates.latitude && demand.professional.coordinates.longitude) {
          let distance = get_distance_between_two_coordinates(demand.address.latitude, demand.address.longitude, demand.professional.coordinates.latitude, demand.professional.coordinates.longitude);
          if (distance < 1) tmp_under_1++;
          else if (distance < 3) tmp_between_1_3++;
          else if (distance < 6) tmp_between_3_6++;
          else tmp_over_6++;
        }
      }
      return [tmp_under_1, tmp_between_1_3, tmp_between_3_6, tmp_over_6];
    },
    chart_options_volume() {
      let options = {
        labels: ["PEC sur Citana", "PEC en dehors", "En attente", "Clôturée (annulée)", "Clôturée (pas de ressources disponibles)", "Clôturée (autre motif)"],
        legend: {position: 'bottom'},
        colors: ['#008FFB', '#00E396', '#00E396', '#FF4560', '#775DD0', '#2B908F'],
        noData: {text: 'Aucune donnée'},
        dataLabels: {
          formatter: function(val ,opts) {
            return opts.w.config.series[opts.seriesIndex];
          }
        },
        tooltip: {
          y: {
            formatter: function (val, opts) {
              let total = 0;
              for (let value of opts.config.series) total += value;
              if (total === 0) return val;
              return `${((val/total)*100).toFixed(2)}%`;
            }
          }
        }
      }
      return options;
    },
    chart_options_profession() {
      return {
        labels: ["Sage-Femme", "Infirmier", "Autres (méd, radios, labo, etc.)"],
        legend: {position: 'bottom'},
        noData: {text: 'Aucune donnée'},
        dataLabels: {
          formatter: function(val ,opts) {
            return opts.w.config.series[opts.seriesIndex];
          }
        },
        tooltip: {
          y: {
            formatter: function (val, opts) {
              let total = 0;
              for (let value of opts.config.series) total += value;
              if (total === 0) return val;
              return `${((val/total)*100).toFixed(2)}%`;
            }
          }
        }
      }
    },
    chart_options_delai() {
      return {
        labels: ['Moins de 24h', 'Entre 24h et 48h', 'Plus de 48h'],
        legend: {position: 'bottom'},
        noData: {text: 'Aucune donnée'},
        dataLabels: {
          formatter: function(val ,opts) {
            return opts.w.config.series[opts.seriesIndex];
          }
        },
        tooltip: {
          y: {
            formatter: function (val, opts) {
              let total = 0;
              for (let value of opts.config.series) total += value;
              if (total === 0) return val;
              return `${((val/total)*100).toFixed(2)}%`;
            }
          }
        }
      }
    },
    chart_options_distance() {
      return {
        labels: ['Moins de 1km', 'Entre 1km et 3km', 'Entre 3km et 6km', 'Plus de 6km'],
        legend: {position: 'bottom'},
        noData: {text: 'Aucune donnée'},
        dataLabels: {
          formatter: function(val ,opts) {
            return opts.w.config.series[opts.seriesIndex];
          }
        },
        tooltip: {
          y: {
            formatter: function (val, opts) {
              let total = 0;
              for (let value of opts.config.series) total += value;
              if (total === 0) return val;
              return `${((val/total)*100).toFixed(2)}%`;
            }
          }
        }
      }
    },
    fileName_xlsx: function(){
      return `export-demandes-interventions ${get_period_text_for_export(this.filter_date_start, this.filter_date_end)}`
    }
  },
  watch: {
    hf_coordination_id: function(val) {
      if(val) {
        this.is_loaded = false;
        this.ddi = [];
      }
    }
  },
  methods: {
    get_statistics: function() {
      this.is_loaded = true;
      this.ddi = [];
      this.loading = true;
      let params = {
        get_old_ddi: true,
        to_statistics: true,
        get_traceabilities: false,
        get_specifics_notes: false,
        date_start: get_formatted_date(this.filter_date_start, {input_format: "DD/MM/YYYY", output_format: "YYYY-MM-DD"}),
        date_end: get_formatted_date(this.filter_date_end, {input_format: "DD/MM/YYYY", output_format: "YYYY-MM-DD"})
      }
      axios.get(`/api/hf-coordination/${this.hf_coordination_id}/demand-intervention`, {params: params}).then(response => {
        this.ddi = response.data;
      }).catch(error => {
        if(error.response) {
          this.$store.dispatch('ManageErrorLight', error.response.status);
        }
        this.$store.commit('PostCommonError');
      }).finally(() => {
        this.loading = false;
      })
    },
    filter_by_postcode(address) {
      if (this.postcodes.length) {
        if (address && address.codePostal) {
          return this.postcodes.includes(address.codePostal);
        }
      }
      return true;
    },
    export_data() {
      this.loading_export = true;
      let data_to_export = [];
      for (let item of this.ddi_export) {
        let tmp_author = "";
        let tmp_author_profession = ""
        if (item.author) {
          tmp_author = set_txt_author(item.author);
          if (item.name_service_hf_etab_author) tmp_author += ` (${item.name_service_hf_etab_author})`;
          tmp_author_profession = get_profession_name(item.author.dmp_type, item.author.speciality_code);
        }

        let tmp_pro_pec = "";
        if(item.professional) tmp_pro_pec = set_txt_author(item.professional, false);

        let tmp_cares = "";
        if (item.cares_type && item.cares_type.length > 0) tmp_cares = get_list_txt_cares(item.cares_type).join(', ');
        data_to_export.push([
          item.id,
          get_formatted_date(item.creation_date),
          tmp_author,
          tmp_author_profession,
          item.patient.patient_id,
          get_profession_name(item.dmp_type),
          tmp_cares,
          get_txt_start_date(item.dmp_type, item.start_date, item.delay, item.delay_type),
          item.frequency ? get_sentence_frequencies(item.frequency, item.frequency_type) : "",
          item.address ? get_sentence_address_pec(item.address_pec_type, item.address) : "",
          item.closed ? 'Clôturée' : get_txt_status(item.state),
          item.accept_date ? get_formatted_date(item.accept_date) : "",
          tmp_pro_pec,
          item.closed ? get_formatted_date(item.closed_date) : "",
          item.closed ? get_ddi_motif_closed_txt(item.closed_motif) : "",
          item.address && item.address.codePostal ? item.address.codePostal : "",
          item.address && item.address.localite ? item.address.localite : "",
        ]);
      }
      this.loading_export= false
      return data_to_export;
    },
    generate_csv: function() {
      generate_csv(this.export_data(), this.headers_export, this.fileName_xlsx);
    },
    generate_xlsx: function() {
      generateXlsx(this.export_data(), this.headers_export, "Export demandes intervention", this.fileName_xlsx);
    },
  }
}
</script>

<style>
.input_postcodes input::-webkit-outer-spin-button,
.input_postcodes input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
}
</style>