<template>
  <v-window-item class="pb-5">
    <v-container fluid>
      <v-row class="ma-0 mt-sm-10">
        <v-col cols="12" sm="8" md="6" offset-sm="2" offset-md="3">
          <p class="mb-2 primary--text font-size--16 semibold--text">Votre nº{{ is_rpps_type ? 'RPPS' : 'ADELI' }}</p>
          <v-text-field v-model="idpp"
                        solo filled
                        class="textfield-solo-elevation-0"
                        :placeholder="`Ex: ${is_rpps_type ? '10102304098' : `75${profession}XXXXX`}`"
                        counter
                        maxLength="11"
                        @keyup.enter="search_idpp()"/>
          <v-expand-transition>
            <v-alert v-if="alert_error_idpp" type="error" text>
              <span class="font-size--14 medium--text">
                Vous devez renseigner un numéro {{ is_rpps_type ? 'RPPS' : 'ADELI' }} valide pour continuer (il doit être composé de {{ is_rpps_type ? '11' : '9' }} chiffres).</span>
            </v-alert>
          </v-expand-transition>
<!--          <div class="d-flex">-->
<!--            <v-divider class="primary align-self-center"></v-divider>-->
<!--            <span class="mx-8 medium&#45;&#45;text font-size&#45;&#45;16 primary&#45;&#45;text">OU</span>-->
<!--            <v-divider class="primary align-self-center"></v-divider>-->
<!--          </div>-->
<!--          <p class="mt-8 medium&#45;&#45;text font-size&#45;&#45;16 primary&#45;&#45;text text-center">-->
<!--            S'identifier avec-->
<!--          </p>-->
<!--          <div class="text-center">-->
<!--            <v-btn x-large class="px-0" color="transparent" elevation="0" text>-->
<!--              <img :src="logo_psc" alt="Logo Pro Santé Connect" width="200"/>-->
<!--            </v-btn>-->
<!--          </div>-->
          <div class="mt-6 mt-sm-15 pt-8 text-center">
            <RegistryBtnAction txt_btn="Continuer"
                               :loading="loading"
                               @enter="search_idpp()"
                               @click="search_idpp()"/>
          </div>
          <div class="text-center mt-3">
            <RegistryBtnBack @click="back()"/>
          </div>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog_matching_pro" width="700" content-class="rounded-xl">
        <v-card class="rounded-xl pt=2">
          <v-card-title class="justify-center primary--text">
            <RegistryTitle :title="`Votre nº${is_rpps_type ? 'RPPS' : 'ADLI'} correspond à ce professionnel`"/>
          </v-card-title>
          <v-card-text class="pt-4 pb-0">
            <v-list class="bg_primary rounded-xl mx-sm-10">
              <v-list-item>
                <v-list-item-avatar size="80" class="white hidden-xs-only">
                  <v-icon x-large color="primary">mdi-account</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="primary--text semibold--text font-size--18">
                    {{ professional_cat18.code_civilite|civility }} {{ professional_cat18.nom_exercice|last_name }} {{ professional_cat18.prenom_exercice|first_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="primary--text normal--text font-size--14 mb-2">{{ professional_cat18.code_profession|dmp_name }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="primary--text normal--text font-size--14">{{ professional_cat18|address }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="professional_cat18.telephone" class="primary--text normal--text font-size--14">{{ professional_cat18.telephone }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="professional_cat18.email" class="primary--text normal--text font-size--14">{{ professional_cat18.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <RegistryTitle title="Est-ce bien vous ?" class="mt-8"/>
          </v-card-text>
          <v-card-actions class="justify-center">
            <div class="d-block">
              <div :class="$vuetify.breakpoint.xsOnly ? 'text-center' : 'd-flex'">
                <RegistryBtnAction txt_btn="Non ce n'est pas moi" color="secondary" small class="mb-4 mb-sm-0" @click="next(false)"/>
                <RegistryBtnAction txt_btn="Oui c'est moi" small @click="next(true)"/>
              </div>
              <div class="text-center">
                <RegistryBtnBack small @click="dialog_matching_pro=false"/>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-dialog v-model="dialog_already_registered" width="700" content-class="rounded-xl">
        <v-card class="rounded-xl pt=2">
          <v-card-title class="justify-center primary--text">
            <RegistryTitle :title="`Votre nº${is_rpps_type ? 'RPPS' : 'ADLI'} est déjà associé à un compte sur notre plateforme`"/>
          </v-card-title>
          <v-card-text class="pt-4">
            <p class="font-size--16 normal--text text-center">
              Pour vous connecter cliquez <a class="semibold--text text-decoration-underline" @click="go_to_connexion()">ici</a>.
            </p>
            <p class="text-center font-size--12 font-italic">
              Si vous pensez qu'il s'agit d'une erreur contactez
              <a class="normal--text text-decoration-underline" href="mailto:support@anamnese.care">support@anamnese.care</a>.
            </p>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-window-item>
</template>

<script>
import RegistryTitle from "@/components/public/registry/components/RegistryTitle.vue";
import RegistryBtnBack from "@/components/public/registry/components/RegistryBtnBack.vue";
import RegistryBtnAction from "@/components/public/registry/components/RegistryBtnAction.vue";

import rules from "anam-library/src/js/rules";
import logo_psc from "@/assets/logo_psc.png";
import {capitalize_str, get_txt_civility} from "anam-library/src/js/utils";
import {get_formated_address} from "anam-library/src/js/address";
import {get_profession_name, PROFESSIONS_WITH_ADELI, PROFESSIONS_WITH_RPPS} from "anam-library/src/data/professions";

export default {
  name: "RegistryStepIDPP",
  components: {RegistryBtnAction, RegistryBtnBack, RegistryTitle},
  props: {
    profession: {
      type: String,
      default: null
    }
  },
  data: () => ({
    logo_psc: logo_psc,
    dialog_matching_pro: false,
    dialog_already_registered: false,
    idpp: null,
    alert_error_idpp: false,
    professional_cat18: {},
    loading: false
  }),
  computed: {
    valid_idpp: function() {
      if(!this.idpp) return false;
      if(this.is_rpps_type && this.idpp.length !== 11) return false;
      else if(this.is_adeli_type && this.idpp.length !== 9) return false;
      if(!rules.number(this.idpp)) return false;
      return true;
    },
    is_rpps_type: function() {
      return PROFESSIONS_WITH_RPPS.includes(this.profession);
    },
    is_adeli_type: function() {
      return PROFESSIONS_WITH_ADELI.includes(this.profession);
    }
  },
  methods: {
    search_idpp: function() {
      this.alert_error_idpp = false;
      if(!this.valid_idpp) this.alert_error_idpp = true;
      else {
        this.loading = true;
        let params = {
          check_intern_registration: true,
          agency: "inzeecare"
        }
        this.$http.get(`/api/public/search-professional/${this.idpp}`, {params: params}).then(response => {
          if(response && response.status === 200) {
            if(response.data.intern_registration) {
              this.dialog_already_registered = true;
            } else {
              this.professional_cat18 = response.data;
              this.dialog_matching_pro = true;
            }
          } else {
            this.next(false);
          }
        }).finally(() => {
          this.loading = false;
        })
      }
    },
    next: function(with_identity) {
      if(with_identity) this.$emit("professional_cat18", this.professional_cat18);
      else this.$emit("professional_cat18", {});
      this.$emit("set_idpp", this.idpp);
      this.$emit("next");
      this.dialog_matching_pro = false;
      this.dialog_already_registered = false;
    },
    back: function() {
      this.$emit('back');
      this.reset_data();
    },
    reset_data: function() {
      this.dialog_matching_pro = false;
      this.dialog_already_registered = false;
      this.idpp = null;
      this.alert_error_idpp = false;
      this.professional_cat18 = {};
      this.$emit("professional_cat18", {});
      this.loading = false;
    },
    go_to_connexion: function() {
      this.$router.push({name: "ConnexionLayout"});
    }
  },
  filters: {
    civility: function(val) {
      return get_txt_civility(val);
    },
    last_name: function(val) {
      if(val)
      {
        try {
          return val.toUpperCase();
        } catch (e) {
          return val;
        }
      }
      return ""
    },
    first_name: function(val) {
      return capitalize_str(val);
    },
    address: function(val) {
      return get_formated_address(val);
    },
    dmp_name: function(val) {
      return get_profession_name(val);
    }
  }
}
</script>

<style scoped>

</style>