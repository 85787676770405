<template>
  <v-row class="mx-0">
    <v-col cols="12" sm="3" class="align-self-end pb-0 pb-sm-3">
      <DoctorDashboardCreateBtn v-on:move_to_create="$emit('move_to_create')"/>
    </v-col>
    <v-col :sm="current_user_is_pds ? 2 : 6" class="hidden-xs-only"></v-col>
    <v-col v-if="has_access_to_coordination && current_user_is_pds" cols="12" sm="4" class="align-self-center pb-0 pb-sm-3">
      <DoctorDashboardTagsFilter :filter_tags.sync="filter_tags" :loading="loading"/>
    </v-col>
    <v-col cols="12" sm="3" class="align-self-center pb-0 pb-sm-3">
      <DoctorDashboardSearch :loading="loading"/>
    </v-col>
    <v-col cols="12" class="py-0">
      <p class="font-italic font-size--14 mb-0 mt-2">
        Cette liste n'affiche que les 30 patients les plus récents. Si vous souhaitez accéder à un patient plus ancien, vous pouvez effectuer une recherche depuis l'onglet
        <span class="font-size--14 medium--text text-decoration-underline cursor_pointer" @click="$emit('move_tab', 1)">Tous</span>.
      </p>
    </v-col>
    <v-col cols="12">
       <AnDataTable :headers="headers"
                    :items="patients_filtered"
                    :loading="loading"
                    :table_items_mapping="table_items_mapping"
                    :search="$store.state.dashboard_search"
                    :mobile_card_format="true"
                    :patient_table="true"
                    elevation="4"
                    rounded="rounded-lg"
                    no_data_txt="Aucun résultat"
                    v-on:move_to_dpa="$emit('move_to_dpa', $event)"/>
    </v-col>
  </v-row>
</template>

<script>
import AnDataTable from "anam-library/src/components/an-data-table/AnDataTable";
import DoctorDashboardTagsFilter from "../filters/DoctorDashboardTagsFilter";
import DoctorDashboardSearch from "../filters/DoctorDashboardSearch";
import DoctorDashboardCreateBtn from "../buttons/DoctorDashboardCreateBtn";

import {clean_txt} from "anam-library/src/js/utils";
import {get_formatted_date} from "anam-library/src/js/dates";

export default {
  name: "DoctorRecentPatientsDataTable",
  components: {AnDataTable, DoctorDashboardTagsFilter, DoctorDashboardSearch, DoctorDashboardCreateBtn},
  props: {
    table_items_mapping: {
      type: Object,
      default: () => {}
    },
    class_headers: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    loading: false,
    patients: [],
    filter_tags: []
  }),
  computed: {
    has_access_to_coordination() {
      return this.$store.getters.getPermission('has_access_to_coordination');
    },
    current_user_is_pds: function() {
      return this.$store.getters.getPermission("is_pds");
    },
    headers: function() {
      let class_headers = this.class_headers;
      let tmp = [
        {text: "Sexe", value: "sexe", class: `${class_headers} rounded-tl-lg`},
        {text: "Nom(s) / Prénom", value: "full_name", class: class_headers},
        {text: "Âge", value: "birthdate", class: class_headers},
        {text: "Adresse", value: "fullAddress", class: class_headers},
        {text: "Téléphone", value: "mobile", class: class_headers},
        {text: "Tags", value: "tags", class: class_headers},
        {text: "Fiche patient", value: "dpa", class: `${class_headers} rounded-tr-lg`, sortable: false, align: "center"},
        {text: "SEARCH", value: "search", class: "d-none", cellClass: "d-none"},
      ]
      if(!this.has_access_to_coordination) return tmp.filter(x => x.value !== "tags");
      return tmp;
    },
    patients_filtered: function() {
      let tmp = JSON.parse(JSON.stringify(this.patients));
      if(this.filter_tags.length > 0 && this.current_user_is_pds) {
        tmp = tmp.filter(x => x.tags.filter(y => this.filter_tags.includes(y.meta_indicator.id)).length > 0);
      }
      return tmp
    }
  },
  mounted() {
    this.loading = true;
    this.$http.get("/api/coordination/patients/recent").then(response => {
      this.patients = response.data.map(el => {
        el.full_name = `${el.last_name} ${el.first_name}`;
        el.search = `${clean_txt(el.last_name)} ${clean_txt(el.last_name_used)} ${clean_txt(el.first_name)} ${get_formatted_date(el.birthdate, {output_format: "DD/MM/YYYY"})} ${clean_txt(el.fullAddress)} ${el.mobile}`;
        return el
      });
    }).finally(() => {
      this.loading = false;
    })
  }
}
</script>

<style scoped>
.cursor_pointer {
  cursor: pointer;
}
</style>