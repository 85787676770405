import Vue from 'vue'
import VueRouter from 'vue-router'

import router_error_pages from "anam-library/src/js/router_error_pages_new_charte";

// COMMON
import ConnexionLayout from "../components/public/ConnexionLayout";
import ConnexionBrLayout from "@/components/public/ConnexionBrLayout";
import RedirectionBrView from "@/components/public/RedirectionBrView";
import CoRedirectionView from "coordination-library/src/components/common/CoRedirectionView";
import RegistryProfessionalView from "@/components/public/registry/RegistryProfessionalView.vue";
import NotFoundView from "@/components/NotFoundView.vue";
import DeconnexionView from "@/components/DeconnexionView.vue";


// DOCTOR
import DoctorLayout from "../components/doctor/DoctorLayout";
import DoctorSettingsView from "@/components/doctor/settings/DoctorSettingsView";
import CoDoctorExportsView from "coordination-library/src/components/doctor/exports/CoDoctorExportsView";
import CoDoctorProfileView from "coordination-library/src/components/doctor/profile/CoDoctorProfileView";
import DoctorOnboardingPJView from "@/components/doctor/onboarding/DoctorOnboardingPJView.vue";

// Doctor - Home / Creation patient / Recherche avancée
import DoctorHomeView from "@/components/doctor/home/DoctorHomeView.vue";
import DashboardView from "@/components/doctor/dashboard/DashboardView";
import CoPatientEditLayout from "coordination-library/src/components/doctor/patient-edit/CoPatientEditLayout";

// Doctor - Dpa / Notes
import DpaView from "../components/doctor/dpa/DpaView";
import NotesLayout from "../components/doctor/notes/NotesLayout";
import CoDoctorAttachmentView from "coordination-library/src/components/doctor/attachment/CoDoctorAttachmentView";

// Doctor - Calendar
import CalendarLayout from "../components/doctor/calendar/CalendarLayout";
import CoMyCalendarLayout from "coordination-library/src/components/doctor/calendar/my-calendar/CoMyCalendarLayout";
import CoCalendarEvenementsLayout from "coordination-library/src/components/doctor/calendar/evenements/CoCalendarEvenementsLayout";
import CalendarManageEventsLayout from "@/components/doctor/calendar/CalendarManageEventsLayout";

// Doctor - Demands
import CoMyDemandsView from "coordination-library/src/components/doctor/demand-intervention/my-demands/CoMyDemandsView";
import CoAcceptDemandInterventionView from "coordination-library/src/components/doctor/demand-intervention/accept-demand/CoAcceptDemandInterventionView";
import CoDemandInterventionPatientHistoricView from "coordination-library/src/components/doctor/demand-intervention/historic-patient/CoDemandInterventionPatientHistoricView";
import CoProArchiveDemandesView from "coordination-library/src/components/doctor/demand-intervention/archive-demandes/CoProArchiveDemandesView.vue";

// Doctor - Protocoles / Questionnaires
import CoQuestionnaireView from "coordination-library/src/components/doctor/protocoles/CoQuestionnaireView";
import CoDoctorProtocolesHistoryView from "coordination-library/src/components/doctor/protocoles/CoDoctorProtocolesHistoryView";
import CoDoctorProtocolEndQuestionnaireView
    from "coordination-library/src/components/doctor/protocoles/CoDoctorProtocolEndQuestionnaireView";

// Doctor - Medical Record
import CoDoctorMedicalRecordView from "coordination-library/src/components/doctor/medical-record/CoDoctorMedicalRecordView.vue";

// Doctor - Visio
import ProgramVisioLayout from "../components/doctor/teleconsultation/ProgramVisioLayout";
import ProgramVideoConsultation from "coordination-library/src/components/doctor/visio/ProgramVideoConsultation";
import InvitationVisioLayout from "../components/doctor/teleconsultation/InvitationVisioLayout";
import ProEndVisioView from "../components/doctor/visio/ProEndVisioView";

// Doctor - Chat
import ChatView from "../components/doctor/chat/ChatView";
import ChatHistoryView from "@/components/doctor/chat/ChatHistoryView";

// Doctor - Pilotage opérationnel
import ManagerLayout from "../components/manager/ManagerLayout";
import CoPhoneBookView from "coordination-library/src/components/doctor/phonebook/CoPhoneBookView";

// Doctor - Questionnaire
import ProQuestionnaireView from "@/components/doctor/questionnaire/ProQuestionnaireView.vue";
import ProEndQuestionnaireView from "@/components/doctor/questionnaire/ProEndQuestionnaireView.vue";

// Doctor - DPA / Suivi domicile
import SuiviDomicileView from "@/components/doctor/dpa/suivi_domicile/SuiviDomicileView.vue";

// PATIENT
import CoAgendaPatientView from "coordination-library/src/components/patient/agenda/CoAgendaPatientView";
import PatientEndVisioView from "../components/patient/visio/PatientEndVisioView";
import PatientConfirmIdentityView from "../components/patient/confirm-identity/PatientConfirmIdentityView";
import PatientActeDocumentsSharedView from "../components/patient/visio/PatientActeDocumentsSharedView";

// Patient - Waiting List
import PatientWaitingListLayout from "@/components/patient/waiting-list/PatientWaitingListLayout";
import PatientWaitingListHomeView from "@/components/patient/waiting-list/home/PatientWaitingListHomeView";
import PatientWaitingListFormView from "@/components/patient/waiting-list/form/PatientWaitingListFormView";
import PatientWaitingListEndView from "@/components/patient/waiting-list/end/PatientWaitingListEndView";
import VisioLayout from "../components/visio/VisioLayout";

// MANAGER
import ManagerHomeView from "@/components/manager/home/ManagerHomeView.vue";
// Structures
import ManagerListingStructuresView from "@/components/manager/structures/listing/ManagerListingStructuresView";
import ManagerSettingsCPTSView from "@/components/manager/structures/settings/ManagerSettingsCPTSView";
import ManageCPTSView from "@/components/manager/structures/details/ManageCPTSView";
import ArchivedDemandsView from "@/components/manager/structures/details/ddi/ArchivedDemandsView";
import ActionFromEmailView from "@/components/patient/ActionFromEmailView";
// Users
import ManagerUsersListingView from "@/components/manager/users/listing/ManagerUsersListingView.vue";
import ManagerUsersToValidateListingView from "@/components/manager/users/listing/ManagerUsersToValidateListingView.vue";
import ManagerUsersDetailsView from "@/components/manager/users/details/ManagerUsersDetailsView.vue";


import store from "../store"

Vue.use(VueRouter);

function redirectDoctor(to, from, next) {
    if (store.getters.getUserPermission === 2) {
        if(to.query.redirection) next(to.query.redirection);
        else next("/doctor");
    }
    next();
}

function redirectUnauthorized(to, from, next) {
    if (store.getters.getUserPermission !== 2) next("/");
    next();
}

function hasAccessToDDI(to, from, next) {
    if (store.getters.getUserPermission !== 2) next("/");
    else if (store.getters.getPermission('has_access_to_ddi')) next();
    else next("/403");
}

function hasAccessToSNP(to, from, next) {
    if (store.getters.getUserPermission !== 2) next("/");
    else if (store.getters.getPermission('has_access_to_snp')) next();
    else next("/403");
}

const routes = [
    {
        path: '/404',
        name: '404',
        component: NotFoundView,
    },
    {
        path: '/401',
        name: '401',
        component: DeconnexionView
    },
    router_error_pages.wildcard_page,
    router_error_pages.forbidden_page,
    router_error_pages.nginx_error_page,
    router_error_pages.error_server_page,
    router_error_pages.expiration_token_page,
    {
        path: '/redirection',
        name: 'RedirectionView',
        component: CoRedirectionView,
        props: {
            app_name: "inzeecare"
        }
    },
    {
        path: '/onboarding-professional',
        name: "RegistryProfessional",
        component: RegistryProfessionalView
    },
    {
        path: '/v/:room_uid',
        name: 'VisioLayout',
        component: VisioLayout,
    },
    {
        path: "/pro-questionnaire/end",
        name: "ProEndQuestionnaire",
        component: ProEndQuestionnaireView
    },
    {
        path: "/pro-questionnaire/:questionnaire_uid",
        name: "ProQuestionnaire",
        component: ProQuestionnaireView
    },
    {
        path: '/professional',
        alias: '/doctor',
        redirect: {name: 'DoctorHome'},
        name: 'DoctorLayout',
        component: DoctorLayout,
        children: [
            {
                path: 'home',
                name: 'DoctorHome',
                component: DoctorHomeView,
                beforeEnter: redirectUnauthorized,
            },
            {
                path: 'dashboard',
                alias: '/dashboard',
                name: 'DoctorDashboard',
                component: DashboardView,
                beforeEnter: redirectUnauthorized
            },
            {
                path: 'chat',
                alias: '/chat',
                name: 'ChatHistoryView',
                component: ChatHistoryView,
                beforeEnter: redirectUnauthorized
            },
            {
                path: 'chat/patient/:patient_id',
                name: 'ChatHistoryPatientView',
                component: ChatHistoryView,
                beforeEnter: redirectUnauthorized
            },
            {
                path: 'chat/:chatroom_id',
                name: 'ChatView',
                component: ChatView,
                beforeEnter: redirectUnauthorized
            },
            {
                path: 'calendar',
                alias: '/calendar',
                name: 'DoctorCalendar',
                component: CalendarLayout,
                redirect: {'name': 'CoMyCalendarLayout'},
                beforeEnter: redirectUnauthorized,
                children: [
                    {
                        path: 'my-calendar',
                        name: 'CoMyCalendarLayout',
                        component: CoMyCalendarLayout,
                        beforeEnter: redirectUnauthorized,
                        props: {
                            app_name: 'inzeecare'
                        }
                    },
                    {
                        path: 'my-evenements',
                        name: 'CalendarEvenementLayout',
                        component: CoCalendarEvenementsLayout,
                        beforeEnter: hasAccessToSNP
                    },
                    {
                        path: 'manage-evenements',
                        name: 'CalendarManageEventsLayout',
                        component: CalendarManageEventsLayout,
                        beforeEnter: hasAccessToSNP
                    },
                ]
            },
            {
                path: '/patient-edit',
                name: 'PatientEditLayout',
                component: CoPatientEditLayout,
                beforeEnter: redirectUnauthorized,
            },
            {
                path: '/patients/:patient_id',
                name: 'DoctorDpa',
                component: DpaView,
                beforeEnter: redirectUnauthorized
            },
            {
                path: '/patients/:id/notes',
                name: 'NotesLayout',
                component: NotesLayout,
                beforeEnter: redirectUnauthorized
            },
            {
                path: '/patients/:patient_id/attachments',
                name: 'DoctorAttachmentView',
                component: CoDoctorAttachmentView,
                beforeEnter: redirectUnauthorized
            },
            {
                path: '/patients/:patient_id/suivi_domicile/:questionnaire_type',
                name: 'SuiviDomicileView',
                component: SuiviDomicileView,
                beforeEnter: redirectUnauthorized
            },
            {
                path: '/patients/:patient_id/actes/:acte_id/attachments',
                name: 'DoctorAttachmentView',
                component: CoDoctorAttachmentView,
                beforeEnter: redirectUnauthorized
            },
            {
                path: '/patients/:id/invitation-visio',
                name: 'InvitationVisioLayout',
                component: InvitationVisioLayout,
                beforeEnter: redirectUnauthorized
            },
            {
                path: '/patients/:id/program-visio',
                name: 'ProgramVisioLayout',
                component: ProgramVisioLayout,
                beforeEnter: redirectUnauthorized
            },
            {
                path: '/patients/:id/video-consultations',
                name: 'ProgramVideoConsultation',
                component: ProgramVideoConsultation,
                beforeEnter: redirectUnauthorized
            },
            {
                path: '/manage_cpts/:id',
                name: "ManageCPTS",
                component: ManageCPTSView,
                beforeEnter: redirectUnauthorized
            },
            {
                path: '/manage_cpts/:hf_id/archived-demands',
                name: "ArchivedDemandsView",
                component: ArchivedDemandsView,
                beforeEnter: hasAccessToDDI,
                props: {
                    app_name: "inzeecare"
                }
            },
            {
                path: '/patients/:patient_id/questionnaire',
                name: 'QuestionnaireView',
                component: CoQuestionnaireView,
                props: {
                    agency_name: "inzeecare",
                    app_name: "inzeecare"
                }
            },
            {
                path: '/patients/:patient_id/end-questionnaire/:questionnaire_uid',
                name: 'CoDoctorProtocolEndQuestionnaire',
                component: CoDoctorProtocolEndQuestionnaireView
            },
            {
                path: '/patients/:patient_id/history-protocoles',
                name: 'DoctorProtocolesHistory',
                component: CoDoctorProtocolesHistoryView,
                beforeEnter: redirectUnauthorized
            },
            {
                path: '/patients/:patient_id/medical-record',
                name: 'CoDoctorMedicalRecord',
                component: CoDoctorMedicalRecordView,
                beforeEnter: redirectUnauthorized,
                props: {
                    app_name: "inzeecare"
                }
            },
            {
                path: '/patients/:patient_id/demand-intervention',
                name: 'DemandInterventionPatientHistoricView',
                component: CoDemandInterventionPatientHistoricView,
                beforeEnter: hasAccessToDDI,
                props: {
                    app_name: "inzeecare"
                }
            },
            {
                path: 'phonebook',
                name: 'PhoneBookView',
                component: CoPhoneBookView,
                props: {
                    agency_name: "inzeecare",
                    app_name: "inzeecare"
                }
            },
            {
                path: 'my-demands',
                name: 'MyDemandsView',
                component: CoMyDemandsView,
                beforeEnter: hasAccessToDDI
            },
            {
                path: 'archive-demands',
                name: 'ProArchiveDemandsView',
                component: CoProArchiveDemandesView,
                beforeEnter: hasAccessToDDI
            },
            {
                path: 'end-visio/:patient_id/:consultation_id',
                name: 'ProEndVisioView',
                component: ProEndVisioView,
                beforeEnter: redirectUnauthorized
            },
            {
                path: 'exports',
                name: 'CoDoctorExports',
                component: CoDoctorExportsView,
            },
            {
                path: 'settings',
                name: 'DoctorSettingsView',
                component: DoctorSettingsView,
            },
            {
                path: 'my-account',
                name: 'DoctorProfile',
                component: CoDoctorProfileView,
            },
        ]
    },
    {
        path: '/professional/onboarding-pj',
        name: 'DoctorOnboardingPJ',
        component: DoctorOnboardingPJView,
    },
    {
        path: "/manager",
        name: "ManagerLayout",
        component: ManagerLayout,
        children: [
            {
                path: "home",
                name: "ManagerHome",
                component: ManagerHomeView
            },
            {
                path: "cpts",
                name: "ManagerStructures",
                component: ManagerListingStructuresView
            },
            {
                path: "cpts/:hf_id/settings",
                name: "CoManagerSettingsCPTS",
                component: ManagerSettingsCPTSView
            },
            {
                path: 'manage_cpts/:id',
                name: "ManageCPTSManager",
                component: ManageCPTSView,
            },
            {
                path: 'manage_cpts/:hf_id/archived-demands',
                name: "ArchivedDemandsManagerView",
                component: ArchivedDemandsView,
                props: {
                    app_name: "inzeecare"
                }
            },
            {
                path: "users",
                name: "ManagerUsers",
                component: ManagerUsersListingView
            },
            {
                path: "users-to-validate",
                name: "ManagerUsersToValidateListing",
                component: ManagerUsersToValidateListingView
            },
            {
                path: "users/:user_id",
                name: "ManagerUsersDetails",
                component: ManagerUsersDetailsView
            },
        ]
    },
    {
        path: "/agenda/:id_health_facility/:id_hfcalendar",
        name: "AgendaPatient",
        component: CoAgendaPatientView,
        props: {
            agency_name: "inzeecare",
            app_name: "inzeecare"
        }
    },
    {
        path: "/coordination/:id_health_facility/liste-attente/mt",
        name: "PatientWaitingList",
        redirect: {name: "PatientWaitingListHome"},
        component: PatientWaitingListLayout,
        children: [
            {
                path: "home",
                name: "PatientWaitingListHome",
                component: PatientWaitingListHomeView
            },
            {
                path: "formulaire",
                name: "PatientWaitingListForm",
                component: PatientWaitingListFormView
            },
            {
                path: "end",
                name: "PatientWaitingListEnd",
                component: PatientWaitingListEndView
            },
        ]
    },
    {
        path: "/action-from-email/:action/:token",
        name: "ActionFromEmailView",
        component: ActionFromEmailView
    },
    {
        path: "/demand-intervention/:token",
        name: "CoAcceptDemandInterventionView",
        component: CoAcceptDemandInterventionView,
        props: {
            app_name: 'inzeecare'
        }
    },
    {
        path: "/end-visio",
        name: "PatientEndVisioView",
        component: PatientEndVisioView
    },
    {
        path: "/:patient_id/confirm-identity",
        name: "PatientConfirmIdentity",
        component: PatientConfirmIdentityView
    },
    {
        path: "/patients/:patient_id/actes/:acte_id/documents-shared",
        name: "PatientActeDocumentsSharedView",
        component: PatientActeDocumentsSharedView
    },
    {
        path: "/br/connexion",
        name: "ConnexionBrLayout",
        component: ConnexionBrLayout,
        beforeEnter: redirectDoctor
    },
    {
        path: "/br/connexion-gcs",
        name: "RedirectionBrView",
        component: RedirectionBrView,
        props: {
            access: true,
            common_error: false,
            user_unknow: false,
            not_actif: false
        }
    },
    {
        path: "/br/connexion-gcs-error",
        name: "CommonErrorRedirectionBrView",
        component: RedirectionBrView,
        props: {
            access: false,
            common_error: true,
            user_unknow: false,
            not_actif: false
        }
    },
    {
        path: "/br/connexion-gcs-unknown-user",
        name: "UnknownErrorRedirectionBrView",
        component: RedirectionBrView,
        props: {
            access: false,
            common_error: false,
            user_unknow: true,
            not_actif: false
        }
    },
    {
        path: "/br/connexion-gcs-inactive",
        name: "InactiveErrorRedirectionBrView",
        component: RedirectionBrView,
        props: {
            access: false,
            common_error: false,
            user_unknow: false,
            not_actif: true
        }
    },
    {
        path: "/",
        name: "ConnexionLayout",
        component: ConnexionLayout,
        beforeEnter: redirectDoctor
    }
];

const router = new VueRouter({
    mode: "history",
    routes
});


router.beforeEach((to, from, next) => {
    if (!store.state.websocket && store.getters.getUserPermission === 2) store.dispatch("startWebsocket");
    next();
})

export default router
