<template>
  <CoDoctorSettingsView/>
</template>

<script>
import CoDoctorSettingsView from "coordination-library/src/components/doctor/settings/CoDoctorSettingsView";

export default {
  name: "DoctorSettingsView",
  components: {CoDoctorSettingsView},
}
</script>

<style scoped>

</style>