<template>
  <p class="text-center font-size--22 bold--text primary--text text-break">{{ title }}</p>
</template>

<script>
export default {
  name: "RegistryTitle",
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>