import { render, staticRenderFns } from "./ProtocoleICGresivaudanComponent.vue?vue&type=template&id=274a765e&scoped=true"
import script from "./ProtocoleICGresivaudanComponent.vue?vue&type=script&lang=js"
export * from "./ProtocoleICGresivaudanComponent.vue?vue&type=script&lang=js"
import style0 from "./ProtocoleICGresivaudanComponent.vue?vue&type=style&index=0&id=274a765e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../inzeecare/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274a765e",
  null
  
)

export default component.exports