<template>
  <CoCalendarLayout></CoCalendarLayout>
</template>

<script>
import CoCalendarLayout from "coordination-library/src/components/doctor/calendar/CoCalendarLayout";

export default {
  name: "CalendarLayout",
  components: {CoCalendarLayout}
};
</script>