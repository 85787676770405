import { render, staticRenderFns } from "./RegistryChoiceField.vue?vue&type=template&id=4689e717&scoped=true"
import script from "./RegistryChoiceField.vue?vue&type=script&lang=js"
export * from "./RegistryChoiceField.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4689e717",
  null
  
)

export default component.exports