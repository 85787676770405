import ProtocoleCommonComponent from "../components/doctor/protocoles/components/ProtocoleCommonComponent";
import ProtocoleCystiteComponent from "../components/doctor/protocoles/pt-cystite/ProtocoleCystiteComponent";
import ProtocoleVaricelleComponent from "../components/doctor/protocoles/pt-varicelle/ProtocoleVaricelleComponent";
import ProtocoleLombalgieComponent from "../components/doctor/protocoles/pt-lombalgie/ProtocoleLombalgieComponent";
import ProtocoleEntorseComponent from "../components/doctor/protocoles/pt-entorse/ProtocoleEntorseComponent";
import ProtocoleRhiniteComponent from "../components/doctor/protocoles/pt-rhinite/ProtocoleRhiniteComponent";
import ProtocoleAngineComponent from "../components/doctor/protocoles/pt-angine/ProtocoleAngineComponent";
import ProtocoleBPCOComponent from "../components/doctor/protocoles/pt-bpco/ProtocoleBPCOComponent";
import ProtocoleBPCOToulouseComponent from "../components/doctor/protocoles/pt-bpco-toulouse/ProtocoleBPCOToulouseComponent";
import ProtocoleOsteoComponent from "../components/doctor/protocoles/pt-osteo/ProtocoleOsteoComponent";
import ProtocoleICToulouseComponent from "../components/doctor/protocoles/pt-ic-toulouse/ProtocoleICToulouseComponent.vue";
import ProtocoleICGresivaudanComponent from "../components/doctor/protocoles/pt-ic-gresivaudan/ProtocoleICGresivaudanComponent.vue";
import ProtocoleSportSanteComponent from "../components/doctor/protocoles/pt-sport-sante/ProtocoleSportSanteComponent";
import ProtocoleLeveeDouteComponent from "../components/doctor/protocoles/pt-levee-doute/ProtocoleLeveeDouteComponent";
import ProtocoleDiabeteComponent from "../components/doctor/protocoles/pt-diabete/ProtocoleDiabeteComponent";
import ProtocoleDiabeteHautilComponent from "../components/doctor/protocoles/pt-diabete-hautil/ProtocoleDiabeteHautilComponent";
import ProtocoleDeshydratationComponent from "../components/doctor/protocoles/pt-deshydratation/ProtocoleDeshydratationComponent";
import ProtocoleOrientationGexComponent from "../components/doctor/protocoles/pt-orientation-gex/ProtocoleOrientationGexComponent.vue";
import ProtocoleBronchioliteComponent from "../components/doctor/protocoles/pt-bronchiolite/ProtocoleBronchioliteComponent.vue";
import ProtocoleObesiteComponent from "../components/doctor/protocoles/pt-obesite/ProtocoleObesiteComponent.vue";
import ProtocoleDACGrasseComponent from "../components/doctor/protocoles/pt-dac-grasse/ProtocoleDACGrasseComponent.vue";
import ProtocoleTDAGrasseComponent from "../components/doctor/protocoles/pt-tda-grasse/ProtocoleTDAGrasseComponent.vue";
import ProtocoleEvaluationBesoinsEVComponent from "../components/doctor/protocoles/pt-evaluation-besoins-ev/ProtocoleEvaluationBesoinsEVComponent.vue";
import ProtocoleProgrammesAPAETPComponent from "../components/doctor/protocoles/pt-programmes-apa-etp/ProtocoleProgrammesAPAETPComponent.vue";
import ProtocoleInclusionComponent from "../components/doctor/protocoles/components/ProtocoleInclusionComponent";


export const MAP_PROTOCOLE_COMPONENT = {
    "coordination-pt-cystite": ProtocoleCystiteComponent,
    "coordination-pt-cystite-gresivaudan": ProtocoleCystiteComponent,
    "coordination-pt-cystite-littoral-vendeen": ProtocoleCystiteComponent,
    "coordination-pt-cystite-drac-sud": ProtocoleCystiteComponent,
    "coordination-pt-varicelle": ProtocoleVaricelleComponent,
    "coordination-pt-lombalgie": ProtocoleLombalgieComponent,
    "coordination-pt-entorse": ProtocoleEntorseComponent,
    "coordination-pt-rhinite": ProtocoleRhiniteComponent,
    "coordination-pt-angine": ProtocoleAngineComponent,
    "coordination-pt-angine-drac-sud": ProtocoleAngineComponent,
    "coordination-pt-bpco": ProtocoleBPCOComponent,
    "coordination-pt-bpco-toulouse": ProtocoleBPCOToulouseComponent,
    "coordination-pt-osteo": ProtocoleOsteoComponent,
    "coordination-pt-ic-toulouse": ProtocoleICToulouseComponent,
    "coordination-pt-ic-gresivaudan": ProtocoleICGresivaudanComponent,
    "coordination-pt-sport-sante": ProtocoleSportSanteComponent,
    "coordination-pt-levee-doute": ProtocoleLeveeDouteComponent,
    "coordination-pt-diabete": ProtocoleDiabeteComponent,
    "coordination-pt-diabete-hautil": ProtocoleDiabeteHautilComponent,
    "coordination-pt-deshydratation": ProtocoleDeshydratationComponent,
    "coordination-pt-orientation-gex": ProtocoleOrientationGexComponent,
    "coordination-pt-bronchiolite": ProtocoleBronchioliteComponent,
    "coordination-pt-obesite": ProtocoleObesiteComponent,
    "coordination-pt-dac-pays-de-grasse": ProtocoleDACGrasseComponent,
    "coordination-pt-tda-pays-de-grasse": ProtocoleTDAGrasseComponent,
    "coordination-pt-evaluation-besoins-ev": ProtocoleEvaluationBesoinsEVComponent,
    "coordination-pt-programmes-apa-etp": ProtocoleProgrammesAPAETPComponent,
    "mj-ygee": ProtocoleCommonComponent,
    "EcoordinationGrilleInclusion": ProtocoleInclusionComponent
}

export const get_protocole_component =  function(questionnaire_name) {
    if(Object.keys(MAP_PROTOCOLE_COMPONENT).includes(questionnaire_name)) {
        return MAP_PROTOCOLE_COMPONENT[questionnaire_name];
    }
    return ProtocoleCommonComponent;
}


const MAP_ORDONANCE = {
    "radio_cheville": [
        {
            "title": "Examen radiologique ",
            "description": "radiographie de la cheville et du pied droite/gauche: : face, profil, ¾ (exploration  d’une entorse de la cheville)" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": ""
        }
    ],
    "paracetamol_attele_canne_botte": [
        {
            "title": "PARACETAMOL - 1 000 MG - Cp. - Voie orale",
            "description": "1 comprimé toutes les 6h en cas de douleurs" ,
            "nbr_boites": "QSP 3 jours",
            "infos": "",
            "more_infos": ""
        },
        {
            "title": "1 Attelle cheville Malleo Dynastab Boa - Thuasne",
            "description": "" ,
            "nbr_boites": "",
            "infos":  "",
            "more_infos": ""
        },
        {
            "title": "1 Botte de marche AirCast Airselect version basse  - Donjoy",
            "description": "" ,
            "nbr_boites": "",
            "infos":  "",
            "more_infos": ""
        },
        {
            "title": "1 paire de canne anglaise ",
            "description": "" ,
            "nbr_boites": "",
            "infos":  "",
            "more_infos": "En cas d’urgence, appelez le 15"
        },
    ],
    "paracetamol_attele_botte": [
        {
            "title": "PARACETAMOL - 1 000 MG - Cp. - Voie orale",
            "description": "1 comprimé toutes les 6h en cas de douleurs" ,
            "nbr_boites": "QSP 3 jours",
            "infos": "",
            "more_infos": ""
        },
        {
            "title": "1 Attelle cheville Malleo Dynastab Boa - Thuasne",
            "description": "",
            "nbr_boites": "",
            "infos":  "",
            "more_infos": ""
        },
        {
            "title": "1 Botte de marche AirCast Airselect version basse  - Donjoy",
            "description": "",
            "nbr_boites": "",
            "infos":  "",
            "more_infos": "En cas d’urgence, appelez le 15"
        },
    ],
    "paracetamol_canne": [
        {
            "title": "PARACETAMOL - 1 000 MG - Cp. - Voie orale",
            "description": "1 comprimé toutes les 6h en cas de douleurs" ,
            "nbr_boites": "QSP 3 jours",
            "infos": "",
            "more_infos": ""
        },
        {
            "title": "1 paire de canne anglaise ",
            "description": "" ,
            "nbr_boites": "",
            "infos":  "",
            "more_infos": "En cas d’urgence, appelez le 15"
        },
    ],
    "paracetamol_entorse": [
        {
            "title": "PARACETAMOL - 1 000 MG - Cp. - Voie orale",
            "description": "1 comprimé toutes les 6h en cas de douleurs" ,
            "nbr_boites": "QSP 3 jours",
            "infos": "",
            "more_infos": "En cas d’urgence, appelez le 15",
        }
    ],
    "paracetamol": [
        {
            "title": "PARACETAMOL - 1 000 MG - Cp. - Voie orale",
            "description": "1 comprimé toutes les 6h en cas de douleurs ou fièvre" ,
            "nbr_boites": "QSP 3 jours",
            "infos": "",
            "more_infos": "En cas d’urgence, appelez le 15",
        }
    ],
    "doliprane": [
        {
            "title": "DOLIPRANE - 24 MG/ML - Susp. buvable - Voie orale",
            "description": "60 MG/KG/J en 4 prises, pendant 6 jours si nécessaire" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72h ou d'aggravation de ceux-ci",
        }
    ],
    "naproxene": [
        {
            "title": "NAPROXÈNE SODIQUE - 550 MG - Cp. - voie orale",
            "description": "1 comprimé matin et soir, pendant 7 jours" ,
            "nbr_boites": "",
            "infos": "Les comprimés doivent être avalés avec un verre d'eau, sans être croqués,\n" +
                "de préférence au milieu des repas",
            "more_infos":  "Consultez un médecin généraliste à 5 jours en cas de persistance des symptômes ou à 24H en cas d'aggravation " +
                "ou d’apparition de nouveaux symptômes"
        }
    ],
    "naproxene_omeprazole": [
        {
            "title": "NAPROXÈNE SODIQUE - 550 MG - Cp. - voie orale",
            "description": "1 comprimé matin et soir, pendant 7 jours" ,
            "nbr_boites": "",
            "infos": "Les comprimés doivent être avalés avec un verre d'eau, sans être croqués,\n" +
                "de préférence au milieu des repas",
            "more_infos": "Consultez un médecin généraliste à 5 jours en cas de persistance des symptômes ou à 24H " +
                "en cas d'aggravation ou d’apparition de nouveaux symptômes"
        },
        {
            "title": "OMEPRAZOLE - 20 MG - Gel. gastro-résistantes - voie orale",
            "description": "1 gélule le matin pendant 7 jours" ,
            "nbr_boites": "",
            "infos": "A jeun de préférence",
            "more_infos":""
        },
    ],
    "ecbu": [
        {
            "title": "ECBU",
            "description": "Avec culture et antibiogramme si germes > 10<sup>3</sup> UFC/mL<br>Consulter un médecin avec les résultats" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos":"" +
                "Buvez beaucoup d’eau (volume au moins égal à 1,5L par jour)\n\n" +
                "Informer et consulter rapidement un médecin, même avant l’obtention des résultats si :\n" +
                " - apparition d’un nouveau symptôme\n" +
                " - apparition d’un critère de gravité (fièvre, symptômes évocateurs d’une pyélonéphrite, altération de l’état général) dans les 24H\n" +
                " - persistance ou aggravation des symptômes avant l’obtention du résultat de l’ECBU"
        },
    ],
    "ecbu_paracetamol": [
        {
            "title": "PARACETAMOL - 1 000 MG - Cp. - Voie orale",
            "description": "1 comprimé toutes les 6h en cas de douleurs ou fièvre" ,
            "nbr_boites": "QSP 3 jours",
            "infos": "",
            "more_infos": "",
        },
        {
            "title": "ECBU",
            "description": "Avec culture et antibiogramme si germes > 10<sup>3</sup> UFC/mL<br>Consulter un médecin avec les résultats" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos":"" +
                "Buvez beaucoup d’eau (volume au moins égal à 1,5L par jour)\n\n" +
                "Informer et consulter rapidement un médecin, même avant l’obtention des résultats si :\n" +
                " - apparition d’un nouveau symptôme\n" +
                " - apparition d’un critère de gravité (fièvre, symptômes évocateurs d’une pyélonéphrite, altération de l’état général) dans les 24H\n" +
                " - persistance ou aggravation des symptômes avant l’obtention du résultat de l’ECBU"
        }
    ],
    "fosfomycine": [
        {
            "title": "FOSFOMYCINE TROMETAMOL PO - 3 G - Sachet - Voie orale",
            "description": "1 dose" ,
            "nbr_boites": "",
            "infos": "Le sachet est à dissoudre dans un grand verre d'eau et à boire immédiatement.\n" +
                "À prendre à jeun (ne pas manger dans les 2 heures précédant et les 2 heures\n" +
                "suivant la prise)",
            "more_infos":"" +
                "Buvez beaucoup d’eau (volume au moins égal à 1,5L par jour)\n\n" +
                "Consultez un médecin généraliste ou appeler le 15 en cas :\n" +
                " - d’apparition de nouveaux symptômes ou persistance des symptômes initiaux 48h après la prise du traitement : " +
                "envie d’uriner fréquente, sang dans les urines, fièvre, douleurs dans le bas du ventre, brûlures mictionnelles\n" +
                " - d’apparition de symptômes évocateurs d'une pyélonéphrite : douleurs lombaires, fièvre supérieure à 38,5°C\n"
        }
    ],
    "pivmecillinam": [
        {
            "title": "CHLORHYDRATE DE PIVMECILLINAM - 400 MG - Cp. - voie orale",
            "description": "400 mg 2 fois par jour, pendant 3 jours" ,
            "nbr_boites": "",
            "infos": "Afin d'éviter la survenue de lésions de l'œsophage, les comprimés sont à avaler en\n" +
                "position assise ou debout. Évitez de vous allonger dans les 30 minutes qui suivent la\n" +
                "prise. A prendre au milieu d'un repas, avec un grand verre d'eau.",
            "more_infos":"" +
                "Buvez beaucoup d’eau (volume au moins égal à 1,5L par jour)\n\n" +
                "Consultez un médecin généraliste ou appeler le 15 en cas :\n" +
                " - d’apparition de nouveaux symptômes ou persistance des symptômes initiaux 48h après la prise du traitement : " +
                "envie d’uriner fréquente, sang dans les urines, fièvre, douleurs dans le bas du ventre, brûlures mictionnelles\n" +
                " - d’apparition de symptômes évocateurs d'une pyélonéphrite : douleurs lombaires, fièvre supérieure à 38,5°C\n"
        },
    ],
    "amoxicilline": [
        {
            "title": "AMOXICILLINE PO - 1 000 MG - Cp. dispersibles - Voie orale",
            "description": "1 comprimé deux fois par jour, pendant 6 jours" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72H ou d’aggravation de ceux-ci"
        },
    ],
    "amoxicilline_paracetamol": [
        {
            "title": "AMOXICILLINE PO - 1 000 MG - Cp. dispersibles - Voie orale",
            "description": "1 comprimé deux fois par jour, pendant 6 jours" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": ""
        },
        {
            "title": "PARACETAMOL - 1 000 MG - Cp. - Voie orale",
            "description": "1 comprimé toutes les 6h en cas de douleurs ou fièvre" ,
            "nbr_boites": "QSP 3 jours",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72H ou d’aggravation de ceux-ci",
        }
    ],
    "amoxicilline_less_40": [
        {
            "title": "AMOXICILLINE PO - 250 MG / 5ML Pdre. pour susp. buvable - Voie orale",
            "description": "50 MG/KG/J (sans dépasser 2 G/J) en 2 prises par jour, pendant 6 jours" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72H ou d’aggravation de ceux-ci"
        },
    ],
    "amoxicilline_less_40_doliprane": [
        {
            "title": "AMOXICILLINE PO - 250 MG / 5ML Pdre. pour susp. buvable - Voie orale",
            "description": "50 MG/KG/J (sans dépasser 2 G/J) en 2 prises par jour, pendant 6 jours" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": ""
        },
        {
            "title": "DOLIPRANE - 24 MG/ML - Susp. buvable - Voie orale",
            "description": "60 MG/KG/J en 4 prises, pendant 6 jours si nécessaire" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72h ou d'aggravation de ceux-ci",
        }
    ],
    "cefuroxime_axetil": [
        {
            "title": "CEFUROXIME-AXETIL PO - 250 MG - Cp. - Voie orale",
            "description": "1 comprimé deux fois par jour, pendant 4 jours" ,
            "nbr_boites": "",
            "infos": "A prendre toujours après les repas",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72H ou d’aggravation de ceux-ci"
        },
    ],
    "cefuroxime_axetil_paracetamol": [
        {
            "title": "CEFUROXIME-AXETIL PO - 250 MG - Cp. - Voie orale",
            "description": "1 comprimé deux fois par jour, pendant 4 jours" ,
            "nbr_boites": "",
            "infos": "A prendre toujours après les repas",
            "more_infos": ""
        },
        {
            "title": "PARACETAMOL - 1 000 MG - Cp. - Voie orale",
            "description": "1 comprimé toutes les 6h en cas de douleurs ou fièvre" ,
            "nbr_boites": "QSP 3 jours",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72H ou d’aggravation de ceux-ci",
        }
    ],
    "azithromycine": [
        {
            "title": "AZITHROMYCINE PO - 500 MG - Cp. - Voie orale",
            "description": "1 comprimé par jour, pendant 3 jours" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72H ou d’aggravation de ceux-ci"
        },
    ],
    "azithromycine_paracetamol": [
        {
            "title": "AZITHROMYCINE PO - 500 MG - Cp. - Voie orale",
            "description": "1 comprimé par jour, pendant 3 jours" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": ""
        },
        {
            "title": "PARACETAMOL - 1 000 MG - Cp. - Voie orale",
            "description": "1 comprimé toutes les 6h en cas de douleurs ou fièvre" ,
            "nbr_boites": "QSP 3 jours",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72H ou d’aggravation de ceux-ci",
        }
    ],
    "azithromycine_less_40": [
        {
            "title": "AZITHROMYCINE PO - 40 MG/ML - Susp. buvable - Voie orale",
            "description": "20 MG/KG/J (sans dépasser 500 MG/J) en 1 prise par jour, pendant 3 jours" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72H ou d’aggravation de ceux-ci"
        },
    ],
    "azithromycine_less_40_doliprane": [
        {
            "title": "AZITHROMYCINE PO - 40 MG/ML - Susp. buvable - Voie orale",
            "description": "20 MG/KG/J (sans dépasser 500 MG/J) en 1 prise par jour, pendant 3 jours" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": ""
        },
        {
            "title": "DOLIPRANE - 24 MG/ML - Susp. buvable - Voie orale",
            "description": "60 MG/KG/J en 4 prises, pendant 6 jours si nécessaire" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72h ou d'aggravation de ceux-ci",
        }
    ],
    "clarithromycine": [
        {
            "title": "CLARITHROMYCINE PO - 250 MG - Cp. - Voie orale",
            "description": "1 comprimé deux fois par jour, pendant 5 jours" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72H ou d’aggravation de ceux-ci"
        },
    ],
    "clarithromycine_paracetamol": [
        {
            "title": "CLARITHROMYCINE PO - 250 MG - Cp. - Voie orale",
            "description": "1 comprimé deux fois par jour, pendant 5 jours" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": ""
        },
        {
            "title": "PARACETAMOL - 1 000 MG - Cp. - Voie orale",
            "description": "1 comprimé toutes les 6h en cas de douleurs ou fièvre" ,
            "nbr_boites": "QSP 3 jours",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72H ou d’aggravation de ceux-ci",
        }
    ],
    "clarithromycine_less_40": [
        {
            "title": "CLARITHROMYCINE PO - 50 MG/ML - Susp. buvable - Voie orale",
            "description": "15 MG/KG/J en 2 prises par jour, pendant 5 jours" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72H ou d’aggravation de ceux-ci"
        },
    ],
    "clarithromycine_less_40_doliprane": [
        {
            "title": "CLARITHROMYCINE PO - 50 MG/ML - Susp. buvable - Voie orale",
            "description": "15 MG/KG/J en 2 prises par jour, pendant 5 jours" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": ""
        },
        {
            "title": "DOLIPRANE - 24 MG/ML - Susp. buvable - Voie orale",
            "description": "60 MG/KG/J en 4 prises, pendant 6 jours si nécessaire" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72h ou d'aggravation de ceux-ci",
        }
    ],
    "josamycine": [
        {
            "title": "JOSACINE PO - 1000 MG - Cp. - Voie orale",
            "description": "1 comprimé deux fois par jour, pendant 5 jours" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72H ou d’aggravation de ceux-ci"
        },
    ],
    "josamycine_paracetamol": [
        {
            "title": "JOSACINE PO - 1000 MG - Cp. - Voie orale",
            "description": "1 comprimé deux fois par jour, pendant 5 jours" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": ""
        },
        {
            "title": "PARACETAMOL - 1 000 MG - Cp. - Voie orale",
            "description": "1 comprimé toutes les 6h en cas de douleurs ou fièvre" ,
            "nbr_boites": "QSP 3 jours",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72H ou d’aggravation de ceux-ci",
        }
    ],
    "josamycine_less_40": [
        {
            "title": "JOSACINE PO - 250MG/ML - Sirop - Voie orale",
            "description": "50 MG/KG/J en 1 prise orale par jour, pendant 5 jours" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72H ou d’aggravation de ceux-ci"
        },
    ],
    "josamycine_less_40_doliprane": [
        {
            "title": "JOSACINE PO - 250MG/ML - Sirop - Voie orale",
            "description": "50 MG/KG/J en 1 prise orale par jour, pendant 5 jours" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": ""
        },
        {
            "title": "DOLIPRANE - 24 MG/ML - Susp. buvable - Voie orale",
            "description": "60 MG/KG/J en 4 prises, pendant 6 jours si nécessaire" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72h ou d'aggravation de ceux-ci",
        }
    ],
    "cefpodoxime": [
        {
            "title": "CEFPODOXIME-PROXETIL PO - 100 MG - Cp. - Voie orale",
            "description": "1 comprimé deux fois par jour, pendant 4 jours" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72H ou d’aggravation de ceux-ci"
        },
    ],
    "cefpodoxime_paracetamol": [
        {
            "title": "CEFPODOXIME-PROXETIL PO - 100 MG - Cp. - Voie orale",
            "description": "1 comprimé deux fois par jour, pendant 4 jours" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": ""
        },
        {
            "title": "PARACETAMOL - 1 000 MG - Cp. - Voie orale",
            "description": "1 comprimé toutes les 6h en cas de douleurs ou fièvre" ,
            "nbr_boites": "QSP 3 jours",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72H ou d’aggravation de ceux-ci",
        }
    ],
    "cefpodoxime_less_40": [
        {
            "title": "CEFPODOXIME-PROXETIL PO - 8 MG/ML - Susp. buvable - Voie orale",
            "description": "8 MG/KG/J (sans dépasser 200 MG/J) en deux prises par jour, pendant 5 jours" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72H ou d’aggravation de ceux-ci"
        },
    ],
    "cefpodoxime_less_40_doliprane": [
        {
            "title": "CEFPODOXIME-PROXETIL PO - 8 MG/ML - Susp. buvable - Voie orale",
            "description": "8 MG/KG/J (sans dépasser 200 MG/J) en deux prises par jour, pendant 5 jours" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": ""
        },
        {
            "title": "DOLIPRANE - 24 MG/ML - Susp. buvable - Voie orale",
            "description": "60 MG/KG/J en 4 prises, pendant 6 jours si nécessaire" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de persistance des symptômes à 72h ou d'aggravation de ceux-ci",
        }
    ],
    "osteodensitometrie": [
        {
            "title": "Ostéodensitométrie",
            "description": "Faire réaliser une densitométrie osseuse sur deux sites (fémoral et lombaire) par méthode biphotonique (DXA)" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos":""
        },
    ],
    "paracetamol_solution_antiseptique": [
        {
            "title": "",
            "description": "" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de maux de tête, vomissements, trouble du " +
                "comportement, confusion, trouble de d’équilibre ou de la marche, convulsion, trouble respiratoire, " +
                "difficultés importantes à avaler, fièvre de plus de 39°C et/ou de plus de 3 jours"
        },
    ],
    "rhinite_allergique": [
        {
            "title": "",
            "description": "" ,
            "nbr_boites": "",
            "infos": "",
            "more_infos": "Consultez un médecin généraliste en cas de difficultés ou gêne respiratoire"
        },
    ],
    "apa": [
        {
            "title": "Activité Physique Adaptée",
            "description": "Je déclare avoir examiné ce jour le patient et n’avoir constaté aucune contre-indication apparente ou cliniquement décelable à la pratique d’activité physique adaptée.",
            "nbr_boites": "",
            "infos": "",
            "more_infos": "" +
                "Préconisation d’activité et recommandations : \n\n" +
                "..............................................................................................................................................................................................................\n" +
                "..............................................................................................................................................................................................................\n" +
                "..............................................................................................................................................................................................................\n" +
                "..............................................................................................................................................................................................................\n" +
                "..............................................................................................................................................................................................................\n" +
                "..............................................................................................................................................................................................................\n\n\n"
        }
    ],
    "mkde": [
         {
            "title": "Bilan",
            "description": "Trois séances de masso-kinésithérapie du rachis lombaire",
            "nbr_boites": "",
            "infos": "(éducation thérapeutique avec participation du patient, apprentissage de l’auto-rééducation, sans thérapies passives)\n",
            "more_infos": "Vous pouvez vous adresser au Masseur-kinésithérapeute de votre choix"
        }
    ],
    "mkde_entorse": [
         {
            "title": "Bilan",
            "description": "Séances de masso-kinésithérapie",
            "nbr_boites": "",
            "infos": "",
            "more_infos": ""
        }
    ],
    "bilan_biologique": [
         {
            "title": "Bilan biologique",
            "description": "Faire par un IDE un bilan biologique au domicile avec :<br>- NFS plaquettes<br>- CRP<br>- Ionogramme sanguin, urée, créatininémie<br>- Calcémie, Phosphorémie<br>- ECBU",
            "nbr_boites": "",
            "infos": "",
            "more_infos": ""
        }
    ],
    "ordo": [],

};


export const get_ordonnance_data = function(ordonnance_name) {
    if (Object.keys(MAP_ORDONANCE).includes(ordonnance_name)) {
        return MAP_ORDONANCE[ordonnance_name];
    }
    return [];
}

export const get_ordonnance_name_from_type = function (of_type) {
    let data_ordo = get_ordonnance_data(of_type);
    let tmp = [];
    for(let el in data_ordo) {
        if(data_ordo[el].title) tmp.push(data_ordo[el].title);
        else tmp.push("Ordonnance");
    }
    return tmp.join(', ');
}
