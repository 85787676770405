<template>
  <v-col cols="12" class="py-1">
    <v-divider inset></v-divider>
  </v-col>
</template>

<script>
export default {
  name: "DividerCol"
}
</script>

<style scoped>

</style>