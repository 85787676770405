<template>
  <v-row class="mx-0">
    <v-col v-if="loading" cols="12" class="text-center">
      <v-progress-circular indeterminate color="primary_pastel"></v-progress-circular>
    </v-col>
    <v-col v-if="!loading" cols="12" sm="8" offset-sm="2">
      <v-expand-transition>
        <v-card v-if="!invalid_commune" class="rounded-lg elevation-0">
          <v-card-title class="white--text medium--text font-size--24" :style="`background-color: ${color}`">
            Demande de médecin traitant
          </v-card-title>
          <v-card-text class="mt-4 mt-sm-6">
            <p class="normal--text black--text font-size--18">
              {{ home_txt }} met à disposition un formulaire
              d’inscription sur une liste d’attente pour faciliter la mise en relation entre les patients et les
              médecins du territoire qui prennent de nouveaux patients.
            </p>

            <p class="mb-0 black--text semibold--text font-size--16 mt-4 mt-sm-8">Votre Commune</p>
            <v-autocomplete v-model="commune_selected"
                            :items="communes_items_sorted"
                            item-value="com"
                            item-text="libelle"
                            :loading="loading"
                            :filter="filter_search"
                            filled
                            placeholder="Entrez le nom de votre commune"
                            no-data-text="Aucune commune"></v-autocomplete>

            <v-checkbox v-model="has_no_mt" label="Je n’ai pas de médecin traitant"></v-checkbox>
          </v-card-text>
          <v-card-actions class="justify-center py-3 py-sm-6">
            <v-btn rounded :color="color" class="white--text px-12" elevation="0" large :disabled="!commune_selected || !has_no_mt" @click="check_validity_commune()">
              <span class="font-size--16 medium--text">Valider</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-expand-transition>


      <v-expand-transition>
        <v-card v-if="invalid_commune" class="rounded-lg elevation-0">
          <v-card-title class="white--text medium--text font-size--24" :style="`background-color: ${color}`">
            Demande de médecin traitant
          </v-card-title>
          <v-card-text class="mt-4 mt-sm-6">
            <p class="semibold--text black--text font-size--20">
              Vous n'êtes pas dans un secteur que nous couvrons.
            </p>
            <p class="normal--text black--text font-size--18">
              Désolé, nous ne pouvons pas donner suite à votre demande car vous n'êtes pas dans un secteur que notre structure couvre.
            </p>
          </v-card-text>
          <v-card-actions class="justify-center py-3 py-sm-6">
            <v-btn rounded :color="color" class="white--text px-12" elevation="0" large @click="reset()">
              <span class="font-size--16 medium--text">Retour à l'accueil</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-expand-transition>
    </v-col>
  </v-row>
</template>

<script>
import {clean_txt} from "anam-library/src/js/utils";
import {get_element_waiting_list} from "coordination-library/src/modules/waiting_list";

export default {
  name: "PatientWaitingListHomeView",
  data: () => ({
    communes_items: [],
    loading: false,
    commune_selected: null,
    has_no_mt: false,
    invalid_commune: false,
    hf_data: {}
  }),
  computed: {
    hf_id: function() {
      return this.$route.params.id_health_facility;
    },
    home_txt: function() {
      return get_element_waiting_list("home_txt", this.$store.getters.getHfPublicTemplateName);
    },
    color: function() {
      return get_element_waiting_list("color", this.$store.getters.getHfPublicTemplateName);
    },
    communes_items_sorted: function() {
      let tmp = JSON.parse(JSON.stringify(this.communes_items));
      return tmp.sort((a, b) => (a.ncc > b.ncc) ? 1 : (a.ncc < b.ncc) ? -1 : 0);
    }
  },
  watch: {
    invalid_commune: function(val) {
      if(val) {
        this.commune_selected = null;
        this.has_no_mt = false;
      }
    }
  },
  created() {
    if(this.$route.query.invalid_commune === true) this.invalid_commune = true;

    this.communes_items = [];

    this.loading = true;
    this.$http.get(`/api/hf-coordination/${this.hf_id}/public-infos-wl`).then(response => {
      this.hf_data = response.data;
      this.$store.commit('setHfPublicTemplateName', response.data.public_template_name);
      this.$store.commit('setHfWaitingListSpecificsTags', response.data.waiting_list_specifics_tags);
      for(let region of this.hf_data.regions_managed) {
        this.$http.get(`/api/communes-insee?reg=${region}`).then(response => {
          this.communes_items = this.communes_items.concat(response.data);
        })
      }
    }).finally(() => {
      this.loading = false;
    })
  },
  methods: {
    reset: function() {
      this.$vuetify.goTo(0);
      this.invalid_commune = false;
    },
    check_validity_commune: function() {
      this.$vuetify.goTo(0);
      if(this.hf_data.communes_managed.includes(this.commune_selected)) {
        let index = this.communes_items.findIndex(x => x.com === this.commune_selected);
        if(index > -1) {
          this.$router.push({
            name: "PatientWaitingListForm",
            query: {
              commune: this.commune_selected,
              localite: this.communes_items[index].libelle
            }
          })
        } else {
          this.invalid_commune = true;
        }
      }
      else this.invalid_commune = true;
    },
    filter_search(item, queryText, itemText) {
      if (clean_txt(itemText).includes(clean_txt(queryText))) return item;
    }
  }

}
</script>

<style scoped>

</style>