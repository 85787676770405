<template>
  <CoChatHistoryView
      :search_rpps="true"
      :add_by_cercle_de_soin="false"
      url_send_email_rpps="/api/send_inzeecare_invitation_email"
  ></CoChatHistoryView>
</template>

<script>
import CoChatHistoryView from "coordination-library/src/components/doctor/chat/CoChatHistoryView";

export default {
  name: "ChatHistoryView",
  components: {CoChatHistoryView},
}
</script>