<template>
  <v-menu v-model="menu" :close-on-content-click="true" offset-x z-index="20">
    <template v-slot:activator="{ on }">
      <AnDrawerListItem :activator="on" :item="admin_item" color_content_active="primary_dark" color_bg_active="primary_pastel"/>
    </template>
    <v-list class="primary_dark">
      <v-list-item-group>
        <v-list-item v-for="(item, key) in all_cpts" :key="key" @click="go_to_manage_cpts(item.id)" active-class="bg_primary active_item active_item_primary_dark">
          <v-list-item-subtitle class="white--text font-size--14 px-4 medium--text text-break">
            {{ item.commercial_name }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import AnDrawerListItem from "anam-library/src/components/an-header/an-drawer/AnDrawerListItem";

export default {
  name: "DoctorDrawerMenuManage",
  components: {AnDrawerListItem},
  props: {
    all_cpts: {
      type: Array,
      default: () => []
    },
  },
  data: () => ({
    menu: false,
    admin_item: {
      text: "Pilotage opérationnel",
      icon: "mdi-home-group",
      to: "",
      exact_path: false
    }
  }),
  methods: {
    go_to_manage_cpts: function(cpts_id) {
      let manage_cpts_views = ['ManageCPTSManager', 'ManageCPTS'];
      if(manage_cpts_views.includes(this.$route.name)) {
        if(parseInt(this.$route.params.id) !== parseInt(cpts_id)) {
          this.$router.push(`/manage_cpts/${cpts_id}`);
        }
      } else {
        this.$router.push(`/manage_cpts/${cpts_id}`);
      }
    },
  }
}
</script>

<style scoped>

</style>