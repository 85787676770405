<template>
  <v-dialog v-model="_dialog" width="600" persistent>
    <v-card>
      <v-card-title class="medium--text primary--text justify-center">
        <v-icon color="primary" class="mr-4">{{ icon }}</v-icon>
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn icon text color="primary" @click="_dialog=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-10">
        <v-form v-model="valid_form">
          <AnTextField :value.sync="name" title="Nom du tag" placeholder="Nom" icon="" type_field="text" required/>

          <div class="pl-1 font-size--16 medium--text">
            Visibilité du tag <v-icon color="red" size="8" class="mb-3">mdi-asterisk</v-icon>
          </div>
          <v-select v-model="visibility"
                    :items="items_visibility"
                    :rules="[rules.required]"
                    filled
                    placeholder="Choisir parmi la liste"/>

          <AnTextField :value.sync="color" title="Couleur du tag" placeholder="Code hexa" icon="" type_field="color" required/>
          <AnTextField :value.sync="pdf_url" title="Lien PDF du tag" placeholder="URL" icon="" type_field="text"/>
        </v-form>

        <v-alert v-model="error_alert" text type="error" transition="scale-transition">
          {{ error_msg }}
        </v-alert>
      </v-card-text>
      <v-card-actions class="justify-center pb-8">
        <v-btn text color="primary" @click="_dialog=false">
          <v-icon small class="mr-2">mdi-close</v-icon>
          Annuler
        </v-btn>
        <v-btn elevation="0" color="green" class="white--text px-6" @click="save()" :disabled="!valid_form" :loading="loading">
          <v-icon class="mr-sm-4">mdi-content-save</v-icon>
          {{ txt_btn_save }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AnTextField from "anam-library/src/components/an-ui/textfield/AnTextField";

import rules from "anam-library/src/js/rules";
import {clean_text_no_special_characters} from "anam-library/src/js/utils";

export default {
  name: "DialogCreateEditTag",
  components: {
    AnTextField
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    creation: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    valid_form: false,
    rules: rules,
    loading: false,
    name: null,
    visibility: null,
    color: null,
    pdf_url: null,
    error_alert: false,
    error_msg: null,
    items_visibility: [
      {text: "Médical", value: 1},
      {text: "Médico-social", value: 2},
      {text: "Administratif", value: 3}
    ],
  }),
  computed: {
    _dialog: {
      get: function() {
        return this.dialog;
      },
      set: function(val) {
        this.$emit("update:dialog", val);
      }
    },
    of_type: function() {
      return `tag_${clean_text_no_special_characters(this.name)}`
    },
    icon: function() {
      if(this.creation) return 'mdi-tag-plus';
      else return 'mdi-tag';
    },
    title: function() {
      if(this.creation) return 'Créer un tag';
      else return `Éditer le tag ${this.item.commercial_name}`;
    },
    txt_btn_save: function() {
      if(this.creation) return 'Créer';
      else return 'Enregistrer';
    }
  },
  watch: {
    _dialog: function(val) {
      if(!val) {
        this.name = null;
        this.visibility = null;
        this.color = null;
        this.pdf_url = null;
        this.error_alert = false;
        this.error_msg = false;
      } else {
        if(!this.creation) this.set_data();
      }
    }
  },
  methods: {
    set_data: function() {
      this.color = this.item.color;
      this.pdf_url = this.item.pdf_url;
      this.name = this.item.commercial_name;
      this.visibility = this.item.visibility;
    },
    save: function() {
      this.loading = true;
      this.error_alert = false;
      this.error_msg = null;

      let data = {
        color: this.color,
        pdf_url: this.pdf_url,
        visibility: this.visibility,
        class_name: 'TagIndicator'
      }

      if(this.creation) {
        data.commercial_name = this.name;
        data.of_type = this.of_type;
        this.create_tag(data);
      } else {
        if(this.item.of_type !== this.of_type) {
          data.commercial_name = this.name;
          data.of_type = this.of_type;
        }
        this.edit_tag(data);
      }
    },
    create_tag: function(data) {
      this.$http.post("/api/hf-coordination/tags", data).then(response => {
        this.$store.commit('pushMetaTags', response.data);
        this.$store.commit('PostSuccess', 'Tag créé');
        this._dialog = false;
      }).catch(error => {
        if(error.response && error.response.status === 422) {
          this.manage_error_422(error);
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    edit_tag: function(data) {
      data["tag_id"] = this.item.id;
      this.$http.put("/api/hf-coordination/tags", data).then(response => {
        this.$store.commit('setSpecificMetaTags', response.data);
        this.$store.commit('PostSuccess', 'Modification(s) enregistrée(s)');
        this._dialog = false;
      }).catch(error => {
        if(error.response && error.response.status === 422) {
          this.manage_error_422(error);
          this.set_data();
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    manage_error_422: function(error) {
      let temp = null;
      for(let el in error.response.data) {
        if(temp) temp = `${temp}, ${error.response.data[el].join()}`;
        else temp = error.response.data[el].join();
      }
      this.error_alert = true;
      this.error_msg = temp;
    }
  }
}
</script>

<style scoped>

</style>