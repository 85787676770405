<template>
  <CoDoctorDpaView app_name="inzeecare"
                   :show_auth_terresante="true"
                   :auth_terresante="auth_terresante"
                   :action_map="action_map"
                   :search_rpps="true"
                   url_send_email_rpps="/api/send_inzeecare_invitation_email"
                   :previous_route="previous_route"/>
</template>

<script>
import CoDoctorDpaView from "coordination-library/src/components/doctor/dpa/CoDoctorDpaView";
import AuthTerresante from "@/components/doctor/dpa/AuthTerresante";
import action_map_json from "../../../data/dpa/action_map.json"

export default {
  name: "DpaView",
  components: {CoDoctorDpaView},

  data: () => ({
    action_map: JSON.parse(JSON.stringify(action_map_json)),
    auth_terresante: AuthTerresante,
    previous_route: null
  }),

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.previous_route = from;
    })
  }
}
</script>