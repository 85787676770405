<template>
  <v-row class="mx-0">
    <v-col cols="12">
      <v-hover v-slot:default="{ hover }">
       <v-btn rounded color="green" :elevation="hover ? 4 : 0" class="white--text" @click="new_structure()">
         <v-icon class="mr-2">mdi-plus</v-icon>
         <span class="font-size--16 medium--text">Créer un service</span>
       </v-btn>
     </v-hover>
    </v-col>
    <v-col cols="12" class="py-0">
      <p class="font-italic font-size--14 mb-0 mt-2">
        Il s'agit des services des établissements liés à votre structure ({{ hf_name }}).
      </p>
    </v-col>
    <v-col cols="12">
      <v-data-table :headers="headers"
                    :items="services"
                    class="elevation-4 rounded-lg"
                    fixed-header
                    :loading="loading"
                    loading-text="Chargement en cours..."
                    no-data-text="Aucun service"
                    no-results-text="Aucun résultat"
                    :footer-props="{
                      itemsPerPageText: 'Lignes par page',
                      itemsPerPageAllText: 'Tous',
                      'items-per-page-options': [20, 100, 500, -1]}"
                    :header-props="{sortByText: 'Trier par'}">

        <template v-slot:[`item.of_type`]="{item}">
          <span>{{ item.of_type|service_type_name }}</span>
        </template>

        <template v-slot:[`item.address`]="{item}">
          <span>{{ item.address|address }}</span>
        </template>

        <template v-slot:[`item.nb_pds`]="{item}">
          <span>{{ item['health-practitioners'] ? item['health-practitioners'].length : '0' }}</span>
        </template>

        <template v-slot:[`item.actions`]="{item}">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon text :color="color_btn_actions" @click="manage_members(item)">
                <v-icon>mdi-account-group</v-icon>
              </v-btn>
            </template>
            <span>Gestion des membres</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon text :color="color_btn_actions" @click="edit_structure(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Modifier le service</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon text :color="color_btn_actions" @click="edit_settings_service(item)" :disabled="!service_has_settings(item)">
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </template>
            <span>Modifier les paramètres du service</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-col>

    <DialogCreateEditStructure :dialog.sync="dialog_structure"
                               :hf_name="hf_name"
                               :item="selected_structure"
                               :color_title="color_title"
                               :color_txt="color_txt"
                               v-on:saved="update_services($event)"/>

    <DialogManageMembersService :dialog.sync="dialog_manage_members"
                                :service="selected_structure"
                                :hf_name="hf_name"
                                :hf_id="hf_id"
                                :app_name="app_name"
                                :color_title="color_title"
                                v-on:edit-pds="edit_pds($event)"
                                v-on:add-pds="add_pds($event)"/>

    <DialogSettingsService :dialog.sync="dialog_settings_service"
                           :service="selected_structure"
                           :color_txt="color_txt"
                           :color_title="color_title"/>

  </v-row>
</template>

<script>
import DialogCreateEditStructure from "./DialogCreateEditStructure";
import DialogManageMembersService from "./DialogManageMembersService";
import DialogSettingsService from "./DialogSettingsService";

import {get_formated_address} from "anam-library/src/js/address";
import {get_service_type_name} from "anam-library/src/data/services";
import {service_has_settings} from "coordination-library/src/data/interventions";

export default {
  name: "StructuresView",
  components: {
    DialogCreateEditStructure,
    DialogManageMembersService,
    DialogSettingsService
  },
  props: {
    hf_name: {
      type: String,
      default: null
    },
    color_title: {
      type: String,
      default: 'primary'
    },
    color_btn_actions: {
      type: String,
      default: 'primary'
    },
    color_txt: {
      type: String,
      default: 'primary_dark'
    },
    app_name: {
      type: String,
      required: true
    }
  },
  data: () => ({
    dialog_structure: false,
    dialog_manage_members: false,
    dialog_settings_service: false,
    services: [],
    headers: [
      {text: "Nom du service", value: "commercial_name", class: 'primary white--text rounded-tl-lg'},
      {text: "Type du service", value: "of_type", class: "primary white--text"},
      {text: "Adresse", value: "address", class: "primary white--text"},
      {text: "Nb. membres", value: "nb_pds", class: "primary white--text"},
      {text: "Actions", value: "actions", class: "primary white--text rounded-tr-lg"},
    ],
    loading: false,
    selected_structure: {},
  }),
  computed: {
    hf_id: function() {
      return this.$route.params.id;
    }
  },
  watch: {
    hf_id: function(val) {
      if(val) this.get_services();
    }
  },
  mounted() {
    this.get_services();
  },
  methods: {
    get_services: function() {
      this.loading = true;
      this.$http.get(`/api/hf-coordination/${this.hf_id}/services`).then(response => {
        this.services = response.data;
      }).finally(() => {
        this.loading = false;
      })
    },
    update_services: function(data) {
      let index = this.services.findIndex(x => x.id === data.id);
      if(index > -1) {
        let temp = JSON.parse(JSON.stringify(this.services))
        temp[index] = data;
        this.services = temp;
      } else {
        this.services.push(data);
      }
    },
    new_structure: function() {
      this.selected_structure = {};
      this.dialog_structure = true;
    },
    edit_structure: function(structure) {
      this.selected_structure = structure;
      this.dialog_structure = true;
    },
    edit_settings_service: function(structure) {
      this.selected_structure = structure;
      this.dialog_settings_service = true;
    },
    manage_members: function(structure) {
      this.selected_structure = structure;
      this.dialog_manage_members = true;
    },
    add_pds: function(data) {
      let temp = {
        id: data.professionnel.id,
        name: `${data.first_name} ${data.last_name.toUpperCase()}`,
        email: data.email,
        last_login: data.last_login,
        user_id: data.id,
        dmp_type: data.professionnel.dmp_type,
        is_manager_service: data.manager_service
      }
      this.selected_structure['health-practitioners'].push(temp);
    },
    edit_pds(data) {
      let tmp = JSON.parse(JSON.stringify(this.selected_structure['health-practitioners']));
      let index =  tmp.findIndex(x => x.id === data.professionnel.id);
      if (index > -1) {
        tmp[index] = {
          id: data.professionnel.id,
          id_agency: null,
          name: `${data.first_name} ${data.last_name.toUpperCase()}`,
          email: data.email,
          last_login: data.last_login,
          user_id: data.id,
          dmp_type: data.professionnel.dmp_type,
          is_manager_service: tmp[index].is_manager_service
        }
      }
      this.selected_structure['health-practitioners'] = tmp;
    },
    service_has_settings: function(item) {
      return service_has_settings(item.of_type);
    },
  },
  filters: {
    address: function(val) {
      return get_formated_address(val);
    },
    service_type_name: function(val) {
      return get_service_type_name(val);
    }
  }
}
</script>

<style scoped>

</style>