<template>
  <div class="align-self-center">
    <v-hover v-slot:default="{ hover }">
      <v-btn color="green" rounded :elevation="hover ? 4 : 0" class="white--text ml-5" @click="dialog=true">
        <v-icon small class="mr-3">mdi-stethoscope</v-icon>
        <span class="medium--text">Voir les médecins traitants disponibles</span>
      </v-btn>
    </v-hover>

    <AnDialogComponent :dialog.sync="dialog"
                       title="Liste des médecins traitants disponibles"
                       :closed_in_header="true">
      <template v-slot:card-text>
        <div>
          <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
          <v-select v-model="selected_cpts" :items="hfs_coordination" :loading="loading" label="Structure" placeholder="Sélectionner la structure" dense></v-select>
          <template  v-if="selected_cpts">
            <div class="text-center my-8">
              <v-btn color="primary" rounded text @click="dialog_search_pro=true">
                <v-icon class="mr-2">mdi-account-plus</v-icon>
                <span class="font-size--16 medium--text text-decoration-underline">Ajouter un médecin</span>
              </v-btn>
              <v-btn v-if="current_user_is_medge" color="primary" text @click="add_myself()">
                <v-icon class="mr-2">mdi-account-plus</v-icon>
                <span class="font-size--16 medium--text text-decoration-underline">M'ajouter à la liste</span>
              </v-btn>
            </div>
            <v-divider></v-divider>
            <p v-if="professionals.length === 0" class="font-italic text-center mt-6">
              Aucun médecin déclaré
            </p>
            <v-list>
              <v-list-item v-for="(item, key) in professionals" class="py-0" :key="key">
                <v-list-item-content>
                  <v-list-item-title class="text-break white-space">
                    <span class="medium--text text-capitalize">{{ item.first_name }}</span> <span class="medium--text text-uppercase">{{ item.last_name }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-break white-space">{{ item.address }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="text-break white-space">{{ item.phone }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn v-if="current_pro_id === item.id" text color="red" small @click="remove_myself()">
                    <v-icon class="mr-2" small>mdi-delete</v-icon>
                    <span class="text-decoration-underline">Me retirer de la liste</span>
                  </v-btn>
                  <v-btn v-else text color="red" small @click="selected_professional=item;dialog_confirmation=true">
                    <v-icon class="mr-2" small>mdi-delete</v-icon>
                    <span class="text-decoration-underline">Supprimer de la liste</span>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </template>
        </div>
      </template>
    </AnDialogComponent>

    <CoDialogSearchPro :dialog.sync="dialog_search_pro"
                       dialog_title="Ajouter un médecin"
                       :emit="true"
                       :map_professions="map_professions"
                       :search_all_professions="false"
                       :search_user_in_cpts="true"
                       :hf_coordination_ids="[selected_cpts]"
                       :loading_action="loading_add"
                       v-on:close="dialog_search_pro=false"
                       v-on:doctor_clicked="add_pro($event)"/>

    <AnDialogConfirmation :dialog.sync="dialog_confirmation"
                          title="Supprimer le professionnel de la liste des médecins traitants"
                          :loading="loading_remove"
                          title_color="primary_dark"
                          question_color="primary_dark"
                          v-on:cancel="cancel_action_remove()"
                          v-on:confirm="remove_pro()"/>
  </div>
</template>

<script>
import AnDialogComponent from "anam-library/src/components/an-ui/an-dialog/AnDialogComponent";
import AnDialogConfirmation from "anam-library/src/components/an-dialogs/AnDialogConfirmation";
import CoDialogSearchPro from "coordination-library/src/components/doctor/common/CoDialogSearchPro";

export default {
  name: "WaitingListManageProfessionals",
  components: {AnDialogComponent, AnDialogConfirmation, CoDialogSearchPro},
  data: () => ({
    loading: false,
    dialog: false,
    selected_cpts: null,
    dialog_search_pro: false,
    dialog_confirmation: false,
    settings_waiting_list: [],
    load_settings: true,
    professionals: [],
    selected_professional: null,
    map_professions: [{text: "Médecine générale", value: "SM54"}],
    loading_add: false,
    loading_remove: false
  }),
  computed: {
    hfs_coordination: function() {
      let tmp = [];
      for (let el of this.settings_waiting_list) {
        tmp.push({text: el.hf_coordination.commercial_name, value: el.hf_coordination.id});
      }
      return tmp;
    },
    current_pro_id: function() {
      return this.$store.getters.getProfessionnelId;
    },
    current_user_is_medge: function() {
      return this.$store.getters.isMedGe;
    }
  },
  watch: {
    dialog: function(val) {
      if(val && this.load_settings) {
        this.settings_waiting_list = [];
        for (let cpts of this.$store.getters.getServicesCPTS) {
          this.loading = true;
          this.$http.get(`/api/hf-coordination/${cpts.hf_id}/settings-waiting-list`).then(response => {
            let index = response.data.findIndex(x => x.of_type_waiting_list === '334');
            if(index > -1) this.settings_waiting_list.push(response.data[index]);
          }).finally(() => {
            this.loading = false;
          })
          this.load_settings = false;
        }
      }
    },
    settings_waiting_list: function(val) {
      if(val && val.length === 1) this.selected_cpts = val[0].hf_coordination.id;
    },
    selected_cpts: function(val) {
      if(val) {
        let index = this.settings_waiting_list.findIndex(x => x.hf_coordination.id === val);
        if(index > -1) this.professionals = this.settings_waiting_list[index].available_professionals;
        else this.professionals = []
      } else {
        this.professionals = [];
        this.selected_professional = null;
      }
    }
  },
  methods: {
    cancel_action_remove: function() {
      this.selected_professional = null;
      this.dialog_confirmation = false;
    },
    remove_myself: function() {
      this.selected_professional = {id: this.current_pro_id};
      this.dialog_confirmation = true;
    },
    remove_pro: function() {
      this.loading_remove = true;
      let tmp = [];
      for(let el of this.professionals) {
        if(el.id.toString() !== this.selected_professional.id.toString()) tmp.push(el.id)
      }

      this.patch_settings_waiting_list(tmp, "loading_remove", "dialog_confirmation");
    },
    add_myself: function() {
      this.add_pro({professionnel: {id: this.current_pro_id}});
    },
    add_pro: function(data) {
      this.loading_add = true;
      let tmp = [];
      for(let el of this.professionals) {
        tmp.push(el.id)
      }
      tmp.push(data.professionnel.id);

      this.patch_settings_waiting_list(tmp, "loading_add", "dialog_search_pro");

    },
    patch_settings_waiting_list: function(list_pro, loading, dialog) {
      let data = {"available_professionals": list_pro}

      let id_hf_coordination = this.selected_cpts;
      let settings_index = this.settings_waiting_list.findIndex(x => x.hf_coordination.id === this.selected_cpts);
      let settings_id = null;
      if(settings_index > -1) settings_id = this.settings_waiting_list[settings_index].id;

      this.$http.patch(`/api/hf-coordination/${id_hf_coordination}/settings-waiting-list/${settings_id}`, data).then(response => {
        this.$store.commit("PostSuccess", "Liste des médecins modifiée");
        let tmp = JSON.parse(JSON.stringify(this.settings_waiting_list));
        let index = tmp.findIndex(x => x.id === settings_id);
        if(index > -1) tmp[index] = response.data;
        this.settings_waiting_list = tmp;
        this.selected_cpts = id_hf_coordination;
        this.professionals = response.data.available_professionals;
      }).finally(() => {
        this[loading] = false;
        this[dialog] = false;
      })
    }
  }
}
</script>

<style scoped>

</style>