<template>
  <v-app-bar color="white" elevation="2" app>
    <v-row class="align-center">
      <v-col cols="2" class="hidden-sm-and-down pl-4" @click="$router.push('/dashboard')">
        <v-tooltip bottom z-index="10">
          <template v-slot:activator="{ on }">
          <span v-on="on" style="cursor: pointer">
            <img width="70%" src="https://www.anamnese.care/hubfs/config/Citana/inzeecare/logo/logo-citana-couleur-BL.png" alt="Logo"/>
          </span>
          </template>
          <span>Accueil</span>
        </v-tooltip>
      </v-col>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" elevation="0" fab small light color="white" class="primary--text mr-5" @click="disconnect_inzeecare()">
            <v-icon class="primary--text" size="30">
              mdi-power
            </v-icon>
          </v-btn>
        </template>
        <span>Se déconnecter</span>
      </v-tooltip>
    </v-row>
  </v-app-bar>
</template>

<script>

export default {
  name: "ManagerHeader",
  methods: {
    disconnect_inzeecare: function(){
      this.$http.get("/api/deconnexion").then(() => {
        this.$store.commit('setUserPermission', 0);
        this.$store.commit('resetState');
        this.$store.commit('setAuthTS', false);
        if(this.$keycloak) this.$keycloak.logout({redirectUri: this.$store.getters.getEnvKeycloakAnamnese('redirect_logout')});
        this.$router.push({name: "ConnexionLayout"})
      })
    }
  }
}
</script>