<template>
  <v-app>
    <v-main class="bg_primary pb-sm-6">
      <v-container>
        <v-row>
          <v-col cols="12" md="8" offset-md="2" class="mt-15 pt-15">
            <v-card class="mt-sm-15 pt-6 rounded-lg mx-5" elevation="0">
              <v-card-text>
                <p class="text-center font-size--18 medium--text primary_dark--text text-break">Merci d'avoir répondu à ce questionnaire !</p>
              </v-card-text>
              <v-card-actions class="justify-center py-6">
                <v-btn color="primary" rounded large class="px-sm-8" @click="go_to_connexion()" :loading="loading">
                  <span class="font-size--16 medium--text">Accéder à mon espace professionnel</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  name: "ProEndQuestionnaireView",
  data: () => ({
    loading: false
  }),
  created() {
    axios.get("/api/deconnexion");
  },
  methods: {
    go_to_connexion: function() {
      this.loading = true;
      this.$store.dispatch('logoutInzeecare');
      if(this.$keycloak) this.$keycloak.logout({redirectUri: this.$store.getters.getEnvKeycloakAnamnese('redirect_logout')});
      this.$router.push({name: "ConnexionLayout"});
    }
  }
}
</script>

<style scoped>

</style>