<template>
  <v-row class="mx-0">
    <v-col cols="12" md="5" :class="($vuetify.breakpoint.mdAndUp ? 'd-flex': '') + ' align-self-center'">
      <v-hover v-slot:default="{ hover }">
        <v-btn v-if="can_add_pds" rounded color="green" :elevation="hover ? 4 : 0" class="white--text" @click="dialog_add_pro=true">
          <v-icon small class="mr-2">mdi-account-plus</v-icon>
          <span class="font-size--16 medium--text">Ajouter un professionnel</span>
        </v-btn>
      </v-hover>

      <CoDialogSearchPro :dialog.sync="dialog_add_pro"
                         dialog_title="Ajouter un professionnel"
                         :search_rpps="true"
                         :url_send_email_rpps="url_send_email_rpps"
                         :emit="true"
                         v-on:doctor_clicked="add_pro($event, 'new');"
                         v-on:close="dialog_add_pro = false"/>

       <v-hover v-slot:default="{ hover }">
         <v-btn :elevation="hover ? 4 : 0" rounded color="primary" class="px-6 ml-sm-4" @click="export_data()" :loading="loading_export">
           <v-icon small class="mr-2">mdi-download</v-icon>
           <span class="font-size--16 medium--text">Exporter en xlsx</span>
         </v-btn>
       </v-hover>
      <AnPrintLayoutButton class="ml-md-3 align-self-end" :large="$vuetify.breakpoint.mdAndUp"/>
    </v-col>
    <v-col cols="12" sm="4" md="2" class="align-self-center">
      <v-switch v-model="filter_pro_not_in_service" hide-details class="mt-0">
        <template v-slot:label>
          <span class="font-size--14 normal--text">Afficher les membres retirés</span>
        </template>
      </v-switch>
    </v-col>
<!--    <v-col cols="12" sm="4" md="2" class="align-self-center">-->
<!--      <p class="mb-0 font-size&#45;&#45;14 medium&#45;&#45;text secondary&#45;&#45;text">Filtrer par statut de compte</p>-->
<!--    </v-col>-->
    <v-col cols="12" sm="4" md="3" offset-sm="4" offset-md="2" class="align-self-center">
      <p class="mb-0 font-size--14 medium--text primary_dark--text">Rechercher</p>
      <v-text-field v-model="search" dense solo hide-details clearable color="primary" placeholder="Entrez votre texte" prepend-inner-icon="mdi-magnify"/>
    </v-col>
    <v-col cols="12">
      <p class="font-italic font-size--14">
        Il se peut que la liste des professionnels ne soit pas à jour si vous avez effectué des actions récentes.
        Cliquez <a class="text-decoration-underline" @click="$router.go()">ici</a> pour actualiser.
      </p>
      <v-expand-transition>
        <v-card style="width: max-content" class="mb-4" v-if="selected.length > 0">
          <v-card-title class="primary--text pb-0 medium--text">ACTIONS</v-card-title>
          <v-card-text class="pb-0">
            <v-radio-group row v-model="mass_actions">
              <v-radio value="email" label="Envoyer un email"></v-radio>
              <v-radio value="notification" label="Envoyer une notification"></v-radio>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-radio v-on="on" label="Envoyer un sms" readonly style="opacity: 0.4"></v-radio>
                </template>
                <span>À venir</span>
              </v-tooltip>
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-btn rounded color="green" class="white--text px-8" elevation="0"
                   @click="manage_mass_action_click()">Valider</v-btn>
          </v-card-actions>
        </v-card>
      </v-expand-transition>

      <v-data-table
          v-model="selected"
          :headers="headers"
          :items="filter_function(in_members)"
          class="elevation-4 data-table-hfmanager rounded-lg"
          fixed-header
          :show-select="$vuetify.breakpoint.smAndUp"
          item-key="professionnel.id"
          :loading="loading"
          :loading-text="$t('dashboard.loading') + ' ...'"
          no-data-text="Aucun professionnel"
          no-results-text="Aucun résultat"
          checkbox-color="primary"
          :sort-by="['date_enter_leave', 'status']"
          sort-desc
          :custom-sort="custom_sort"
          :footer-props="{
            itemsPerPageText: $t('dashboard.footer.line_per_page'),
            itemsPerPageAllText: $t('dashboard.footer.all'),
            'items-per-page-options': [20, 100, 500, -1], pageText: '{0}-{1} sur {2}'}"
          :header-props="{sortByText: 'Trier par'}">

        <template v-slot:[`item.identity`]="{ item }">
          <div style="width: max-content;" class="py-2">
            <p :class="`mb-0 ${color_title}--text font-size--16 medium--text`">
              {{ item.first_name }} <span class="text-uppercase font-size--16 medium--text">{{ item.last_name }}</span>
            </p>
            <p :class="`mb-0 ${color_table_txt}--text font-size--14 normal--text`">{{ item.professionnel.dmp_type|dmp_name }}</p>
            <p v-if="item.professionnel.speciality_code" :class="`mb-0 ${color_table_txt}--text font-size--12 light--text`">{{item.professionnel.speciality_code|speciality_name}}</p>
            <p :class="`mb-0 ${color_table_txt}--text font-size--14 normal--text`">{{ item.professionnel.status }}</p>

            <template v-if="item.professionnel.status === 'Salarié' && item.professionnel.company_name && item.professionnel.company_name.length > 0">
              <p :class="`mb-0 ${color_table_txt}--text`">
                <span v-for="(company, key) in item.professionnel.company_name" :key="key" class="font-size--14 light--text">
                  {{ company }}<br>
                </span>
              </p>
            </template>
            <p class="mb-0 secondary--text normal--text font-size--10" v-if="item.is_admin">ADMINISTRATEUR</p>
          </div>
        </template>

        <template v-slot:[`item.divider`]>
          <v-divider vertical inset></v-divider>
        </template>

        <template v-slot:[`item.idpp`]="{ item }">
          <p v-if="item.professionnel.idpp" :class="`${color_table_txt}--text normal--text text-center`">{{ item.professionnel.idpp }}</p>
          <div v-else class="text-center"><v-icon small>mdi-minus</v-icon></div>
        </template>

        <template v-slot:[`item.full_address`]="{ item }">
          <p v-if="item.personnephysique && item.personnephysique.adresse" :class="`mb-0 ${color_table_txt}--text text-center`">{{ item.personnephysique.adresse|address }}</p>
          <div v-else class="text-center"><v-icon small>mdi-minus</v-icon></div>
        </template>

        <template v-slot:[`item.contact`]="{ item }">
          <p :class="`${color_table_txt}--text normal--text font-size--12 text-center`">{{ item.email }}</p>
          <p v-if="item.personnephysique && item.personnephysique.personel_phone" :class="`mb-0 ${color_table_txt}--text normal--text font-size--12 text-center`">{{ item.personnephysique.personel_phone }}</p>
        </template>

        <template v-slot:[`item.date_enter_leave`]="{ item }">
          <p :class="`${color_table_txt}--text normal--text mb-0 text-center`">{{ item.date_enter|date }}</p>
          <template v-if="item.date_leave">
            <div class="text-center"><v-icon x-small>mdi-transfer-down</v-icon></div>
            <p :class="`${color_table_txt}--text normal--text mb-0 text-center`">{{ item.date_leave|date }}</p>
          </template>
        </template>

        <template v-slot:[`item.last_login`]="{ item }">
          <div v-if="item.last_login">
            <p :class="`${color_table_txt}--text normal--text mb-0 text-center`">{{ item.last_login|date }}</p>
            <p :class="`${color_table_txt}--text normal--text mb-0 text-center`">{{ item.last_login|hour }}</p>
          </div>
          <div v-else class="text-center">
            <v-icon small>mdi-minus</v-icon>
          </div>
        </template>

        <template v-slot:[`item.status`]="{item}">
          <div v-if="item.status === 1">
            <p class="orange--text semibold--text mb-2"><v-icon color="orange" small class="mr-2">mdi-clock-time-four</v-icon>En attente</p>
            <div v-if="item.status_account !== 1 && item.status_account !== 2" class="d-flex justify-center">
              <v-btn @click="add_pro(item, 'old')" color="green" outlined elevation="0" rounded x-small class="mr-1">
                <span class="font-size--10 medium--text">Accepter</span>
              </v-btn>
              <v-btn @click="remove_pro(item, 'refuse')" color="pastel_red" elevation="0" outlined rounded x-small class="ml-1">
                <span class="font-size--10 medium--text">Refuser</span>
              </v-btn>
            </div>
            <p v-else class="font-italic font-size--12">Validation du compte en cours</p>
          </div>
          <div v-else-if="item.status === 2">
            <v-btn @click="remove_pro(item)" text color="pastel_red" small>
              <v-icon small class="mr-sm-1">mdi-minus-circle</v-icon>
              <span class="font-size--12 text-decoration-underline normal--text">Retirer de ma structure</span>
            </v-btn>
          </div>
          <div v-else-if="item.status === 3">
            <v-btn @click="add_pro(item, 'old')" color="primary" text small>
              <v-icon small class="mr-sm-1">mdi-plus-circle</v-icon>
              <span class="font-size--12 text-decoration-underline normal--text">Réintégrer à ma structure</span>
            </v-btn>
          </div>
        </template>

        <template v-slot:[`item.status_agency`]="{ item }">
          <StatusAgencyItem :item="item" :color_table_txt="color_table_txt"/>
        </template>

        <template v-slot:[`item.waiting_list`]="{ item }">
          <WaitingListItem :hf_id="hf_id" :hf_data="hf_data" :user="item"/>
        </template>

        <template v-slot:[`item.is_admin`]="{ item }">
          <v-switch v-model="item.is_admin" color="green" class="mt-0" hide-details :disabled="!item.professionnel.id_agency && !item.status_account" dense @change="patch_pro(item)"></v-switch>
        </template>
      </v-data-table>
    </v-col>

    <DialogSendEmail :dialog.sync="dialog_send_email"
                     :ids_send_email="ids_send_email"
                     :append_text="`\n\n${hf_name}`"
                     :append_title="`- ${hf_name}`"
                     v-on:update_mass_action="selected=[]"/>
    
    <DialogSendNotification :dialog.sync="dialog_send_notif"
                     :ids_send_notif="ids_send_notif"
                     v-on:update_mass_action="selected=[]"/>

    <v-dialog v-model="dialog_alert_is_admin" width="500">
      <v-card>
        <v-card-title class="justify-center pastel_red--text medium--text">
          <v-icon class="mr-2" color="pastel_red">mdi-cancel</v-icon>
          Action non permise
        </v-card-title>
        <v-card-text class="pt-sm-8">
          <p class="semibold--text primary_dark--text mb-0">Ce professionnel est administrateur de la structure, vous ne pouvez pas le retirer.</p>
          <p class="font-italic">Si vous souhaitez le retirer de la structure, vous devez d'abord supprimer ses accès administrateur</p>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" outlined @click="dialog_alert_is_admin=false">
            <span class="px-4 font-size--14 normal--text">Fermer</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import DialogSendEmail from "anam-library/src/components/an-dialogs/DialogSendEmail";
import AnPrintLayoutButton from "anam-library/src/components/an-ui/button/AnPrintLayoutButton";
import DialogSendNotification from "anam-library/src/components/an-dialogs/DialogSendNotification";
import CoDialogSearchPro from "coordination-library/src/components/doctor/common/CoDialogSearchPro";
import StatusAgencyItem from "./StatusAgencyItem";
import WaitingListItem from "./WaitingListItem";

import axios from "axios";
import getCookie from "anam-library/src/js/common";
import {get_formatted_date} from "anam-library/src/js/dates";
import {get_profession_name} from "anam-library/src/data/professions";
import {get_speciality_name} from "anam-library/src/data/professionnal_specialities";
import {get_formated_address} from "anam-library/src/js/address";
import {clean_txt} from "anam-library/src/js/utils";
import {generateXlsx} from "anam-library/src/js/export_xlsx";

export default {
  name: "HealthPractitionersCPTS",
  components: {
    StatusAgencyItem,
    DialogSendEmail,
    DialogSendNotification,
    AnPrintLayoutButton,
    CoDialogSearchPro,
    WaitingListItem
  },
  props: {
    app_name: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    members: {
      type: Array,
      default: () => []
    },
    managers_users: {
      type: Array,
      default: () => []
    },
    hf_name: {
      type: String,
      default: null
    },
    hf_data: {
      type: Object,
      default: () => {}
    },
    color_create_button: {
      type: String,
      required: true
    },
    color_title: {
      type: String,
      default: 'primary'
    },
    color_table_txt: {
      type: String,
      default: 'primary_dark'
    },
    url_send_email_rpps: {
      type: String,
      required: true
    },
    can_add_pds: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    selected: [],
    mass_actions: 'email',
    dialog_send_email: false,
    dialog_send_notif: false,
    ids_send_email: [],
    ids_send_notif: [],
    search: null,
    in_members: [],
    loading_export: false,
    dialog_alert_is_admin: false,
    dialog_add_pro: false,
    filter_pro_not_in_service: false
  }),
  computed: {
    hf_id() {
      return this.$route.params.id;
    },
    headers: function() {
      let class_header = "white--text primary";
      let tmp = [
        {text: "", value: "data-table-select", class: `${class_header} rounded-tl-lg`},
        {text: "Identité statut & profession", value: "identity", class: class_header},
        {text: "", value: "divider", sortable: false, class: class_header},
        {text: "RPPS/ADELI", value: "idpp", class: class_header, align: 'center'},
        {text: "Adresse", value: "full_address", class: class_header, align: 'center'},
        {text: "Contact", value: "contact", class: class_header, align: 'center'},
        {text: "Entrée / Sortie", value: "date_enter_leave", class: class_header},
        {text: "Dernière connexion", value: "last_login", class: class_header},
        {text: "Statut compte", value: "status_agency", class: class_header},
        {text: `Gestion dans la structure`, value: "status", align: 'center', class: class_header}
      ];
      if(this.hf_data.option_waiting_list) tmp.push({text: "Accès liste d'attente", value: "waiting_list", class:class_header, align: "center"});
      tmp.push({text: "Admin", value: "is_admin", class: class_header})
      return tmp;
    }
  },
  watch: {
    members: function() {
      this.set_members();
    },
  },
  mounted() {
    this.$store.state.is_mounted_app = true;
    this.set_members();
  },
  methods: {
    custom_sort(items, index, isDesc){
      for (let i in index) {
        let key = index[i];
        let isdesc = isDesc[i];
        if (key === "date_enter_leave") key = "date_enter";
        items.sort((a, b) => {
          if (a[key] === null && isdesc === true) return 1;
          if (a[key] === null && isdesc === false) return -1;
          if (isdesc === true) return a[key] < b[key] ? 1 : -1;
          if (isdesc === false) return a[key] < b[key] ? -1 : 1;
          return 0;
        })
      }
      return items;
    },
    set_members() {
      let items = JSON.parse(JSON.stringify(this.members));
      let temp = [];
      for (let el in items) {
        let is_admin = false;
        let access_waiting_list = {};
        if(this.managers_users.includes(items[el].id)) is_admin = true;
        if(this.hf_data.professionals_in_waiting_list) {
          for (let of_type in this.hf_data.professionals_in_waiting_list) {
            access_waiting_list[of_type] = this.hf_data.professionals_in_waiting_list[of_type].includes(items[el].professionnel.id);
          }
        }
        temp.push({...items[el], is_admin: is_admin, waiting_list: access_waiting_list});
      }
      this.in_members = temp;
    },
    remove_pro(item, action="remove") {
      if(item.is_admin) {
        this.dialog_alert_is_admin = true;
        return
      }
      let params = {
        id: item.professionnel.id,
        action: action,
        app_name: this.app_name
      };
      axios.put("/api/hf_coordination/" + this.hf_id + "/health-practitioners", params, {headers: {"X-CSRFToken": getCookie('csrftoken')}}).then(() => {
        if (action === "refuse") this.$store.commit('PostSuccess', `Professionnel refusé`);
        else this.$store.commit('PostSuccess', `Professionnel retiré de la structure`);
        let index = this.in_members.findIndex(x => x.professionnel.id === item.professionnel.id)
        if (index > -1) {
          this.in_members[index].status = 3;
        }
      }).catch(error => {
        if(error.response) {
          if(error.response.status === 417) {
            this.dialog_alert_is_admin = true;
            return;
          }
          else this.$store.dispatch('ManageErrorLight', error.response.status);
        }
        this.$store.commit('PostCommonError');
      })
    },
    add_pro(user, action) {
      let params = {
        id: user.professionnel.id,
        action: "add",
        app_name: this.app_name
      };
      this.$http.put(`/api/hf_coordination/${this.hf_id}/health-practitioners`, params).then(() => {
        this.$store.commit('PostSuccess', `Professionnel ajouté à la structure`);

        if(action === 'new') this.$emit('reload_data');
        else {
          let index = this.in_members.findIndex(x => x.professionnel.id === user.professionnel.id)
          if (index > -1) {
            this.in_members[index].status = 2;
          }
        }
      }).catch(error => {
        if(error.response) {
          if(error.response.status === 417) this.$store.commit('PostInformation', `Professionnel déjà présent au sein de la structure`);
        }
      }).finally(() => {
        this.dialog_add_pro = false;
      })
    },
    patch_pro(user) {
      let data = {
        'technical_account': {
          'agency': user.professionnel.agency,
          'health_facilities': [this.hf_id]
        },
        'action': user.is_admin ? 'add' : 'remove',
        'send_email': true
      }

      let url = `/api/health-practitioners/${user.professionnel.id_agency}`;
      if(user.status_account) url = `/api/professionals/${user.professionnel.id}`

      axios.patch(url, data, {params: {app_name: this.app_name}, headers: {"X-CSRFToken": getCookie('csrftoken')}}).then(() => {
        this.$store.commit('PostSuccess', 'Modification enregistrée')
      }).catch(error => {
        this.$store.dispatch('ManageDeconnexion', error.response.status);
        this.$store.commit('PostError', 'Une erreur est survenue, nous ne pouvons pas enregistrer votre modification');

        user.is_admin = !user.is_admin
      })
    },
    filter_function: function (list) {
      function filter_txt (value, label, search) {
        if(value[label])
          return clean_txt(value[label]).includes(clean_txt(search))
        return false
      }
      function filter_list_txt (value, label, search) {
        for(let el in value[label]) {
          if (filter_txt(value[label], el, search)) return true;
        }
        return false;
      }

      if(!this.filter_pro_not_in_service) list = list.filter(x => x.status !== 3);

      if(this.search) {
        list = list.filter(value =>
            filter_txt(value, 'last_name', this.search) ||
            filter_txt(value, 'first_name', this.search) ||
            filter_txt(value, 'email', this.search) ||
            (value.personnephysique ? filter_txt(value.personnephysique, 'personel_phone', this.search) : false) ||
            (value.personnephysique && value.personnephysique.adresse ? filter_txt(value.personnephysique.adresse, 'localite', this.search) : false) ||
            (value.professionnel ? filter_txt(value.professionnel, 'idpp', this.search) : false) ||
            (value.professionnel ? filter_txt(value.professionnel, 'status', this.search) : false) ||
            (value.professionnel ? filter_list_txt(value.professionnel, 'company_name', this.search) : false) ||
            (value.professionnel.dmp_type ? clean_txt(this.$options.filters.dmp_name(value.professionnel.dmp_type)).includes(clean_txt(this.search)) : false)
        )
      }
      return list;
    },
    manage_mass_action_click: function() {
      this.ids_send_email = [];
      this.ids_send_notif = [];

      if (this.mass_actions === "notification") {
        for (let user of this.selected) {
          this.ids_send_notif.push(user.id);
        }
        this.dialog_send_notif = true
      }
      else if (this.mass_actions == "email") {
        for (let user of this.selected) {
          this.ids_send_email.push(user.id);
        }
        this.dialog_send_email = true;
      }
    },
    export_data() {
      this.loading_export = true;
      let headers = ["Prénom", "Nom", "Profession", "RPPS/ADELI", "Statut", "Établissement", "Adresse", "Code Postal", "Adresse mail", "Téléphone", "Dernière connexion"];
      let data_xlsx = [];
      let items = JSON.parse(JSON.stringify(this.filter_function(this.in_members)));
      for (let item of items) {
        data_xlsx.push([
          item.first_name,
          item.last_name,
          this.$options.filters.dmp_name(item.professionnel.dmp_type),
          item.professionnel.idpp,
          item.professionnel.status,
          item.professionnel.company_name,
          item.personnephysique.adresse ? get_formated_address(item.personnephysique.adresse) : "",
          item.personnephysique.adresse ? item.personnephysique.adresse.codePostal : "",
          item.email,
          item.personnephysique.personel_phone,
          get_formatted_date(item.last_login),
        ])
      }
      generateXlsx(data_xlsx, headers, "export-members-data", `export-members-data`);
      this.loading_export = false;
    },
  },
  filters: {
    dmp_name: function(dmp_type) {
      return get_profession_name(dmp_type);
    },
    speciality_name: function(speciality_code) {
      return get_speciality_name(speciality_code);
    },
    address: function(address) {
      return get_formated_address(address);
    },
    status_txt: function(status) {
      if (status === 1) return 'En attente';
      else if (status === 2) return 'Présent';
      else if (status === 3) return 'Sorti';
      return "";
    },
    date: function(value) {
      if(value) return get_formatted_date(value, {output_format: 'DD/MM/YYYY'})
      return value
    },
    hour: function (value) {
      if(value) return get_formatted_date(value, {output_format: 'HH:mm'})
      return value
    },
  }

}
</script>

<style>
.data-table-hfmanager .v-data-table-header th{
  padding: 0 8px !important;
}
.data-table-hfmanager .v-data-table-header tr th div i{
  color: unset !important;
}

.data-table-hfmanager td{
  padding: 0 8px !important;
}
</style>