import { render, staticRenderFns } from "./PatientActeDocumentsSharedView.vue?vue&type=template&id=2c74628f&scoped=true"
import script from "./PatientActeDocumentsSharedView.vue?vue&type=script&lang=js"
export * from "./PatientActeDocumentsSharedView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c74628f",
  null
  
)

export default component.exports