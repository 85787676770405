<!--
Composant 'Tab' pour afficher les tags disponibles d'une HFCoordination + les statistiques liées à cette HFCoordination
Inclus aussi la gestion par les super admin (= technical_account) des tags pour une HFCoordination (ajout ou suppression)

Props
  members: Array ; liste des membres (=professionnels) de la structure (= HFCoordination) ; passage en props car cette liste est chargé dans une autre 'Tab' de la page
-->

<template>
  <v-row class="mx-0">
    <v-col cols="10" class="pb-0">
      <p :class="`font-size--18 normal--text ${color_title}--text`">
        Tags disponibles pour les parcours patient :
      </p>
    </v-col>
    <v-col cols="2" class="text-right pb-0">
      <AnPrintLayoutButton/>
    </v-col>
    <v-col cols="12">
      <v-progress-linear indeterminate v-if="loading" color="primary_pastel"></v-progress-linear>
      <TagChipComponent v-for="(item, key) in tags_linked_to_cpts" :key="'linked_'+key"
                        :item="item" action="remove" :is_super_admin="is_super_admin"
                        v-on:remove_tag="remove_tag_from_cpts(item)"/>

      <p v-if="tags_linked_to_cpts.length === 0" :class="`${color_txt}--text font-italic`">Aucun tag n'est lié à cette structure.</p>


      <div v-if="is_super_admin">
        <div class="d-flex">
          <p :class="`my-6 font-size--22 ${color_title}--text`">
            <v-icon :color="color_title" class="mr-4" large>mdi-tag-multiple</v-icon>
            <span class="semibold--text font-size--22">Tags</span> existants que vous pouvez ajouter à la structure :
          </p>
          <v-spacer></v-spacer>
          <v-btn text color="primary" class="align-self-center" @click="dialog_create_tag=true">
            <v-icon class="mr-2">mdi-tag-plus</v-icon>
            <span class="medium--text font-size--14">Créer un tag</span>
          </v-btn>
        </div>


        <v-row class="mx-0" no-gutters>
          <v-col cols="12" sm="4" offset-sm="8">
            <v-text-field v-model="search"
                          solo dense
                          prepend-inner-icon="mdi-magnify"
                          placeholder="Rechercher par nom"
                          clearable/>
          </v-col>
        </v-row>

        <TagChipComponent v-for="(item, key) in tags_not_linked_to_cpts" :key="'not_linked_'+key"
                          :item="item" action="add" :is_super_admin="is_super_admin"
                          v-on:add_tag="add_tag_to_cpts(item)"/>

        <p v-if="tags_not_linked_to_cpts.length === 0" :class="`${color_txt}--text font-italic`">Tous les tags existants sont liés à cette structure.</p>
      </div>

      <div v-if="!is_super_admin">
        <p class="mt-10 font-size--18 semibold--text primary--text mb-0">Nouveau Tag ?</p>
        <p class="font-size--16">
          Si vous souhaitez qu'un nouveau tag soit ajouté, contactez le support :
          <span class="primary--text semibold--text font-size--16">support@anamnese.care</span>
        </p>
      </div>

      <DialogCreateEditTag :dialog.sync="dialog_create_tag" :creation="true"/>
    </v-col>
  </v-row>
</template>

<script>
import AnPrintLayoutButton from "anam-library/src/components/an-ui/button/AnPrintLayoutButton";
import TagChipComponent from "./TagChipComponent";
import DialogCreateEditTag from "./DialogCreateEditTag";
import {clean_txt} from "anam-library/src/js/utils";

export default {
  name: "AdminTagsTab",
  components: {
    AnPrintLayoutButton,
    TagChipComponent,
    DialogCreateEditTag
  },
  props: {
    color_title: {
      type: String,
      default: 'primary'
    },
    color_txt: {
      type: String,
      default: 'primary_dark'
    }
  },
  data: () => ({
    dialog_create_tag: false,
    loading: false,
    search: null
  }),
  computed: {
    hf_coordination_id() {
      return this.$route.params.id;
    },
    is_super_admin() {
      return this.$store.getters.getUserPermission === 3;
    },
    tags_linked_to_cpts: function() {
      let meta_tags = this.$store.getters.getAllMetaTags;
      return meta_tags.filter(x => x.cpts.includes(parseInt(this.hf_coordination_id)));
    },
    tags_not_linked_to_cpts: function() {
      let meta_tags = this.$store.getters.getMetaTags;
      let tmp = meta_tags.filter(x => !x.cpts.includes(parseInt(this.hf_coordination_id)));
      if(this.search) tmp = tmp.filter(value => clean_txt(value["commercial_name"]).includes(clean_txt(this.search)));
      return tmp;
    }
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch('apiGetMetaTags');
    this.loading = false;
  },
  methods: {
    add_tag_to_cpts(item) {
      if (!this.is_super_admin) return;
      let data = {tag_id: item.id, action: "add"};
      this.$http.patch(`/api/hf-coordination/${this.hf_coordination_id}/tags`, data).then(() => {
        this.$store.commit('addCPTSInTag', {tag_id: item.id, cpts_id: this.hf_coordination_id});
      })
    },
    remove_tag_from_cpts(item) {
      if (!this.is_super_admin) return;
      let data = {tag_id: item.id, action: "remove"};
      this.$http.patch(`/api/hf-coordination/${this.hf_coordination_id}/tags`, data).then(() => {
        this.$store.commit('removeCPTSInTag', {tag_id: item.id, cpts_id: this.hf_coordination_id});
      })
    },

  }
}
</script>

<style scoped>

</style>