<template>
  <v-card class="rounded-lg">
    <v-card-text>
      <v-row class="mx-0">
        <v-col cols="12" class="pb-0">
          <v-alert v-model="error" type="error" text transition="scale-transition">
            <span v-for="(item, key) in error_messages" :key="key">{{item[0]}}</span>
          </v-alert>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-alert transition="scale-transition" class="pa-0">
            <span class="normal--text primary_dark--text">
              Pour des raisons d’optimisations, les statistiques des plannings ne sont disponibles que pour une durée de
              1 mois. Si vous souhaitez plus d’informations n’hésitez pas à nous contacter à
            </span>
            <span class="semibold--text primary_dark--text">support@anamnese.care</span>.
          </v-alert>
        </v-col>
        <v-col cols="12" sm="4">
          <p class="align-self-center primary--text semibold--text font-size--16 mb-0">
            <v-icon color="primary" class="mr-1">mdi-tune-variant</v-icon>
            Filtrer par planning :
          </p>
          <v-autocomplete v-model="selected_calendar"
                          :items="calendars"
                          item-text="name"
                          item-value="id"
                          no-data-text="Aucun résultat"
                          hide-details
                          :loading="loading_calendars"/>
<!--                          Mise en commentaire le 19/10/2023 pour des problèmes de chargement et d'optimisations-->
<!--                          @change="get_statistics(selected_calendar)"/>-->
        </v-col>
        <v-col cols="12" sm="4">
          <p class="align-self-center primary--text semibold--text font-size--16 mb-0">
            <v-icon color="primary" class="mr-1">mdi-tune-variant</v-icon>
            Filter par mois :
          </p>
          <v-autocomplete v-model="filter_by_month"
                          :items="items_months"
                          hide-details
                          no-data-text="Aucun résultat"
                          placeholder="Sélectionner un mois"/>
        </v-col>
        <v-col cols="12" sm="3" class="align-self-end text-center">
          <v-hover v-slot:default="{ hover }">
            <v-btn :elevation="hover ? 4 : 0" rounded color="primary" class="px-6 ml-sm-4" :disabled="!filter_by_month" :loading="loading_stats" @click="get_statistics_by_month()">
              <v-icon small class="mr-2">mdi-magnify</v-icon>
              <span class="font-size--16 medium--text">Lancer la recherche</span>
            </v-btn>
          </v-hover>
        </v-col>
        <v-col cols="12" v-if="!stats_is_loaded">
          <v-alert type="info" text transition="scale-transition">
            <span class="font-size--16 medium--text">
              Pour visualiser les statistiques vous devez sélectionner un planning et un mois puis cliquer sur 'Lancer la recherche'.
            </span>
          </v-alert>
        </v-col>
        <v-col cols="12" class="text-center" v-if="loading_stats">
          <v-progress-circular color="primary_pastel" indeterminate></v-progress-circular>
        </v-col>
        <template v-if="!loading_stats && stats_is_loaded">
          <v-col cols="12" sm="6" md="3" class="mt-4">
            <p :class="`${color_title}--text semibold--text font-size--18 text-center ma-0`">Vacations</p>
            <p class="font-size--12 font-italic text-center ma-0"><span class="primary--text semibold--text font-size--12">{{data_vacations.proposees}}h</span> proposées par la structure</p>
            <p class="font-size--12 font-italic text-center ma-0"><span class="primary--text semibold--text font-size--12">{{data_vacations.prises}}h</span> prises par les professionnels</p>
            <apex-chart type="bar"
                        :options="{chart: {toolbar: {show: false,},},xaxis:{categories: ['Proposées', 'Prises'],},}"
                        :series="[{name: 'En heures', data: Object.values(data_vacations)},]"/>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="mt-4">
            <p :class="`${color_title}--text semibold--text font-size--18 text-center ma-0`">Consultations</p>
            <p class="font-size--12 font-italic text-center ma-0"><span class="primary--text semibold--text font-size--12">{{data_consultations.proposees}}</span> proposées</p>
            <p class="font-size--12 font-italic text-center ma-0"><span class="primary--text semibold--text font-size--12">{{data_consultations.prises}}</span> prises</p>
            <apex-chart type="bar"
                        :options="{chart: {toolbar: {show: false,},},xaxis:{categories: ['Proposées', 'Prises'],},}"
                        :series="[{name: 'En nombre', data: Object.values(data_consultations)},]"/>
          </v-col>
          <v-col v-if="selected_calendar_is_sas" cols="12" sm="6" md="3" class="mt-4">
            <p :class="`${color_title}--text semibold--text font-size--18 text-center ma-0`">Consultations SAS / SNP</p>
            <p class="font-size--12 font-italic text-center ma-0"><span class="primary--text semibold--text font-size--12">{{data_consultations_sas.sas}}</span> consultations SAS</p>
            <p class="font-size--12 font-italic text-center ma-0"><span class="primary--text semibold--text font-size--12">{{data_consultations_sas.snp}}</span> consultations SNP</p>
            <apex-chart type="bar"
                        :options="{chart: {toolbar: {show: false,},},xaxis:{categories: ['SAS', 'SNP'],},}"
                        :series="[{name: 'En nombre', data: Object.values(data_consultations_sas)},]"/>
          </v-col>
        </template>
      </v-row>
      <v-row v-if="!loading_stats && stats_is_loaded" class="mx-0">
        <v-col cols="12" sm="4" md="3">
          <p :class="`${color_title}--text semibold--text font-size--18 text-center mb-0`">Délai de prise en charge</p>
          <p class="font-size--10 font-italic text-center">(Entre la date de prise du rendez-vous et la date du rendez-vous)</p>
          <apex-chart type="pie"
                      :series="Object.values(data_delais)"
                      :options="{
              labels: ['Moins de 1 jour', 'Entre 1 et 2 jours', 'Entre 2 et 3 jours', 'Entre 4 et 7 jours', 'Entre 8 et 15 jours', 'Plus de 15 jours', 'RDV pris a posteriori'],
              colors: ['#008ffb', '#25fde9', '#00e396', '#feb019', '#ff4560', '#775dd0', '#A5978B'],
              legend: {position: 'bottom'},
              noData: {text: 'Aucune donnée'},
              dataLabels: {
                formatter: function(val ,opts) {
                  return opts.w.config.series[opts.seriesIndex];
                }
              }}"/>
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <p :class="`${color_title}--text semibold--text font-size--18 text-center mb-6`">Répartition du nb de professionnels</p>
          <apex-chart type="pie"
                      :series="Object.values(data_pds)"
                      :options="{
              labels: Object.keys(data_pds),
              legend: {position: 'bottom'},
              noData: {text: 'Aucune donnée'},
              dataLabels: {
                formatter: function(val ,opts) {
                  return opts.w.config.series[opts.seriesIndex];
                }
              }}"/>
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <p :class="`${color_title}--text semibold--text font-size--18 text-center mb-6`">Statut des consultations</p>
          <apex-chart type="pie"
                      :series="Object.values(actes_statuses)"
                      :options="{
              labels: ['Actes réalisés', 'Absences excusées', 'Absences non excusées', 'Aucun statut'],
              legend: {position: 'bottom'},
              noData: {text: 'Aucune donnée'},
              dataLabels: {
                formatter: function(val ,opts) {
                  return opts.w.config.series[opts.seriesIndex];
                }
              }}"/>
        </v-col>
        <v-col cols="12" class="my-8">
          <div :class="$vuetify.breakpoint.smAndUp ? 'my-3 d-flex' : 'mb-3'">
            <p :class="`mb-0 semibold--text ${color_title}--text font-size--24`">
              <v-icon :color="color_title" class="mr-3" large>mdi-account-multiple</v-icon>
              Professionnels qui assurent les vacations
            </p>
            <AnExportBtn :loading="loading_export_pds"
                         v-on:generate_csv="generate_csv('pds')"
                         v-on:generate_xlsx="generate_xlsx('pds', 'Pros qui assurent les vacations')"/>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" hide-details clearable color="primary" label="Rechercher">
              <template v-slot:prepend-inner>
                <v-icon color="primary">mdi-magnify</v-icon>
              </template>
            </v-text-field>
          </div>
          <v-data-table :headers="headers_pds"
                        :items="pds"
                        :search="search"
                        :class="`elevation-0 data-table-stats ${color_txt}--text`"
                        fixed-header
                        :loading="loading_stats"
                        loading-text="Chargement en cours ..."
                        no-data-text="Aucun professionnel"
                        no-results-text="Aucun résultat"
                        :footer-props="footer_props"
                        :header-props="{sortByText: 'Trier par'}">
            <template v-slot:[`item.postcode`]="{ item }">
              <span v-if="item.postcode">{{item.postcode}}</span>
              <v-icon v-else>mdi-minus</v-icon>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" class="my-8">
          <div :class="$vuetify.breakpoint.smAndUp ? 'my-3 d-flex' : 'mb-3'">
            <p :class="`mb-0 semibold--text ${color_title}--text font-size--24`">
              <v-icon :color="color_title" class="mr-3" large>mdi-account-multiple</v-icon>
              Professionnels qui créent les rendez-vous
            </p>
            <AnExportBtn :loading="loading_export_pds_create_rdv"
                         v-on:generate_csv="generate_csv('pds_create_rdv')"
                         v-on:generate_xlsx="generate_xlsx('pds_create_rdv', 'Pros qui creent les rdv')"/>

            <v-spacer></v-spacer>
            <v-text-field v-model="search_create_rdv" hide-details clearable color="primary" label="Rechercher">
              <template v-slot:prepend-inner>
                <v-icon color="primary">mdi-magnify</v-icon>
              </template>
            </v-text-field>
          </div>
          <v-data-table :headers="headers_pds_create_rdv"
                        :items="pds_create_rdv"
                        :search="search_create_rdv"
                        :class="`elevation-0 data-table-stats ${color_txt}--text`"
                        fixed-header
                        :loading="loading_stats"
                        loading-text="Chargement en cours ..."
                        no-data-text="Aucun professionnel"
                        no-results-text="Aucun résultat"
                        :footer-props="footer_props"
                        :header-props="{sortByText: 'Trier par'}">
            <template v-slot:[`item.postcode`]="{ item }">
              <span v-if="item.postcode">{{item.postcode}}</span>
              <v-icon v-else>mdi-minus</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import AnExportBtn from "anam-library/src/components/an-export/AnExportBtn"

import axios from "axios";
import moment from "moment-timezone";

import {get_profession_name} from "anam-library/src/data/professions";
import {mixin_dates} from "anam-library/src/js/dates";
import {generate_csv} from "anam-library/src/js/export_file";
import {generateXlsx} from "anam-library/src/js/export_xlsx";
import {get_period_text_for_export} from "coordination-library/src/js/utils";

export default {
  name: "ManagerStatisticsCardPlanning",
  mixins: [mixin_dates],
  components: {
    AnExportBtn
  },
  props: {
    hf_coordination_id: {
      type: String,
      default: null
    },
    load_data: {
      type: Boolean,
      default: false
    },
    color_title: {
      type: String,
      default: 'primary'
    },
    color_txt: {
      type: String,
      default: 'primary_dark'
    }
  },
  data: () => ({
    data_pds: {},
    data_delais: {},
    data_vacations: {},
    data_consultations: {},
    data_consultations_sas: {},
    loading_calendars: false,
    loading_stats: false,
    stats_is_loaded: false,
    headers_pds: [
      {text: 'Nom', value: 'last_name'},
      {text: 'Prénom', value: 'first_name'},
      {text: 'Profession', value: 'profession'},
      {text: 'Code postal', value: 'postcode'},
      {text: 'Vacations prises', value: 'nb_ressources'},
      {text: 'Actes réalisés', value: 'nb_actes_real'},
      {text: 'Absences excusées', value: 'nb_abs_exc'},
      {text: 'Absences non excusées', value: 'nb_abs_no_exc'}
    ],
    headers_pds_create_rdv: [
      {text: 'Nom', value: 'last_name'},
      {text: 'Prénom', value: 'first_name'},
      {text: 'Profession', value: 'profession'},
      {text: 'Code postal', value: 'postcode'},
      {text: 'Nombre de rdv créés', value: 'nb_rdv_created'}
    ],
    pds: [],
    pds_create_rdv: [],
    search: null,
    search_create_rdv: null,
    calendars: [],
    selected_calendar: 0,
    filter_by_month: null,
    filter_date_start: null,
    filter_date_end: null,
    valid_form_filter_date: false,
    error: false,
    error_messages: [],
    loading_export_pds: false,
    loading_export_pds_create_rdv: false,
    actes_statuses: {},
    footer_props: {
      itemsPerPageText: 'Lignes par page',
      itemsPerPageAllText:  'Tous',
      ItemsPerPageOptions: [20, 100, 500, -1],
      pageText: `{0}-{1} de {2}`
    },
  }),
  computed: {
    items_months: function() {
      let tmp = [];
      let last_months = this.get_x_months(14, true);
      for(let el of last_months) {
        tmp.unshift({text: moment(el, 'YYYY-MM').locale('fr').format('MMMM YYYY'), value: el});
      }
      tmp.unshift({header: 'Mois passés'});

      let next_months = this.get_x_months(12, false);
      for(let el of next_months) {
        tmp.unshift({text: moment(el, 'YYYY-MM').locale('fr').format('MMMM YYYY'), value: el});
      }
      tmp.unshift({header: 'Mois à venir'});

      return tmp;
    },
    selected_calendar_is_sas: function() {
      if(this.selected_calendar) {
        let index = this.calendars.findIndex(x => x.id === this.selected_calendar);
        if(index > -1) return this.calendars[index].is_sas_calendar;
      }
      return false;
    }
  },
  mounted() {
    this.get_calendars();
    // Mise en commentaire le 19/10/2023 pour des problèmes de chargement et d'optimisations
    // this.get_statistics(this.selected_calendar);
  },
  watch: {
    hf_coordination_id(val) {
      if(val) {
        this.get_calendars();
        // Mise en commentaire le 19/10/2023 pour des problèmes de chargement et d'optimisations
        // this.get_statistics(0);
      }
    },
    load_data(val) {
      if(val) {
        this.get_calendars();
        // Mise en commentaire le 19/10/2023 pour des problèmes de chargement et d'optimisations
        // this.get_statistics(0);
      }
    },
    // Mise en commentaire le 19/10/2023 pour des problèmes de chargement et d'optimisations
    // filter_date_end(val) {
    //   let filters = null;
    //   if(this.filter_date_start) {
    //     filters = {'start_date': get_formatted_date(this.filter_date_start, {input_format: "DD/MM/YYYY", output_format: 'YYYY-MM-DD'})};
    //   }
    //   if(val !== null) {
    //     if (filters === null) filters = {};
    //     filters['end_date'] = get_formatted_date(val, {input_format: "DD/MM/YYYY", output_format: 'YYYY-MM-DD'});
    //   }
    //   this.get_statistics(this.selected_calendar, filters);
    // },
    // filter_date_start(val) {
    //   let filters = null;
    //   if(this.filter_date_end) {
    //     filters = {'end_date': get_formatted_date(this.filter_date_end, {input_format: "DD/MM/YYYY", output_format: 'YYYY-MM-DD'})};
    //   }
    //   if(val !== null) {
    //     if (filters === null) filters = {};
    //     filters['start_date'] = get_formatted_date(val, {input_format: "DD/MM/YYYY", output_format: 'YYYY-MM-DD'});
    //   }
    //   this.get_statistics(this.selected_calendar, filters);
    // },
  },
  methods: {
    get_calendars: function() {
      this.loading_calendars = true;
      axios.get(`/api/doctors/0/hf-calendars`, {params: {user_type: 'admin'}}).then(response => {
        this.calendars = response.data.filter(x => x.health_facility === parseInt(this.hf_coordination_id))
        this.calendars.unshift({name: 'Tous', id: 0});
      }).catch(error => {
        if(error.response) {
          this.manage_common_errors(error);
          this.$store.commit('PostError', 'Une erreur est survenue veuillez nous excuser pour la gêne occasionnée.')
        }
      }).finally(() => {
        this.loading_calendars = false;
      })
    },
    get_statistics_by_month: function() {
      let month = this.filter_by_month;
      let tmp_start_month = moment(month, "YYYY-MM").startOf('month').format('YYYY-MM-DD');
      let tmp_end_month = moment(month, "YYYY-MM").endOf('month').format('YYYY-MM-DD');

      let filters = {
        'start_date': tmp_start_month,
        'end_date': tmp_end_month
      };
      this.get_statistics(this.selected_calendar, filters);
    },
    get_statistics: function (id_calendar, filters=null) {
      this.loading_stats = true;
      this.data_pds = {};
      this.data_delais = {};
      this.data_vacations = {};
      this.data_consultations = {};
      this.data_consultations_sas = {};
      this.pds = []
      this.pds_create_rdv = []

      let params = {id_calendar: id_calendar}
      if (filters) {
        params.start_date = filters.start_date;
        params.end_date = filters.end_date;
      }

      this.error = false;
      this.error_messages = [];

      axios.get(`/api/hf-coordination/${this.hf_coordination_id}/statistics`, {params: params}).then(response => {
        this.data_delais = response.data.delais;
        this.data_vacations = {'proposees': response.data.hours_slots, 'prises': response.data.hours_slots_full}
        this.data_consultations = {'proposees': response.data.nb_slots, 'prises': response.data.nb_consultations}
        if(this.selected_calendar_is_sas) {
            this.data_consultations_sas = {'sas': response.data.nb_consultations_sas, 'snp': (response.data.nb_consultations-response.data.nb_consultations_sas)};
        }
        for (let pds of response.data.pds) {
          let profession = get_profession_name(pds.dmp_type, pds.speciality_code);
          if (profession in this.data_pds) this.data_pds[profession] += 1;
          else this.data_pds[profession] = 1;
        }
        this.pds = JSON.parse(JSON.stringify(response.data.pds));
        this.pds = this.pds.map(el => {el.profession = get_profession_name(el.dmp_type, el.speciality_code);return el;});
        this.actes_statuses = {"realises": 0, "excuses": 0, "non_excusees": 0, "no_status": 0};
        for (let pds of this.pds) {
          this.actes_statuses.realises += pds.nb_actes_real;
          this.actes_statuses.excuses += pds.nb_abs_exc;
          this.actes_statuses.non_excusees += pds.nb_abs_no_exc;
          this.actes_statuses.no_status += pds.nb_no_status;
        }
        for (let el of response.data.pds_rdv_created) {
          if (el.id_author === null) continue;
          let index = this.pds_create_rdv.findIndex(x => x.id_author === el.id_author);
          if (index === -1) {
            let tmp_data = {
              profession: get_profession_name(el.dmp_type, el.speciality_code),
              nb_rdv_created: 1
            };
            this.pds_create_rdv.push({...el, ...tmp_data});
          }
          else {
            this.pds_create_rdv[index].nb_rdv_created += 1;
          }
        }
      }).catch(error => {
        if(error.response) {
          this.manage_common_errors(error)
          if(error.response.status === 422) {
            this.error = true;
            this.error_messages = Object.values(error.response.data)
          } else {
            this.$store.commit('PostError', 'Une erreur est survenue, veuillez nous excuser pour la gêne occasionnée.')
          }
        }
      }).finally(() => {
        this.loading_stats = false;
        this.stats_is_loaded = true;
      })
    },
    manage_common_errors: function(error) {
      let status = error.response.status;
      if(status === 401) this.$router.push('/401');
      else if(status === 403) this.$router.push('/403');
      else if(status === 502) this.$router.push('/502');
      else if(status === 404) this.$router.push('/404');
    },
    file_name_xlsx_csv: function(type){
      let file_name = "";
      if (type === "pds") file_name = "Professionnels_qui_assurent_les_vacations";
      else if (type === "pds_create_rdv") file_name = "Professionnels_qui_creent_les_rdv";
      file_name = `${file_name} ${get_period_text_for_export(this.filter_date_start, this.filter_date_end)}`
      return file_name

    },
    headers_export: function(type){
      let headers = [];
      for (let header of this[`headers_${type}`]) {
        headers.push(header.text);
      }
      return headers
    },
    download_xlsx_csv: function(type) {
      this[`loading_export_${type}`] = true;
      let data_xlsx = [];
      for (let item of this[type]) {
        let rowData = [];
        for (let header of this[`headers_${type}`]) {
          if (item[header.value] !== null) {
            rowData.push(item[header.value].toString());
          } else {
            rowData.push("N/A");
          }
        }
        data_xlsx.push(rowData);
      }
      this[`loading_export_${type}`] = false;
      return data_xlsx;
    },
    generate_csv: function(of_type) {
      generate_csv(this.download_xlsx_csv(of_type), this.headers_export(of_type), this.file_name_xlsx_csv(of_type), false);
    },
    generate_xlsx: function(of_type, title) {
      generateXlsx(this.download_xlsx_csv(of_type), this.headers_export(of_type), title, this.file_name_xlsx_csv(of_type));
    }
  }
}
</script>

<style scoped>
.data-table-stats .v-data-table-header th{
  color: var(--v-primary-base) !important;
  font-weight: bold;
}
</style>