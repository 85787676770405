<template>
  <CoInvitationVisioLayout
      :sms_option="true"
      api_url_email="/api_inzeecare/send_email"
      api_url_sms="/api_inzeecare/send_sms"
  ></CoInvitationVisioLayout>
</template>

<script>
import CoInvitationVisioLayout
  from "coordination-library/src/components/doctor/teleconsultation/CoInvitationVisioLayout";

export default {
    name: "InvitationVisioLayout",
    components: {CoInvitationVisioLayout},
  }
</script>
