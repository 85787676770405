<template>
  <span>
    <v-chip :color="item.color" class="ma-2 px-4" text-color="white"
            :close="is_super_admin"
            :close-icon="action === 'remove' ? 'mdi-close-circle' : 'mdi-plus-circle'"
            @click:close="action_click()">
      <span class="semibold--text font-size--14">{{ item.commercial_name }}</span>
      <v-btn v-if="item.pdf_url" text icon small color="white" class="ml-1" :href="item.pdf_url" target="_blank">
        <v-icon>mdi-file</v-icon>
      </v-btn>
      <v-btn v-if="is_super_admin" text icon x-small color="white" class="ml-1" @click="dialog_edit_tag=true">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </v-chip>

    <AnDialogConfirmation :dialog.sync="dialog_remove_tag"
                          icon="mdi-tag"
                          title="Supprimer le tag"
                          question="Souhaitez vous retirer le tag de cette structure ?"
                          title_color="primary_dark"
                          question_color="primary_dark"
                          v-on:cancel="dialog_remove_tag = false"
                          v-on:confirm="remove_tag()">
      <template v-slot:additional-content>
        <p>Les professionnels ne pourront plus l'ajouter à leurs patients.</p>
      </template>
    </AnDialogConfirmation>

    <DialogCreateEditTag :dialog.sync="dialog_edit_tag" :creation="false" :item="item"/>
  </span>
</template>

<script>
import AnDialogConfirmation from "anam-library/src/components/an-dialogs/AnDialogConfirmation";
import DialogCreateEditTag from "./DialogCreateEditTag";

export default {
  name: "TagChipComponent",
  components: {
    AnDialogConfirmation,
    DialogCreateEditTag
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    action: {
      type: String,
      default: null
    },
    is_super_admin: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    dialog_remove_tag: false,
    dialog_edit_tag: false,
  }),
  methods: {
    action_click: function() {
      if (this.action === 'remove') this.dialog_remove_tag = true;
      else if (this.action === 'add') this.$emit('add_tag');
    },
    remove_tag: function() {
      this.$emit('remove_tag');
      this.dialog_remove_tag = false;
    }
  }
}
</script>

<style scoped>

</style>