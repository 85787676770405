<template>
  <v-row class="mx-0">
    <v-col cols="12" sm="3">
      <p class="align-self-center primary--text semibold--text font-size--16 mb-0">Choisir les statistiques à afficher</p>
      <v-select v-model="display_stats" :items="stats_items" hide-details dense color="primary"></v-select>
    </v-col>
    <v-col cols="9" class="text-right hidden-xs-only">
      <AnPrintLayoutButton/>
    </v-col>

    <v-col cols="12">
      <v-expand-transition>
        <ManagerStatisticsCardGeneral v-show="display_stats===0"
                                      :hf_coordination_id="hf_coordination_id"
                                      :members="members"
                                      :loading_members="loading_members"
                                      :color_txt="color_txt"
                                      :color_title="color_title"
                                      :show_snp="_has_access_to_snp"
                                      :show_ddi="_has_access_to_ddi"
                                      :show_wt="_has_access_to_waiting_list"
                                      :show_protocoles="_has_access_to_protocoles"
                                      :show_tlc="_has_access_to_tlc"
                                      v-on:change_display="display_stats = $event"/>
      </v-expand-transition>

      <v-expand-transition>
        <ManagerStatisticsCardPlanning v-if="_has_access_to_snp"
                                       v-show="display_stats===1"
                                       :load_data="display_stats===1"
                                       :hf_coordination_id="hf_coordination_id"
                                       :color_txt="color_txt" :color_title="color_title"/>
      </v-expand-transition>

      <v-expand-transition>
        <ManagerStatisticsCardDDI v-if="_has_access_to_ddi"
                                  v-show="display_stats===2"
                                  :hf_coordination_id="hf_coordination_id"/>
      </v-expand-transition>

      <v-expand-transition>
        <ManagerStatisticsCardProtocoles v-if="_has_access_to_protocoles"
                                         v-show="display_stats===3"
                                         :load_data="display_stats===3"
                                         :hf_coordination_id="hf_coordination_id"
                                         :hf_name="hf_name"
                                         :hf_finess="hf_finess"
                                         :data_protocols="protocols"
                                         :loading_protocols="loading_protocols"/>
      </v-expand-transition>
      <v-expand-transition>
        <iframe v-if="!_has_access_to_protocoles"
                v-show="display_stats===3"
                :src="hubspot_url_iframe_protocoles"
                width="100%"
                style="border: unset; height: 200vh"/>
      </v-expand-transition>

      <v-expand-transition>
        <ManagerStatisticsTags v-show="display_stats === 4"
                               :load_data="display_stats === 4"
                               :hf_coordination_id="hf_coordination_id"
                               :members="members"
                               :color_title="color_title"
                               :color_txt="color_txt"/>
      </v-expand-transition>

      <v-expand-transition>
        <ManagerStatisticsWaitingList v-if="_has_access_to_waiting_list"
                                      v-show="display_stats === 5"
                                      :load_data="display_stats === 5"
                                      :hf_coordination_id="hf_coordination_id"/>
      </v-expand-transition>

      <v-expand-transition>
        <ManagerStatisticsTelemedecine v-if="_has_access_to_tlc"
                                       v-show="display_stats === 6"
                                       :load_data="display_stats === 6"
                                       :hf_coordination_id="hf_coordination_id"/>
      </v-expand-transition>
    </v-col>
  </v-row>
</template>

<script>
import AnPrintLayoutButton from "anam-library/src/components/an-ui/button/AnPrintLayoutButton";
import ManagerStatisticsCardGeneral from "./ManagerStatisticsCardGeneral";
import ManagerStatisticsCardPlanning from "./ManagerStatisticsCardPlanning";
import ManagerStatisticsCardDDI from "./ManagerStatisticsCardDDI";
import ManagerStatisticsCardProtocoles from "./ManagerStatisticsCardProtocoles";
import ManagerStatisticsTags from "./ManagerStatisticsTags";
import ManagerStatisticsWaitingList from "./ManagerStatisticsWaitingList";
import ManagerStatisticsTelemedecine from "./ManagerStatisticsTelemedecine";

export default {
  name: "StatisticsCPTS",
  components: {
    ManagerStatisticsCardGeneral,
    ManagerStatisticsTags,
    AnPrintLayoutButton,
    ManagerStatisticsCardPlanning,
    ManagerStatisticsCardDDI,
    ManagerStatisticsCardProtocoles,
    ManagerStatisticsWaitingList,
    ManagerStatisticsTelemedecine
  },
  props: {
    color_title: {
      type: String,
      default: 'primary'
    },
    color_txt: {
      type: String,
      default: 'primary_dark'
    },
    members: {
      type: Array,
      default: () => []
    },
    hf_name: {
      type: String,
      default: null
    },
    hf_finess: {
      type: String,
      default: null
    },
    protocols: {
      type: Array,
      default: () => []
    },
    loading_members: {
      type: Boolean,
      default: false
    },
    loading_protocols: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    display_stats: 0,
  }),
  mounted() {
    if(!this._has_access_to_snp) {
      if(!this._has_access_to_ddi) {
        this.display_stats = 3;
      } else this.display_stats = 2;
    }
    this.get_ddi();
  },
  computed: {
    hf_coordination_id() {
      return this.$route.params.id
    },
    _has_access_to_protocoles: function() {
      return this.$store.getters.getPermission("has_access_to_protocoles");
    },
    _has_access_to_coordination: function() {
      return this.$store.getters.getPermission('has_access_to_coordination');
    },
    _has_access_to_ddi: function() {
      return this.$store.getters.getPermission('has_access_to_ddi');
    },
    _has_access_to_snp: function() {
      return this.$store.getters.getPermission('has_access_to_snp');
    },
    _has_access_to_waiting_list: function() {
      return this.$store.getters.getPermission('has_access_to_waiting_list')["334"];
    },
    _has_access_to_tlc: function() {
      return this.$store.getters.getPermission('has_access_to_tlc');
    },
    stats_items: function() {
      let tmp = [{text: 'Général', value: 0}];
      if(this._has_access_to_snp) tmp.push({text: 'Planning', value: 1})
      if(this._has_access_to_ddi) tmp.push({text: 'Demandes d\'intervention', value: 2});
      tmp.push({text: "Protocoles", value: 3});
      if(this._has_access_to_coordination) tmp.push({text: 'Tags', value: 4});
      if(this._has_access_to_waiting_list) tmp.push({text: "Liste d'attente MT", value: 5});
      if(this._has_access_to_tlc) tmp.push({text: "Télémédecine", value: 6});
      return tmp;
    },
    hubspot_url_iframe_protocoles: function() {
      if(window.location.hostname.includes("inzeecare")) return "https://www.citana.care/module/activer-protocole";
      return "https://www.citana.care/module/activer-protocole-msp";
    }
  },
  watch: {
    hf_coordination_id(val) {
      if (val) this.get_ddi();
    }
  },
  methods: {
    get_ddi() {

    }
  }
}
</script>

<style>

</style>