<template>
  <v-window-item class="pb-5">
    <v-container fluid class="px-sm-10">
      <v-alert text color="primary_dark" class="mt-5 px-8 py-5" style="border-radius: 10px !important">
        <span class="bold--text primary--text font-size--22">Bienvenue sur Citana, la plateforme de coordination entre professionnels de santé</span>
      </v-alert>
      <v-row class="ma-0">
        <v-col cols="12" sm="8" md="6" offset-sm="2" offset-md="3">
          <RegistryTitle title="Quelle est votre profession ?" class="mb-6"/>
          <v-autocomplete v-model="dmp_type"
                          :items="items_professions"
                          :filter="filter_search"
                          filled
                          solo
                          placeholder="Ex: Infirmier"
                          prepend-inner-icon="mdi-magnify"
                          hide-no-data
                          class="textfield-solo-elevation-0"/>
          <v-expand-transition>
            <v-alert v-if="alert_dmp_missing" type="error" text>
              <span class="font-size--14 medium--text">Vous devez sélectionner une profession avant de valider.</span>
            </v-alert>
          </v-expand-transition>
          <div class="text-center">
            <RegistryBtnAction txt_btn="Valider"
                               :large="$vuetify.breakpoint.mdAndUp"
                               @click="next()"/>
          </div>
        </v-col>
        <template v-if="$vuetify.breakpoint.smAndUp">
          <v-col cols="12" md="10" offset-md="1" class="mt-6">
            <div class="d-flex">
              <span class="medium--text font-size--16 primary_dark--text">Professions les plus fréquentes</span>
              <v-divider class="align-self-center ml-4 primary_dark"></v-divider>
            </div>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="1"></v-col>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="1"></v-col>
          <v-col cols="3" md="2" v-for="(item, key) in common_professions" :key="key">
            <v-hover v-slot="{ hover }">
              <v-card :elevation="hover ? 6 : 0" height="100%" @click="next_card(item.dmp_type)">
                <v-card-text>
                  <img :src="item.img" width="100%" alt="Image"/>
                  <p class="secondary--text text-center semibold--text font-size--18 mb-0">{{ item.txt }}</p>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </v-window-item>
</template>

<script>
import {ITEMS_PROFESSIONS_FOR_COORDINATION_ONBOARDING} from "anam-library/src/data/professions";
import {clean_txt} from "anam-library/src/js/utils";

import img_infirmier from "coordination-library/src/assets/infirmier.png";
import img_ortho from "coordination-library/src/assets/orthophoniste.png";
import img_sf from "coordination-library/src/assets/sage_femme.png";
import img_medecin from "coordination-library/src/assets/medecin.png";
import img_pharmacien from "coordination-library/src/assets/labo.png";
import RegistryTitle from "@/components/public/registry/components/RegistryTitle.vue";
import RegistryBtnAction from "@/components/public/registry/components/RegistryBtnAction.vue";

export default {
  name: "RegistryStepProfession",
  components: {RegistryBtnAction, RegistryTitle},
  data: () => ({
    dmp_type: null,
    common_professions: [
      {txt: "Infirmier", dmp_type: "60", img: img_infirmier},
      {txt: "Orthophoniste", dmp_type: "91", img: img_ortho},
      {txt: "Médecin", dmp_type: "10", img: img_medecin},
      {txt: "Pharmacien", dmp_type: "21", img: img_pharmacien},
      {txt: "Sage-femme", dmp_type: "50", img: img_sf},
    ],
    alert_dmp_missing: false
  }),
  computed: {
    items_professions: function() {
      return ITEMS_PROFESSIONS_FOR_COORDINATION_ONBOARDING();
    },
  },
  watch: {
    dmp_type: function(val) {
      if(val) this.alert_dmp_missing = false;
    }
  },
  methods: {
    filter_search(item, queryText, itemText) {
      if (clean_txt(itemText).includes(clean_txt(queryText))) return item;
    },
    next_card: function(dmp_type) {
      this.dmp_type = dmp_type;
      this.next();
    },
    next: function() {
      this.alert_dmp_missing = false;

      if(!this.dmp_type) {
        this.$vuetify.goTo(0,{'duration': 500, 'offset': 60, 'easing': 'easeOutQuad'});
        this.alert_dmp_missing = true;
      }
      else {
        this.$emit("update_profession", this.dmp_type);
        this.$emit("next");
      }
    }
  }
}
</script>

<style scoped>

</style>