import moment from "moment-timezone";

export const mixin_search_ddi = {
    data: () => ({
        filter_profession: null,
        filter_state_demand: null,
        filter_closed_demand: null,
        filter_passed_demand: null,
        filter_timerange: null,
    }),
    methods: {
        filter_status_4: function(val, id_current_pds) {
            return val.closed || (val.professional && val.professional.pro_id !== id_current_pds);
        },
        filter_status_3: function(val, id_current_pds) {
            return (!val.closed) && (val.traceabilities.findIndex(x => x.practitioner === id_current_pds) > -1) && (val.traceabilities.filter(x => x.practitioner === id_current_pds)[0].status === 4);
        },
        filter_status_2: function(val, id_current_pds) {
            return !val.closed && (val.professional && val.professional.pro_id === id_current_pds);
        },
        filter_status_1: function(val, id_current_pds) {
            return (!val.closed) && (!val.professional) && (val.traceabilities.findIndex(x => x.practitioner === id_current_pds) > -1) && (val.traceabilities.filter(x => x.practitioner === id_current_pds)[0].status !== 4);
        },
        filter_table: function(items) {
            if (this.filter_profession) {
                if(this.filter_profession.startsWith('SM')) {
                    items = items.filter(x => (x.dmp_type.toString() === "10" && x.speciality_code.toString() === this.filter_profession));
                } else {
                    items = items.filter(x => x.dmp_type.toString() === this.filter_profession);
                }
            }
            if (this.filter_state_demand) {
                if (this.filter_state_demand === 1) items = items.filter(x => (x.state === 1 || x.state === 2 || x.state === 5) && !x.closed);
                else if (this.filter_state_demand === 3) items = items.filter(x => (x.state === 3 || x.state === 6 || x.state === 7) && !x.closed);
                else items = items.filter(x => x.state === this.filter_state_demand && !x.closed);
            }
            if (this.filter_closed_demand) items = items.filter(x => !x.closed);
            if (this.filter_passed_demand) items = items.filter(x => moment(x.start_date, "YYYY-MM-DD").isSameOrAfter(moment()));

            if(this.filter_status_demand.length > 0) {
                let id_current_pds = this.$store.getters.getUserInfosElement("professionnel")["id"];
                let conditions = [];
                if (this.filter_status_demand.includes(1)) conditions.push(x => this.filter_status_1(x, id_current_pds));
                if (this.filter_status_demand.includes(2)) conditions.push(x => this.filter_status_2(x, id_current_pds));
                if (this.filter_status_demand.includes(3)) conditions.push(x => this.filter_status_3(x, id_current_pds));
                if (this.filter_status_demand.includes(4)) conditions.push(x => this.filter_status_4(x, id_current_pds));
                items = items.filter(x => conditions.some(c => c(x)));
            }

            if(this.filter_timerange) {
                switch (this.filter_timerange) {
                    // Aujourd'hui
                    case 1:
                        items = items.filter(x => this.is_same_date(x, 'day'));
                        break;
                    // Hier
                    case 2:
                        items = items.filter(x => this.is_same_date(x, 'day', null, 1));
                        break;
                    // Cette semaine
                    case 3:
                        items = items.filter(x => this.is_same_date(x, 'week'));
                        break;
                    // La semaine dernière
                    case 4:
                        items = items.filter(x => this.is_same_date(x, 'week', null, 1));
                        break;
                    // La semaine prochaine
                    case 5:
                        items = items.filter(x => this.is_same_date(x, 'week', 1));
                        break;
                    // Ce mois-ci
                    case 6:
                        items = items.filter(x => this.is_same_date(x, 'month'));
                        break;
                    // Le mois dernier
                    case 7:
                        items = items.filter(x => this.is_same_date(x, 'month', null, 1));
                        break;
                }
            }
            return items
        },
        is_same_date(item, granularity, add=null, substract=null) {
            let date_to_compare = moment();
            if (add) date_to_compare.add(add, granularity);
            else if (substract) date_to_compare.subtract(substract, granularity);
            return moment(item.start_date, "YYYY-MM-DD").isSame(date_to_compare, granularity);
        },
        custom_search(value, search, item) {
            let str_search = search.toString().toLowerCase();
            if (item.patient && item.patient.first_name && item.patient.last_name) {
                let name = `${item.patient.first_name.toLowerCase()} ${item.patient.last_name.toLowerCase()}`;
                if (name.includes(str_search)) return true;
            }
            if (item.patient && item.patient.phone && item.patient.phone.includes(str_search)) return true;
            if (item.author && item.author.first_name && item.author.last_name) {
                let name = `${item.author.first_name.toLowerCase()} ${item.author.last_name.toLowerCase()}`;
                if (name.includes(str_search)) return true;
            }
            if (item.address) {
                if (item.address.fullAddress && item.address.fullAddress.toLowerCase().includes(str_search)) return true;
                if (item.address.localite && item.address.localite.toLowerCase().includes(str_search)) return true;
            }
            if (item.name_service_hf_etab_author) {
                if(item.name_service_hf_etab_author.toLowerCase().includes(str_search)) return true;
            }
            return item.id.toString().includes(str_search);
    }
    }
}