<template>
  <div>
    <v-dialog v-model="dialog_image" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar class="mb-1" dark color="primary">
          <v-toolbar-title>visualisation de l'image</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog_image = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container class="fill-height px-0" fluid>
          <v-row no-gutters align="center" justify="center">
            <v-img :src="selected_image" contain :max-height="max_height"></v-img>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-expand-transition>
      <div v-if="!loading_pj">
        <v-row class="ma-0">
          <image-upload
            label="pièce d'identité"
            :image_url="user_pj.inscription_pj_identity_card"
            v-on:file-change="submit_document('identity_card', $event)"
            v-on:open-image="open_image()"
          />
          <image-upload
            label="photo"
            :image_url="user_pj.inscription_pj_photo"
            v-on:file-change="submit_document('photo', $event)"
            v-on:open-image="open_image()"
          />
        </v-row>
      </div>
    </v-expand-transition>
    
  </div>
</template>

<script>

import ImageUpload from './ImageUpload.vue';

export default {
  components: {
    ImageUpload
  },
  props: {
    user_pj: {
      type : Object,
      default : () => {}
    },
    loading_pj: {
      type: Boolean,
      default : false
    }
  },
  data: () => ({
    identity_card: null,
    photo: null,
    selected_image: '',
    dialog_image: false,
  }),
  computed: {
    max_height() {
      return window.innerHeight * 0.7 + 'px';
    }
  },
  methods: {
    submit_document(document_type, file) {
      let data = {"document_type": document_type, "file": file};
      this.$emit('submit-document', data);
    },
    open_image(image_url) {
      this.selected_image = image_url;
      this.dialog_image = true;
    },
  }
}
</script>

<style scoped>
</style>
