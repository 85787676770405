<template>
  <v-btn class="rounded-lg px-15" style="border-radius: 15px" text color="primary" x-large @click="$emit('click')">
    <span :class="`medium--text ${small ? 'font-size--14' : 'font-size--18'}`">Retour</span>
  </v-btn>
</template>

<script>
export default {
  name: "RegistryBtnBack",
  props: {
    small: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>