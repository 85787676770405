<template>
  <v-chip-group v-model="_value" color="primary" column>
    <v-chip v-for="(item, key) in items"
            :key="key"
            :value="item.value"
            class="px-5 white"
            active-class="secondary white--text">
      <span class="normal--text">{{ item.text }}</span>
    </v-chip>
  </v-chip-group>
</template>

<script>
export default {
  name: "RegistryChoiceField",
  props: {
    value: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    _value: {
      get: function() {
        return this.value;
      },
      set: function(val) {
        this.$emit("update:value", val);
      }
    }
  }
}
</script>

<style scoped>

</style>