<template>
  <div v-if="status_account" class="text-center my-1">
    <v-chip :color="color_status" small>
      <span :class="`${color_txt_status} semibold--text`">{{ status_account }}</span>
    </v-chip>
    <p class="font-size--10 font-italic mb-0">{{ subtext_status_account }}</p>
  </div>
  <v-icon v-else small>mdi-minus</v-icon>
</template>

<script>
import {get_txt_status_account, get_subtxt_status_account, get_chip_color_status_account, get_color_status_account} from "anam-library/src/js/onboarding";

export default {
  name: "StatusAccountItem",
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    status_account: function() {
      if(!this.item.is_active) return "Désactivé";
      else if(this.item.status_account) return get_txt_status_account(this.item.status_account);
      else return null;
    },
    subtext_status_account: function() {
      if(!this.item.is_active) return null;
      else if(this.item.status_account) return get_subtxt_status_account(this.item.status_account);
      else return null;
    },
    color_status: function() {
      if(!this.item.is_active) return "red lighten-4";
      return get_chip_color_status_account(this.item.status_account);
    },
    color_txt_status: function() {
      if(!this.item.is_active) return "red--text darken-4--text";
      if(this.item.status_account) return `${get_color_status_account(this.item.status_account)}--text darken-4--text`;
      else return "";
    }
  }
}
</script>

<style scoped>

</style>