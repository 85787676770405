<template>
  <div>
    <AnDialogComponent :dialog.sync="_dialog"
                       :title="title"
                       :closed_in_header="true">
      <template v-slot:card-text>
        <div>
          <div class="text-center mb-8">
            <v-btn color="primary" text @click="dialog_search_pro=true">
              <v-icon class="mr-2">mdi-account-plus</v-icon>
              <span class="font-size--16 medium--text text-decoration-underline">Ajouter un {{ name_type_pro }}</span>
            </v-btn>
          </div>
          <v-divider></v-divider>
          <p v-if="professionals.length === 0" class="font-italic text-center mt-6">
            Aucun {{ name_type_pro }} déclaré
          </p>
          <v-list>
            <v-list-item v-for="(item, key) in professionals" class="py-0" :key="key">
              <v-list-item-content>
                <v-list-item-title class="text-break white-space`">
                  <span class="medium--text text-capitalize">{{ item.first_name }}</span> <span class="medium--text text-uppercase">{{ item.last_name }}</span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn text color="red" small @click="selected_professional=item;dialog_confirmation=true">
                  <v-icon class="mr-2" small>mdi-delete</v-icon>
                  <span class="text-decoration-underline">Supprimer de la liste</span>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
      </template>
    </AnDialogComponent>

    <CoDialogSearchPro :dialog.sync="dialog_search_pro"
                       :dialog_title="`Ajouter un ${name_type_pro}`"
                       :emit="true"
                       :map_professions="map_professions"
                       :search_all_professions="false"
                       :search_user_in_cpts="true"
                       :hf_coordination_ids="[hf_id]"
                       :loading_action="loading_add"
                       v-on:close="dialog_search_pro=false"
                       v-on:doctor_clicked="add_pro($event)"/>

    <AnDialogConfirmation :dialog.sync="dialog_confirmation"
                          :title="`Supprimer le professionnel de la liste des ${name_type_pro}s`"
                          :loading="loading_remove"
                          title_color="primary_dark"
                          question_color="primary_dark"
                          v-on:cancel="cancel_action_remove()"
                          v-on:confirm="remove_pro()"/>
  </div>
</template>

<script>
import AnDialogComponent from "anam-library/src/components/an-ui/an-dialog/AnDialogComponent";
import AnDialogConfirmation from "anam-library/src/components/an-dialogs/AnDialogConfirmation";
import CoDialogSearchPro from "coordination-library/src/components/doctor/common/CoDialogSearchPro";

import {MAP_PROFESSIONS_COORDINATION} from "anam-library/src/data/professions";

export default {
  name: "ManagerProtocolsManageDelegatingDialog",
  components: {AnDialogComponent, AnDialogConfirmation, CoDialogSearchPro},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    protocol: {
      type: Object,
      default: () => {}
    },
    hf_id: {
      type: String,
      default: null
    },
    of_type: {
      type: String,
      required: true
    }
  },
  data: () => ({
    dialog_confirmation: false,
    selected_professional: null,
    loading_remove: false,
    dialog_search_pro: false,
    loading_add: false,
  }),
  computed: {
    _dialog: {
      get: function() {
        return this.dialog;
      },
      set: function(val) {
        this.$emit("update:dialog", val);
      }
    },
    protocol_name: function() {
      if(this.protocol && this.protocol.meta_protocol) return this.protocol.meta_protocol.commercial_name;
      return null;
    },
    professionals: function() {
      if(this.protocol) {
        if(this.of_type === "delegates") return this.protocol.delegate_professionals;
        if(this.of_type === "delegating") return this.protocol.delegating_professionals;
      }
      return []
    },
    name_type_pro : function() {
      if(this.of_type === "delegates") return "délégué";
      else if(this.of_type === "delegating") return "délégant";
      return ""
    },
    title: function() {
      return `Liste des ${this.name_type_pro}s - ${this.protocol_name}`;
    },
    map_professions: function() {
      if(this.of_type === "delegates") return MAP_PROFESSIONS_COORDINATION();
      else if(this.of_type === "delegating") {
        let tmp = [{text: "Médecin généraliste", value: "SM54"}]
        let protocols_with_orl_delegating = [ "protocol_angine_drac_sud", "protocol_rhinite"];
        if(protocols_with_orl_delegating.includes(this.protocol.meta_protocol.name)) {
          tmp.push({text: "Médecin ORL", value: "SM34"});
        }
        return tmp;
      }
      return [];
    }
  },
  methods: {
    cancel_action_remove: function() {
      this.selected_professional = null;
      this.dialog_confirmation = false;
    },
    remove_pro: function() {
      let id_pro_to_rm = this.selected_professional.id;
      this.loading_remove = true;
      let tmp = [];
      for(let el of this.professionals) {
        if(el.id.toString() !== id_pro_to_rm.toString()) tmp.push(el.id)
      }

      let tmp_duo = JSON.parse(JSON.stringify(this.protocol.duo_delegate_delegating));
      if(this.of_type === "delegates") {
        tmp_duo[id_pro_to_rm.toString()] = [];
      } else if(this.of_type === "delegating") {
        for(let el in tmp_duo) {
          let index = tmp_duo[el].findIndex(x => x === id_pro_to_rm);
          if(index > -1) tmp_duo[el].splice(index, 1);
        }
      }

      this.patch_settings_protocol(tmp, tmp_duo, "loading_remove", "dialog_confirmation");
    },
    add_pro: function(data) {
      this.loading_add = true;
      let tmp = [];
      for(let el of this.professionals) {
        tmp.push(el.id)
      }
      tmp.push(data.professionnel.id);

      let tmp_duo = JSON.parse(JSON.stringify(this.protocol.duo_delegate_delegating));
      if(this.of_type === "delegates") {
        if(!Object.keys(tmp_duo).includes(data.professionnel.id.toString())) {
          tmp_duo[data.professionnel.id.toString()] = [];
        }
      }
      this.patch_settings_protocol(tmp, tmp_duo, "loading_add", "dialog_search_pro");

    },
    patch_settings_protocol: function(list_pro, duo_delegate_delegating, loading, dialog) {
      let data = {};
      if(this.of_type === "delegates") data = {"delegate_professionals": list_pro};
      else if(this.of_type === "delegating") data = {"delegating_professionals": list_pro};

      if(duo_delegate_delegating) data["duo_delegate_delegating"] = duo_delegate_delegating;

      this.$http.patch(`/api/hf-coordination/${this.hf_id}/settings-protocols/${this.protocol.id}`, data).then(response => {
        this.$emit("update_protocol", response.data);
        this.$store.commit("PostSuccess", `Liste des ${this.name_type_pro}s modifiée`);
      }).finally(() => {
        this[loading] = false;
        this[dialog] = false;
      })
    }
  }
}
</script>

<style scoped>

</style>