<template>
  <v-card class="rounded-lg">
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <p class="primary_dark--text semibold--text font-size--16 mb-0 align-self-center">Lien accès public :</p>
          <a :href="url_waiting_list" target="_blank"
             class="primary_dark--text medium--text font-size--14 text-decoration-underline">
            {{ url_waiting_list }}
          </a>
          <v-btn icon color="primary" class="ml-2" @click="copy_link()" small>
            <v-icon small>mdi-content-copy</v-icon>
          </v-btn>
          <v-snackbar v-model="success_copy">
            <v-icon color="green" class="mr-4">mdi-check</v-icon>
            <span class="medium--text font-size--16">Lien copié</span>
          </v-snackbar>
        </v-col>
        <v-col cols="12" sm="6">
          <p class="primary--text font-size--18 semibold--text">Répartition par statut</p>
          <v-progress-circular v-if="loading" color="primary_pastel" indeterminate></v-progress-circular>
          <apex-chart v-else type="pie" width="60%" :options="options_status" :series="data_status"/>
        </v-col>
        <v-col cols="12" sm="6">
          <p class="primary--text font-size--18 semibold--text">
            Répartition par type d'inscription
            <v-tooltip top max-width="400">
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" small v-on="on">
                  <v-icon small>mdi-information</v-icon>
                </v-btn>
              </template>
            <span>Le patient peut s'ajouter lui même en remplissant le formulaire ou un professionnel peut l'inscrire en ajoutant le tag 'En attente MT' sur sa fiche patient</span>
          </v-tooltip>
          </p>
          <v-progress-circular v-if="loading" color="primary" indeterminate></v-progress-circular>
          <apex-chart v-else type="pie" width="60%" :options="options_authors" :series="data_authors"/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import {get_status_name, get_url_waiting_list} from "coordination-library/src/modules/waiting_list";

export default {
  name: "ManagerStatisticsWaitingList",
  props: {
    hf_coordination_id: {
      type: String,
      required: true
    },
    load_data: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    status: null,
    authors: null,
    success_copy: false,
    is_loaded: false
  }),
  computed: {
    options_status() {
      let categories = [
          get_status_name(1),
          get_status_name(2),
          get_status_name(3),
          get_status_name(4),
      ]
      let options = {
        chart: {toolbar: {show: false}},
        labels: categories,
        dataLabels: {
          formatter: function(val ,opts) {
            return opts.w.config.series[opts.seriesIndex];
          }
        },
        tooltip: {
          y: {
            formatter: function (val, opts) {
              let percentage = 0;
              let total = opts.config.series.reduce((a, b) => a + b, 0);
              if (total > 0) percentage = Math.round(val * 100) / total;
              return `${val} (${percentage.toFixed(2)}%)`;
            }
          }
        }
      }
      return options;
    },
    data_status() {
      let data = [];
      if (this.status) data = [this.status.status_1, this.status.status_2, this.status.status_3, this.status.status_4];
      return data;
    },
    options_authors() {
      return {
        chart: {toolbar: {show: false}},
        labels: ["Patient", "Professionnel"],
        dataLabels: {
          formatter: function(val ,opts) {
            return opts.w.config.series[opts.seriesIndex];
          }
        },
        tooltip: {
          y: {
            formatter: function (val, opts) {
              let percentage = 0;
              let total = opts.config.series.reduce((a, b) => a + b, 0);
              if (total > 0) percentage = Math.round(val * 100) / total;
              return `${val} (${percentage.toFixed(2)}%)`;
            }
          }
        }
      }
    },
    data_authors() {
      if (this.authors) return [this.authors.patient, this.authors.professionnel];
      return [];
    },
    url_waiting_list() {
      return get_url_waiting_list(this.hf_coordination_id);
    }
  },
  watch: {
    load_data: function(val) {
      if(val && !this.is_loaded) this.get_statistics();
    },
    hf_coordination_id: function(val) {
      if(val) this.get_statistics();
    }
  },
  methods: {
    get_statistics() {
      this.loading = true;
      axios.get(`/api/hf-coordination/${this.hf_coordination_id}/waiting_list-statistics`).then(response => {
        this.status = response.data.status;
        this.authors = response.data.authors;
      }).catch(error => {
        if (error.response) this.$store.dispatch('ManageErrorLight', error.response.status);
      }).finally(() => {
        this.loading = false;
        this.is_loaded = true;
      })
    },
    copy_link() {
      navigator.clipboard.writeText(this.url_waiting_list);
      this.success_copy = true;
    }
  }
}
</script>

<style scoped>

</style>