import {get_formated_address} from "anam-library/src/js/address";
import {get_formatted_date} from "anam-library/src/js/dates";

// Status
export const get_txt_status = function (status) {
    if (status === 1 || status === 2) return "En attente";
    else if (status === 3) return "Prise en charge";
    else if (status === 7) return "Prise en charge établissement";
    return "";
}

// PEC
export const PEC_DDI = {
    "pec_domicile": "PEC à domicile",
    "pec_cabinet": "PEC au cabinet du praticien",
}

export const get_sentence_address_pec = function (address_pec_type, address) {
    if (address_pec_type === "pec_cabinet") return PEC_DDI[address_pec_type];
    else if (address_pec_type && Object.keys(PEC_DDI).includes(address_pec_type)) return `${PEC_DDI[address_pec_type]} : ${get_formated_address(address)}`;
    return get_formated_address(address);
}


// Fréquences et délais
export const FREQUENCIES = {
    1: "jour",
    2: "semaine",
    3: "mois"
}

export const FREQUENCIES_SELECT = function () {
    let list_to_return = [];
    for (let el in FREQUENCIES) {
        let temp = {"text": FREQUENCIES[el], "value": parseInt(el)}
        list_to_return.push(temp);
    }
    return list_to_return;
}

export const get_frequency_name = function (value) {
    if (value) {
        if (Object.keys(FREQUENCIES).includes(value.toString())) return FREQUENCIES[value];
    }
    return ""
}

export const get_sentence_frequencies = function (frequency, frequency_type) {
    if (!frequency) return "Non précisée";
    return `${frequency} fois par ${get_frequency_name(frequency_type)}`;
}

export const get_sentence_recurrence = function (recurrence, recurrence_type) {
    if (!recurrence) return "Non précisée";
    return `pendant ${recurrence} ${get_frequency_name(recurrence_type)}`;
}

export const get_txt_start_date = function(dmp_type, start_date, delay, delay_type) {
    if ((delay || delay === 0) && delay_type) {
        return get_sentence_delay(delay, delay_type);
    }
    else {
        return get_formatted_date(start_date, {output_format: "DD/MM/YYYY"});
    }
}

export const get_sentence_delay = function (delay, delay_type, start_date = null) {
  let tmp = "";
  if (!delay && delay !== 0) return "Non précisé";
  else if ((delay === "0" || delay === 0) && delay_type === 1) tmp = "Dès que possible"
  else tmp = `Sous ${delay} ${get_frequency_name(delay_type)}${delay > 1 && delay_type !== 3 ? "s" : ""}`;
  if (start_date) tmp = `${tmp} (demande faite le ${get_formatted_date(start_date, {output_format: "DD/MM/YYYY"})})`;
  return tmp;
}

// Traçabilité
export const DDI_TRACEABILITY_STATUS = {
    1: "Envoyé (cercle de soin)",
    2: "Envoyé (manuellement)",
    3: "Envoyé (automatiquement)",
    4: "Refusé",
    5: "Envoyé (admin étab.)"
}

export const get_ddi_traceability_status_txt = function (val) {
    if (val && Object.keys(DDI_TRACEABILITY_STATUS).includes(val.toString())) return DDI_TRACEABILITY_STATUS[val];
    return null
}

// Motifs
export const DDI_MOTIFS_CLOSED = {
    1: "Demande annulée",
    2: "Demande prise en charge hors Citana",
    3: "Pas de ressources disponibles",
    4: "Autre"
}

export const get_ddi_motif_closed_txt = function (val) {
    if (val && Object.keys(DDI_MOTIFS_CLOSED).includes(val.toString())) return DDI_MOTIFS_CLOSED[val];
    return null
}

export const get_ddi_motif_closed_for_select = function () {
    let tmp = [];
    for (let el in DDI_MOTIFS_CLOSED) {
        tmp.push({text: DDI_MOTIFS_CLOSED[el], value: el})
    }
    return tmp
}

// Settings
import DoctorSettingsMedecinEndocrinologie
    from "../components/doctor/settings/ddi-components/DoctorSettingsMedecinEndocrinologie";
import DoctorSettingsMedecinNeurologie
    from "../components/doctor/settings/ddi-components/DoctorSettingsMedecinNeurologie";
import DoctorSettingsMedecinRadiologie
    from "../components/doctor/settings/ddi-components/DoctorSettingsMedecinRadiologie";
import DoctorSettingsSageFemme from "../components/doctor/settings/ddi-components/DoctorSettingsSageFemme";
import DoctorSettingsInfirmier from "../components/doctor/settings/ddi-components/DoctorSettingsInfirmier.vue";
import DoctorSettingsKine from "../components/doctor/settings/ddi-components/DoctorSettingsKine.vue";
import DoctorSettingsCoordinateur from "../components/doctor/settings/ddi-components/DoctorSettingsCoordinateur";
import DoctorSettingsOrthophoniste from "../components/doctor/settings/ddi-components/DoctorSettingsOrthophoniste";
import DoctorSettingsAPA from "../components/doctor/settings/ddi-components/DoctorSettingsAPA";
import DoctorSettingsAssistantSocial from "../components/doctor/settings/ddi-components/DoctorSettingsAssistantSocial";
import DoctorSettingsDefault from "../components/doctor/settings/ddi-components/DoctorSettingsDefault";
import ServiceSettingsDefault
    from "@/components/manager/structures/details/etablissements/settings/ServiceSettingsDefault";
import ServiceSettingsDiabetologie
    from "@/components/manager/structures/details/etablissements/settings/ServiceSettingsDiabetologie";
import ServiceSettingsParcoursComplexes
    from "@/components/manager/structures/details/etablissements/settings/ServiceSettingsParcoursComplexes";
import ServiceSettingsRadiologie
    from "@/components/manager/structures/details/etablissements/settings/ServiceSettingsRadiologie";
import ServiceSettingsNeurologie
    from "@/components/manager/structures/details/etablissements/settings/ServiceSettingsNeurologie";

export const MAP_COMPONENTS_SETTINGS_PROFESSIONS = {
    "41": DoctorSettingsAssistantSocial,
    "50": DoctorSettingsSageFemme,
    "60": DoctorSettingsInfirmier,
    "70": DoctorSettingsKine,
    "99": DoctorSettingsCoordinateur,
    "91": DoctorSettingsOrthophoniste,
    "310": DoctorSettingsAPA,
    "SM16": DoctorSettingsMedecinEndocrinologie,
    "SM32": DoctorSettingsMedecinNeurologie,
    "SM44": DoctorSettingsMedecinRadiologie
}

export const MAP_COMPONENTS_SETTINGS_SERVICES = {
    "039": ServiceSettingsDiabetologie,
    "066": ServiceSettingsRadiologie,
    "097": ServiceSettingsNeurologie,
    "335": ServiceSettingsParcoursComplexes,
}

export const professional_has_settings = function (dmp_or_speciality) {
    return Object.keys(MAP_COMPONENTS_SETTINGS_PROFESSIONS).includes(dmp_or_speciality);
}

export const get_component_settings_for_professional = function (dmp_or_speciality) {
    if (professional_has_settings(dmp_or_speciality)) return MAP_COMPONENTS_SETTINGS_PROFESSIONS[dmp_or_speciality];
    else return DoctorSettingsDefault;
}

export const service_has_settings = function (of_type) {
    return Object.keys(MAP_COMPONENTS_SETTINGS_SERVICES).includes(of_type);
}

export const get_component_settings_for_service = function (of_type) {
    if (service_has_settings(of_type)) return MAP_COMPONENTS_SETTINGS_SERVICES[of_type];
    else return ServiceSettingsDefault;
}


// Care
export const CARES = [
    {
        "id": "avis_diabeto",
        "name": "Consultation avis en endocrinologie et diabétologie",
        "dmp": "10",
        "speciality": "SM16",
        "service": "039"
    },
    {
        "id": "pied_diabetique",
        "name": "Consultation pied diabétique",
        "dmp": "10",
        "speciality": "SM16",
        "service": "039"
    },
    {
        "id": "monitoring",
        "name": "Monitoring",
        "dmp": "50",
        "speciality": null,
        "service": null
    },
    {
        "id": "post_partum",
        "name": "Suivi post partum",
        "dmp": "50",
        "speciality": null,
        "service": null
    },
    {
        "id": "reeducation_perinee",
        "name": "Rééducation du périnée",
        "dmp": "50",
        "speciality": null,
        "service": null
    },
    {
        "id": "echographie_sf",
        "name": "Échographie (sage-femme)",
        "dmp": "50",
        "speciality": null,
        "service": null
    },
    {
        "id": "consult_suivi_gyneco",
        "name": "Consultation de suivi gynécologique",
        "dmp": "50",
        "speciality": null,
        "service": null
    },
    {
        "id": "doppler",
        "name": "Doppler",
        "dmp": "10",
        "speciality": "SM44",
        "service": "066"
    },
    {
        "id": "echographie",
        "name": "Echographie",
        "dmp": "10",
        "speciality": "SM44",
        "service": "066"
    },
    {
        "id": "irm",
        "name": "IRM",
        "dmp": "10",
        "speciality": "SM44",
        "service": "066"
    },
    {
        "id": "mammographie",
        "name": "Mammographie",
        "dmp": "10",
        "speciality": "SM44",
        "service": "066"
    },
    {
        "id": "osteodensitometrie",
        "name": "Ostéodensitométrie",
        "dmp": "10",
        "speciality": "SM44",
        "service": "066"
    },
    {
        "id": "radio",
        "name": "Radiologie",
        "dmp": "10",
        "speciality": "SM44",
        "service": "066"
    },
    {
        "id": "radio_dentaire_beam",
        "name": "Radiologie dentaire avec cône Beam",
        "dmp": "10",
        "speciality": "SM44",
        "service": "066"
    },
    {
        "id": "scanner",
        "name": "Scanner",
        "dmp": "10",
        "speciality": "SM44",
        "service": "066"
    },
    {
        "id": "telos",
        "name": "Télos (radiographie dynamique)",
        "dmp": "10",
        "speciality": "SM44",
        "service": "066"
    },
    {
        "id": "bilan_sanguin_labo",
        "name": "Bilan sanguin au laboratoire",
        "dmp": "86",
        "speciality": null,
        "service": null
    },
    {
        "id": "bilan_sanguin_domicile",
        "name": "Bilan sanguin à domicile",
        "dmp": "86",
        "speciality": null,
        "service": null
    },
    {
        "id": "pcr",
        "name": "Test PCR",
        "dmp": "86",
        "speciality": null,
        "service": null
    },
    {
        "id": "autre",
        "name": "Autre acte laboratoire",
        "dmp": "86",
        "speciality": null,
        "service": null
    },
    {
        "id": "avis_coordinateur",
        "name": "Avis coordinateur",
        "dmp": "99",
        "speciality": null,
        "service": null
    },
    {
        "id": "demande_mas",
        "name": "Demande MAS",
        "dmp": "41",
        "speciality": null,
        "service": "335"
    },
    {
        "id": "avis_neurologie",
        "name": "Consultation avis en neurologie",
        "dmp": "10",
        "speciality": "SM32",
        "service": "097"
    },
    // Soins infirmiers
    {
        "id": "prise_de_sang",
        "name": "Prise de sang / Prélèvement sanguin / Sérologie",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "ecbu",
        "name": "Prélèvement urines / ECBU",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "ablation_agrafes_suture",
        "name": "Ablation agrafes et/ou fils ou points de suture",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "vaccin",
        "name": "Vaccin (hors COVID)",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "pansements",
        "name": "Pansement(s)",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "injections",
        "name": "Injection (IM, SC, IV) Intramusculaire, Sous-cutanées ou intraveineuse",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "perfusion",
        "name": "Perfusion",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "aerosols",
        "name": "Aérosol(s)",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "collyres",
        "name": "Instillation de collyres, gouttes oculaires",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "saignee",
        "name": "Saignée",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "glycemie",
        "name": "Glycémie / insuline",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "dialyse_peritoneale",
        "name": "Dialyse péritonéale",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "aide_toilette_soins",
        "name": "Aide à la toilette / nursing / soins d'hygiène / séance de soins infirmiers",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "pose_bas",
        "name": "Pose de bas de contention, bas à varices",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "sonde_urinaire",
        "name": "Sondage Urinaire (pose) / Soins de sonde urinaire",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "sonde_urinaire_retrait",
        "name": "Retrait sonde urinaire",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "lavement_rectal",
        "name": "Lavement rectal / évacuateur",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "soins_stomie",
        "name": "Soins de stomie / colostomie",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "prise_de_medicaments",
        "name": "Préparation, distribution et surveillance de prise de médicament",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "chimio_orale",
        "name": "Chimiothérapie orale",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "surveillance_chimio",
        "name": "Surveillance et débranchement de chimiothérapie",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "nutrition_enterale",
        "name": "Alimentation (nutrition entérale) / surveillance de sonde (ex : nasogastrique)",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "soins_picc_line",
        "name": "Soins sur cathéter central (Picc Line)",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "seances_soins_infirmiers",
        "name": "Séance de soins infirmiers / surveillance clinique",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "surveillance_clinique_hebdo",
        "name": "Surveillance clinique hebdomadaire",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "surveillance_clinique_quotidienne",
        "name": "Surveillance clinique quotidienne (induction ou modification de traitement)",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "surveillance_clinique_postop",
        "name": "Séances de surveillance clinique et d’accompagnement postopératoire",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "surveillance_catheter_postop",
        "name": "Séance de surveillance et/ou retrait de cathéter périnerveux pour une analgésie postopératoire",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "surveillance_drain",
        "name": "Surveillance de drain de redon et/ou retrait postopératoire de drain",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "pansement_et_surveillance_clinique_postop",
        "name": "Pansement et séances de surveillance clinique et d’accompagnement postopératoire CH",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "soins_tracheo",
        "name": "Soins de trachéostomie ou trachéotomie",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "soins_palliatifs",
        "name": "Soins palliatifs",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "soins_pediatriques",
        "name": "Soins pédiatriques",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "prado",
        "name": "Prado",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "covid_tlc",
        "name": "Téléconsultation Coronavirus (CoVid)",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "covid_depistage_antigenique",
        "name": "Dépistage Covid par test antigénique",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "autres_soins_infirmiers",
        "name": "Autre soins infirmiers",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    {
        "id": "chirurgie_bariatrique",
        "name": "Chirurgie bariatrique",
        "dmp": "60",
        "speciality": null,
        "service": null
    },
    // Soins kiné
    {
        "id": "trouble_circulation",
        "name": "Trouble de la circulation (œdème lymphatique, veineux, drainage)",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "prothese",
        "name": "Prothèse",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "ligamentoplastie",
        "name": "Ligamentoplastie",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "suture_tendineuse",
        "name": "Suture tendineuse (ex : coiffe des rotateurs, tendon d'Achille)",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "colonne_vertebrale_operee",
        "name": "Colonne vertébrale opérée",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "incontinence_urinaire",
        "name": "Incontinence urinaire",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "incontinence_fecale",
        "name": "Incontinence fécale (rééducation ano-rectale)",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "douleur_dos",
        "name": "Mal de dos (lumbago, torticoli, sciatique, discopathie, scoliose)",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "scoliose",
        "name": "Scoliose",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "deformation_pied",
        "name": "Déformation du pied",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "asymetrie_posturale",
        "name": "Asymétrie posturale (plagiocéphalie, torticolis)",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "pb_hanche",
        "name": "Atteinte de la hanche (ex : luxation congénitale de hanche)",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "pec_brulures",
        "name": "Prise en charge des brûlures",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "pec_cicatrices",
        "name": "Prise en charge des cicatrices",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "avc_hemiplegie",
        "name": "Accident Vasculaire Cérébral et hémiplégie",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "sclerose_en_plaques",
        "name": "Sclérose en plaques",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "parkinson",
        "name": "Sclérose en plaques",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "paraplegie_tetraplegie",
        "name": "Paraplégique/tétraplégique",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "bpco",
        "name": "Broncho-Pneumopathie Chronique Obstructive (BPCO)",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "mucoviscidose",
        "name": "Mucoviscidose",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "bronchiolite",
        "name": "Bronchiolite",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "arthrose",
        "name": "Arthrose",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "pr_spa",
        "name": "Polyarthrite rhumatoïde (PR) / Spondylarthrite ankylosante (SPA)",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "fibromyalgie",
        "name": "Fibromyalgie",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "fracture_opere",
        "name": "Fracture opérée",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "fracture_non_opere",
        "name": "Fracture non opérée",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "capsulite",
        "name": "Capsulite",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "soin_nerf",
        "name": "Section, compression, étirement d'un nerf",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "maladie_neuromusculaires",
        "name": "Maladies neuromusculaires (ex : myopathie)",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "retard_neuro_moteur",
        "name": "Retard neuro-moteur",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "imc_pc",
        "name": "Infirmité Motrice Cérabrale (IMC), Paralysie Cérébrale (PC), Encéphalopathie",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "entorse",
        "name": "Entorse, luxation, foulure",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "dechirure",
        "name": "Déchirure, tendinite (tendinopathie), claquage",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "pb_respi_courant",
        "name": "Atteintes respiratoires courantes (ex : bronchite, pneumonie, asthme)",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "pec_palliatifs",
        "name": "Prise en charge en soins palliatifs",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "bilan_apa_kine",
        "name": "Bilan d'activité physique adapté",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "entrainement_effort_post_pb_cardiaque",
        "name": "Réentraînement à l'effort après accident cardiaque (crise cardiaque, pontage, infarctus, greffe)",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "reeducation_abdos",
        "name": "Rééducation des abdominaux",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "reeducation_perinee_kine",
        "name": "Rééducation du périnée (périnéale)",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "reeducation_troubles_deglutition",
        "name": "Rééducation des troubles de déglutition",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "reeducation_vestibulaire",
        "name": "Rééducation des vertiges (vestibulaire) et/ou de l'équilibre (oreille interne)",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "reeducation_maxillo_faciale",
        "name": "Rééducation de la mâchoire (maxillo-faciale)",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "reeducation_marche_geriatrie",
        "name": "Rééducation marche et/ou équilibre chez la personne âgée",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "reeducation_oncologie",
        "name": "Rééducation en cancérologie (ex : post-cancer du sein)",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "reeducation_post_amputation",
        "name": "Rééducation suite à une amputation",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "reeducation_respi_postop",
        "name": "Rééducation respiratoire suite à une chirurgie thoracique ou en cancérologie (ex : lobectomie, cancer du poumon)",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "autre_reeducation_ortho_operatoire",
        "name": "Autre type de rééducation orthopédique pré ou post opératoire (ex : méniscectomie)",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "autre_reeducation_ortho_pediatrique",
        "name": "Autre type de rééducation orthopédique pédiatrique",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "autre_reeducation_neuro",
        "name": "Autre type de rééducation neurologique",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "autre_reeducation_pneumo",
        "name": "Autre type de rééducation pneumologique",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "autre_reeducation_rhumato",
        "name": "Autre type de rééducation rhumatologique (ex : ostéporose, algoneurodystrophie)",
        "dmp": "70",
        "speciality": null,
        "service": null
    },
    {
        "id": "autre_reeducation_traumato",
        "name": "Autre type de rééducation traumatologique",
        "dmp": "70",
        "speciality": null,
        "service": null
    }
]

export const PROFESSIONS_WITH_CARES = ["50", "86", "99", "41", "60", "70"];
export const SPECIALITES_WITH_CARES = ["SM16", "SM44", "SM32"]

export const get_all_cares_for_select = function () {
    let tmp = [];
    for (let el of CARES) {
        tmp.push({"value": el.id, "text": el.name, "dmp": el.dmp});
    }
    return tmp;
}

export const get_cares_profession = function (dmp, speciality) {
    return CARES.filter(x => x.dmp === dmp && (!x.speciality || x.speciality === speciality));
}

export const get_cares_profession_for_select = function (dmp, speciality) {
    let tmp = [];
    let cares = get_cares_profession(dmp, speciality);
    for (let el of cares) {
        tmp.push({"value": el.id, "text": el.name});
    }
    return tmp;
}

export const get_txt_care = function (care) {
    let index = CARES.findIndex(x => x.id === care);
    if (index > -1) return CARES[index].name;
    return null;
}

export const get_list_txt_cares = function (cares) {
    let tmp = [];
    for (let el in cares) {
        tmp.push(get_txt_care(cares[el]));
    }
    return tmp;
}

export const get_info_from_care = function (care, info) {
    let index = CARES.findIndex(x => x.id === care);
    if (index > -1) return CARES[index][info];
    return null;
}

export const get_dmp_from_care = function (care) {
    return get_info_from_care(care, "dmp");
}

export const get_speciality_from_care = function (care) {
    return get_info_from_care(care, "speciality")
}

export const get_service_from_care = function (care) {
    return get_info_from_care(care, "service")
}