<template>
  <v-container fluid>
    <AnBackButton v-on:back="$router.push({name: 'ManagerHome'})" color="primary" :x_large="false"/>
    <v-row class="mx-0 mt-3">
      <v-col cols="6" class="align-self-center">
        <AnCreateButton v-on:click="open_add_cpts_dialog()" text="Ajouter une structure" color="green"/>
      </v-col>
      <v-col cols="4" offset="2" class="align-self-center">
        <v-card style="border-radius: 50px">
          <v-text-field
              rounded filled hide-details clearable
              prepend-inner-icon="mdi-magnify" background-color="white"
              label="Rechercher"
              v-model="search"/>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            class="elevation-1"
            :loading="loading"
            :loading-text="$t('dashboard.loading') + ' ...'"
            no-data-text="Aucune structure"
            no-results-text="Aucun résultat"
            :footer-props="{
              itemsPerPageText: $t('dashboard.footer.line_per_page'),
              itemsPerPageAllText: $t('dashboard.footer.all'),
              'items-per-page-options': [20, 100, 500, -1]
            }"
        >
          <template v-slot:[`item.commercial_name`]="{ item }">
            <span class="medium--text">{{ item.commercial_name }}</span>
          </template>
          <template v-slot:[`item.is_published`]="{ item }">
            <BooleanItem :item="item" variable="is_published" :option_color="true"/>
          </template>
          <template v-slot:[`item.is_client`]="{ item }">
            <BooleanItem :item="item" variable="is_client" :option_color="true"/>
          </template>
          <template v-slot:[`item.region`]="{ item }">
            <span>{{ item.region }}</span>
          </template>
          <template v-slot:[`item.creation_date`]="{ item }">
            <DateItem :item="item" variable="creation_date" :option_icon="false"/>
          </template>
          <template v-slot:[`item.option_snp`]="{ item }">
            <BooleanItem :item="item" variable="option_snp" :option_color="true"/>
          </template>
          <template v-slot:[`item.option_ddi`]="{ item }">
            <BooleanItem :item="item" variable="option_ddi" :option_color="true"/>
          </template>
          <template v-slot:[`item.option_protocoles`]="{ item }">
            <BooleanItem :item="item" variable="option_protocoles" :option_color="true"/>
          </template>
          <template v-slot:[`item.option_waiting_list`]="{ item }">
            <BooleanItem :item="item" variable="option_waiting_list" :option_color="true"/>
          </template>
          <template v-slot:[`item.option_tlc`]="{ item }">
            <BooleanItem :item="item" variable="option_tlc" :option_color="true"/>
          </template>
          <template v-slot:[`item.option_medical_record`]="{ item }">
            <BooleanItem :item="item" variable="option_medical_record" :option_color="true"/>
          </template>
          <template v-slot:[`item.option_communication`]="{ item }">
            <BooleanItem :item="item" variable="option_communication" :option_color="true"/>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn small elevation="0" color="bg_primary" class="primary--text my-1" block :href="`/manager/manage_cpts/${item.id}`" target="_blank">
              <v-icon small class="mr-2" color="primary">mdi-eye</v-icon>
              <span class="medium--text">Pilotage opérationnel</span>
            </v-btn>
            <v-btn small elevation="0" color="bg_primary" class="primary--text mb-1" block :href="`/manager/cpts/${item.id}/settings`" target="_blank">
              <v-icon small color="primary" class="mr-2">mdi-cog</v-icon>
              <span class="medium--text">Paramètres</span>
            </v-btn>
            <v-btn small text color="pastel_red" block @click="open_delete_cpts_dialog(item)">
              <v-icon small class="mr-2">mdi-delete</v-icon>
              <span class="font-size--12">Supprimer</span>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <DialogCreateCPTS ref="create_cpts" v-on:update_data="update_data($event)"/>

    <AnDialogConfirmation :dialog.sync="dialog_delete_cpts"
                          icon="mdi-delete"
                          title="Supprimer la structure"
                          question="Êtes-vous sûr de vouloir supprimer la structure ?"
                          title_color="primary_dark"
                          question_color="primary_dark"
                          v-on:cancel="dialog_delete_cpts=false"
                          v-on:confirm="delete_cpts()">
      <template v-slot:additional-content>
        <p>Nom de la structure : <span class="primary--text bold--text">{{cpts_to_delete ? cpts_to_delete.commercial_name : "" }}</span></p>
      </template>
    </AnDialogConfirmation>

    <v-dialog v-model="dialog_delete_cpts_warning" width="600">
      <v-card>
        <v-card-title class="primary white--text justify-center medium--text">
          <v-icon class="mr-4" color="white">mdi-alert</v-icon>
          Attention
        </v-card-title>
        <v-card-text class="pt-6 primary_dark--text">
          <p class="semibold--text">Vous ne pouvez pas supprimer une structure cliente.</p>
          <p>Si vous souhaitez la désactiver, vous devez au préalable modifier la structure en <span class="semibold--text">désactivant l'option "cliente"</span>.
            Pour le faire, cliquez sur l'icône "<v-icon small>mdi-pencil</v-icon>" dans la colonne "Actions" de la structure concernée</p>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" outlined @click="dialog_delete_cpts_warning=false">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_delete_cpts_impossible" width="600">
      <v-card>
        <v-card-title class="primary white--text justify-center medium--text">
          <v-icon class="mr-4" color="white">mdi-alert</v-icon>
          Suppression impossible
        </v-card-title>
        <v-card-text class="pt-6 text-center primary_dark--text">
          <p class="semibold--text">Vous ne pouvez pas supprimer une structure contenant plus de 10 professionnels de santé.</p>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" outlined @click="dialog_delete_cpts_impossible=false">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import AnCreateButton from "anam-library/src/components/an-ui/button/AnCreateButton";
import DialogCreateCPTS from "./DialogCreateCPTS";
import BooleanItem from "anam-library/src/components/an-dashboard/an-dashboard-table-items/BooleanItem";
import DateItem from "anam-library/src/components/an-dashboard/an-dashboard-table-items/DateItem";
import AnDialogConfirmation from "anam-library/src/components/an-dialogs/AnDialogConfirmation";

import {GET_REGION_NAME} from "coordination-library/src/data/region";
import axios from "axios"
import AnBackButton from "anam-library/src/components/an-ui/button/AnBackButton.vue";

export default {
  name: "ManagerListingStructuresView",
  components: {
    AnBackButton,
    DialogCreateCPTS,
    AnCreateButton,
    BooleanItem,
    DateItem,
    AnDialogConfirmation
  },
  data: () => ({
    dialog_delete_cpts: false,
    cpts_to_delete: null,
    headers: [
      {text: "Id", value: "id", class: "white--text primary"},
      {text: "Nom", value: "commercial_name", class: "white--text primary"},
      {text: "Publiée", value: "is_published", class: "white--text primary"},
      {text: "Client", value: "is_client", class: "white--text primary"},
      {text: "Numéro FINESS", value: "id_structure", class: "white--text primary"},
      {text: "Adresse", value: "address", class: "white--text primary"},
      {text: "Région", value: "region", class: "white--text primary"},
      {text: "Création", value: "creation_date", class: "white--text primary"},
      {text: "Opt. SNP", value: "option_snp", class: "white--text primary"},
      {text: "Opt. DDI", value: "option_ddi", class: "white--text primary"},
      {text: "Opt. Protocole", value: "option_protocoles", class: "white--text primary"},
      {text: "Opt. Liste d'attente", value: "option_waiting_list", class: "white--text primary"},
      {text: "Opt. TLC", value: "option_tlc", class: "white--text primary"},
      {text: "Opt. Dossier Med", value: "option_medical_record", class: "white--text primary"},
      {text: "Opt. Com.", value: "option_communication", class: "white--text primary"},
      {text: "Actions", value: "actions", class: "white--text primary", align: "center"},
    ],
    items: [],
    search: null,
    loading: false,
    dialog_delete_cpts_warning: false,
    dialog_delete_cpts_impossible: false,
  }),
  created() {
    this.get_data();
  },
  mounted() {
    this.$store.state.is_mounted_app = true;
  },
  methods: {
    get_data() {
      this.items = [];
      this.loading = true;
      axios.get("/api/hf_coordination").then(response => {
        for (let cpts of response.data) {
          this.items.push({
            id: cpts.id,
            commercial_name: cpts.commercial_name,
            is_published: cpts.is_published,
            is_client: cpts.is_client,
            creation_date: cpts.creation_date,
            id_structure: cpts.id_structure,
            address: this.get_address_str(cpts.address),
            region: GET_REGION_NAME(cpts.region),
            cities_caring_for: cpts.cities_caring_for ? cpts.cities_caring_for : {},
            communes_managed: cpts.communes_managed ? cpts.communes_managed : [],
            regions_managed: cpts.regions_managed ? cpts.regions_managed : [],
            option_ddi: cpts.option_ddi,
            option_snp: cpts.option_snp,
            option_protocoles: cpts.option_protocoles,
            option_waiting_list: cpts.option_waiting_list,
            option_tlc: cpts.option_tlc,
            option_medical_record: cpts.option_medical_record,
            option_communication: cpts.option_communication,
          })
        }
      }).catch(error => {
        if (error.response) {
          let status_redirection = [401, 403, 502, 500];
          if(status_redirection.includes(error.response.status)) {
            this.$router.push(`/${error.response.status.toString()}`)
          } else {
            this.$store.commit('PostError', 'Une erreur est survenue, veuillez nous excuser pour la gêne occasionneée');
          }
        }else {
          this.$store.commit('PostError', 'Une erreur est survenue, veuillez nous excuser pour la gêne occasionneée');
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    update_data: function(data) {
      let index = this.items.findIndex(x => x.id === data['id_cpts']);
      if(index > -1) {
        for(let el in data['updated_data']) {
          if(el === 'address') {
            this.items[index][el] = this.get_address_str(data['updated_data'][el])
          } else if (el === 'region') {
            this.items[index][el] = GET_REGION_NAME(data['updated_data'][el])
          } else {
            this.items[index][el] = data['updated_data'][el]
          }
        }
      } else {
        data['address'] = this.get_address_str(data['address']);
        data['region'] = GET_REGION_NAME(data['region']);
        this.items.push(data)
      }
    },
    open_add_cpts_dialog(item = null) {
      this.$refs.create_cpts.open_dialog(item);
    },
    open_delete_cpts_dialog(item) {
      if(item.is_client) {
        this.dialog_delete_cpts_warning = true;
      } else if(parseInt(item.nb_doctors) > 10) {
        this.dialog_delete_cpts_impossible = true;
      } else {
        this.cpts_to_delete = item;
        this.dialog_delete_cpts = true;
      }
    },
    delete_cpts() {
      this.$http.delete("/api/hf_coordination/" + this.cpts_to_delete.id).then(() => {
        this.dialog_delete_cpts = false;
        let tmp = JSON.parse(JSON.stringify(this.items));
        let index = tmp.findIndex(x => x.id === this.cpts_to_delete.id);
        if(index > -1) tmp.splice(index, 1);
        this.items = tmp;
        this.$store.commit('PostSuccess', 'Suppression effectuée');
      })
    },
    get_address_str: function(address) {
      if(address) {
        let address_str = ""
        if (address.numeroVoie) address_str = `${address_str} ${address.numeroVoie}`;
        if (address.libelleVoie) address_str = `${address_str} ${address.libelleVoie}`;
        if (address.codePostal) address_str = `${address_str} ${address.codePostal}`;
        if (address.localite) address_str = `${address_str} ${address.localite}`;
        return address_str
      } else {
        return null
      }
    }
  },
}
</script>