<template>
  <v-col cols="12" class="my-8">
    <div :class="$vuetify.breakpoint.smAndUp ? 'my-3 d-flex' : 'mb-3'">
      <p :class="`mb-0 semibold--text primary_dark--text font-size--24 align-self-center`">
        {{ protocol_name }} - Prescriptions
      </p>
    </div>
    <AnDataTable :headers="headers"
                 :items="items"
                 :loading="loading"
                 :hide_footer="true"
                 :items_per_page="-1"
                 elevation="0"
                 no_data_txt="Aucune prescription"
                 class="mt-4 mt-sm-6"/>
  </v-col>
</template>

<script>
import AnDataTable from "anam-library/src/components/an-data-table/AnDataTable";
import {get_ordonnance_name_from_type} from "coordination-library/src/data/protocoles";

export default {
  name: "StatsProtocolPrescriptionsTable",
  components: {AnDataTable},
  props: {
    protocol_name: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    protocols: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    headers: [
      {text: 'Type Ordonnance', value: 'prescription', class: 'primary_dark--text'},
      {text: 'Total', value: 'total', class: 'primary_dark--text'}
    ]
  }),
  computed: {
    items: function() {
      let tmp = {};
      let prescriptions_null = 0
      for (let pt of this.protocols) {
        if(!pt.prescription) prescriptions_null += 1;
        else {
          if(Object.keys(tmp).includes(pt.prescription)) tmp[pt.prescription] += 1;
          else tmp[pt.prescription] = 1;
        }
      }

      let tmp_to_return = []
      for(let el in tmp) {
        tmp_to_return.push({'prescription': this.get_prescription_name(el), 'total': tmp[el]});
      }
      tmp_to_return.push({'prescription': 'Non sélectionnée', 'total': prescriptions_null});
      return tmp_to_return;
    }
  },
  methods: {
    get_prescription_name: function(of_type) {
      return get_ordonnance_name_from_type(of_type);
    }
  }
}
</script>

<style scoped>

</style>