import {get_app_name_by_host} from "coordination-library/src/js/utils";
import axios from "axios";
import getCookie from "anam-library/src/js/common";

export default {
    data: () => ({
        loading_possible_ressources: false,
        possible_ressources: [],
        all_selected: false,
        selected_ressources: [],
        api_calls: [],
        api_calls_completed: false,
        success: false,
        error: false,
    }),
    watch: {
        all_selected: function (val) {
            if (val) {
                this.selected_ressources = [];
                for (let el in this.possible_ressources) {
                    this.selected_ressources.push(this.possible_ressources[el].id)
                }
            } else this.selected_ressources = [];
        },
        api_calls_completed(val) {
            if (val) {
                this._dialog = false;
                this.loading_unsubscribe = false;
                this.success = true;
                this.error = this.api_calls.filter(x => x.value === false).length > 0;
            }
        },
    },
    methods: {
        get_possible_ressources(ressource_id, user_id) {
            this.possible_ressources = [];
            this.selected_ressources = [];
            this.loading_possible_ressources = true;
            let params = {
                user_id: user_id,
                action: "unsubscribe"
            }
            this.$http.get(`/api/ressources/${ressource_id}/inscription_recurrence`, {"params": params}).then(response => {
                for (let res of response.data) {
                    let text = `${this.$options.filters.datetime_full_a_fr_with_day(res.start_slot)} (jusqu'à ${this.$options.filters.time(res.end_slot)})`;
                    this.possible_ressources.push({id: res.id, text: text});
                }
            }).finally(() => {
                this.loading_possible_ressources = false;
            })
        },

        setup_api_call(user_id, ressource, planning, send_email, type_patch) {
            if (planning <= 0) planning = ressource.calendar;
            this.loading_unsubscribe = true;
            this.api_calls_completed = false;
            this.success = false;
            this.error = false;
            this.api_calls = [{id: ressource.id, value: null, text: `${this.$options.filters.datetime_full_a_fr_with_day(ressource.start_slot)} (jusqu'à ${this.$options.filters.time(ressource.end_slot)})`}];
            for (let el in this.selected_ressources) {
                let index = this.possible_ressources.findIndex(x => x.id === this.selected_ressources[el])
                if (index > -1) {
                    let res = this.possible_ressources[index];
                    this.api_calls.push({id: res.id, value: null, text: res.text});
                }
            }
            for (let res of this.api_calls) {
                this.api_call(user_id, res.id, planning, send_email, type_patch);
            }
        },

        api_call(user_id, res_id, planning, send_email, type_patch) {
            let formdata = new FormData();
            formdata.append("ressource_id", res_id);
            formdata.append("user_id", user_id);
            formdata.append("type_patch", type_patch);
            formdata.append("app_name", get_app_name_by_host());
            formdata.append("send_email", send_email);

            if(type_patch === "remove" || type_patch === "unsubscribe") {
                formdata.append("available_sas", false);
                formdata.append("available_snp", false);
            }

            axios.patch(`/api/hf-calendars/${planning}/ressources`, formdata, {headers: {"X-CSRFToken": getCookie('csrftoken')}}).then(response => {
                let index = this.api_calls.findIndex(x => x.id === response.data.ressource_id);
                if (index > -1) this.api_calls[index].value = true;
            }).catch(error => {
                let index = this.api_calls.findIndex(x => x.id === res_id);
                if (index > -1) this.api_calls[index].value = false;
                if (error.response) {
                    this.$store.dispatch("ManageErrorLight", error.response.status);
                    if (error.response.status === 500) this.$store.commit("PostCommonError");
                }
            }).finally(() => {
                if (!this.api_calls.length) this.api_calls_completed = false;
                else this.api_calls_completed = this.api_calls.filter(x => x.value === null).length === 0;
            });
        },
    }
}