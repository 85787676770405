<template>
  <div v-if="!phone_format" class="my-2">
    <v-btn v-if="$vuetify.breakpoint.smAndUp"
           :disabled="!is_pds"
           @click.exact="$emit('move_to_dpa')"
           @click.ctrl="$emit('open_dpa_window')"
           @click.middle="$emit('open_dpa_window')"
           color="bg_primary" class="primary--text" elevation="0"
           rounded>
      <v-icon class="mr-1" small>mdi-account</v-icon>
      <span class="semibold--text">Voir</span>
    </v-btn>
    <br>
    <v-btn v-if="patient_service && !patient_service.is_active" text color="red" small @click="unarchive_patient()">
      <span class="text-decoration-underline medium--text">Désarchiver</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "DashboardActionsItems",
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    phone_format: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading_archive: false
  }),
  computed: {
    is_pds() {
      return this.$store.getters.getPermission("is_pds");
    },
    patient_service: function() {
      if(this.item && this.item.patient_service_1001) return this.item.patient_service_1001;
      return null;
    }
  },
  methods: {
    unarchive_patient() {
      this.loading_archive = true;
      let data = {is_active: true};
      this.$http.patch(`/api/patient_services/${this.patient_service.id}`, data).then(() => {
        this.patient_service.is_active = true;
        this.$store.commit("PostSuccess", "Fiche patient désarchivée avec succès");
      }).finally(() => {
        this.loading_archive = false;
      })
    },
  }
}
</script>

<style scoped>

</style>