<template>
  <div>
    <v-dialog v-model="_dialog" fullscreen>
      <v-card-text class="pt-sm-10">
        <v-form v-model="valid_form">
          <v-row class="mx-0">
            <v-col cols="12" sm="8" offset-sm="2">
              <p class="font-size--28 semibold--text letter-spacing--1 primary_dark--text">{{pro_to_edit ? "Modifier le" : "Créer un"}} professionnel</p>
              <v-divider></v-divider>
              <v-progress-linear v-if="loading_pds_info" indeterminate color="primary"></v-progress-linear>
              <v-row class="mx-sm-4 mt-3 mt-sm-6" no-gutters>
                <v-col cols="12" sm="6" class="pr-4">
                  <AnTextField :value.sync="last_name" title="Nom" placeholder="Nom du professionnel" :required="true" :dense="true" icon="mdi-account" type_field="last_name"/>
                </v-col>
                <v-col cols="12" sm="6" class="pl-4">
                  <AnTextField :value.sync="first_name" title="Prénom" placeholder="Prénom du professionnel" :required="true" :dense="true" icon="mdi-account" type_field="text"/>
                </v-col>
                <v-col cols="12" sm="6" class="pr-4">
                  <AnTextField :value.sync="sex" title="Sexe" placeholder="Choisir le sexe" :required="true" :dense="true" icon="mdi-gender-male-female" type_field="sexe"/>
                </v-col>
                <v-col cols="12" sm="6" class="pl-4">
                  <div class="pl-1 font-size--16 medium--text">
                    Téléphone <v-icon color="red" size="8" class="mb-3">mdi-asterisk</v-icon>
                  </div>
                  <AnTelInputVuetify :required="true"
                                     filled dense
                                     :label="null"
                                     placeholder="N° de téléphone"
                                     v-on:update_phone="phone=$event"/>
                </v-col>
                <v-col cols="12" sm="6" class="pr-4">
                  <AnTextField :value.sync="email" title="Email (= identifiant de connexion)" placeholder="Adresse email du professionnel" :required="true" :dense="true" icon="mdi-at" type_field="email"/>
                </v-col>
                <v-col cols="12" sm="6" class="pl-4">
                  <div class="pl-1 font-size--16 medium--text">Adresse <span v-if="address && address.fullAddress">(actuellement: {{address.fullAddress}})</span></div>
                  <AnSearchAddressGoogleAPI
                      :address.sync="address"
                      class_input="filled-input"
                      placeholder="Entrez l'adresse"
                      street_number_required/>
                </v-col>
                <v-col cols="12" sm="6" class="pr-4">
                  <div class="pl-1 font-size--16 medium--text primary--text">Type d'identifiant (RPPS, ADELI)</div>
                  <v-select v-model="type_idpp" :items="items_idpp" dense filled color="primary" placeholder="Choisir le type d'identifiant du professionnel">
                    <template v-slot:prepend-inner>
                      <v-btn x-small icon>
                        <v-icon class="mr-1">mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:append>
                      <v-avatar size="20" :class="type_idpp ? 'green' : 'bg_light'">
                        <v-icon small color="white">mdi-check</v-icon>
                      </v-avatar>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" class="pl-4">
                  <AnTextField :value.sync="idpp" :title="txt_idpp" :dense="true" :placeholder="`${txt_idpp} du professionnel`" icon="" type_field="number"/>
                </v-col>
                <v-col cols="12" sm="6" class="pr-4">
                  <div class="pl-1 font-size--16 medium--text primary--text">
                    Profession <v-icon color="red" size="8" class="mb-3">mdi-asterisk</v-icon>
                  </div>
                  <v-select v-model="dmp_type" :items="professions" dense filled color="primary" :rules="[rules.required]" placeholder="Choisir la profession">
                    <template v-slot:prepend-inner>
                      <v-btn x-small icon>
                        <v-icon class="mr-1">mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:append>
                      <v-avatar size="20" :class="dmp_type ? 'green' : 'bg_light'">
                        <v-icon small color="white">mdi-check</v-icon>
                      </v-avatar>
                    </template>
                  </v-select>
                </v-col>
                <v-expand-transition>
                  <v-col v-if="dmp_type === '10'" cols="12" sm="6" class="pl-4">
                    <div class="pl-1 font-size--16 medium--text primary--text">
                      Spécialité <v-icon color="red" size="8" class="mb-3">mdi-asterisk</v-icon>
                    </div>
                    <v-select v-model="speciality_code" :items="specialities" dense filled color="soft_blue" :rules="[rules.required]" placeholder="Choisir la spécialité médicale">
                      <template v-slot:prepend-inner>
                        <v-btn x-small icon>
                          <v-icon class="mr-1">mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:append>
                        <v-avatar size="20" :class="speciality_code ? 'green' : 'bg_light'">
                          <v-icon small color="white">mdi-check</v-icon>
                        </v-avatar>
                      </template>
                    </v-select>
                  </v-col>
                </v-expand-transition>
                <v-col cols="12" class="pl-4 align-self-center" v-if="!pro_to_edit">
                  <v-checkbox v-model="admin_service" :label="`Est administrateur de la structure '${service.commercial_name}'`"></v-checkbox>
                </v-col>
              </v-row>
              <v-alert v-if="error" type="error" text transition="scale-transition">
                {{ error_msg }}
              </v-alert>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center pb-8">
        <v-btn text @click="_dialog=false" class="px-sm-6 mx-sm-3" large>
          <v-icon class="mr-2">mdi-close</v-icon>
          Annuler
        </v-btn>
        <v-btn elevation="0" color="green" class="white--text px-sm-6 mx-sm-3" large @click="click_create_or_save()" :loading="loading" :disabled="!(valid_form && phone)">
          <v-icon color="white" class="mr-2">mdi-content-save</v-icon>
          <span class="medium--text font-size--16">{{pro_to_edit ? "Enregistrer" : "Créer"}}</span>
        </v-btn>
      </v-card-actions>
    </v-dialog>

    <v-dialog v-model="dialog_confirm_creation" width="600" persistent>
      <v-card-title class="primary_dark--text medium--text letter-spacing--1">
        Confirmer la création
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <p class="font-size--16 primary_dark--text normal--text">La création d'un professionnel entrainera les actions suivantes :</p>
        <ul class="primary_dark--text font-size--16">
          <li class="normal--text">Ajout du professionnel aux structures : <span class="semibold--text font-size--16">{{ service.commercial_name }} ; {{ hf_name }}</span></li>
          <li><span class="semibold--text font-size--16">Accès</span> à l'application Coordination</li>
          <li>Envoi d'un <span class="semibold--text font-size--16">email afin de confirmer son inscription</span> sur cette adresse email : <span class="semibold--text font-size--16">{{ email }}</span></li>
        </ul>
        <p class="mt-4 mt-sm-8 semibold--text font-size--18 primary_dark--text text-center">Êtes-vous sûr de vouloir créer ce compte ?</p>
      </v-card-text>
      <v-card-actions class="justify-center pb-sm-8">
        <v-btn text color="pastel_red" class="px-sm-6" @click="dialog_confirm_creation=false;_dialog=false">
          <v-icon class="mr-sm-2">mdi-close</v-icon>
          Annuler
        </v-btn>
        <v-btn elevation="0" color="green" class="white--text px-sm-6" @click="save(true)" :loading="loading">
          <v-icon class="mr-sm-2">mdi-check</v-icon>
          <span class="medium--text font-size--16">Confirmer</span>
        </v-btn>
      </v-card-actions>
    </v-dialog>
  </div>
</template>

<script>
import AnTextField from "anam-library/src/components/an-ui/textfield/AnTextField";
import AnTelInputVuetify from "anam-library/src/components/an-ui/phone/AnTelInputVuetify";
import AnSearchAddressGoogleAPI from "anam-library/src/components/an-ui/an-address/AnSearchAddressGoogleAPI";

import {ITEMS_PROFESSIONS_FOR_COORDINATION_NEW_PDS} from "anam-library/src/data/professions";
import {get_speciality_med_only} from "anam-library/src/data/professionnal_specialities";
import rules from "anam-library/src/js/rules";
import {get_dict_formated_address_from_google_search} from "anam-library/src/js/address";

export default {
  name: "DialogNewProfessionnel",
  components: {
    AnTextField,
    AnSearchAddressGoogleAPI,
    AnTelInputVuetify,
  },
  props: {
    app_name: {
      type: String,
      required: true
    },
    dialog: {
      type: Boolean,
      default: false
    },
    service: {
      type: Object,
      default: () => {}
    },
    hf_name: {
      type: String,
      default: null
    },
    pro_to_edit: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    valid_form: false,
    rules: rules,
    first_name: null,
    last_name: null,
    sex: null,
    phone: null,
    email: null,
    type_idpp: null,
    items_idpp: [
      {text: 'N° RPPS', value: '8'},
      {text: 'N° ADELI', value: '0'}
    ],
    idpp: null,
    dmp_type: null,
    speciality_code: null,
    address: null,
    admin_service: false,
    dialog_confirm_creation: false,
    loading: false,
    error_msg: null,
    error: false,
    loading_pds_info: false
  }),
  computed: {
    _dialog: {
      get: function() {
        if (this.dialog && this.pro_to_edit) this.set_info_pro();
        return this.dialog;
      },
      set: function(val) {
        this.$emit('update:dialog', val);
      }
    },
    professions: function() {
      return ITEMS_PROFESSIONS_FOR_COORDINATION_NEW_PDS();
    },
    specialities: function() {
      return Object.values(get_speciality_med_only());
    },
    txt_idpp: function() {
      if(this.type_idpp === '0') return 'N° ADELI';
      else if(this.type_idpp === '8') return 'N° RPPS';
      else return 'Identifiant (RPPS, ADELI)';
    }
  },
  watch: {
    dialog: function(val) {
      if(!val) {
        this.last_name = null;
        this.first_name = null;
        this.sex = null;
        this.phone = null;
        this.email = null;
        this.type_idpp = null;
        this.idpp = null;
        this.dmp_type = null;
        this.speciality_code = null;
        this.address = null;
        this.admin_service = false;
        this.error = false;
        this.error_msg = null;
      }
    }
  },
  methods: {
    set_info_pro() {
      this.loading_pds_info = true;
      this.$http.get(`/api/professionals/${this.pro_to_edit.id}`).then(response => {
        let data = response.data;
        this.last_name = data.last_name;
        this.first_name = data.first_name;
        this.sex = data.personnephysique.sexe;
        this.email = data.email;
        this.phone = data.personnephysique.personel_phone;
        this.type_idpp = data.professionnel.typeIdNat_PP;
        this.idpp = data.professionnel.idpp;
        this.dmp_type = data.professionnel.dmp_type;
        this.speciality_code = data.professionnel.speciality_code;
        if (data.personnephysique.adresse) this.address = data.personnephysique.adresse;
      }).finally(() => {
        this.loading_pds_info = false;
      })
    },

    click_create_or_save() {
      if (this.pro_to_edit) this.save(false);
      else this.dialog_confirm_creation = true;
    },

    save: function(creation) {
      this.loading = true;
      this.error = false;
      this.error_msg = null;

      let data = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        personnephysique: {
          sexe: this.sex,
          adresse: get_dict_formated_address_from_google_search(this.address)
        },
        professionnel: {
          dmp_type: this.dmp_type,
          status: "Salarié"
        },
        service: {
          id: this.service.id,
          is_admin: this.admin_service
        }
      }

      if(creation) data["professionnel"]["company_name"] = [this.service.commercial_name];

      if(this.dmp_type === '10') data["professionnel"]["speciality_code"] = this.speciality_code;

      if(this.phone) data["personnephysique"]['personel_phone'] = this.phone;
      if(this.idpp) data["professionnel"]["idpp"] = this.idpp;
      if(this.type_idpp) data["professionnel"]["typeIdNat_PP"] = this.type_idpp;

      let api;
      if (this.pro_to_edit) api = this.$http.patch(`/api/coordination/professionals/${this.pro_to_edit.id}?app_name=${this.app_name}`, data);
      else api = this.$http.post(`/api/coordination/professionals?application=${this.app_name}`, data);

      api.then(response => {
        this._dialog = false;
        if (this.pro_to_edit) {
          this.$store.commit('PostSuccess', 'Les modifications ont été enregistrées');
          this.$emit('success_edit', response.data);
        }
        else {
          this.$store.commit('PostSuccess', 'Professionnel créé et ajouté à la structure');
          let data = response.data;
          data["manager_service"] = this.admin_service;
          this.$emit('success', response.data);
        }
      }).catch(error => {
        if(error.response) {
          if(error.response.status === 422) {
            this.error = true;
            if(Object.keys(error.response.data).includes('email')) this.error_msg = "Cet email est déjà utilisé, vous ne pouvez pas créer un professionnel avec cet email."
            else this.error_msg = 'Un ou plusieurs champs sont invalides.'
          }
        }
      }).finally(() => {
        this.dialog_confirm_creation = false;
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>