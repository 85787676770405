<template>
  <v-btn color="bg_primary" class="mb-1" elevation="0" small @click="$emit('action')" block>
    <v-icon class="mr-sm-1" small color="primary">mdi-account</v-icon>
    <span class="medium--text primary--text">Fiche utilisateur</span>
  </v-btn>
</template>

<script>
export default {
  name: "ActionItem",
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped>

</style>