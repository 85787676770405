<template>
  <v-row class="mx-0">
    <v-col cols="12" sm="8" offset-sm="2">
      <v-card class="rounded-lg elevation-0">
        <v-card-title class="white--text medium--text font-size--24" :style="`background-color: ${color}`">
          Demande de médecin traitant
        </v-card-title>
        <v-card-text class="mt-4 mt-sm-8">
          <p v-if="!global_end_txt" class="black--text font-size--18 medium--text mb-6">
            Votre demande a bien été prise en compte et votre nom a été intégré à la liste des patients en recherche de
            médecins traitants. Cette démarche ne vous empêche pas de poursuivre les recherches de votre côté.
            <br>
            <br>
            Par transparence : nous avons fait le choix de prioriser les patients âgés, ayant des pathologies chroniques,
            qui sans suivi, risquent une dégradation de leur état de santé.
            <br>
            <br>
            Dans l’attente de trouver un médecin traitant, lorsque vous avez besoin de consulter, vous pouvez en parler
            à un professionnel de santé du territoire. Plusieurs secrétariats, pharmaciens et infirmiers du territoire
            ont accès à des créneaux disponibles, vers lesquels vous orienter lorsque vous avez besoin d’être vu
            rapidement (en moins de 48h).
            <br>
            <br>
            Lorsqu’il s’agit d’une urgence, avant de vous rendre à l’hôpital, appelez le 15.
            <br>
            <br>
            {{ end_specific_txt }}
          </p>
          <p v-else v-html="global_end_txt" class="black--text font-size--18 medium--text mb-6"></p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {get_element_waiting_list} from "coordination-library/src/modules/waiting_list";

export default {
  name: "PatientWaitingListEndView",
  computed: {
    color: function() {
      return get_element_waiting_list("color", this.$store.getters.getHfPublicTemplateName);
    },
    end_specific_txt: function() {
      return get_element_waiting_list("end_specific_txt", this.$store.getters.getHfPublicTemplateName);
    },
    global_end_txt: function() {
      return get_element_waiting_list("global_end_txt", this.$store.getters.getHfPublicTemplateName);
    }
  }
}
</script>

<style scoped>

</style>