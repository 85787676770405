<template>
  <v-dialog v-model="_dialog" persistent width="600">
    <v-card>
      <v-card-title :class="`medium--text ${color_title}--text justify-center`">
        <v-icon :color="color_title" class="mr-4">mdi-hospital-building</v-icon>
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn icon text :color="color_title" @click="_dialog=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <p :class="`pb-5 font-size--16 ${color_txt}--text`">Ce service {{ is_creation ? 'sera' : 'est' }} rattaché à la structure '<span class="font-size--16 semibold--text">{{ hf_name }}</span>'.</p>
        <v-form v-model="valid_form">
          <AnTextField :value.sync="name" title="Nom de l'établissement" placeholder="Nom" icon="" type_field="text" title_color="primary" required/>

          <div class="pl-1 font-size--16 medium--text soft_blue--text">
            Type de service <v-icon color="red" size="8" class="mb-3">mdi-asterisk</v-icon>
          </div>
          <v-select v-model="of_type" :items="types_services" filled color="primary" :rules="[rules.required]" placeholder="Choix du type de service"></v-select>

          <div class="pl-1 font-size--16 medium--text primary--text">
            Adresse du service <v-icon color="red" size="8" class="mb-3">mdi-asterisk</v-icon>
          </div>
          <p v-if="!is_creation" class="pl-1 mt-2 font-size--16 primary--text">Adresse actuelle : {{ initial_address }}</p>
          <AnSearchAddressGoogleAPI ref="search_address" :address.sync="address" class_input="filled-input" placeholder="Rechercher une adresse"/>
        </v-form>
        <v-alert v-model="error_alert" text type="error" class="mt-6" transition="scale-transition">
          <span class="font-size--16 medium--text">{{ error_msg }}</span>
        </v-alert>
      </v-card-text>
      <v-card-actions class="justify-center py-8">
        <v-btn text color="primary" @click="_dialog=false">
          <v-icon small class="mr-2">mdi-close</v-icon>
          Annuler
        </v-btn>
        <v-btn elevation="0" color="green" class="white--text px-6" @click="save()" :disabled="!_valid_form" :loading="loading">
          <v-icon class="mr-sm-4">mdi-content-save</v-icon>
          {{ txt_save }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AnTextField from "anam-library/src/components/an-ui/textfield/AnTextField";
import AnSearchAddressGoogleAPI from "anam-library/src/components/an-ui/an-address/AnSearchAddressGoogleAPI";

import {get_items_services_for_select, get_service_type_name} from "anam-library/src/data/services";
import rules from "anam-library/src/js/rules";
import {get_dict_formated_address_from_google_search, get_formated_address} from "anam-library/src/js/address";
import axios from "axios";
import getCookie from "anam-library/src/js/common";

export default {
  name: "DialogCreateEditStructure",
  components: {
    AnTextField,
    AnSearchAddressGoogleAPI
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    hf_name: {
      type: String,
      default: null
    },
    item: {
      type: Object,
      default: () => {}
    },
    color_title: {
      type: String,
      default: 'primary'
    },
    color_txt: {
      type: String,
      default: 'primary_dark'
    }
  },
  data: () => ({
    valid_form: false,
    loading: false,
    rules: rules,
    name: null,
    of_type: null,
    address: null,
    initial_address: null,
    error_alert: false,
    error_msg: null
  }),
  computed: {
    _dialog: {
      get: function() {
        return this.dialog;
      },
      set: function(val) {
        this.$emit('update:dialog', val);
      }
    },
    _valid_form: function() {
      return this.valid_form && (this.address !== null || this.initial_address !== null);
    },
    hf_id: function() {
      return this.$route.params.id;
    },
    types_services: function() {
      return get_items_services_for_select();
    },
    is_creation: function() {
      return Object.keys(this.item).length === 0
    },
    title: function() {
      if(this.is_creation) return 'Créer un service';
      else return "Éditer le service";
    },
    txt_save: function() {
      if(this.creation) return 'Créer';
      else return 'Enregistrer';
    }
  },
  watch: {
    _dialog: function(val) {
      if(!val) {
        this.loading = false;
        this.name = null;
        this.of_type = null;
        this.address = null;
        this.$refs.search_address.reset_search();
        this.error_alert = false;
        this.error_msg = null;
        this.initial_address = null;
      } else {
        if(!this.is_creation) {
          let tmp_name = this.item.commercial_name;
          if(tmp_name.includes(get_service_type_name(this.item.of_type))) {
            this.name = tmp_name.replace(`- ${get_service_type_name(this.item.of_type)}`, '');
          } else {
            this.name = tmp_name;
          }
          this.of_type = this.item.of_type;
          this.initial_address = get_formated_address(this.item.address);
        }
      }
    }
  },
  methods: {
    save: function() {
      if(this.is_creation) this.create();
      else this.update();
    },
    create: function() {
      this.loading = true;
      this.error_alert = false;
      this.error_msg = null;

      let data = {
        commercial_name: `${this.name} - ${get_service_type_name(this.of_type)}`,
        of_type: this.of_type,
        health_facility: this.hf_id,
        address: get_dict_formated_address_from_google_search(this.address),
        managers: [],
      }

      axios.post(`/api/hf-coordination/${this.hf_id}/services`, data, {headers: {"X-CSRFToken": getCookie('csrftoken')}}).then(response => {
        this.$store.commit('PostSuccess', 'Service créé');
        this.$emit('saved', response.data);
        this._dialog = false;
      }).catch(error => {
        this.manage_errors(error);
      }).finally(() => {
        this.loading = false;
      })
    },
    update: function() {
      this.loading = true;
      this.error_alert = false;
      this.error_msg = null;

      let data = {
        commercial_name: `${this.name} - ${get_service_type_name(this.of_type)}`,
        of_type: this.of_type,
      }

      if(this.address) data['address'] = get_dict_formated_address_from_google_search(this.address);

      axios.patch(`/api/hf-coordination/${this.hf_id}/services/${this.item.id}`, data, {headers: {"X-CSRFToken": getCookie('csrftoken')}}).then(response => {
        this.$store.commit('PostSuccess', 'Service modifié');
        this.$emit('saved', response.data);
        this._dialog = false;
      }).catch(error => {
        this.manage_errors(error)
      }).finally(() => {
        this.loading = false;
      })
    },
    manage_errors: function(error) {
      if(error.response) {
        this.$store.dispatch('ManageErrorLight', error.response.data);
        if(error.response.status === 422) {
          this.error_alert = true;
          if(Object.keys(error.response.data).includes('commercial_name')) {
            this.error_msg = "Le nom de ce service existe déjà sur Coordination, vous ne pouvez pas nommer ce service avec ce nom.";
          } else {
            this.error_msg = `Un champs ci-dessus est invalide, nous ne pouvons pas ${this.is_creation ? 'créer' : 'modifier'} le service`;
          }
          return;
        }
      }
      this.$store.commit('PostCommonError');
      this._dialog = false;
    }
  }
}
</script>

<style scoped>

</style>