export const ITEMS_MOTIF_KINE = [
    "Suivi SNP",
    "Rééducation post-opératoire programmée",
    "Rééducation post-opératoire non-programmée",
    "Troubles musculo-squelettiques avec arrêt de travail",
    "Troubles musculo-squelettique sans arrêt de travail",
    "Kinésithérapie traumatologique avec arrêt de travail",
    "Kinésithérapie traumatologique sans arrêt de travail",
    "Kinésithérapie respiratoire",
    "Autre"
]
