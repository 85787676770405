import mixin_calendar from "anam-library/src/js/mixin_calendar";
import moment from "moment-timezone";

export default {
    mixins: [mixin_calendar],
    methods: {
        get_event_full(event) {
            for (let el in event.constraints) {
                if (parseInt(event.constraints[el]) !== parseInt(event.registered[el])) return false;
            }
            return true;
        },
        get_event_color(event) {
            if(event.type === 'teleconsultation') return "teal"

            if(event.slot_available) {
                if (moment(event.start_slot).isBefore(moment(), "minute")) return "grey lighten-3";

                if(event.ressource_consultations_full || event.delai_preavis_patient || event.visibility_patient || event.is_active === false) return 'grey lighten-3'
                else if(event.ressource_full) {
                    if(event.is_sas_calendar) return 'purple lighten-3';
                    else return 'primary';
                }
                else return 'grey lighten-3'
            }
            if (event.is_active === false) return "grey";
            if (event.already_registered) {
                if(event.is_sas_calendar) return "pink accent-4";
                else return 'secondary'
            }

            if(this.get_event_full(event)) {
                if(event.status) {
                    if(event.status === 1) return 'amber';
                    else if(event.status === 2) return 'red';
                    else return "green darken-4"
                }
                else {
                    if(event.is_sas_calendar) {
                        if(event.from_sas) return "#00B1F2";
                        else return "#662E9B";
                    }
                    else return "green"
                }
            } else {
                if(event.is_sas_calendar) return "purple lighten-3";
                else return "primary"
            }
        }
    }
}