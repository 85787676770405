<template>
  <v-select v-if="!phone_format"
            v-model="status"
            :items="items_status"
            rounded dense
            hide-details
            class="select_status"
            :background-color="get_bg_color()" :item-color="get_color()" :color="get_color()"
            :disabled="loading"
            @change="patch_status()">
  </v-select>
</template>

<script>
import {get_data_waiting_list, MAP_STATUS} from "coordination-library/src/modules/waiting_list";

export default {
  name: "WaitingListStatusItem",
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    variable: {
      type: String,
      default: null
    },
    tab_name: {
      type: String,
      default: null
    },
    phone_format: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    map_status: MAP_STATUS,
    status: null,
  }),
  computed: {
    data_waiting_list: function() {
      return get_data_waiting_list(this.item.waiting_list_of_type);
    },
    items_status: function() {
      let available_status = this.data_waiting_list.available_status;
      if(available_status) return this.map_status.filter(x => available_status.includes(x.value));
      return [];
    }
  },
  watch: {
    item: function(val) {
      if(val) this.status = this.item.patient_service_waiting_list.status;
    },
  },
  created() {
    this.status = this.item.patient_service_waiting_list.status;
  },
  methods: {
    patch_status: function() {
      let patient_service = this.item.patient_service_waiting_list;
      this.loading = true;
      let data = {status: this.status};
      this.$http.patch(`/api/patient_services/${patient_service.id}`, data).then(() => {
        this.$store.commit("PostSuccess", "Modification enregistrée");
      }).finally(() => {
        this.loading = false;
      })
    },
    get_bg_color: function() {
      switch (this.item.patient_service_waiting_list.status) {
        case 1:
          return 'red darken-2'
        case 2:
          return 'amber darken-2'
        case 3:
        case 6:
        case 7:
          return 'light-green darken-1'
        case 4:
          return 'blue-grey lighten-2'
        case 5:
        case 8:
          return 'blue lighten-1'
        default:
          return ''
      }
    },
    get_color: function() {
      switch (this.item.patient_service_waiting_list.status) {
        case 1:
          return 'red'
        case 2:
          return 'orange'
        case 3:
        case 6:
          return 'green'
        case 4:
          return 'blue-grey'
        case 5:
          return 'blue'
        default:
          return ''
      }
    }
  }
}
</script>

<style>
.select_status .v-select__selection{
  color: white;
}
.select_status .v-input__slot{
  padding-left: 12px !important;
  padding-right: 10px !important;
}
</style>