import { render, staticRenderFns } from "./CoDialogAddTag.vue?vue&type=template&id=c87ac0fe&scoped=true"
import script from "./CoDialogAddTag.vue?vue&type=script&lang=js"
export * from "./CoDialogAddTag.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../inzeecare/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c87ac0fe",
  null
  
)

export default component.exports