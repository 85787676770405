<template>
  <v-row class="ma-0">
    <v-col cols="12" class="py-0">
      <AnBtnActions :actions="actions"
                    :row_actions="false"
                    :loading="loading_action"
                    :large="false"
                    color_btn="green"
                    icon_btn="mdi-dots-grid"
                    v-on:action="mass_action($event)"/>
    </v-col>
    <v-col cols="12" sm="6" md="3">
      <p class="mb-0 font-size--12 primary_dark--text medium--text">Filtrer par profession</p>
      <v-autocomplete v-model="filter_profession"
                      :items="items_professions"
                      :filter="filter_search"
                      solo
                      dense
                      hide-details
                      clearable
                      placeholder="Sélectionnez"
                      no-data-text="Aucune donnée"/>
    </v-col>
    <v-col cols="12" sm="6" md="3">
      <p class="mb-0 font-size--12 primary_dark--text medium--text">Filtrer par spécialité</p>
      <v-autocomplete v-model="filter_specialities"
                      :items="items_specialities"
                      :filter="filter_search"
                      solo
                      dense
                      hide-details
                      clearable
                      placeholder="Sélectionnez"
                      no-data-text="Aucune donnée"/>
    </v-col>
    <v-col cols="12" sm="6" md="3">
      <p class="mb-0 font-size--12 primary_dark--text medium--text">Filtrer par acte</p>
      <v-autocomplete v-model="filter_actes"
                      :items="items_actes"
                      solo
                      dense
                      hide-details
                      clearable
                      placeholder="Sélectionnez"
                      no-data-text="Aucune donnée"/>
    </v-col>
    <v-col cols="12" sm="6" md="3">
      <p class="mb-0 font-size--12 primary_dark--text medium--text">Filtrer par groupe</p>
      <v-autocomplete v-model="filter_groups"
                      :items="items_groups"
                      solo
                      dense
                      hide-details
                      clearable
                      placeholder="Sélectionnez"
                      no-data-text="Aucune donnée"/>
    </v-col>
    <v-col cols="12">
      <AnDataTable :headers="headers"
                   :items="filter_professionals"
                   :loading="loading"
                   :table_items_mapping="table_items_mapping"
                   :search="search"
                   :option_selection="true"
                   :items_selected.sync="pros_selected"
                   rounded="rounded-lg"
                   elevation="0"/>
    </v-col>

    <DialogSendEmail :dialog.sync="dialog_send_email"
                     :ids_send_email="ids_send_action"
                     v-on:update_mass_action="pros_selected=[];ids_send_action=[]"/>

    <DialogSendNotification :dialog.sync="dialog_send_notif"
                            :ids_send_notif="ids_send_action"
                            v-on:update_mass_action="pros_selected=[];ids_send_action=[]"/>

    <DialogSendSms :dialog.sync="dialog_send_sms"
                   :ids_users="ids_send_action"
                   :signature="hf_name"
                   v-on:update_mass_action="pros_selected=[];ids_send_action=[]"/>
  </v-row>
</template>

<script>
import AnDataTable from "anam-library/src/components/an-data-table/AnDataTable.vue";
import BasicItem from "anam-library/src/components/an-data-table/an-table-items-components/BasicItem.vue";
import DmpProItem from "anam-library/src/components/an-data-table/an-table-items-components/DmpProItem.vue";
import SpecialityProItem from "anam-library/src/components/an-data-table/an-table-items-components/SpecialityProItem.vue";
import DateTimeItem from "anam-library/src/components/an-data-table/an-table-items-components/DateTimeItem.vue";
import ContactItem from "@/components/manager/structures/details/communication/items/ContactItem.vue";
import DialogSendEmail from "anam-library/src/components/an-dialogs/DialogSendEmail.vue";
import DialogSendNotification from "anam-library/src/components/an-dialogs/DialogSendNotification.vue";
import DialogSendSms from "anam-library/src/components/an-dialogs/DialogSendSms.vue";

import {clean_txt} from "anam-library/src/js/utils";
import {MAP_PROFESSIONS_COORDINATION} from "anam-library/src/data/professions";
import {get_items_specialities_med} from "anam-library/src/data/professionnal_specialities";
import AnBtnActions from "anam-library/src/components/an-dashboard/an-dashboard-toolbar/buttons/AnBtnActions.vue";

export default {
  name: "ManagerCommunicationTab",
  components: {
    DialogSendNotification, DialogSendEmail, DialogSendSms,
    AnBtnActions,
    AnDataTable,
  },
  props: {
    members: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    hf_name: {
      type: String,
      default: null
    }
  },
  data: () => ({
    headers: [
      {text: "", value: "data-table-select", class: "primary white--text rounded-tl-lg"},
      {text: "Nom & Prénom", value: "name", class: "primary white--text"},
      {text: "Contact", value: "contact", class: "primary white--text", align: "center"},
      {text: "Code Postal", value: "zipcode", class: "primary white--text"},
      {text: "Profession", value: "dmp_type", class: "primary white--text"},
      {text: "Spécialité", value: "speciality_code", class: "primary white--text"},
      {text: "Compétence", value: "competence", class: "primary white--text"},
      {text: "Groupe", value: "groupe", class: "primary white--text"},
      {text: "Dernière connexion", value: "last_login", class: "primary white--text rounded-tr-lg"},
    ],
    table_items_mapping: {
      name: BasicItem,
      contact: ContactItem,
      zipcode: BasicItem,
      dmp_type: DmpProItem,
      speciality_code: SpecialityProItem,
      competence: BasicItem,
      groupe: BasicItem,
      last_login: DateTimeItem,
    },
    search: null,
    filter_profession: null,
    filter_specialities: null,
    filter_actes: null,
    items_actes: [],
    filter_groups: null,
    items_groups: [],
    pros_selected: [],
    actions: [
      {text: "Envoyer un email", value: "send_email"},
      {text: "Envoyer une notification", value: "send_notif"},
      {text: "Envoyer un sms", value: "send_sms"},
    ],
    loading_action: false,
    dialog_send_email: false,
    dialog_send_notif: false,
    dialog_send_sms: false,
    ids_send_action: []
  }),
  computed: {
    professionals: function() {
      let tmp = JSON.parse(JSON.stringify(this.members.filter(x => x.status === 2)));
      return tmp.map(el => {
        el.name = `${el.last_name.toUpperCase()} ${el.first_name}`;
        el.phone = el.personnephysique ? el.personnephysique.personel_phone : null;
        el.dmp_type = el.professionnel.dmp_type;
        el.speciality_code = el.professionnel.speciality_code;
        el.zipcode = el.personnephysique ? (el.personnephysique.adresse ? el.personnephysique.adresse.codePostal : null) : null;
        return el;
      })
    },
    filter_professionals: function() {
      let tmp = this.professionals;
      if(this.filter_profession) tmp = tmp.filter(x => x.dmp_type === this.filter_profession);
      if(this.filter_specialities) tmp = tmp.filter(x => x.speciality_code === this.filter_specialities);
      return tmp;
    },
    items_professions: function() {
      return MAP_PROFESSIONS_COORDINATION(false, 0);
    },
    items_specialities: function() {
      return get_items_specialities_med();
    }
  },
  methods: {
    filter_search(item, queryText, itemText) {
      if (clean_txt(itemText).includes(clean_txt(queryText))) return item;
    },
    mass_action: function(action) {
      this.loading_action = true;
      if(this.pros_selected.length === 0) {
        this.$store.commit("PostInformation", "Aucun utilisateur n'est sélectionné");
        this.loading_action = false;
      } else {
        let tmp_users = [];
        for(let el of this.pros_selected) {
          tmp_users.push(el.id);
        }
        this.ids_send_action = tmp_users;

        if(action === "send_email") this.dialog_send_email = true;
        else if(action === "send_notif") this.dialog_send_notif = true;
        else if(action === "send_sms") this.dialog_send_sms = true;
        this.loading_action = false;
      }
    }
  }
}
</script>

<style scoped>

</style>