<template>
  <CoActionFromEmailView :src_img="src_img" width_img="30%"/>
</template>

<script>
import CoActionFromEmailView from "coordination-library/src/components/patient/cercle-de-soin/CoActionFromEmailView";
import img from "@/assets/logo.png"

export default {
  name: "ActionFromEmailView",
  components: {
    CoActionFromEmailView
  },
  data: () => ({
    src_img: img
  })
}
</script>