export const mixin_protocol = {
    props: {
        questionnaire: {
          type: Object,
          default: () => {}
        },
        history_mode: {
          type: Boolean,
          default: true
        }
    },
    data: () => ({
        professional_delegating_infos: {},
        prescription_selected: null
    }),
    computed: {
        patient_id: function() {
            if(this.questionnaire.patient) return this.questionnaire.patient.toString();
            return null;
        },
        patient_infos: function(){
            return this.questionnaire.patient_infos
        },
        questionnaire_id: function() {
          if(this.questionnaire) return this.questionnaire.id.toString();
          return null;
        }
    },
    watch: {
        questionnaire: function(val) {
            if(val) this.set_questionnaire_infos();
        }
    },
    created() {
        if(this.questionnaire) this.set_questionnaire_infos();
    },
    methods: {
        protocol_has_tag: function(of_type) {
            if(this.questionnaire.tags) {
                let index = this.questionnaire.tags.findIndex(x => x.meta_indicator.of_type === of_type);
                return index > -1;
            }
            return false;
        },
        get_score_value: function(of_type) {
            if(this.questionnaire.scores && (this.questionnaire.scores[of_type] || this.questionnaire.scores[of_type] === 0)) {
                return this.questionnaire.scores[of_type];
            }
            return null;
        },
        set_questionnaire_infos: function() {
            if(this.questionnaire.professional_delegating_infos) this.professional_delegating_infos = this.questionnaire.professional_delegating_infos;
            this.prescription_selected = this.questionnaire.prescription_selected;
        },
        patch_prescription: function() {
            let data = {prescription_selected: this.prescription_selected};
            this.$http.patch(`/api/patients/${this.patient_id}/questionnaires-tags/${this.questionnaire.uid}`, data).then(response => {
                this.$store.commit("PostSuccess", "Sélection enregistrée");
                this.questionnaire.prescription_selected = response.data.prescription_selected;
            })
        },
    }
}