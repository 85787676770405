<template>
  <v-card class="rounded-lg">
    <v-card-text>
      <v-row class="mx-0">
        <v-col cols="12" sm="4">
          <p class="align-self-center primary--text semibold--text font-size--16 mb-0">
            <v-icon color="primary" class="mr-1">mdi-tune-variant</v-icon>
            Choisir le protocole :
          </p>
          <v-autocomplete v-model="filter_protocol"
                          :items="protocols_items"
                          :loading="loading"
                          no-data-text="Aucun résultat"
                          hide-details
                          @change="set_statistics()"/>
        </v-col>
        <v-col cols="12" sm="5">
          <p class="align-self-center primary--text semibold--text font-size--16 mb-0">
            <v-icon color="primary" class="mr-1">mdi-tune-variant</v-icon>
            Filtrer par période :
          </p>
          <v-form v-model="valid_form_filter_date" :class="$vuetify.breakpoint.smAndUp ? 'd-flex' : ''">
            <AnDate :date="filter_date_start"
                    :filled="false"
                    reset_icon
                    label="Début période"
                    v-on:formatted_date="filter_date_start = $event"
                    v-on:date_reset="filter_date_start = null"/>

            <AnDate :date="filter_date_end"
                    :filled="false"
                    reset_icon
                    class="ml-sm-8"
                    label="Fin période"
                    v-on:formatted_date="filter_date_end = $event"
                    v-on:date_reset="filter_date_end = null"/>
          </v-form>
        </v-col>
        <v-col cols="12" sm="3" class="align-self-center text-center">
          <AnExportBtn :loading="loading_export"
                       v-on:generate_csv="generate_csv()"
                       v-on:generate_xlsx="generate_xlsx()"/>
        </v-col>

        <v-col cols="12" v-if="loading" class="text-center">
          <v-progress-circular indeterminate color="primary_pastel"></v-progress-circular>
        </v-col>
        <v-col cols="12" v-else-if="!filter_protocol">
          <p class="text-center font-size--18 semibold--text">Séléctionnez un protocole pour voir les statistiques.</p>
        </v-col>

        <template v-else>
          <v-col cols="4">
            <v-progress-circular v-if="loading_stats" indeterminate color="primary_pastel"></v-progress-circular>
            <apex-chart width="100%" type="pie" :series="series_questionnaires" :options="options_questionnaires"/>
          </v-col>

          <StatsProtocolPrescriptionsTable v-if="selected_protocol_is_has"
                                           :protocol_name="selected_protocol_name"
                                           :loading="loading"
                                           :protocols="protocols_accomplished"/>
        </template>
        <template v-if="filter_protocol === 'protocol_osteoporose' || filter_protocol === 'protocol_osteoporose_ariege_pyrenees'">
          <v-col cols="12" sm="4">
            <p class="semibold--text primary_dark--text font-size--24">
              Ostéodensitométrie
            </p>
            <apex-chart width="100%" type="pie" :series="series_ci" :options="options_ci"/>
          </v-col>
          <v-col cols="12" sm="4">
            <p class="semibold--text primary_dark--text font-size--24">
              Suivi
            </p>
            <apex-chart width="100%" type="pie" :series="series_suivi" :options="options_suivi"/>

            <div class="text-center mt-6">
              <v-btn text class="primary" @click="export_patients_osteo_with_consent()" :loading="loading_xlsx">
                <v-icon class="mr-2">mdi-download</v-icon>
                <span class="medium--text text-decoration-underline">Exporter la liste de patients consentant au suivi</span>
              </v-btn>
            </div>
          </v-col>
        </template>

        <template v-if="selected_protocol_is_has">
          <v-col cols="12" class="my-8">
            <p class="mb-0 semibold--text primary_dark--text font-size--24 align-self-center">
              {{ selected_protocol_name }} - Satisfaction Délégués
            </p>
            <v-progress-linear v-if="loading_stat_satisfaction" color="primary_pastel" class="mt-5" indeterminate></v-progress-linear>
            <v-row v-else class="mx-0">
              <v-col cols="4">
                <apex-chart width="100%" type="pie" :series="series_questionnaires_pros_delegues" :options="options_questionnaires_pros"/>
              </v-col>
              <v-col cols="4">
                <apex-chart width="100%" type="pie" :series="series_questionnaires_pros_scores_delegues" :options="options_questionnaires_pros_scores"/>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="my-8">
            <p class="mb-0 semibold--text primary_dark--text font-size--24 align-self-center">
              {{ selected_protocol_name }} - Satisfaction Délégants
            </p>
            <v-progress-linear v-if="loading_stat_satisfaction" color="primary_pastel" class="mt-5" indeterminate></v-progress-linear>
            <v-row v-else class="mx-0">
              <v-col cols="4">
                <apex-chart width="100%" type="pie" :series="series_questionnaires_pros_delegants" :options="options_questionnaires_pros"/>
              </v-col>
              <v-col cols="4">
                <apex-chart width="100%" type="pie" :series="series_questionnaires_pros_scores_delegants" :options="options_questionnaires_pros_scores"/>
              </v-col>
            </v-row>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import AnDate from "anam-library/src/components/an-date/AnDate";
import StatsProtocolPrescriptionsTable from "./protocols/StatsProtocolPrescriptionsTable";

import {get_profession_name} from "anam-library/src/data/professions";
import {get_formatted_date} from "anam-library/src/js/dates";
import {get_period_text_for_export} from "coordination-library/src/js/utils";
import {get_ordonnance_name_from_type} from "coordination-library/src/data/protocoles";
import AnExportBtn from "anam-library/src/components/an-export/AnExportBtn"
import {generateXlsx} from "anam-library/src/js/export_xlsx";
import {generate_csv} from "anam-library/src/js/export_file";

import moment from "moment-timezone";


export default {
  name: "ManagerStatisticsCardProtocoles",
  components: {
    StatsProtocolPrescriptionsTable,
    AnDate,
    AnExportBtn,
  },
  props: {
    hf_coordination_id: {
      type: String,
      required: true
    },
    load_data: {
      type: Boolean,
      default: false
    },
    hf_name: {
      type: String,
      default: null
    },
    hf_finess: {
      type: String,
      default: null
    },
    data_protocols: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    is_loaded: false,
    loading: false,
    loading_stats: false,
    filter_protocol: null,
    valid_form_filter_date: false,
    filter_date_end: null,
    filter_date_start: null,
    series_questionnaires: [],
    protocols: [],
    loading_export: false,
    loading_xlsx: false,
    loading_stat_satisfaction: false,
    questionnaires_pros: [],
    series_questionnaires_pros_delegues: [],
    series_questionnaires_pros_delegants: [],
    series_questionnaires_pros_scores_delegues: [],
    series_questionnaires_pros_scores_delegants: [],
  }),
  computed: {
    headers_export: function() {
      let tmp = [
        "Nom du protocole", "Année", "Mois", "Date de lancement du protocole",
        "Nom du délégué", "Prénom du délégué", "Profession du délégué", "N° RPPS du délégué",
        "Nom du délégant", "Prénom du délégant", "N° RPPS du délégant", "Profession du délégant",
        "Type de délivrance", "Identifiant patient", "Couverture sociale", "Date de déclaration des protocoles à l'ARS",
        "FINESS structure", "Nom structure"
      ]
      if(this.selected_protocol && this.selected_protocol.meta_protocol.name === 'protocol_orientation_gex') {
        tmp.push("Orientation vers");
      }
      return tmp;
    },
    protocols_items: function() {
      let tmp = []
      for(let el of this.protocols) {
        tmp.push({text: el.meta_protocol.commercial_name, value: el.meta_protocol.name});
      }
      return tmp;
    },
    selected_protocol: function() {
      if(this.filter_protocol) {
        let index = this.protocols.findIndex(x => x.meta_protocol.name === this.filter_protocol);
        if(index > -1) return this.protocols[index];
      }
      return null
    },
    selected_protocol_name: function() {
      if(this.selected_protocol) return this.selected_protocol.meta_protocol.commercial_name;
      return null;
    },
    selected_protocol_is_has: function() {
      if(this.selected_protocol) return this.selected_protocol.meta_protocol.is_has_protocol;
      return false;
    },
    questionnaires_filtered: function() {
      let tmp = JSON.parse(JSON.stringify(this.data_protocols.filter(x => !x.closed)));
      tmp = tmp.filter(x => x.protocol === this.filter_protocol);
      if(this.filter_date_start) {
        let filter_start = moment(get_formatted_date(this.filter_date_start, {input_format: "DD/MM/YYYY", output_format: "YYYY-MM-DD"}));
        tmp = tmp.filter(x => moment(x.creation_date).isSameOrAfter(filter_start));
      }
      if(this.filter_date_end) {
        let filter_end = moment(get_formatted_date(this.filter_date_end, {input_format: "DD/MM/YYYY", output_format: "YYYY-MM-DD"}));
        tmp = tmp.filter(x => moment(x.creation_date).isSameOrBefore(filter_end));
      }
      return tmp;
    },
    questionnaires_pros_filtered: function() {
      let tmp = JSON.parse(JSON.stringify(this.questionnaires_pros));
      tmp = tmp.filter(x => x.meta_protocol_id === this.selected_protocol.meta_protocol.id);
      if(this.filter_date_start) {
        let filter_start = moment(get_formatted_date(this.filter_date_start, {input_format: "DD/MM/YYYY", output_format: "YYYY-MM-DD"}));
        tmp = tmp.filter(x => moment(x.creation_date).isSameOrAfter(filter_start));
      }
      if(this.filter_date_end) {
        let filter_end = moment(get_formatted_date(this.filter_date_end, {input_format: "DD/MM/YYYY", output_format: "YYYY-MM-DD"}));
        tmp = tmp.filter(x => moment(x.creation_date).isSameOrBefore(filter_end));
      }
      return tmp;
    },
    protocols_accomplished: function() {
      return this.questionnaires_filtered.filter(x => x.nb_tags > 0);
    },
    options_questionnaires: function() {
      let labels = ["Nombre de patients réorientés vers un médecin généraliste", "Nombre de patients pris en charge par le délégué"];
      if (this.filter_protocol === "protocol_bpco") {
        labels = ["Pas de test de souffle recommandé", "Test de souffle effectué et FEV1/FEV6 >= 0,70", "Test de souffle effectué et FEV1/FEV6 < 0,70"]
      } else if(this.filter_protocol === "protocol_obesite") {
        labels = ["Parcours obésité en ville", "Parcours obésité à l'hôpital"]
      } else if(this.filter_protocol === "protocol_osteoporose" || this.filter_protocol === "protocol_osteoporose_ariege_pyrenees") {
        labels = ["Nombre de patients n'ayant aucun facteur de risque", "Nombre de patients ayant un ou plusieurs facteurs de risque"];
      } else if(this.filter_protocol === "protocol_ic_gresivaudan") {
        labels = [
          "Nombre de patient exclus",
          "Nombre de patients adressés à un MT",
          "Nombre de patients orientés vers un MT",
          "Nombre de patients orientés vers le 15/urgences",
          "Nombre de patients adressés à un MG + CPTS",
          "Nombre de patients orientés vers un MG + CPTS",
        ]
      }
      return {
        labels: labels,
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#2B908F'],
        legend: {position: 'bottom'},
        noData: {text: 'Aucune donnée'},
        dataLabels: {
          formatter: function(val ,opts) {
            return opts.w.config.series[opts.seriesIndex];
          }
        }
      }
    },
    series_ci() {
      return [this.data_protocols.filter(x => x.ci_osteo === 0 && (x.protocol === "protocol_osteoporose" || x.protocol === "protocol_osteoporose_ariege_pyrenees") && !x.closed).length, this.data_protocols.filter(x => x.ci_osteo === 1 && (x.protocol === "protocol_osteoporose" || x.protocol === "protocol_osteoporose_ariege_pyrenees") && !x.closed).length];
    },
    options_ci() {
      return {
        labels: ["Nombre de patients pouvant réaliser une ostéodensitométrie", "Nombre de patients ayant une CI à l’ostéodensitométrie"],
        legend: {position: 'bottom'},
        noData: {text: 'Aucune donnée'},
        dataLabels: {
          formatter: function(val ,opts) {
            return opts.w.config.series[opts.seriesIndex];
          }
        }
      }
    },
    series_suivi() {
      return [this.data_protocols.filter(x => x.suivi_1_osteo === 1 && (x.protocol === "protocol_osteoporose" || x.protocol === "protocol_osteoporose_ariege_pyrenees") && !x.closed).length, this.data_protocols.filter(x => x.suivi_2_osteo === 1 && (x.protocol === "protocol_osteoporose" || x.protocol === "protocol_osteoporose_ariege_pyrenees") && !x.closed).length];
    },
    options_suivi() {
      return {
        labels: ["Nombre de patients consentant au suivi", "Nombre de patients refusant le suivi"],
        legend: {position: 'bottom'},
        noData: {text: 'Aucune donnée'},
        dataLabels: {
          formatter: function(val ,opts) {
            return opts.w.config.series[opts.seriesIndex];
          }
        }
      }
    },
    options_questionnaires_pros: function() {
      let labels = ["Répondus", "Non répondus"];
      return {
        labels: labels,
        colors: ['#00E396', '#008FFB'],
        legend: {position: 'bottom'},
        noData: {text: 'Aucune donnée'},
        dataLabels: {
          formatter: function(val ,opts) {
            return opts.w.config.series[opts.seriesIndex];
          }
        }
      }
    },
    options_questionnaires_pros_scores: function() {
      let labels = ["Insatisfait", "Peu satisfait", "Satisfait", "Très satisfait"];
      return {
        labels: labels,
        colors: ['#FF4560', '#FEB019', '#008FFB', '#00E396'],
        legend: {position: 'bottom'},
        noData: {text: 'Aucune donnée'},
        dataLabels: {
          formatter: function(val ,opts) {
            return opts.w.config.series[opts.seriesIndex];
          }
        }
      }
    },
  },
  watch: {
    load_data: function(val) {
      if(val && !this.is_loaded) this.get_statistics();
    },
    loading_protocols: function(val) {
      if(!val) this.get_statistics();
    },
    hf_coordination_id: function(val) {
      if(val) this.get_statistics();
    },
    filter_date_start: function() {
      this.set_statistics();
    },
    filter_date_end: function() {
      this.set_statistics();
    }
  },
  methods: {
    get_statistics: function() {
      this.get_protocols();
      this.get_questionnaires_satisfaction_pros()
    },
    get_protocols: function() {
      this.loading = true;
      this.$http.get(`/api/hf-coordination/${this.hf_coordination_id}/settings-protocols`).then(response => {
        this.protocols = response.data;
        if(this.protocols.length > 0) this.filter_protocol = this.protocols[0].meta_protocol.name;
        else this.filter_protocol = null;
        this.set_statistics_protocols();
        this.set_statistics_questionnaires_pros();
      }).finally(() => {
        this.loading = false;
        this.is_loaded = true;
      })
    },
    get_questionnaires_satisfaction_pros: function() {
      this.loading_stat_satisfaction = true;
      this.$http.get(`/api/hf-coordination/${this.hf_coordination_id}/questionnaires-pt-satisfaction-pro`).then(response => {
        this.questionnaires_pros = response.data;
        this.set_statistics_questionnaires_pros();
      }).catch(() => {
        this.loading_stat_satisfaction = false;
      })
    },
    set_statistics: function() {
      this.set_statistics_protocols();
      this.set_statistics_questionnaires_pros();
    },
    set_statistics_questionnaires_pros: function() {
      this.loading_stat_satisfaction = true;
      let tmp = JSON.parse(JSON.stringify(this.questionnaires_pros_filtered));
      let quest_delegues = tmp.filter(x => x.questionnaire_name === "coordination-pt-satisfaction-delegues");
      let quest_delegants = tmp.filter(x => x.questionnaire_name === "coordination-pt-satisfaction-delegants");

      this.series_questionnaires_pros_delegues = this.get_series_pros(quest_delegues);
      this.series_questionnaires_pros_scores_delegues = this.get_series_pros_scores(quest_delegues, 5);
      this.series_questionnaires_pros_delegants = this.get_series_pros(quest_delegants);
      this.series_questionnaires_pros_scores_delegants = this.get_series_pros_scores(quest_delegants, 4);
      this.loading_stat_satisfaction = false;
    },
    get_series_pros: function(questionnaires, ) {
      let total = questionnaires.length;
      let answered = questionnaires.filter(x => x.questionnaire_is_completed).length;
      return [answered, total-answered];
    },
    get_series_pros_scores: function(questionnaires, total_questions) {
      let tmp_quest_with_scores = JSON.parse(JSON.stringify(questionnaires.filter(x => x.questionnaire_is_completed)));
      tmp_quest_with_scores = tmp_quest_with_scores.map(el => {
        if(el.score_pt_global) el["average_score"] = Math.round(el.score_pt_global/total_questions);
        else el["average_score"] = null;
        return el;
      });

      let nb_score_0 = tmp_quest_with_scores.filter(x => x.average_score === 0);
      let nb_score_1 = tmp_quest_with_scores.filter(x => x.average_score === 1);
      let nb_score_2 = tmp_quest_with_scores.filter(x => x.average_score === 2);
      let nb_score_3 = tmp_quest_with_scores.filter(x => x.average_score === 3);
      return [nb_score_0.length, nb_score_1.length, nb_score_2.length, nb_score_3.length];
    },
    set_statistics_protocols: function() {
      this.loading_stats = true;

      if (this.filter_protocol === "protocol_bpco") {
        let test_and_fev_over = this.questionnaires_filtered.filter(x => x.nb_tags > 0 && x.nb_fev > 0).length;
        let test_and_fev_under = this.questionnaires_filtered.filter(x => x.nb_tags > 0 && x.nb_fev <= 0).length;
        let no_test = this.questionnaires_filtered.filter(x => x.nb_tags <= 0).length;
        this.series_questionnaires = [no_test, test_and_fev_over, test_and_fev_under];
      } else if (this.filter_protocol === "protocol_obesite") {
        let tags_obesite_ville = this.questionnaires_filtered.filter(x => x.nb_obesite_ville > 0).length;
        let tags_obesite_hopital = this.questionnaires_filtered.filter(x => x.nb_obesite_hopital > 0).length;
        this.series_questionnaires = [tags_obesite_ville, tags_obesite_hopital];
      } else if(this.filter_protocol === "protocol_ic_gresivaudan") {
        let protocoles_positives = this.questionnaires_filtered.filter(x => x.nb_tags > 0).length;
        let protocoles_gravite = this.questionnaires_filtered.filter(x => x.nb_ic_gresivaudan_gravite > 0).length;
        let protocoles_adresse_mt = this.questionnaires_filtered.filter(x => x.nb_ic_gresivaudan_adresse_mt > 0).length;
        let protocoles_oriente_mt = this.questionnaires_filtered.filter(x => x.nb_ic_gresivaudan_oriente_mt > 0).length;
        let protocoles_adresse_mg = this.questionnaires_filtered.filter(x => x.nb_ic_gresivaudan_adresse_mg > 0).length;
        let protocoles_oriente_mg = this.questionnaires_filtered.filter(x => x.nb_ic_gresivaudan_oriente_mg > 0).length;
        this.series_questionnaires = [
          (this.questionnaires_filtered.length - protocoles_positives),
          protocoles_adresse_mt,
          protocoles_oriente_mt,
          protocoles_gravite,
          protocoles_adresse_mg,
          protocoles_oriente_mg
        ];
      }
      else {
        let protocoles_positives = this.questionnaires_filtered.filter(x => x.nb_tags > 0).length;
        this.series_questionnaires = [(this.questionnaires_filtered.length - protocoles_positives), protocoles_positives];
      }
      this.loading_stats = false;
    },
    export_data: function() {
      this.loading_export = true;
      let data_xlsx = [];
      for (let item of this.questionnaires_filtered) {
        let name_protocol = "";
        let index_pt = this.protocols.findIndex(x => x.meta_protocol.name === item.protocol);
        if(index_pt > -1) name_protocol = this.protocols[index_pt].meta_protocol.commercial_name;

        let tmp = [
          name_protocol,
          get_formatted_date(item.creation_date, {output_format: "YYYY"}),
          get_formatted_date(item.creation_date, {output_format: "MMMM"}, true),
          get_formatted_date(item.creation_date),
          item.author_last_name,
          item.author_first_name,
          get_profession_name(item.author_dmp_type),
          item.author_idpp,
          item.professional_delegating ? item.delegating_last_name: '',
          item.professional_delegating ? item.delegating_first_name : "",
          item.professional_delegating ? item.delegating_idpp : "",
          item.professional_delegating ? get_profession_name(item.delegating_dmp_type) : "",
          item.prescription ? get_ordonnance_name_from_type(item.prescription) : "",
          item.patient_id,
          item.patient_social_welfare !== null ? (item.patient_social_welfare ? 'Oui' : 'Non') : 'Non renseigné',
          "",
          this.hf_finess || "",
          this.hf_name || "",
        ];
        if(this.selected_protocol && this.selected_protocol.meta_protocol.name === 'protocol_orientation_gex') {
          let tmp_data_pt = "";
          if(item.data_protocol && item.data_protocol.orientation) tmp_data_pt = item.data_protocol.orientation.value;
          tmp.push(tmp_data_pt);
        }
        data_xlsx.push(tmp);
      }
      this.loading_export = false;
      return data_xlsx
    },
    generate_csv: function() {
      generate_csv(this.export_data(), this.headers_export, "reporting_protocoles", false);
    },
    generate_xlsx: function() {
      generateXlsx(this.export_data(), this.headers_export, "reporting_protocoles", "reporting_protocoles");
    },
    export_patients_osteo_with_consent() {
      this.loading_xlsx = true;
      let params = {"questionnaires_id": []};
      for (let questionnaire of this.questionnaires_filtered.filter(x => x.suivi_1_osteo === 1 && (x.protocol === "protocol_osteoporose" || x.protocol === "protocol_osteoporose_ariege_pyrenees"))) {
        params.questionnaires_id.push(questionnaire.id);
      }
      this.$http.get(`/api/hf-coordination/${this.hf_coordination_id}/exports-protocoles-osteoporose`, {params: params}).then(response => {
        let headers = ["Date de complétion du protocole", "Professionnel déléguant", "Nom", "Prénom", "Date de naissance", "Commune de résidence", "N° de téléphone"];
        let data_xlsx = [];
        for (let data of response.data) {
          data_xlsx.push([
            data.completion_date,
            data.professional_delegating,
            data.patient_last_name,
            data.patient_first_name,
            data.birthdate,
            data.codePostal,
            data.phone_number
          ]);
        }

        generateXlsx(data_xlsx, headers, "Export protocole ostéoporose", `export-protocole-ostéoporose ${get_period_text_for_export(this.filter_date_start, this.filter_date_end)}`);
        this.loading_xlsx = false;
      }).catch(() => {
        this.loading_xlsx = false;
      })
    },
  }
}
</script>

<style scoped>

</style>