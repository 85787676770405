<template>
    <div>
        <v-expansion-panels hover flat accordion class="mt-3" v-model="panel">
            <v-expansion-panel>
                <v-expansion-panel-header class="pa-sm-5 pa-3">
                    <v-row no-gutters>
                        <v-col cols="2">
                            <img width="100%" src="@/assets/terresante.png"/>
                        </v-col>
                        <v-col cols="8" class="align-self-center">
                            <span class="soft_blue--text font-weight-bold pl-6"> Terr-eSanté </span>
                        </v-col>
                        <v-col cols="2" class="align-self-center" v-if="ts_button === 'see'">
                            <v-badge bordered :color="(notif_patient && notif_patient !== '0') ? '#b60f83': 'grey'" :content="notif_patient" overlap>
                                <v-icon>mdi-bell</v-icon>
                            </v-badge>
                        </v-col>
                    </v-row>

                </v-expansion-panel-header>
                <v-expansion-panel-content v-if="!$store.getters.getAuthTS" class="text-center pt-4">
                   <v-btn @click="dialog=true" color="rgb(51 182 231)" rounded outlined>
                       <v-icon class="mr-2">mdi-account</v-icon>
                       Se connecter
                   </v-btn>
                </v-expansion-panel-content>

                <v-expansion-panel-content v-else class="text-center pt-4">
                    <v-progress-circular indeterminate v-if="loading" color="secondary"></v-progress-circular>
                    <v-row v-if="ts_button === 'warning'">
                        <v-col cols="2" class="text-right">
                            <v-icon color="rgb(51 182 231)">mdi-alert</v-icon>
                        </v-col>
                        <v-col cols="10" class="text-left">
                            <span>Vous ne pouvez pas créer le dossier Terr-eSanté de ce patient.</span>
                        </v-col>
                        <v-col cols="12" class="text-left">
                            <span>Raison : {{ warning_txt }}</span>
                        </v-col>
                    </v-row>

                    <v-row v-else-if="ts_button === 'creation'">
                        <v-col cols="12">
                            <span>Vous pouvez créer le dossier Terr-eSanté de ce patient en cliquant sur ce bouton.</span>
                        </v-col>
                        <v-col cols="12">
                            <v-btn color="rgb(51 182 231)" rounded class="white--text" @click="access_to_terresante()">
                                <v-icon color="white" class="mr-2">mdi-plus</v-icon>
                                Créer Dossier
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row v-else-if="ts_button === 'see'">
                        <v-col cols="12">
                            <span>Vous pouvez consulter le dossier Terr-eSanté de ce patient en cliquant sur ce bouton.</span>
                        </v-col>
                        <v-col cols="12">
                            <v-btn color="rgb(51 182 231)" rounded class="white--text" @click="access_to_terresante()">
                                <v-icon color="white" class="mr-2">mdi-eye</v-icon>
                                Consulter Dossier
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row v-else-if="error_conexion_terresante">
                        <v-col cols="12">
                            <span>Nous n'arrivons pas à établir la connexion avec Terr-eSanté, merci de réessayer ultérieurement.</span>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-dialog v-model="dialog" persistent width="500">
            <v-card>
                <v-card-title class="secondary white--text medium--text">
                    Connexion à Terr-eSanté
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog=false">
                        <v-icon color="white">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row v-if="!sms_sent">
                        <v-col cols="12" class="mt-4">
                            <p class="normal--text font-size--16">
                                Afin de vous authentifier sur Terr-eSanté, nous allons vous envoyer un code par SMS au
                                <span class="semibold--text secondary--text font-size--16">{{ $store.state.user_infos.personnephysique.personel_phone }}</span>.
                            </p>
                            <p class="normal--text font-size--16">Cliquez sur le bouton ci-dessous pour recevoir le SMS.</p>
                        </v-col>
                        <v-col cols="12" class="text-center">
                            <v-btn color="soft_green" large class="px-6 white--text" rounded @click="send_sms()" :loading="loading_sms">
                                <v-icon color="white" class="mr-2">mdi-email-send</v-icon>
                                <span class="medium--text font-size--16">Envoyer le code</span>
                            </v-btn>
                            <br>
                            <v-btn text rounded color="secondary" class="mt-1" @click="sms_sent=true">
                                <span class="font-size--12 text-decoration-underline">J'ai déjà un code</span>
                            </v-btn>
                        </v-col>
                        <v-col cols="12" v-if="error_sms" class="text-center">
                            <p class="error--text font-size--16 medium--text">
                                Aucun numéro de téléphone n'est renseigné, nous ne pouvons pas vous envoyer un code d'authentification...
                            </p>
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col cols="12" class="mt-4">
                            <p class="normal--text font-size--16 mb-0">
                                Renseigner le code reçu par SMS ci-dessous :
                            </p>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="code_auth" label="Code d'authentification" filled color="secondary"></v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="error_auth" class="text-center">
                            <p class="error--text font-size--18 medium--text">{{ error_txt }}</p>
                            <v-btn v-if="can_send_new_sms" text color="secondary" rounded class="px-0" @click="sms_sent=false">
                                <span class="font-size--12 text-decoration-underline">Envoyer un nouveau code</span>
                            </v-btn>
                        </v-col>
                        <v-col cols="12" class="text-center">
                            <v-btn color="soft_green" large class="px-6 white--text" rounded :loading="loading_auth"
                                   :disabled="!code_auth"
                                   @click="validate_authentification()">
                                <span class="medium--text font-size--16">Valider</span>
                                <v-icon color="white" class="ml-2">mdi-check</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import axios from "axios"

    export default {
        name: "AuthTerresante",
        props: {
            is_mounted: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            dialog: false,
            panel: 0,
            sms_sent: false,
            can_send_new_sms: false,
            code_auth: null,
            loading_sms: false,
            loading_auth: false,
            ts_button: null,
            warning_txt: 'Non renseigné',
            notif_patient: null,
            loading: true,
            error_sms: false,
            error_auth: false,
            error_txt: '',
            error_conexion_terresante: false,
        }),
        watch: {
            dialog(val) {
                if(!val) {
                    this.error_auth = false;
                    this.error_txt = null;
                    this.code_auth = null;
                }
            },
            sms_sent(val) {
                if(!val) {
                    this.error_auth = false;
                    this.error_txt = null;
                    this.code_auth = null;
                }
            },
            is_mounted(val) {
                if(val) {
                    if(this.$store.getters.getAuthTS){
                        this.check_patient();
                    }
                }
            }
        },
      methods: {
            send_sms: function() {
                this.error_sms = false;
                if(this.$store.state.user_infos.personnephysique.personel_phone){
                    this.loading_sms = true;
                    axios.post('/api/terrisante/send-sms-authentification').then(() => {
                        this.sms_sent = true;
                    }).catch(() => {
                        this.error_sms = true;
                    }).finally(() => {
                        this.loading_sms = false;
                    })
                } else {
                    this.error_sms = true
                }
            },
            validate_authentification: function (){
                this.error_auth = false;
                if(this.code_auth){
                    this.loading_auth = true;
                    axios.post('/api/terrisante/check-authentification', {'code_auth': this.code_auth}).then(() => {
                        this.dialog = false;
                        this.$store.commit('setAuthTS', true)
                        this.check_patient();
                        this.is_existing_patient();
                    }).catch(error => {
                        if(error.response.status === 422){
                            this.error_txt = error.response.data['error']
                            this.error_auth = true;
                            if(error.response.data['expiration']) {
                                this.can_send_new_sms = true;
                                this.code_auth = null;
                            }
                        }
                    }).finally(() => {
                        this.loading_auth = false;
                    })
                } else {
                    this.error_txt = 'Aucun code n\'est renseigné'
                    this.error_auth = true;
                }
            },
            check_patient: function(){
                if(!this.$store.getters.getPatientInfosBirthdate('birth_zipcode')) {
                    this.ts_button = 'warning';
                    this.warning_txt = 'Le lieu de Naissance n\'est pas renseigné.';
                    this.loading = false;
                } else if(!this.$store.getters.getPatientInfosBirthdate('birth_country')) {
                    this.ts_button = 'warning';
                    this.warning_txt = 'Le pays de Naissance n\'est pas renseigné.';
                    this.loading = false;
                } else {
                    this.is_existing_patient();
                }
            },
            is_existing_patient: function() {
                axios.get('/api/terrisante/is_existing_patient/' + this.$store.state.patient_id).then(response => {
                    if (response.data === "1") {
                        this.ts_button = 'creation';
                    } else if (response.data === "0") {
                        this.ts_button = 'see'
                        this.get_notif_patient();
                    }
                }).catch(() => {
                    this.error_conexion_terresante = true;
                }).finally(() => {
                    this.loading = false
                })
            },
            access_to_terresante: function () {
                window.open('/api/terrisante/connexion/' + this.$store.state.patient_id, "_blank")
            },
            get_notif_patient: function() {
                axios.get('/api/terrisante/get_notification_count_patient/' + this.$store.state.patient_id).then(response => {
                    this.notif_patient = response.data
                })
            }
        }
    }
</script>

<style scoped>

</style>