<template>
  <span v-if="value || value === 0" :class="`semibold--text font-size--16 ${alert ? 'red--text' : ''}`">{{ value }}</span>
  <v-icon v-else small>mdi-minus</v-icon>
</template>

<script>
export default {
  name: "NbDelegatingTableItem",
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    variable: {
      type: String,
      default: null
    }
  },
  computed: {
    value: function() {
      return this.item[this.variable];
    },
    alert: function() {
      return this.value === 0;
    }
  }
}
</script>

<style scoped>

</style>