<template>
  <AnDrawer :drawer_value.sync="_drawer_value"
            :drawer_buttons="drawer_buttons"
            bg_drawer="primary_dark"
            bg_item_active="primary_pastel"
            color_divider="white">
    <template v-slot:append-list-item-primary-action>
      <DoctorDrawerMenuManage v-if="has_access_to_manage_cpts" :all_cpts="all_cpts"/>
    </template>
  </AnDrawer>
</template>

<script>
import AnDrawer from "anam-library/src/components/an-header/an-drawer/AnDrawer";
import DoctorDrawerMenuManage from "./DoctorDrawerMenuManage";

export default {
  name: "DoctorDrawer",
  components: {AnDrawer, DoctorDrawerMenuManage},
  props: {
    drawer_value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    _drawer_value: {
      get() {
        return this.drawer_value;
      },
      set(value) {
        this.$emit("update:drawer_value", value);
      },
    },
    all_cpts: function() {
      return this.$store.getters.getUserInfosElement('all_cpts');
    },
    has_access_to_manage_cpts: function() {
      return this.all_cpts && this.all_cpts.length;
    },
    has_access_to_calendar() {
      return this.$store.getters.getPermission('has_access_to_calendar');
    },
    has_access_to_coordination() {
      return this.$store.getters.getPermission('has_access_to_coordination');
    },
    has_access_to_ddi() {
      return this.$store.getters.getPermission('has_access_to_ddi');
    },
    drawer_buttons: function() {
      let tmp = [
        {text: "Accueil", icon: "mdi-home", to: "/professional/home", primary_action: true, exact_path: true},
        {text: "Patients", icon: "mdi-account-group", to: "/professional/dashboard", primary_action: true, exact_path: true}
      ];

      if(this.has_access_to_calendar) {
        tmp.push({text: "Agenda / Planning", icon: "mdi-calendar-blank", to: "/calendar/", primary_action: true, exact_path: false});
      }

      if(this.has_access_to_coordination) {
        tmp.push({text: "Discussions", icon: "mdi-forum", to: "/professional/chat", primary_action: true, exact_path: false});
        if(this.has_access_to_ddi) tmp.push({text: "Demandes", icon: "mdi-file-export", to: "/professional/my-demands", primary_action: true, exact_path: true});
        tmp.push({text: "Annuaire", icon: "mdi-map-search", to: "/professional/phonebook", primary_action: true, exact_path: true});
      }
      return tmp;
    },
  },
}
</script>

<style scoped>

</style>