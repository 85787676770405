<template>
  <CoChatView
      :search_rpps="true"
      url_send_email_rpps="/api/send_inzeecare_invitation_email"
      app_name="inzeecare"
  ></CoChatView>
</template>

<script>
import CoChatView from "coordination-library/src/components/doctor/chat/CoChatView";

export default {
  name: "ChatView",
  components: {CoChatView},
}
</script>