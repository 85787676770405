<template>
  <CoCalendarManageEventsLayout
      :search_rpps="true"
      app_name="inzeecare"
      url_send_email_rpps="/api/send_inzeecare_invitation_email"
  ></CoCalendarManageEventsLayout>
</template>

<script>
import CoCalendarManageEventsLayout
  from "coordination-library/src/components/doctor/calendar/manage-evenements/CoCalendarManageEventsLayout"

export default {
  name: "CalendarManageEventsLayout",
  components: {CoCalendarManageEventsLayout},
}
</script>