<template>
	<v-app id="app">
		<link rel="stylesheet" href="/static/css/common_vue29122020.css">
    <AnEnvSystemBar color_demo="orange lighten-1"/>
		<router-view></router-view>
	</v-app>
</template>

<script>
import AnEnvSystemBar from "anam-library/src/components/an-ui/an-system-bar/AnEnvSystemBar.vue";
export default {
  name: 'App',
  components: {
    AnEnvSystemBar
  }
}

</script>

<style scoped>
</style>