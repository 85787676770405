<template>
  <AnDialogComponent :dialog.sync="_dialog"
                     :title="title"
                     :icon="$vuetify.breakpoint.smAndUp ? 'mdi-flag' : ''"
                     width="830">
    <template v-slot:card-text>
      <FormSearchPatient v-if="!new_patient"
                         ref="form_search_patient"
                         class="mt-6 mt-sm-0"
                         :option_search_ddn="true"
                         :option_create_patient="true"
                         v-on:selected_patient="start_tlc($event.patient_id)"
                         v-on:new_patient="new_patient=true"/>

          <v-expand-transition>
            <div v-if="new_patient">
              <p class="semibold--text primary_dark--text font-size--16 text-decoration-underline mb-10">
                Champs à remplir pour la création du patient :
              </p>
              <v-form v-model="valid_form_creation_patient" ref="form_creation_patient">
                <CoPatientEditComponent :create.sync="create_patient"
                                        :service_to_add_patient="services_to_add_patient"
                                        app_name="inzeecare"
                                        :min_infos="true"
                                        :show_missing_informations="true"
                                        :from_home_action="true"
                                        :show_address="true"
                                        :show_birth_location="true"
                                        v-on:patient_created="start_tlc($event.patient_id)"
                                        v-on:actions_finally_creation_patient="actions_finally_creation()"/>
              </v-form>
              <div class="text-center mt-10">
                <v-btn text rounded color="primary_dark" class="mx-2" :disabled="loading_creation_patient" @click="new_patient=false">
                  <span class="normal--text text-decoration-underline">Retour</span>
                </v-btn>
                <v-btn rounded elevation="0" color="green" class="mx-2 white--text px-6"
                       :loading="loading_creation_patient"
                       :disabled="!valid_form_creation_patient"
                       @click="create_patient=true">
                  <span class="medium--text">Suivant</span>
                </v-btn>
              </div>
            </div>
          </v-expand-transition>
    </template>
  </AnDialogComponent>
</template>

<script>
import AnDialogComponent from "anam-library/src/components/an-ui/an-dialog/AnDialogComponent";
import FormSearchPatient from "coordination-library/src/components/doctor/home/dialog/FormSearchPatient";
import CoPatientEditComponent from "coordination-library/src/components/doctor/patient-edit/CoPatientEditComponent";

export default {
  name: "DoctorHomeNewTlc",
  components: {
    AnDialogComponent,
    FormSearchPatient,
    CoPatientEditComponent,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      required: true
    }
  },
  data: () => ({
    create_patient: false,
    new_patient: false,
    valid_form_creation_patient: false,
    loading_creation_patient: false,
  }),
  computed: {
    _dialog: {
      get: function() {
        return this.dialog;
      },
      set: function(val) {
        this.$emit("update:dialog", val);
      }
    },
    services_to_add_patient: function() {
      let tmp = [null];
      for (let service of this.$store.getters.getAllServices) {
        tmp.push(service.name);
      }
      return tmp;
    },
    title() {
      if (this.action === "start") return "Lancer une vidéo consultation";
      else if (this.action === "program") return "Programmer une vidéo consultation";
      else if (this.action === "invitation") return "Envoyer un lien de vidéo consultation";
      return "";
    }
  },
  watch: {
    _dialog: function(val) {
      if(!val) {
        let ref_search_form = this.$refs.form_search_patient;
        let ref_creation_form = this.$refs.form_creation_patient;
        let ref_fields_protocol_form = this.$refs.form_fields_protocol;
        if(ref_search_form) ref_search_form.reset_form();
        if(ref_creation_form) ref_creation_form.reset();
        if(ref_fields_protocol_form) ref_fields_protocol_form.reset();
        this.new_patient = false;
        this.create_patient = false;
      }
    }
  },
  methods: {
    actions_finally_creation: function() {
      this.loading_creation_patient = false;
    },
    start_tlc(patient_id) {
      if (this.action === "start") {
        this.$http.get(`/api/patients/${patient_id}/create_default_teleconsultation`).then(response => {
          window.open(`/v/${response.data}`, '_blank');
        })
      }
      else if (this.action === "program") {
        if (this.$store.getters.isOrthophonist) this.$router.push(`/patients/${patient_id}/program-visio`);
        else this.$router.push(`/patients/${patient_id}/video-consultations`);
      }
      else if (this.action === "invitation") {
        this.$router.push(`/patients/${patient_id}/invitation-visio`);
      }
    }
  }
}
</script>

<style scoped>

</style>