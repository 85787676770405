import { render, staticRenderFns } from "./DoctorDrawer.vue?vue&type=template&id=74e8274c&scoped=true"
import script from "./DoctorDrawer.vue?vue&type=script&lang=js"
export * from "./DoctorDrawer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74e8274c",
  null
  
)

export default component.exports