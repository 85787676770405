<template>
  <div>
    <v-dialog v-model="_dialog" width="1200">
      <v-card>
        <v-card-title class="primary white--text">
          Étendre la demande
          <v-spacer></v-spacer>
          <v-btn text icon color="white" @click="_dialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-4">
          <p :class="`${color_txt}--text normal--text font-size--16`">
            Seuls les professionnels dont les préférences correspondent à la demande sont affichés (types de soins acceptés, secteurs d'intervention).
          </p>
          <p :class="`${color_txt}--text normal--text font-size--16`">
            Sélectionnez les professionnels pour lesquels vous souhaitez proposer la demande d'intervention.
          </p>
          <p :class="`${color_txt}--text normal--text font-size--16 mb-0`">
            Adresse de la prise en charge du patient :
            <span class="primary--text semibold--text font-size--16" v-if="demand">{{ demand.address|address }}</span>
          </p>
          <p :class="`${color_txt}--text normal--text font-size--16`">
            Profession demandée :
            <span class="primary--text semibold--text font-size--16" v-if="demand">{{ get_profession_txt(demand) }}</span>
          </p>
          <div class="text-right">
            <v-btn color="primary_pastel" small elevation="0" @click="dialog_search_pro=true">
              <v-icon class="mr-2" color="primary" small>mdi-account-search</v-icon>
              <span class="primary--text medium--text">Chercher un professionnel</span>
            </v-btn>
          </div>
          <v-data-table
              v-model="selected_pds"
              :headers="headers_pds"
              :items="list_pds_filtered"
              sort-by="distance"
              class="elevation-0 data-table-hfmanager mt-8"
              fixed-header
              show-select
              item-key="id"
              :loading="loading"
              loading-text="Chargement ..."
              no-data-text="Aucun professionnel"
              no-results-text="Aucun résultat"
              checkbox-color="primary"
              :footer-props="{
                itemsPerPageText: 'Lignes par page',
                itemsPerPageAllText: 'Tous',
                'items-per-page-options': [10, 20, 30, -1]}">

            <template v-slot:[`item.traceability_status`]="{ item }">
              <p v-if="item.traceability_status" class="mb-0">
                {{ item.traceability_status|traceability_txt }}<br>
                <span class="font-size--12">{{ item.traceability_date|datetime }}</span>
              </p>
              <v-icon v-else small>mdi-minus</v-icon>
            </template>
            <template v-slot:[`item.distance`]="{ item }">
              <span :class="item.in_cpts ? 'green--text medium--text' : 'red--text medium--text'">{{ item.distance }} km</span>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <span>{{ item.first_name }} {{ item.last_name }}</span>
            </template>
            <template v-slot:[`item.profession`]="{ item }">
              <span>{{ get_profession_txt(item.professionnel) }}</span>
            </template>
            <template v-slot:[`item.address`]="{ item }">
              <span>{{ item.personnephysique.adresse|address }}</span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-block">
          <p class="primary_dark--text">
            Nombre de professionnels sélectionnés :
            <span class="semibold--text font-size--14">{{ selected_pds.length }}</span>
          </p>
          <div class="text-center">
            <v-btn text color="primary" class="mx-sm-3" @click="_dialog=false">Annuler</v-btn>
            <v-btn elevation="0" color="green" class="white--text mx-sm-3 px-sm-6" :disabled="selected_pds.length === 0" @click="confirm_send_email()">
              <span class="font-size--14 medium--text">Envoyer la demande</span>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <CoDialogSearchPro :dialog.sync="dialog_search_pro"
                       dialog_title="Étendre la demande à un professionnel"
                       :map_professions="profession_to_search"
                       :search_all_professions="false"
                       :emit="true"
                       :search_user_in_cpts="true"
                       :hf_coordination_ids="[hf_id]"
                       btn_select_txt="Étendre"
                       v-on:doctor_clicked="expand_demand_to_one_pro($event)"
                       v-on:close="close_dialog_search_pro()"/>
  </div>
</template>

<script>
import CoDialogSearchPro from "coordination-library/src/components/doctor/common/CoDialogSearchPro";

import {get_formated_address, get_distance_between_two_coordinates} from "anam-library/src/js/address";
import {get_profession_name, MAP_ALL_PROFESSIONS} from "anam-library/src/data/professions";
import {get_ddi_traceability_status_txt} from "coordination-library/src/data/interventions";
import {MAP_SPECIALITES} from "anam-library/src/data/professionnal_specialities";
import {get_formatted_date} from "anam-library/src/js/dates";

export default {
  name: "DialogExpandDemand",
  components: {CoDialogSearchPro},
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    hf_id: {
      type: String,
      required: true
    },
    members: {
      type: Array,
      default: () => []
    },
    demand: {
      type: Object,
      default: () => {}
    },
    color_txt: {
      type: String,
      default: 'primary_dark'
    }
  },
  data: () => ({
    loading: false,
    list_pds: [],
    list_pds_filtered: [],
    selected_pds: [],
    headers_pds: [
      {text: "État", value: "traceability_status"},
      {text: "Distance", value: "distance"},
      {text: "Nom", value: "name"},
      {text: "Profession", value: "profession"},
      {text: "Statut", value: "professionnel.status"},
      {text: "Adresse", value: "address"},
      {text: "Adresse mail", value: "email"},
      {text: "Téléphone", value: "personnephysique.personel_phone"},
    ],
    dialog_search_pro: false,
    profession_to_search: [],
  }),
  computed: {
    _dialog: {
      get: function() {
        return this.dialog;
      },
      set: function(val) {
        this.$emit('update:dialog', val);
      }
    }
  },
  watch: {
    _dialog(val) {
      if(val) {
        this.loading = true;
        let tmp = JSON.parse(JSON.stringify(this.members));
        tmp = tmp.filter(x => x.status === 2 && x.professionnel.dmp_type === this.demand.dmp_type);
        if(this.demand.speciality_code) tmp = tmp.filter(x => x.professionnel.speciality_code === this.demand.speciality_code);
        this.list_pds = tmp;
        this.set_pds();
        this.loading = false;

        let profession = null;
        if(this.demand.speciality_code && Object.keys(MAP_SPECIALITES).includes(this.demand.speciality_code)) profession = [MAP_SPECIALITES[this.demand.speciality_code]];
        if(!profession && Object.keys(MAP_ALL_PROFESSIONS).includes(this.demand.dmp_type)) profession = [MAP_ALL_PROFESSIONS[this.demand.dmp_type]];
        this.profession_to_search = profession;

      } else {
        this.list_pds = [];
        this.list_pds_filtered = [];
        this.selected_pds = [];
        this.dialog_search_pro = false;
        this.profession_to_search = [];
      }
    },
  },
  filters: {
    address(val) {
      return get_formated_address(val);
    },
    traceability_txt: function(val) {
      return get_ddi_traceability_status_txt(val);
    },
    datetime: function(val) {
      return get_formatted_date(val, {input_format: "DD/MM/YYYY HH:mm"});
    }
  },
  methods: {
    get_distance: function(user) {
      if(user && user.personnephysique && user.personnephysique.adresse && this.demand) {
        let address = user.personnephysique.adresse;
        let demand_address = this.demand.address;
        if (address.latitude && address.longitude && demand_address.latitude && demand_address.longitude) {
          return get_distance_between_two_coordinates(demand_address.latitude, demand_address.longitude, address.latitude, address.longitude);
        }
      }
      return "x";
    },
    get_coordinates_in_polygon(x, y, area) {
      if (area.length < 1) return false;
      let in_polygon = false;
      for (let i=0, j=area.length - 1; i<area.length; j=i++) {
          let xi = area[i][0], yi = area[i][1];
          let xj = area[j][0], yj = area[j][1];
          let intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
          if (intersect) in_polygon = !in_polygon;
      }
      return in_polygon;
    },
    confirm_send_email: function() {
      this.$emit('expand_demand', this.selected_pds.map(x => x.id));
    },
    expand_demand_to_one_pro: function(user) {
      this.$emit("expand_demand", [user.id]);
      this.close_dialog_search_pro();
    },
    set_pds: async function() {
      let traceabilities = this.demand.traceabilities;
      if(!traceabilities) {
        let response = await this.$http.get(`/api/demand-intervention/${this.demand.id}/traceabilities`);
        traceabilities = response.data;
      }

      let tmp = JSON.parse(JSON.stringify(this.list_pds));
      let filtered_tmp = [];
      for (let user of tmp) {
        let skip_user = false;
        user["distance"] = this.get_distance(user);
        user["in_cpts"] = true;

        if(!skip_user && user.professionnel && user.professionnel.settings_ddi){
          let settings = user.professionnel.settings_ddi;
          if(this.demand.address && this.demand.address.codeCity) {
            if(settings.cities_code && settings.cities_code.length > 0 && !settings.cities_code.includes(this.demand.address.codeCity)) skip_user = true;
          }
          if(!skip_user && this.demand.cares_type && this.demand.cares_type.length > 0) {
            for(let el in this.demand.cares_type) {
              if(!settings[this.demand.cares_type[el]]) skip_user = true;
            }
          }
        }

        if (!skip_user && this.demand.address && this.demand.address.latitude && this.demand.address.longitude && user.professionnel && user.professionnel.areas && user.professionnel.areas.length) {
          let demand_in_polygon = false;
          for (let area of user.professionnel.areas) {
            demand_in_polygon = this.get_coordinates_in_polygon(this.demand.address.latitude, this.demand.address.longitude, area);
            if (demand_in_polygon) break;
          }
          if (!demand_in_polygon) skip_user = true;
        }

        let index = traceabilities.findIndex(x => x.practitioner.toString() === user.professionnel.id.toString());
        if(index > -1) {
          user["traceability_status"] = traceabilities[index].status;
          user["traceability_date"] = traceabilities[index].update_date;
          skip_user = false;
        }

        if (!skip_user) filtered_tmp.push(user);
      }
      this.list_pds_filtered = filtered_tmp;
      this.loading = false;
    },
    get_profession_txt: function(item) {
      return get_profession_name(item.dmp_type, item.speciality_code);
    },
    close_dialog_search_pro: function() {
      this.dialog_search_pro = false;
      this.profession_to_search = [];
    }
  }
}
</script>

<style scoped>

</style>