import { render, staticRenderFns } from "./CoAutocompleteProfessions.vue?vue&type=template&id=657cc5e0&scoped=true"
import script from "./CoAutocompleteProfessions.vue?vue&type=script&lang=js"
export * from "./CoAutocompleteProfessions.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../inzeecare/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "657cc5e0",
  null
  
)

export default component.exports