<template>
  <v-dialog v-model="_dialog" width="600">
    <v-card>
      <v-card-title class="primary--text semibold--text text-capitalize">
        <v-icon class="mr-2" color="primary">{{ icon }}</v-icon>
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn icon text color="primary" @click="_dialog=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-8 px-15">
        <v-form v-model="valid_form">
          <p class="primary--text medium--text font-size--16 mb-0">Importer {{ text }}</p>
          <v-file-input v-model="document"
                        color="primary" filled
                        placeholder="Sélectionner parmi vos fichiers"
                        show-size
                        accept="image/*"
                        :rules="[rules.max_file_size, rules.accept_image]"
                        prepend-icon="" prepend-inner-icon="mdi-download"/>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center pb-8">
        <v-btn color="primary"
               elevation="0"
               large
               class="px-8 white--text"
               :disabled="!document || !valid_form"
               @click="$emit('add_document', document); _dialog=false">
          <span class="medium--text font-size--18">Valider</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import rules from "anam-library/src/js/rules";
export default {
  name: "DialogAddPJ",
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: "mdi-paperclip"
    }
  },
  data: () => ({
    rules: rules,
    valid_form: false,
    document: null
  }),
  computed: {
    _dialog: {
      get: function() {
        return this.dialog;
      },
      set: function(val) {
        this.$emit("update:dialog", val);
      }
    }
  }
}
</script>


<style scoped>

</style>