<template>
  <CoPatientActeDocumentsShared logo="https://www.anamnese.care/hubfs/config/Citana/inzeecare/logo/logo-2022-citana-couleur-BL.png" />
</template>

<script>
import CoPatientActeDocumentsShared from "coordination-library/src/components/patient/visio/CoPatientActeDocumentsShared";

export default {
  name: "PatientActeDocumentsSharedView",
  components: {CoPatientActeDocumentsShared}
}
</script>

<style scoped>

</style>