<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-card width="250" :elevation="hover ? 4 : 0" rounded class="mx-auto" @click="dialog=true" style="height: 100%;">
        <v-card-text class="text-center pt-10">
          <v-icon v-if="!_document" size="100" color="primary">mdi-download</v-icon>
          <v-icon v-else size="50" color="green">mdi-check</v-icon>
          <p :class="`${_document ? 'green--text' : 'primary--text'} semibold--text font-size--18 mt-6 text-capitalize`">{{ text }}</p>
          <p v-if="_document">
            {{ _document.name }}
          </p>
        </v-card-text>
      </v-card>
    </v-hover>

    <DialogAddPJ :dialog.sync="dialog"
                 :title="title"
                 :text="text"
                 :icon="icon"
                 v-on:add_document="_document=$event"/>
  </div>
</template>

<script>
import DialogAddPJ from "@/components/doctor/onboarding/components/DialogAddPJ.vue";

export default {
  name: "CardAddPJ",
  components: {DialogAddPJ},
  props: {
    document: {
      default: null
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: null
    }
  },
  data: () => ({
    dialog: false
  }),
  computed: {
    _document: {
      get: function() {
        return this.document;
      },
      set: function(val) {
        this.$emit("update:document", val);
      }
    }
  }
}
</script>

<style scoped>

</style>