<template>
  <v-row class="mx-0">
    <v-col cols="12" sm="3" class="align-self-center">
      <v-select v-model="filter_status"
                :items="items_status"
                clearable multiple dense solo
                label="Filtrer par statut"
                hide-details prepend-inner-icon="mdi-tune-variant">
        <template v-slot:item="{item}">
          <v-icon :color="item.color" class="mr-3">mdi-circle</v-icon>
          <span class="normal--text font-size--14">{{ item.text }}</span>
        </template>
        <template v-slot:selection="{item}">
          <v-chip :color="item.color" text-color="white" small>
            <strong>{{ item.text }}</strong>
          </v-chip>
        </template>
      </v-select>
    </v-col>
    <v-col cols="12" sm="3" class="align-self-center">
      <v-combobox v-model="postcodes"
                  label="Filtrer par codes postaux"
                  multiple chips clearable deletable-chips solo dense hide-details
                  type="number"
                  class="input_postcodes"/>
    </v-col>
    <v-col cols="12" sm="3" class="align-self-center">
      <DoctorDashboardTagsFilter :filter_tags.sync="filter_tags" :loading="loading"/>
    </v-col>
    <v-col cols="9" sm="3" class="align-self-center">
      <DoctorDashboardSearch :loading="loading"/>
    </v-col>

    <v-col cols="12" class="py-0">
      <div v-if="of_type==='334'" class="d-flex">
        <p class="font-italic font-size--14 mb-0 mt-2">
          Apparaissent dans la liste d’attente tous les patients qui ont été tagués "En attente MT"
          par un professionnel de votre structure ou via le formulaire public. Si un patient de la liste n’a
          pas le tag "En attente MT", c’est que le tag a été retiré manuellement. Vous pouvez consulter
          l’historique des tags depuis la fiche patient et clôturer la demande le cas échéant.
        </p>
        <WaitingListManageProfessionals/>
      </div>
      <p v-else class="font-italic font-size--14 mb-0 mt-2">
        Apparaissent dans la liste d’attente tous les patients qui ont été tagués suite au lancement du protocole par un professionnel de votre structure.
      </p>
    </v-col>
    <v-col v-if="of_type==='334'" cols="12" class="text-right py-0">
      <AnExportBtn :disabled="loading"
                   :loading="loading_export"
                   :rounded="true"
                   v-on:generate_csv="generate_csv()"
                   v-on:generate_xlsx="generate_xlsx()"/>
    </v-col>
    <v-col cols="12">
       <AnDataTable :headers="headers"
                    :items="patients_filtered"
                    :loading="loading"
                    :table_items_mapping="table_items_mapping"
                    :search="$store.state.dashboard_search"
                    no_data_txt="Aucun résultat"
                    elevation="4"
                    rounded="rounded-lg"
                    :custom_sort_function="custom_sort_function"
                    v-on:move_to_dpa="$emit('move_to_dpa', $event)"/>
    </v-col>
  </v-row>
</template>

<script>
import WaitingListManageProfessionals from "./WaitingListManageProfessionals";
import AnDataTable from "anam-library/src/components/an-data-table/AnDataTable";
import DoctorDashboardSearch from "../filters/DoctorDashboardSearch";
import DoctorDashboardTagsFilter from "../filters/DoctorDashboardTagsFilter";
import AnExportBtn from "anam-library/src/components/an-export/AnExportBtn";

import {get_data_waiting_list, MAP_STATUS, get_status_name} from "coordination-library/src/modules/waiting_list";
import {clean_txt} from "anam-library/src/js/utils";
import {get_formatted_date, get_age} from "anam-library/src/js/dates";
import {generate_csv} from "anam-library/src/js/export_file";
import {generateXlsx} from "anam-library/src/js/export_xlsx";
import {get_profession_name} from "anam-library/src/data/professions";

export default {
  name: "WaitingListDataTable",
  components: {
    WaitingListManageProfessionals,
    AnDataTable,
    DoctorDashboardSearch,
    DoctorDashboardTagsFilter,
    AnExportBtn
  },
  props: {
    of_type: {
      type: String,
      required: true
    },
    access_pt: {
      type: Boolean,
      required: true
    },
    table_items_mapping: {
      type: Object,
      default: () => {}
    },
    class_headers: {
      type: String,
      default: null
    }
  },
  data: () => ({
    patients: [],
    loading: false,
    map_status: MAP_STATUS,
    filter_status: [],
    filter_tags: [],
    loading_export: false,
    headers_to_export: [
      "Profil demandeur", "Prénom demandeur", "Nom demandeur", "Profession demandeur", "Année demande", "Mois demande",
      "Date demande", "Nom patient", "Nom d'usage patient", "Prénom patient", "Adresse patient", "DDN patient",
      "Age patient", "Téléphone patient", "Email Patient", "Tags patient", "Statut demande", "Code postal", "Commune"
    ],
    postcodes: [],
  }),
  computed: {
    headers: function() {
      let class_headers = this.class_headers;
      let tmp = [
          {text: "Date inscription sur la liste", value: "inscription", align: "center", class: `${class_headers} rounded-tl-lg`}
      ]
      if(this.of_type === "334") tmp.push({text: "Provenance", value: "from", align: "center", class: class_headers});
      let tmp_bis = [
        {text: "Nom(s)", value: "last_name", class: class_headers},
        {text: "Prénom", value: "first_name", class: class_headers},
        {text: "DDN", value: "birthdate", class: class_headers},
        {text: "Téléphone", value: "mobile", class: class_headers},
        {text: "Tags", value: "tags", class: class_headers},
        {text: "Adresse", value: "fullAddress", class: class_headers},
        {text: "Statut", value: "status", align: "center", class: class_headers, sortable: false, width: "13%"},
      ];

      tmp = tmp.concat(tmp_bis);
      if(this.access_pt)  tmp.push({text: "Protocole", value: "access_pt", align: "center", class: class_headers, sortable: false})

      tmp.push({text: "Fiche patient", value: "dpa", align: "center", class: `${class_headers} rounded-tr-lg`, sortable: false})
      tmp.push({text: "SEARCH", value: "search", class: "d-none", cellClass: "d-none"})
      return tmp;
    },
    data_waiting_list: function() {
      return get_data_waiting_list(this.of_type);
    },
    items_status: function() {
      return this.map_status.filter(x => this.data_waiting_list.available_status.includes(x.value));
    },
    patients_filtered: function() {
      let tmp = JSON.parse(JSON.stringify(this.patients));
      if(this.filter_tags.length > 0) {
         tmp = tmp.filter(x => x.tags.filter(y => this.filter_tags.includes(y.meta_indicator.id)).length > 0);
       }
       if(this.filter_status.length > 0) {
         tmp = tmp.filter(x => x.patient_service_waiting_list && this.filter_status.includes(x.patient_service_waiting_list.status));
       }
       if(this.postcodes && this.postcodes.length > 0) {
         tmp = tmp.filter(x => this.postcodes.includes(x.codePostal));
       }
      return tmp;
    }
  },
  watch: {
    data_waiting_list: function(val) {
      if(val) {this.filter_status = this.data_waiting_list.default_filter_status}
    }
  },
  mounted() {
    if(this.$vuetify.breakpoint.smAndUp) {
      this.loading = true;
      this.$http.get(`/api/coordination/patients/waiting-list/${this.of_type}`).then(response => {
        this.patients = response.data.map(el => {
          el.waiting_list_of_type = this.of_type;
          el.search = `${clean_txt(el.last_name)} ${clean_txt(el.last_name_used)} ${clean_txt(el.first_name)} ${get_formatted_date(el.birthdate, {output_format: "DD/MM/YYYY"})} ${clean_txt(el.fullAddress)}`;
          return el
        });
      }).finally(() => {
        this.loading = false;
      });
    }

    if(this.data_waiting_list) this.filter_status = this.data_waiting_list.default_filter_status;
  },
  methods: {
    custom_sort_function: function(items, key, isDesc){
      if (key === "inscription") {
        items.map(x => {
          if (x.tags_mt && x.tags_mt.length > 0) {
            x["inscription"] = x.tags_mt[0].creation_date;
          }
          return x;
        })
      } else if(key === "from") {
        items.sort((a, b) => {
          let a_key = null;
          let b_key = null;
          if (a.tags_mt && a.tags_mt.length > 0) {
            a_key = a.tags_mt[0].author.id === a.user_id;
          }
          if (b.tags_mt && b.tags_mt.length > 0) {
            b_key = b.tags_mt[0].author.id === b.user_id;
          }
          if (a_key === null && isDesc[0] === true) return -1;
          if (a_key === null && isDesc[0] === false) return 1;
          if (isDesc[0] === true) return a_key === b_key ? 0 : a_key ? 1 : -1;
          if (isDesc[0] === false) return a_key === b_key ? 0 : b_key ? -1 : 1;
          return 0;
        })
      }
      return items;
    },
    get_data_to_export: function() {
      this.loading_export = true;
      let data_to_export = [];
      for (let item of this.patients) {
        let tags = [];
        for(let el of item.tags) {
          if(el.meta_indicator) tags.push(el.meta_indicator.commercial_name);
        }

        let tag_mt = null;
        let is_pro_author = false;
        if(item.tags_mt && item.tags_mt.length > 0) {
          tag_mt = item.tags_mt[0];
          if(tag_mt.author.id !== item.user_id) is_pro_author = true;
        }

        data_to_export.push([
          is_pro_author ? "PS" : "Patient",
          is_pro_author && tag_mt ? tag_mt.author.first_name : '',
          is_pro_author && tag_mt ? tag_mt.author.last_name : '',
          is_pro_author && tag_mt ? get_profession_name(tag_mt.author.dmp) : '',
          tag_mt && tag_mt.creation_date ? get_formatted_date(tag_mt.creation_date, {output_format: "YYYY"}) : '',
          tag_mt && tag_mt.creation_date ? get_formatted_date(tag_mt.creation_date, {output_format: "MMMM"}, true) : '',
          tag_mt && tag_mt.creation_date ? get_formatted_date(tag_mt.creation_date, {output_format: "DD/MM/YYYY"}) : '',
          item.last_name,
          item.last_name_used || '',
          item.first_name,
          item.fullAddress || '',
          item.birthdate,
          get_age(item.birthdate, false, true),
          item.mobile,
          item.email || '',
          tags.join(", "),
          item.patient_service_waiting_list ? get_status_name(item.patient_service_waiting_list.status) : "",
          item.codePostal,
          item.localite,
        ]);
      }
      this.loading_export= false
      return data_to_export;
    },
    generate_csv: function() {
      generate_csv(this.get_data_to_export(), this.headers_to_export, "export-liste-attente");
    },
    generate_xlsx: function() {
      generateXlsx(this.get_data_to_export(), this.headers_to_export, "export-liste-attente", "export-liste-attente");
    },
  }
}
</script>

<style>
.input_postcodes input::-webkit-outer-spin-button,
.input_postcodes input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
}
</style>