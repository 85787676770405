<template>
  <DeconnexionNotifTemplate :use_as_component="true"
                            color_txt="white--text"
                            color_btn="primary_pastel"
                            color_btn_text="white"
                            :img="img"
                            v-on:logout="logout()"/>
</template>

<script>
import DeconnexionNotifTemplate from "anam-library/src/components/an-error-pages/DeconnexionNotifTemplate";
import img_401 from "anam-library/src/assets/error-pages/new-charte/401_illustration.png";

export default {
  name: "DeconnexionView",
  components: {DeconnexionNotifTemplate},
  data: () => ({
    img: img_401
  }),
  methods: {
    logout: function() {
      this.$store.dispatch("logoutInzeecare");
      if(this.$keycloak) this.$keycloak.logout({redirectUri: this.$store.getters.getEnvKeycloakAnamnese('redirect_logout')});
    }
  }
}
</script>

<style scoped>

</style>