<template>
  <v-row class="mx-0">
    <v-col cols="12">
      <p class="mb-0 primary_dark--text semibold--text font-size--24">Listes des protocoles réalisés</p>
    </v-col>
    <v-col cols="12" sm="6" md="4" class="align-self-center">
      <p class="mb-0 font-size--12 primary_dark--text medium--text">Filtrer par annulé</p>
      <v-select v-model="filter_closed"
                :items="items_bool"
                dense hide-details
                clearable
                placeholder="Sélectionnez"
                no-data-text="Aucune donnée"/>
    </v-col>
    <v-col cols="12" sm="6" md="4" class="align-self-center">
      <p class="mb-0 font-size--12 primary_dark--text medium--text">Filtrer par délivrance</p>
      <v-select v-model="filter_prescription"
                :items="items_bool"
                dense hide-details
                clearable
                placeholder="Sélectionnez"
                no-data-text="Aucune donnée"/>
    </v-col>
    <v-col cols="12" sm="6" md="4" class="align-self-center">
      <p class="mb-0 font-size--12 primary_dark--text medium--text">Filtrer par professionnel</p>
      <v-text-field v-model="filter_name_pro" dense hide-details clearable placeholder="Nom du délégué ou du délégant"/>
    </v-col>
    <v-col cols="12">
      <AnDataTable :headers="headers"
                   :items="protocols_filtered"
                   :loading="loading"
                   elevation="0"
                   :table_items_mapping="table_items_mapping"
                   no_data_txt="Aucun résultat"
                   class="table_protocols"
                   v-on:update_item="update_item($event)"/>
    </v-col>
  </v-row>
</template>


<script>
import AnDataTable from "anam-library/src/components/an-data-table/AnDataTable";
import ManagerProtocolsActionsItem from "./ManagerProtocolsActionsItem.vue";

import {get_questionnaire_name} from "anam-library/src/data/questionnaires";
import {get_formatted_date} from "anam-library/src/js/dates";
import {clean_txt} from "anam-library/src/js/utils";
import {get_ordonnance_name_from_type} from "coordination-library/src/data/protocoles";


export default {
  name: "ManagerProtocolsTable",
  components: {
    AnDataTable
  },
  props: {
    hf_coordination_id: {
      type: String,
      required: true
    },
    protocols: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    headers: [
      {text: "Patient", value: "patient_id", class: "primary_dark--text", align: "center"},
      {text: "Nom", value: "name", class: "primary_dark--text"},
      {text: "Date de lancement", value: "creation_date", class: "primary_dark--text", align: "center"},
      {text: "Délégué", value: "delegate_name", class: "primary_dark--text", align: "center"},
      {text: "Délégant", value: "delegating_name", class: "primary_dark--text", align: "center"},
      {text: "Délivrance", value: "has_prescription", class: "primary_dark--text", align: "center"},
      {text: "Annulé", value: "is_closed", class: "primary_dark--text", align: "center"},
      {text: "Actions", value: "actions", class: "primary_dark--text", align: "center", sortable: false},
    ],
    table_items_mapping: {
      "actions": ManagerProtocolsActionsItem
    },
    filter_closed: null,
    filter_prescription: null,
    filter_name_pro: null,
    items_bool: [{text: "Oui", value: true}, {text: "Non", value: false}]
  }),
  computed: {
    _protocols: {
      get: function() {
        return this.protocols;
      },
      set: function(val) {
        this.$emit("update_protocols", val);
      }
    },
    items_protocols: function() {
      return this.$store.getters.getCurrentProfessionalProtocols;
    },
    protocols_filtered: function() {
      let tmp = JSON.parse(JSON.stringify(this._protocols));
      tmp = this.set_protocols(tmp);
      if(this.filter_closed !== null) tmp = tmp.filter(x => x.closed === this.filter_closed);
      if(this.filter_prescription !== null) {
        if(this.filter_prescription) tmp = tmp.filter(x => x.prescription);
        else tmp = tmp.filter(x => !x.prescription);
      }
      if(this.filter_name_pro) {
        let tmp_search_txt = clean_txt(this.filter_name_pro);
        tmp = tmp.filter(x => clean_txt(x.delegate_name).includes(tmp_search_txt) || clean_txt(x.delegating_name).includes(tmp_search_txt))
      }
      return tmp;
    }
  },
  methods: {
    set_protocols: function(tmp) {
      return tmp.map(el => {
        let tmp_delegating = null;
        if(el.professional_delegating) {
          tmp_delegating = `${el.delegating_first_name} ${el.delegating_last_name}`;
          if(el.delegating_idpp) tmp_delegating += `- ${el.delegating_idpp}`;
        }
        let tmp_delegate = null;
        if(el.author) {
          tmp_delegate = `${el.author_first_name} ${el.author_last_name}`;
          if(el.author_idpp) tmp_delegate += `- ${el.author_idpp}`;
        }

        el.name = get_questionnaire_name(el.meta_questionnaire_name);
        el.creation_date = get_formatted_date(el.creation_date);
        el.delegate_name = tmp_delegate ? tmp_delegate : '-';
        el.delegating_name = tmp_delegating ? tmp_delegating : '-';
        el.has_prescription = el.prescription ? 'Oui' : 'Non';
        el.details_prescription = el.prescription ? get_ordonnance_name_from_type(el.prescription) : '-';
        el.is_closed = el.closed ? `${get_formatted_date(el.closed_date)}` : '-';
        return el
      });
    },
    update_item: function(item) {
      let tmp = JSON.parse(JSON.stringify(this.protocols));
      let index = tmp.findIndex(x => x.id === item.id);
      if(index > -1) tmp[index] = {...tmp[index], "closed_date": item.closed_date, "closed": item.closed};
      this._protocols = tmp;
    }
  }
}
</script>

<style>
.table_protocols .v-data-table-header th {
  white-space: nowrap;
}
</style>