import {order_txt} from "anam-library/src/data/professions";

export const LIST_REGIONS = [
    {
        "code": "01",
        "name": "Guadeloupe",
        "longitude": "-61.5686855",
        "latitude": "16.2528827"
    },
    {
        "code": "02",
        "name": "Martinique",
        "longitude": "-61.0158",
        "latitude": "14.6367"
    },
    {
        "code": "03",
        "name": "Guyane",
        "longitude": "-52.9999",
        "latitude": "4.0039"
    },
    {
        "code": "04",
        "name": "La Réunion",
        "longitude": "55.5364",
        "latitude": "-21.1307"
    },
    {
        "code": "06",
        "name": "Mayotte",
        "longitude": "45.1520",
        "latitude": "-12.8230"
    },
    {
        "code": "11",
        "name": "Île-de-France",
        "longitude": "2.7537",
        "latitude": "48.6443"
    },
    {
        "code": "24",
        "name": "Centre-Val-de-Loire",
        "longitude": "1.7324",
        "latitude": "47.5490"
    },
    {
        "code": "27",
        "name": "Bourgogne-Franche-Comté",
        "longitude": "5.0740",
        "latitude": "47.0510"
    },
    {
        "code": "28",
        "name": "Normandie",
        "longitude": "0.3138532",
        "latitude": "49.0677"
    },
    {
        "code": "32",
        "name": "Hauts-de-France",
        "longitude": "2.7247515",
        "latitude": "50.1024606"
    },
    {
        "code": "44",
        "name": "Grand-Est",
        "longitude": "6.113035",
        "latitude": "48.4845157"
    },
    {
        "code": "52",
        "name": "Pays-de-la-Loire",
        "longitude": "-0.8186143",
        "latitude": "47.6594864"
    },
    {
        "code": "53",
        "name": "Bretagne",
        "longitude": "-2.9202408",
        "latitude": "48.2640845"
    },
    {
        "code": "75",
        "name": "Nouvelle-Aquitaine",
        "longitude": "0.3756199",
        "latitude": "45.4039367"
    },
    {
        "code": "76",
        "name": "Occitanie",
        "longitude": "2.3435684",
        "latitude": "43.6487851"
    },
    {
        "code": "84",
        "name": "Auvergne-Rhône-Alpes",
        "longitude": "4.6604809",
        "latitude": "45.2968119"
    },
    {
        "code": "93",
        "name": "Provence-Alpes-Côte d'Azur",
        "longitude": "5.9919910",
        "latitude": "44.0684127"
    },
    {
        "code": "94",
        "name": "Corse",
        "longitude": "9.0684138",
        "latitude": "42.1880896"
    }
]

export const LIST_DEPARTEMENTS = [
    {
        "num_dep": "01",
        "dep_name": "Ain",
        "region_name": "Auvergne-Rhône-Alpes",
        "longitude": "5.2056",
        "latitude": "46.0558"
    },
    {
        "num_dep": "02",
        "dep_name": "Aisne",
        "region_name": "Hauts-de-France",
        "longitude": "3.606899",
        "latitude": "49.4532854"
    },
    {
        "num_dep": "03",
        "dep_name": "Allier",
        "region_name": "Auvergne-Rhône-Alpes",
        "longitude": "3.1638828",
        "latitude": "46.3674641"
    },
    {
        "num_dep": "04",
        "dep_name": "Alpes-de-Haute-Provence",
        "region_name": "Provence-Alpes-Côte d'Azur",
        "longitude": "6.1878515",
        "latitude": "44.1640832"
    },
    {
        "num_dep": "05",
        "dep_name": "Hautes-Alpes",
        "region_name": "Provence-Alpes-Côte d'Azur",
        "longitude": "6.3520246",
        "latitude": "44.6564666"
    },
    {
        "num_dep": "06",
        "dep_name": "Alpes-Maritimes",
        "region_name": "Provence-Alpes-Côte d'Azur",
        "longitude": "7.1790785",
        "latitude": "43.9210587"
    },
    {
        "num_dep": "07",
        "dep_name": "Ardèche",
        "region_name": "Auvergne-Rhône-Alpes",
        "longitude": "4.3986525",
        "latitude": "44.815194"
    },
    {
        "num_dep": "08",
        "dep_name": "Ardennes",
        "region_name": "Grand-Est",
        "longitude": "4.6716005",
        "latitude": "49.6980117"
    },
    {
        "num_dep": "09",
        "dep_name": "Ariège",
        "region_name": "Occitanie",
        "longitude": "1.4065544",
        "latitude": "42.9455368"
    },
    {
        "num_dep": "10",
        "dep_name": "Aube",
        "region_name": "Grand-Est",
        "longitude": "4.1905397",
        "latitude": "48.3201921"
    },
    {
        "num_dep": "11",
        "dep_name": "Aude",
        "region_name": "Occitanie",
        "longitude": "2.5124715",
        "latitude": "43.0542733"
    },
    {
        "num_dep": "12",
        "dep_name": "Aveyron",
        "region_name": "Occitanie",
        "longitude": "2.5065697",
        "latitude": "44.3158574"
    },
    {
        "num_dep": "13",
        "dep_name": "Bouches-du-Rhône",
        "region_name": "Provence-Alpes-Côte d'Azur",
        "longitude": "5.0343236",
        "latitude": "43.5424182"
    },
    {
        "num_dep": "14",
        "dep_name": "Calvados",
        "region_name": "Normandie",
        "longitude": "0.2413951",
        "latitude": "49.0907648"
    },
    {
        "num_dep": "15",
        "dep_name": "Cantal",
        "region_name": "Auvergne-Rhône-Alpes",
        "longitude": "2.6997176",
        "latitude": "45.0497701"
    },
    {
        "num_dep": "16",
        "dep_name": "Charente",
        "region_name": "Nouvelle-Aquitaine",
        "longitude": "0.097305",
        "latitude": "45.6667902"
    },
    {
        "num_dep": "17",
        "dep_name": "Charente-Maritime",
        "region_name": "Nouvelle-Aquitaine",
        "longitude": "-0.7212876",
        "latitude": "45.7302267"
    },
    {
        "num_dep": "18",
        "dep_name": "Cher",
        "region_name": "Centre-Val-de-Loire",
        "longitude": "2.5753334",
        "latitude": "47.0248824"
    },
    {
        "num_dep": "19",
        "dep_name": "Corrèze",
        "region_name": "Nouvelle-Aquitaine",
        "longitude": "1.8176424",
        "latitude": "45.3429047"
    },
    {
        "num_dep": "21",
        "dep_name": "Côte-d'Or",
        "region_name": "Bourgogne-Franche-Comté",
        "longitude": "4.7481223",
        "latitude": "47.4655034"
    },
    {
        "num_dep": "22",
        "dep_name": "Côtes-d'Armor",
        "region_name": "Bretagne",
        "longitude": "-2.7514328",
        "latitude": "48.4614877"
    },
    {
        "num_dep": "23",
        "dep_name": "Creuse",
        "region_name": "Nouvelle-Aquitaine",
        "longitude": "2.048901",
        "latitude": "46.0593485"
    },
    {
        "num_dep": "24",
        "dep_name": "Dordogne",
        "region_name": "Nouvelle-Aquitaine",
        "longitude": "0.6321258",
        "latitude": "45.1429198"
    },
    {
        "num_dep": "25",
        "dep_name": "Doubs",
        "region_name": "Bourgogne-Franche-Comté",
        "longitude": "6.2356228",
        "latitude": "47.0669915"
    },
    {
        "num_dep": "26",
        "dep_name": "Drôme",
        "region_name": "Auvergne-Rhône-Alpes",
        "longitude": "5.2046372",
        "latitude": "44.7295347"
    },
    {
        "num_dep": "27",
        "dep_name": "Eure",
        "region_name": "Normandie",
        "longitude": "0.9652026",
        "latitude": "49.0756358"
    },
    {
        "num_dep": "28",
        "dep_name": "Eure-et-Loir",
        "region_name": "Centre-Val-de-Loire",
        "longitude": "1.1.399882",
        "latitude": "48.4474102"
    },
    {
        "num_dep": "29",
        "dep_name": "Finistère",
        "region_name": "Bretagne",
        "longitude": "-4.0440902",
        "latitude": "48.2451152"
    },
    {
        "num_dep": "2A",
        "dep_name": "Corse-du-Sud",
        "region_name": "Corse",
        "longitude": "9.1667",
        "latitude": "41.9167"
    },
    {
        "num_dep": "2B",
        "dep_name": "Haute-Corse",
        "region_name": "Corse",
        "longitude": "9.1667",
        "latitude": "42.4167"
    },
    {
        "num_dep": "30",
        "dep_name": "Gard",
        "region_name": "Occitanie",
        "longitude": "4.297637",
        "latitude": "43.95995"
    },
    {
        "num_dep": "31",
        "dep_name": "Haute-Garonne",
        "region_name": "Occitanie",
        "longitude": "0.9716792",
        "latitude": "43.3054546"
    },
    {
        "num_dep": "32",
        "dep_name": "Gers",
        "region_name": "Occitanie",
        "longitude": "0.4101019",
        "latitude": "43.6955276"
    },
    {
        "num_dep": "33",
        "dep_name": "Gironde",
        "region_name": "Nouvelle-Aquitaine",
        "longitude": "-0.6051264",
        "latitude": "44.883746"
    },
    {
        "num_dep": "34",
        "dep_name": "Hérault",
        "region_name": "Occitanie",
        "longitude": "3.3553309",
        "latitude": "43.591422"
    },
    {
        "num_dep": "35",
        "dep_name": "Ille-et-Vilaine",
        "region_name": "Bretagne",
        "longitude": "-1.3819",
        "latitude": "48.0916"
    },
    {
        "num_dep": "36",
        "dep_name": "Indre",
        "region_name": "Centre-Val-de-Loire",
        "longitude": "1.5382052",
        "latitude": "46.8121056"
    },
    {
        "num_dep": "37",
        "dep_name": "Indre-et-Loire",
        "region_name": "Centre-Val-de-Loire",
        "longitude": "0.6866703",
        "latitude": "47.2232046"
    },
    {
        "num_dep": "38",
        "dep_name": "Isère",
        "region_name": "Auvergne-Rhône-Alpes",
        "longitude": "5.6343825",
        "latitude": "45.2897932"
    },
    {
        "num_dep": "39",
        "dep_name": "Jura",
        "region_name": "Bourgogne-Franche-Comté",
        "longitude": "5.7832857",
        "latitude": "46.7833625"
    },
    {
        "num_dep": "40",
        "dep_name": "Landes",
        "region_name": "Nouvelle-Aquitaine",
        "longitude": "-0.6433872",
        "latitude": "44.0099694"
    },
    {
        "num_dep": "41",
        "dep_name": "Loir-et-Cher",
        "region_name": "Centre-Val de Loire",
        "longitude": "1.2971835",
        "latitude": "47.6597752"
    },
    {
        "num_dep": "42",
        "dep_name": "Loire",
        "region_name": "Auvergne-Rhône-Alpes",
        "longitude": "4.0454737",
        "latitude": "45.7538536"
    },
    {
        "num_dep": "43",
        "dep_name": "Haute-Loire",
        "region_name": "Auvergne-Rhône-Alpes",
        "longitude": "3.8338261",
        "latitude": "45.0857249"
    },
    {
        "num_dep": "44",
        "dep_name": "Loire-Atlantique",
        "region_name": "Pays-de-la-Loire",
        "longitude": "-1.8727461",
        "latitude": "47.3481614"
    },
    {
        "num_dep": "45",
        "dep_name": "Loiret",
        "region_name": "Centre-Val-de-Loire",
        "longitude": "2.3075036",
        "latitude": "47.9138724"
    },
    {
        "num_dep": "46",
        "dep_name": "Lot",
        "region_name": "Occitanie",
        "longitude": "1.6657742",
        "latitude": "44.6249918"
    },
    {
        "num_dep": "47",
        "dep_name": "Lot-et-Garonne",
        "region_name": "Nouvelle-Aquitaine",
        "longitude": "0.4539158",
        "latitude": "44.3691703"
    },
    {
        "num_dep": "48",
        "dep_name": "Lozère",
        "region_name": "Occitanie",
        "longitude": "3.5211146",
        "latitude": "44.5425706"
    },
    {
        "num_dep": "49",
        "dep_name": "Maine-et-Loire",
        "region_name": "Pays-de-la-Loire",
        "longitude": "-0.3909097",
        "latitude": "47.3886305"
    },
    {
        "num_dep": "50",
        "dep_name": "Manche",
        "region_name": "Normandie",
        "longitude": "-1.2454952",
        "latitude": "49.0918952"
    },
    {
        "num_dep": "51",
        "dep_name": "Marne",
        "region_name": "Grand-Est",
        "longitude": "4.3122436",
        "latitude": "48.961264"
    },
    {
        "num_dep": "52",
        "dep_name": "Haute-Marne",
        "region_name": "Grand-Est",
        "longitude": "5.2529108",
        "latitude": "48.1329414"
    },
    {
        "num_dep": "53",
        "dep_name": "Mayenne",
        "region_name": "Pays-de-la-Loire",
        "longitude": "-0.6491274",
        "latitude": "48.1507819"
    },
    {
        "num_dep": "54",
        "dep_name": "Meurthe-et-Moselle",
        "region_name": "Grand-Est",
        "longitude": "5.9870383",
        "latitude": "48.9559682"
    },
    {
        "num_dep": "55",
        "dep_name": "Meuse",
        "region_name": "Grand-Est",
        "longitude": "5.4286691",
        "latitude": "49.0129685"
    },
    {
        "num_dep": "56",
        "dep_name": "Morbihan",
        "region_name": "Bretagne",
        "longitude": "-2.7633493",
        "latitude": "47.8259812"
    },
    {
        "num_dep": "57",
        "dep_name": "Moselle",
        "region_name": "Grand-Est",
        "longitude": "6.5380352",
        "latitude": "49.0214"
    },
    {
        "num_dep": "58",
        "dep_name": "Nièvre",
        "region_name": "Bourgogne-Franche-Comté",
        "longitude": "3.5448898",
        "latitude": "47.119697"
    },
    {
        "num_dep": "59",
        "dep_name": "Nord",
        "region_name": "Hauts-de-France",
        "longitude": "3.0883524",
        "latitude": "50.5289671"
    },
    {
        "num_dep": "60",
        "dep_name": "Oise",
        "region_name": "Hauts-de-France",
        "longitude": "2.4064878",
        "latitude": "49.4120546"
    },
    {
        "num_dep": "61",
        "dep_name": "Orne",
        "region_name": "Normandie",
        "longitude": "0.0446617",
        "latitude": "48.5760533"
    },
    {
        "num_dep": "62",
        "dep_name": "Pas-de-Calais",
        "region_name": "Hauts-de-France",
        "longitude": "2.2580078",
        "latitude": "50.5144061"
    },
    {
        "num_dep": "63",
        "dep_name": "Puy-de-Dôme",
        "region_name": "Auvergne-Rhône-Alpes",
        "longitude": "3.0827",
        "latitude": "45.7715343"
    },
    {
        "num_dep": "64",
        "dep_name": "Pyrénées-Atlantiques",
        "region_name": "Nouvelle-Aquitaine",
        "longitude": "-0.7282474",
        "latitude": "43.1871865"
    },
    {
        "num_dep": "65",
        "dep_name": "Hautes-Pyrénées",
        "region_name": "Occitanie",
        "longitude": "0.1586661",
        "latitude": "43.1437925"
    },
    {
        "num_dep": "66",
        "dep_name": "Pyrénées-Orientales",
        "region_name": "Occitanie",
        "longitude": "2.506509",
        "latitude": "42.625894"
    },
    {
        "num_dep": "67",
        "dep_name": "Bas-Rhin",
        "region_name": "Grand-Est",
        "longitude": "7.5336729",
        "latitude": "48.5991783"
    },
    {
        "num_dep": "68",
        "dep_name": "Haut-Rhin",
        "region_name": "Grand-Est",
        "longitude": "7.2315433",
        "latitude": "47.8654746"
    },
    {
        "num_dep": "69",
        "dep_name": "Rhône",
        "region_name": "Auvergne-Rhône-Alpes",
        "longitude": "4.5645336",
        "latitude": "45.8802348"
    },
    {
        "num_dep": "70",
        "dep_name": "Haute-Saône",
        "region_name": "Bourgogne-Franche-Comté",
        "longitude": "6.0951141",
        "latitude": "47.6384233"
    },
    {
        "num_dep": "71",
        "dep_name": "Saône-et-Loire",
        "region_name": "Bourgogne-Franche-Comté",
        "longitude": "4.5585548",
        "latitude": "46.6557086"
    },
    {
        "num_dep": "72",
        "dep_name": "Sarthe",
        "region_name": "Pays-de-la-Loire",
        "longitude": "0.2538217",
        "latitude": "48.0269287"
    },
    {
        "num_dep": "73",
        "dep_name": "Savoie",
        "region_name": "Auvergne-Rhône-Alpes",
        "longitude": "6.3846604",
        "latitude": "45.4948952"
    },
    {
        "num_dep": "74",
        "dep_name": "Haute-Savoie",
        "region_name": "Auvergne-Rhône-Alpes",
        "longitude": "6.344537",
        "latitude": "46.0688208"
    },
    {
        "num_dep": "75",
        "dep_name": "Paris",
        "region_name": "Île-de-France",
        "longitude": "2.3200",
        "latitude": "48.8588"
    },
    {
        "num_dep": "76",
        "dep_name": "Seine-Maritime",
        "region_name": "Normandie",
        "longitude": "0.9401134",
        "latitude": "49.6632374"
    },
    {
        "num_dep": "77",
        "dep_name": "Seine-et-Marne",
        "region_name": "Île-de-France",
        "longitude": "3.0418158",
        "latitude": "48.6190207"
    },
    {
        "num_dep": "78",
        "dep_name": "Yvelines",
        "region_name": "Île-de-France",
        "longitude": "1.8871376",
        "latitude": "48.7620373"
    },
    {
        "num_dep": "79",
        "dep_name": "Deux-Sèvres",
        "region_name": "Nouvelle-Aquitaine",
        "longitude": "-0.649201",
        "latitude": "46.7703949"
    },
    {
        "num_dep": "80",
        "dep_name": "Somme",
        "region_name": "Hauts-de-France",
        "longitude": "2.3738855",
        "latitude": "49.9689482"
    },
    {
        "num_dep": "81",
        "dep_name": "Tarn",
        "region_name": "Occitanie",
        "longitude": "2.339648",
        "latitude": "43.7921741"
    },
    {
        "num_dep": "82",
        "dep_name": "Tarn-et-Garonne",
        "region_name": "Occitanie",
        "longitude": "1.2050633",
        "latitude": "44.080656"
    },
    {
        "num_dep": "83",
        "dep_name": "Var",
        "region_name": "Provence-Alpes-Côte d'Azur",
        "longitude": "6.266462",
        "latitude": "43.4173592"
    },
    {
        "num_dep": "84",
        "dep_name": "Vaucluse",
        "region_name": "Provence-Alpes-Côte d'Azur",
        "longitude": "5.1818898",
        "latitude": "43.9938643"
    },
    {
        "num_dep": "85",
        "dep_name": "Vendée",
        "region_name": "Pays-de-la-Loire",
        "longitude": "-1.2914463",
        "latitude": "46.6757732"
    },
    {
        "num_dep": "86",
        "dep_name": "Vienne",
        "region_name": "Nouvelle-Aquitaine",
        "longitude": "0.465407",
        "latitude": "46.6121165"
    },
    {
        "num_dep": "87",
        "dep_name": "Haute-Vienne",
        "region_name": "Nouvelle-Aquitaine",
        "longitude": "1.2031768",
        "latitude": "45.9190192"
    },
    {
        "num_dep": "88",
        "dep_name": "Vosges",
        "region_name": "Grand-Est",
        "longitude": "6.3820712",
        "latitude": "48.163786"
    },
    {
        "num_dep": "89",
        "dep_name": "Yonne",
        "region_name": "Bourgogne-Franche-Comté",
        "longitude": "3.6450439",
        "latitude": "47.8551257"
    },
    {
        "num_dep": "90",
        "dep_name": "Territoire de Belfort",
        "region_name": "Bourgogne-Franche-Comté",
        "longitude": "6.8993012",
        "latitude": "47.629231"
    },
    {
        "num_dep": "91",
        "dep_name": "Essonne",
        "region_name": "Île-de-France",
        "longitude": "2.2392918",
        "latitude": "48.5303402"
    },
    {
        "num_dep": "92",
        "dep_name": "Hauts-de-Seine",
        "region_name": "Île-de-France",
        "longitude": "2.19863",
        "latitude": "48.8401859"
    },
    {
        "num_dep": "93",
        "dep_name": "Seine-Saint-Denis",
        "region_name": "Île-de-France",
        "longitude": "2.4528635",
        "latitude": "48.9098125"
    },
    {
        "num_dep": "94",
        "dep_name": "Val-de-Marne",
        "region_name": "Île-de-France",
        "longitude": "2.4543321",
        "latitude": "48.7744893"
    },
    {
        "num_dep": "95",
        "dep_name": "Val-d'Oise",
        "region_name": "Île-de-France",
        "longitude": "2.2098114",
        "latitude": "49.0750704"
    },
    {
        "num_dep": "971",
        "dep_name": "Guadeloupe",
        "region_name": "Guadeloupe",
        "longitude": "-61.5686855",
        "latitude": "16.2528827"
    },
    {
        "num_dep": "972",
        "dep_name": "Martinique",
        "region_name": "Martinique",
        "longitude": "-61.0158269",
        "latitude": "14.6367927"
    },
    {
        "num_dep": "973",
        "dep_name": "Guyane",
        "region_name": "Guyane",
        "longitude": "52.999998",
        "latitude": "4.0039882"
    },
    {
        "num_dep": "974",
        "dep_name": "La Réunion",
        "region_name": "La Réunion",
        "longitude": "55.5364801",
        "latitude": "-21.1307379"
    },
    {
        "num_dep": "976",
        "dep_name": "Mayotte",
        "region_name": "Mayotte",
        "longitude": "45.1520755",
        "latitude": "-12.823048"
    }
]

export const TRE_R30_REGIONNOM_SELECT = () => {
    let select_regions = [];
    for (let region of LIST_REGIONS) {
        select_regions.push({
            value: region.code,
            text: region.name,
            coordinates: [parseFloat(region.longitude), parseFloat(region.latitude)]
        })
    }
    return select_regions.sort(order_txt)
}


export const DEPARTEMENTS_SELECT = function (list_filtered=null) {
    let select_departements = [];
    let list_departements = LIST_DEPARTEMENTS;
    if(list_filtered) list_departements = list_filtered;
    for (let el of list_departements) {
        select_departements.push({
            text: `${el.dep_name} (${el.num_dep})`,
            value: el.dep_name,
            num_dep: el.num_dep,
            coordinates: [parseFloat(el.longitude), parseFloat(el.latitude)]
        })
    }
    return select_departements.sort(order_txt);
}

export const GET_REGION_NAME = code => {
    let index = LIST_REGIONS.findIndex(x => x.code === code);
    if (index > -1) return LIST_REGIONS[index].name;
    return "";
}