const MAP_WAITING_LIST = {
    "334": {
        of_type: "334",
        id: "waiting_list_patients_334",
        name: "Liste d'attente médecin traitant",
        short_name: "Liste d'attente MT",
        has_patient_form: true,
        tag: "tag_mt",
        available_status: [1, 2, 3, 4],
        default_filter_status: [1, 2],
        access_pt_on_dashboard: false,
    },
    "330": {
        of_type: "330",
        id: "waiting_list_patients_330",
        name: "Liste d'attente Sport / Santé",
        short_name: "Liste d'attente Sport / Santé",
        has_patient_form: false,
        tag: "pt_sport_sante",
        available_status: [2, 5, 4, 6],
        default_filter_status: [2, 6],
        access_pt_on_dashboard: false,
    },
    "327": {
        of_type: "327",
        id: "waiting_list_patients_327",
        name: "Liste d'attente ETP",
        short_name: "Liste d'attente ETP",
        has_patient_form: false,
        tag: "tag_enattenteetp",
        available_status: [1, 2, 3, 4],
        default_filter_status: [1, 2],
        access_pt_on_dashboard: false,
    },
    "108": {
        of_type: "108",
        id: "waiting_list_patients_108",
        name: "Liste d'attente EMAAS",
        short_name: "Liste d'attente EMAAS",
        has_patient_form: false,
        tag: "tag_enattenteemaas",
        available_status: [1, 2, 3, 4],
        default_filter_status: [1, 2],
        access_pt_on_dashboard: false,
    },
    "1007": {
        of_type: "1007",
        id: "waiting_list_patients_1007",
        name: "Parcours obésité en ville",
        short_name: "Parcours obésité ville",
        has_patient_form: false,
        tag: "pt_obesite",
        available_status: [2, 5, 4, 6],
        default_filter_status: [2, 6],
        access_pt_on_dashboard: false,
    },
    "1008": {
        of_type: "1008",
        id: "waiting_list_patients_1008",
        name: "Parcours obésité à l'hôpital",
        short_name: "Parcours obésité hôpital",
        has_patient_form: false,
        tag: "pt_obesite_hopital",
        available_status: [2, 5, 4, 6],
        default_filter_status: [2, 6],
        access_pt_on_dashboard: false,
    },
    "1009": {
        of_type: "1009",
        id: "waiting_list_patients_1009",
        name: "Inclusion DAC",
        short_name: "Inclusion DAC",
        has_patient_form: false,
        tag: "pt_dac",
        available_status: [1, 2, 3, 4],
        default_filter_status: [1, 2],
        access_pt_on_dashboard: true,
    },
    "1011": {
        of_type: "1011",
        id: "waiting_list_patients_1011",
        name: "Évaluation des besoins",
        short_name: "Évaluation des besoins",
        has_patient_form: false,
        tag: "pt_evaluation_besoins",
        available_status: [1, 2, 3],
        default_filter_status: [1, 2],
        access_pt_on_dashboard: true,
    },
    "200": {
        of_type: "200",
        id: "waiting_list_patients_200",
        name: "Liste d'attente orthophoniste",
        short_name: "Liste d'attente orthophoniste",
        has_patient_form: false,
        tag: "tag_trouble_neuro",
        available_status: [2, 3, 4],
        default_filter_status: [2],
        access_pt_on_dashboard: false,
    },
    "1012": {
        of_type: "1012",
        id: "waiting_list_patients_1012",
        name: "Parcours pré-diabète",
        short_name: "Parcours pré-diabète",
        has_patient_form: false,
        tag: "tag_parcoursprediabete",
        available_status: [2, 7, 8],
        default_filter_status: [2, 7],
        access_pt_on_dashboard: false,
    },
    "1013": {
        of_type: "1013",
        id: "waiting_list_patients_1013",
        name: "Parcours TDA",
        short_name: "Parcours TDA",
        has_patient_form: false,
        tag: "pt_tda",
        available_status: [1, 9, 10, 11],
        default_filter_status: [1],
        access_pt_on_dashboard: true,
    },
    "1014": {
        of_type: "1014",
        id: "waiting_list_patients_1014",
        name: "Programmes APA et ETP",
        short_name: "Programmes APA et ETP",
        has_patient_form: false,
        tag: "pt_apa_etp",
        available_status: [1, 2, 3, 4],
        default_filter_status: [1, 2],
        access_pt_on_dashboard: true,
    },
}

export const MAP_STATUS = [
    {text: "A traiter", value: 1, color: 'red darken-2'},
    {text: "En attente", value: 2, color: 'amber darken-2'},
    {text: "Suivi en cours", value: 6, color: 'light-green darken-1'},
    {text: "Débuté", value: 7, color: 'light-green darken-1'},
    {text: "Résolue", value: 3, color: 'light-green darken-1'},
    {text: "Fin d'accompagnement", value: 5, color: 'blue lighten-1'},
    {text: "Finalisé", value: 8, color: 'blue lighten-1'},
    {text: "Clôturée", value: 4, color: 'blue-grey lighten-2'},

    // Status for TDA Pays de Grasse
    {text: "Vu par orthophoniste", value: 9, color: 'light-green darken-1'},
    {text: "Vu par orthoptiste", value: 10, color: 'light-green darken-1'},
    {text: "Réorienté", value: 11, color: 'light-green darken-1'},
]

export function get_status_name(status) {
    let index = MAP_STATUS.findIndex(x => x.value === status);
    if (index > -1) return MAP_STATUS[index].text;
    return status;
}


export const get_available_waiting_list = function() {
    return Object.keys(MAP_WAITING_LIST);
}

export function get_waiting_list_tag_names() {
    let tag_names = [];
    for (let key in MAP_WAITING_LIST) {
        tag_names.push(MAP_WAITING_LIST[key].tag);
    }
    return tag_names;
}

export function get_name_from_tag(tag) {
    for (let key in MAP_WAITING_LIST) {
        if (MAP_WAITING_LIST[key].tag === tag) {
            return MAP_WAITING_LIST[key].name;
        }
    }
    return "";
}

export const get_data_waiting_list = function(of_type) {
    if(get_available_waiting_list().includes(of_type)) {
        return MAP_WAITING_LIST[of_type];
    }
    return {};
}

export const get_name_waiting_list = function(of_type) {
    let data_waiting_list = get_data_waiting_list(of_type);
    if(data_waiting_list) return data_waiting_list.name;
    return null;
}


const WAITING_LIST_ELEMENTS = {
    "color": {
        "redon": "#3E9DD1",
        "hurepoix": "#2C6732",
        "sante-seine-essone": "#A33D7D",
        "gex": "#014C78",
        "littoral-en-nord": "#00A892",
        "default": "#337477"
    },
    "logo": {
        "redon": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/CPTS/CPTS%20Pays%20de%20Redon-1.png",
        "hurepoix": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/CPTS/CPTS%20Sud%20Hurepoix.png",
        "sante-seine-essone": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/CPTS/CPTS%20Sant%C3%A9%20Seine%20Essonne.png",
        "gex": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/CPTS/CPTS%20Pays%20de%20Gex.png",
        "littoral-en-nord": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/CPTS/CPTS%20Littoral%20Nord.png",
        "brest-sante-oceane": "https://www.anamnese.care/hubfs/Logiciels/VILLE/Coordination/Logos/Logos%20CPTS%20-%20clients/CPTS-LOGO-Brest-sant%C3%A9-oc%C3%A9ane.png ",
        "pays-retz": "https://www.anamnese.care/hubfs/Logiciels/VILLE/Coordination/Logos/Logos%20CPTS%20-%20clients/CPTS-LOGO-Pays-de-retz.png",
        "pays-de-grasse": "https://www.anamnese.care/hubfs/Logiciels/VILLE/Coordination/Logos/Logos%20CPTS%20-%20clients/CPTS-LOGO-Pays-de-grasse.png",
        "default": "https://www.anamnese.care/hubfs/config/Citana/inzeecare/logo/logo-citana-couleur-BL.png"
    },
    "width_logo": {
        "sante-seine-essone": "60%",
        "pays-retz": "60%",
        "pays-de-grasse": "70%",
        "default": "100%"
    },
    "title": {
        "redon": '' +
            '<p class="black--text font-size--34 bold--text mb-0">AccèsS !</p>' +
            '<p class="font-size--25 black--text semibold--text">Un service unique pour ' +
            '<span class="font-size--25 white--text semibold--text px-2 py-1" style="background-color: #3E9DD1">améliorer l\'accès aux soins</span>' +
            '<br>dans le pays de Redon</p>',
        "hurepoix": '' +
            '<div class="text-center text-sm-left d-flex">' +
            '<img src="https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/CPTS/CPTS%20Espace%20Vie.png" alt="Logo Espace Vie" width="20%" class="hidden-xs-only"/>' +
            '<img src="https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/CPTS/CPTS%20Espace%20Vie.png" alt="Logo Espace Vie" width="80%" class="hidden-sm-and-up"/>' +
            '</div>',
        "sante-seine-essone": '' +
            '<div class="text-center text-sm-left">' +
            '<img src="https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/CPTS/CPTS%20Espace%20Vie.png" alt="Logo Espace Vie" width="20%" class="hidden-xs-only"/>' +
            '<img src="https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/CPTS/CPTS%20Espace%20Vie.png" alt="Logo Espace Vie" width="80%" class="hidden-sm-and-up"/>' +
            '</div>',
        "default": ""
    },
    "footer_url_redirection": {
        "redon": "https://cpts-pays-redon.site-sante.fr/",
        "gex": "https://www.cpts-du-pays-de-gex.org/",
        "littoral-en-nord": "https://www.cpts-littoralnord.fr/",
        "default": "https://app.citana.fr/"
    },
    "footer_url_txt": {
        "redon": "www.cpts-pays-redon.site-sante.fr",
        "gex": "www.cpts-du-pays-de-gex.org",
        "littoral-en-nord": "www.cpts-littoralnord.fr",
        "default": "www.app.citana.fr"
    },
    "home_txt": {
        "redon": "La Communauté Professionnelle Territoriale de Santé du Pays de Redon",
        "hurepoix": "La Communauté Professionnelle Territoriale de Santé Sud Hurepoix",
        "sante-seine-essone": "La Communauté Professionnelle Territoriale de Santé, Santé Seine Essonne,",
        "gex": "La Communauté Professionnelle Territoriale de Santé du Pays de Gex",
        "littoral-en-nord": "La Communauté Professionnelle Territoriale de Santé Littoral en Nord",
        "brest-sante-oceane": "La Communauté Professionnelle Territoriale de Santé Brest Santé Océane",
        "pays-retz": "La Communauté Professionnelle Territoriale de Santé du Pays de Retz",
        "default": "Votre Communauté Professionnelle Territoriale de Santé"
    },
    "cgu_specific_txt": {
        "redon": "" +
            "citana est la plateforme sécurisée (hébergeur données de santé) choisie par la CPTS du Pays de Redon " +
            "pour coordonner les soins autour des patients du territoire.",
        "default": "" +
            "citana est la plateforme sécurisée (hébergeur données de santé) choisie par votre structure " +
            "pour coordonner les soins autour des patients du territoire."
    },
    "end_specific_txt": {
        "redon": "" +
            "Les professionnels de santé du Pays de Redon s’engagent volontairement dans cette démarche et font leur " +
            "possible pour contribuer à l’accès aux soins des patients.",
        "default": "" +
            "Les professionnels de santé s’engagent volontairement dans cette démarche et font leur possible pour " +
            "contribuer à l’accès aux soins des patients."
    },
    "global_end_txt": {
        "erdre-et-canal": '' +
            'Votre demande a bien été prise en compte et nous vous remercions d’avoir contribué au recensement des usagers sans médecin traitant.' +
            '<br>' +
            '<br>' +
            'Pour rappel, nous nous fixons une période de 3 mois pour faire cet état des lieux puis fin juin, le groupe de travail (composé de professionnels de santé) ' +
            'envisagera des suites à cette action. Ce recensement nous permettra d’avoir une meilleure visibilité des personnes en demande, de leurs besoins et ainsi ' +
            'identifier des pistes d’actions adaptées au territoire.' +
            '<br>' +
            '<br>' +
            'Nous reviendrons vers vous.',
        "default": null
    }
}

export const get_element_waiting_list = function(element, client=null) {
    if(Object.keys(WAITING_LIST_ELEMENTS).includes(element)) {
        if(Object.keys(WAITING_LIST_ELEMENTS[element]).includes(client)) return WAITING_LIST_ELEMENTS[element][client];
        return WAITING_LIST_ELEMENTS[element]["default"];
    }
    return ""
}

export function get_url_waiting_list(hf_id) {
    return `${window.location.origin}/coordination/${hf_id}/liste-attente/mt`;
}
