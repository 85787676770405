<template>
  <AnDialogComponent :dialog.sync="_dialog" title="Annuler le protocole" icon="mdi-cancel" :actions="true" :persistent="false">
    <template v-slot:card-text>
      <p class="text-center medium--text font-size--18 black--text">
        Êtes-vous sûr de vouloir annuler ce protocole ?
      </p>
      <p class="font-italic text-center">
        Attention, cette action est irréversible.
      </p>
    </template>
    <template v-slot:card-actions>
      <v-btn color="pastel_red" rounded elevation="0" class="px-sm-6 white--text" :loading="loading" @click="cancel_protocol()">
        <span class="medium--text font-size--16">Annuler le protocole</span>
      </v-btn>
    </template>
  </AnDialogComponent>
</template>

<script>
import AnDialogComponent from "anam-library/src/components/an-ui/an-dialog/AnDialogComponent";

export default {
  name: "ManagerProtocolsCancelDialog",
  components: {
    AnDialogComponent
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    protocol: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    _dialog: {
      get: function() {
        return this.dialog;
      },
      set: function(val) {
        this.$emit("update:dialog", val);
      }
    },
  },
  methods: {
    cancel_protocol: function() {
      this.loading = true;
      let data = {closed: true}
      this.$http.patch(`/api/patients/${this.protocol.patient_id}/questionnaires-tags/${this.protocol.uid}`, data).then(response => {
        this.$emit("update_protocol", response.data);
        this.$store.commit("PostSuccess", "Protocole annulé");
        this._dialog = false;
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>