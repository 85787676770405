import axios from "axios";
import {order_txt} from "anam-library/src/data/professions";

const state = {
    meta_tags: []
}

const getters = {
    getMetaTagsVisibility: function(state, getters) {
        let meta_tags = state.meta_tags;
        // TODO coordination : voir pour les professions medical vs medico-sociaux
        let current_user_is_pds = getters.getPermission("is_pds");
        if(!current_user_is_pds) meta_tags = meta_tags.filter(x => x.visibility === 3);
        return meta_tags;
    },
    getAllMetaTags(state) {
        return state.meta_tags.filter(x => !x.is_pt_tag);
    },
    getMetaTags: function (state, getters) {
        return getters.getMetaTagsVisibility.filter(x => !x.is_pt_tag);
    },
    getMetaTagsProtocols: function (state, getters) {
        return getters.getMetaTagsVisibility.filter(x => x.is_pt_tag);
    },
    getAllTagsSelect: function(state, getters) {
        let tags = [];
        let cpts_ids = [];
        for (let cpts of getters.getServicesCPTS) {
            cpts_ids.push(cpts.hf_id);
        }

        let all_meta_tags = getters.getMetaTags.concat(getters.getMetaTagsProtocols);
        for (let tag of all_meta_tags) {
            if (tag.cpts && tag.cpts.filter(x => cpts_ids.includes(x)).length) {
                let tmp = tag;
                tmp["text"] = tag.commercial_name;
                tags.push(tmp);
            }
        }
        return tags.sort(order_txt);
    }
}

const mutations = {
    setMetaTags: function(state, val) {
        state.meta_tags = val;
    },
    pushMetaTags: function(state, val) {
        state.meta_tags.push(val);
    },
    setSpecificMetaTags: function(state, val) {
        let temp = JSON.parse(JSON.stringify(state.meta_tags));
        let index = temp.findIndex(x => x.id === val["id"]);
        if(index > -1) {
            temp[index] = val;
        }
        state.meta_tags = temp;
    },
    // Ajoute la CPTS au MetaTagIndicator
    addCPTSInTag: function(state, {tag_id, cpts_id}) {
        let index = state.meta_tags.findIndex(x => x.id === tag_id);
        if (index > -1) {
            state.meta_tags[index].cpts.push(parseInt(cpts_id));
        }
    },
    // Supprime la CPTS du MetaTagIndictor
    removeCPTSInTag: function(state, {tag_id, cpts_id}) {
        let index = state.meta_tags.findIndex(x => x.id === tag_id);
        if (index > -1) {
            let index_cpts = state.meta_tags[index].cpts.findIndex(x => x === parseInt(cpts_id));
            if (index_cpts > -1) {
                state.meta_tags[index].cpts.splice(index_cpts, 1);
            }
        }
    }
}

const actions = {
    async apiGetMetaTags(context) {
        try {
            let response = await axios.get('/api/hf-coordination/tags');
            context.commit('setMetaTags', response.data);
        }
        catch (error) {
            if (error.response) context.dispatch('ManageErrorLight', error.response.status);
            context.commit('PostCommonError');
        }
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}