<template>
  <v-hover v-slot:default="{ hover }">
    <v-btn :color="color"
           x-large
           style="border-radius: 15px"
           :elevation="hover ? 6 : 0"
           :class="`${large ? 'px-15' : 'px-10'} mx-2`"
           :disabled="disabled"
           :loading="loading"
           @click="$emit('click')">
      <span :class="`${small ? 'font-size--14' : 'font-size--18'} ${large ? 'ml-6 pl-15' : 'pl-2'} medium--text`">{{ txt_btn }}</span>
      <v-icon :class="large ? 'ml-6 pr-15' : 'pl-2'">mdi-chevron-right</v-icon>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: "RegistryBtnAction",
  props: {
    txt_btn: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "primary"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>