<template>
  <v-app>
    <v-main class="bg_primary">
      <AnSnackbar :snackbar_model="$store.state.errors.snack_model"
                  :snackbar_info="$store.state.errors.snack_info"
                  :icon_class="$store.state.errors.snack_info.icon_class"
                  v-on:closed="$store.state.errors.snack_model = false"/>
      <v-container fluid class="pb-8 pb-sm-15">
        <v-row class="mx-0 mt-4 mt-sm-8">
          <v-col cols="12" sm="8" offset-sm="2">
            <v-row class="mx-0">
              <v-col cols="12" sm="3" class="align-self-center text-center pb-0 pb-sm-3">
                <img :src="logo" alt="Logo Structure" :width="width_logo">
              </v-col>
              <v-col cols="12" sm="9" class="text-center align-self-center pt-0 pt-sm-3">
                <div v-html="title"></div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer :color="color">
      <v-col cols="12" class="d-flex">
        <a class="black--text font-size--16 align-self-center bold--text" :href="footer_url_redirection" target="_blank">{{ footer_url_txt }}</a>
        <div class="ml-sm-6 align-self-center">
          <v-icon color="black" large class="mx-1">mdi-twitter</v-icon>
          <v-icon color="black" large class="mx-1">mdi-facebook</v-icon>
          <v-icon color="black" large class="mx-1">mdi-linkedin</v-icon>
        </div>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import AnSnackbar from "anam-library/src/components/an-ui/snackbar/AnSnackbar";
import {get_element_waiting_list} from "coordination-library/src/modules/waiting_list";

export default {
  name: "PatientWaitingListLayout",
  components: {AnSnackbar},
  computed: {
    logo: function() {
      return get_element_waiting_list("logo", this.$store.getters.getHfPublicTemplateName);
    },
    width_logo: function() {
      return get_element_waiting_list("width_logo", this.$store.getters.getHfPublicTemplateName);
    },
    title: function() {
      return get_element_waiting_list("title", this.$store.getters.getHfPublicTemplateName);
    },
    color: function() {
      return get_element_waiting_list("color", this.$store.getters.getHfPublicTemplateName);
    },
    footer_url_redirection: function() {
      return get_element_waiting_list("footer_url_redirection", this.$store.getters.getHfPublicTemplateName);
    },
    footer_url_txt: function() {
      return get_element_waiting_list("footer_url_txt", this.$store.getters.getHfPublicTemplateName);
    }
  }
}
</script>

<style scoped>

</style>