import { render, staticRenderFns } from "./PatientEditConsentement.vue?vue&type=template&id=36934f92&scoped=true"
import script from "./PatientEditConsentement.vue?vue&type=script&lang=js"
export * from "./PatientEditConsentement.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../inzeecare/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36934f92",
  null
  
)

export default component.exports