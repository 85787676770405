const state = {
    manage_cpts: {
        tab_selected: null,
    },
    calendar: {
        tab_selected: null,
    }
};

const getters = {
    getTabSelectedInManage: (state) => {
        return state.manage_cpts.tab_selected;
    },
    getTabSelectedInCalendar: (state) => {
        return state.calendar.tab_selected;
    },
}

const mutations = {
    setTabSelectedInManage: function (state, value) {
        state.manage_cpts.tab_selected = value;
    },
    setTabSelectedInCalendar: function (state, value) {
        state.calendar.tab_selected = value;
    },
};

export default {
    state,
    mutations,
    getters
}

