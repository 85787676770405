<template>
  <v-container fluid class="pt-3 pb-15">
    <v-row class="ma-0">
      <v-col cols="12" sm="11" class="pl-sm-6 align-self-center">
        <p class="semibold--text mb-1 font-size--24 primary--text">Mes patients</p>
        <v-tabs v-model="tab" background-color="bg_primary" color="primary" active-class="bg_primary_2 rounded-xl primary--text semibold--text" hide-slider>
          <v-tab class="font-size--16 semibold--text" style="text-transform: none">Récents</v-tab>
          <v-tab class="font-size--16 semibold--text" style="text-transform: none">Tous</v-tab>
          <template v-if="$vuetify.breakpoint.smAndUp">
            <v-tab v-for="(item, key) in waiting_list" :key="`tab_wl_${key}`"
                   class="font-size--16 semibold--text" style="text-transform: none">
              {{ item.short_name }}
            </v-tab>
          </template>
        </v-tabs>
      </v-col>
      <v-col cols="1" class="pb-0 hidden-xs-only">
        <img :src="img" alt="Logo" width="100%"/>
      </v-col>
      <v-col cols="12" class="px-0 px-sm-3">
        <v-tabs-items v-model="tab" style="width: 100%" class="tabs_patients bg_primary pb-10">
          <v-tab-item>
            <DoctorRecentPatientsDataTable :table_items_mapping="table_items_mapping"
                                           :class_headers="class_headers"
                                           v-on:move_tab="move_tab($event)"
                                           v-on:move_to_dpa="move_to_dpa($event)"
                                           v-on:move_to_create="move_to_create()"/>
          </v-tab-item>
          <v-tab-item>
            <DoctorAdvancedSearchPatients :table_items_mapping="table_items_mapping"
                                          :class_headers="class_headers"
                                          v-on:move_to_dpa="move_to_dpa($event)"
                                          v-on:move_to_create="move_to_create()"/>
          </v-tab-item>
          <template v-if="$vuetify.breakpoint.smAndUp">
            <v-tab-item v-for="(item, key) in waiting_list" :key="`tabitem_wl_${key}`">
              <WaitingListDataTable :of_type="item.of_type"
                                    :table_items_mapping="table_items_mapping"
                                    :access_pt="item.access_pt_on_dashboard"
                                    :class_headers="class_headers"
                                    v-on:move_to_dpa="move_to_dpa($event)"/>
            </v-tab-item>
          </template>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DoctorRecentPatientsDataTable from "./recent/DoctorRecentPatientsDataTable";
import DoctorAdvancedSearchPatients from "./advanced-search/DoctorAdvancedSearchPatients";
import WaitingListDataTable from "./waiting-list/WaitingListDataTable";

import LastNameItem from "anam-library/src/components/an-data-table/an-table-items-components/LastNameItem.vue";
import FirstNameItem from "anam-library/src/components/an-data-table/an-table-items-components/FirstNameItem.vue";
import FullNameItem from "anam-library/src/components/an-data-table/an-table-items-components/FullNameItem.vue";
import SexItemLight from "anam-library/src/components/an-dashboard/an-dashboard-table-items/SexItemLight";
import DDNWithAgeItem from "anam-library/src/components/an-data-table/an-table-items-components/DDNWithAgeItem.vue";
import AddressItem from "anam-library/src/components/an-data-table/an-table-items-components/AddressItem";
import PhoneItem from "anam-library/src/components/an-data-table/an-table-items-components/PhoneItem";
import TagsItem from "anam-library/src/components/an-dashboard/an-dashboard-table-items/TagsItem";
import WaitingListInscriptionItem from "./items/WaitingListInscriptionItem";
import WaitingListFromItem from "./items/WaitingListFromItem";
import WaitingListStatusItem from "./items/WaitingListStatusItem";
import DashboardActionsItems from "./items/DashboardActionsItems";
import AccessProtocolItem from "./waiting-list/AccessProtocolItem.vue";

import {get_available_waiting_list, get_data_waiting_list} from "coordination-library/src/modules/waiting_list";
import img_patients from "@/assets/patients.png";

export default {
  name: "DoctorDashboardView",
  components: {
    DoctorAdvancedSearchPatients,
    WaitingListDataTable,
    DoctorRecentPatientsDataTable
  },
  data: () => ({
    img: img_patients,
    tab: 0,
    waiting_list: [],
    class_headers: "primary white--text",
    table_items_mapping: {
      "sexe": SexItemLight,
      "last_name": LastNameItem,
      "first_name": FirstNameItem,
      "full_name": FullNameItem,
      "birthdate": DDNWithAgeItem,
      "fullAddress": AddressItem,
      "mobile": PhoneItem,
      "tags": TagsItem,
      "dpa": DashboardActionsItems,
      "access_pt": AccessProtocolItem,
      "inscription": WaitingListInscriptionItem,
      "from": WaitingListFromItem,
      "status": WaitingListStatusItem,
    },
  }),
  computed: {
    has_access_to_waiting_list() {
      return this.$store.getters.getPermission('has_access_to_waiting_list');
    }
  },
  watch: {
    tab: function (val) {
      this.$store.commit('setPatientsTab', val);
    }
  },
  mounted() {
    this.$store.state.is_mounted_app = true;
    this.set_waiting_list_available();

    if (this.$route.query.tab || this.$route.query.tab === 0) {
      this.$store.commit('setPatientsTab', this.$route.query.tab);
      this.$router.replace({...this.$router.currentRoute, query: {}});
    }
    this.tab = this.$store.getters.getPatientsTab;
  },
  methods: {
    set_waiting_list_available: function () {
      let tmp = [];
      let available_waiting_list = get_available_waiting_list();
      for (let el in available_waiting_list) {
        let of_type = available_waiting_list[el];
        if (this.has_access_to_waiting_list[of_type]) tmp.push(get_data_waiting_list(of_type));
      }

      let index = tmp.findIndex(x => x.of_type === "334");
      if (index > -1) tmp.splice(0, 0, tmp.splice(index, 1)[0])
      this.waiting_list = tmp;
    },
    move_tab: function (tab) {
      this.tab = tab;
    },
    move_to_create() {
      this.$store.state.creation_mode = true;
      this.$router.push('/patient-edit');
    },
    move_to_dpa(event) {
      // TODO Charlotte setPatientAccess a supprimer quand facto terminée
      this.$store.dispatch('setPatientAccess', event)
      this.$router.push({path: '/patients/' + event['patient_id']})
    }
  }
};
</script>

<style>
.tabs_patients .v-data-table-header th {
  white-space: nowrap;
}
</style>