const state = {
    selected_calendar: null
}

const getters = {
    getSelectedCalendar: function (state) {
        return state.selected_calendar;
    }
}

const mutations = {
    setSelectedCalendar: function(state, val) {
        state.selected_calendar = val;
    }
}

export default {
    state,
    getters,
    mutations
}