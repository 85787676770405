import {get_all_type_services_structures} from "anam-library/src/data/services";
import {status_account_is_validate} from "anam-library/src/js/onboarding";

const state = {
    // USER ACCESS
    has_subscription: false,
    has_access_to_calendar: false,
    has_access_to_discussion: false,
    has_access_to_coordination: false,
    has_access_to_consultation: false,
    has_access_to_snp: false,
    has_access_to_snp_agenda: false,
    has_access_to_ddi: false,
    has_access_to_protocoles: false,
    has_access_to_medical_record: false,
    has_access_to_communication: false,
    has_access_to_tlc: false,
    has_access_to_home_survey: false,
    has_access_to_waiting_list: {},
    has_esp: false,
    is_admin_cpts: false,
    has_cpts: false,
    is_pds: true,
    is_etab_account: false,
    my_hf_services: [],
    my_hf_managed: [],
    admin_cpts_services_structures: [],
    waiting_for_cpts: false,
    waiting_for_validation: false,

    all_hf_coordinations: []
};

const getters = {
    getPermission: (state) => (name) => {
        return state[name]
    },
    getAllServices: (state) => {
        let type_services = get_all_type_services_structures().concat("340");
        return state.my_hf_services.filter(x => type_services.includes(x.of_type));
    },
    getServicesCPTS: (state) => {
        return state.my_hf_services.filter(x => x.of_type === "340");
    },
    getServicesStructures: (state) => {
        return state.my_hf_services.filter(x => get_all_type_services_structures().includes(x.of_type));
    },
    getAdminCptsServicesStructures: (state) => {
        return state.admin_cpts_services_structures;
    },
    getHFManaged: (state) => {
        return state.my_hf_managed;
    },
    getAllHFCoordinations: (state) => {
        return state.all_hf_coordinations;
    },
    getProHFPublicTemplateName: (state) => {
        let services = state.my_hf_services.filter(x => x.of_type === "340");
        let template_name = null;
        if(services) template_name = services[0].hf_public_template_name;
        return template_name;
    }
}

const mutations = {
    setAllPermissions: function (state, {data}) {
        let nb_esp = data.my_hf_services.filter(x => x.of_type === "340").length;

        state.has_subscription = !!data.subscription;
        state.has_access_to_calendar = ["8", "12"].includes(data.subscription) || nb_esp > 0;
        state.has_access_to_consultation = data.subscription === "12";
        state.has_access_to_discussion = nb_esp > 0;

        // Doit devenir la nouvelle variable à utiliser --> supprimer les autres progressivement
        state.has_access_to_coordination = nb_esp > 0;

        // OPTIONS
        if(state.has_access_to_coordination) {
            state.has_access_to_snp = data.has_access_to_snp;
            state.has_access_to_ddi = data.has_access_to_ddi;
            state.has_access_to_protocoles = data.has_access_to_protocoles;
            state.has_access_to_medical_record = data.has_access_to_medical_record;
            state.has_access_to_communication = data.has_access_to_communication;
            state.has_access_to_waiting_list = data.has_access_to_waiting_list;
            state.has_access_to_tlc = data.has_access_to_tlc;
            state.has_access_to_home_survey = data.has_access_to_home_survey;
        } else {
            state.has_access_to_snp = false;
            state.has_access_to_ddi = false;
            state.has_access_to_protocoles = false;
            state.has_access_to_medical_record = false;
            state.has_access_to_communication = false;
            state.has_access_to_waiting_list = {};
            state.has_access_to_tlc = state.has_subscription;
            state.has_access_to_home_survey = false;
        }

        state.has_esp = nb_esp > 0;
        state.is_admin_cpts = Object.keys(data).includes("managed_hf") && !!data.managed_hf.length;
        state.has_cpts = Object.keys(data).includes("my_hf_services") && !!data.my_hf_services.filter(x => x.of_type === "340").length;
        if (state.has_cpts) state.my_hf_services = data.my_hf_services;
        if (state.is_admin_cpts) state.my_hf_managed = data.managed_hf;

        // Cas des non professionnels de santé (exemple : coordinateur)
        state.is_pds = data.professionnel.dmp_type !== "103";

        // Pour identifier les comptes établissements n'ayant pas accès à toutes les fonctionnalités
        state.is_etab_account = data.status_account === 6;
        if(state.is_etab_account) {
            state.has_access_to_snp = false;
            state.has_access_to_snp_agenda = true;
            state.has_access_to_protocoles = false;
            state.has_access_to_medical_record = false;
            state.has_access_to_communication = false;
            state.has_access_to_tlc = false;
            state.has_access_to_home_survey = false;
        }

        // Comptes pas encore validés
        if(data.status_account && !status_account_is_validate(data.status_account)) {
            state.has_access_to_snp = false;
            state.has_access_to_snp_agenda = false;
            state.has_access_to_protocoles = false;
            state.has_access_to_ddi = false;
            state.has_access_to_medical_record = false;
            state.has_access_to_communication = false;
            state.has_access_to_tlc = false;
            state.has_access_to_home_survey = false;
            state.has_access_to_waiting_list = {};
            state.has_access_to_coordination = false;
            state.waiting_for_validation = true;
        }

        if(data.status_account && data.waiting_for_cpts) state.waiting_for_cpts = true;
    },
    setPermissionsSuperAdmin: function (state) {
        state.has_access_to_snp = true;
        state.has_access_to_ddi = true;
        state.has_access_to_protocoles = true;
        state.has_access_to_medical_record = true;
        state.has_access_to_communication = true;
        state.has_access_to_waiting_list = {"334": true};
        state.has_access_to_coordination = true;
        state.has_access_to_tlc = true;
        state.waiting_for_cpts = false;
        state.waiting_for_validation = false;
        state.has_access_to_home_survey = true;
    },
    setPermission: function (state, {name, value}) {
        state[name] = value;
    },
    resetAllPermissions: function(state) {
        state.has_subscription = false;
        state.has_access_to_calendar = false;
        state.has_access_to_discussion = false;
        state.has_access_to_coordination = false;
        state.has_access_to_consultation = false;
        state.has_access_to_home_survey = false;
        state.has_access_program_visio = false;
        state.has_access_invite_visio = false;
        state.has_access_to_snp = false;
        state.has_access_to_snp_agenda = false;
        state.has_access_to_ddi = false;
        state.has_access_to_protocoles = false;
        state.has_access_to_medical_record = false;
        state.has_access_to_communication = false;
        state.has_access_to_tlc = false;
        state.has_access_to_waiting_list = {};
        state.has_esp = false;
        state.is_admin_cpts = false;
        state.has_cpts = false;
        state.is_pds = true;
        state.is_etab_account = false;
        state.my_hf_services = [];
        state.my_hf_managed = [];
        state.admin_cpts_services_structures = [];
        state.waiting_for_cpts = false;
        state.waiting_for_validation = false;
    },
    setAdminCptsServicesStructures: function(state, value) {
        state.admin_cpts_services_structures = value;
    },
    setAllHFCoordinations: function(state, value) {
        state.all_hf_coordinations = value;
    }
};

export default {
    state,
    mutations,
    getters
}

