<template>
  <v-container fluid class="pt-3 pb-15 pl-6">
    <AnBackButton v-on:back="$router.push({name: 'ManagerUsers'})" color="primary" :x_large="false"/>
    <p class="semibold--text mb-1 font-size--24 primary--text">Fiche utilisateur - {{ user_id }}</p>
    <v-expand-transition>
      <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
    </v-expand-transition>
    <v-expand-transition>
      <v-card v-if="!loading" class="mt-2 mb-5 rounded-lg" elevation="0">
        <v-card-text>
          <v-row class="ma-0" no-gutters>
            <v-col cols="5">
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon x-large>mdi-account-circle</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <p class="font-size--20 semibold--text primary--text">{{ first_name }} {{ last_name }}</p>
                  <p class="font-size--16 medium--text primary_dark--text mb-0">{{ profession }}</p>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="3" class="pl-6">
              <p class="mb-1 normal--text">Statut :</p>
              <v-chip v-if="!is_active" color="red lighten-4">
                <span class="red--text darken-4--text semibold--text">Désactivé</span>
              </v-chip>
              <template v-else>
                <v-chip :color="sa_color_chip">
                  <span :class="`${sa_color_txt}--text darken-4--text semibold--text`">{{ status_account }}</span>
                </v-chip>
                <p v-if="status_account_subtext" class="mb-0 font-italic">{{ status_account_subtext }}</p>
              </template>
              <p v-if="old_inzeecare_account" class="mt-2 font-italic mb-2">Ancien compte inzeecare</p>
              <p class="medium--text">Connecté SSO : {{ has_anamnese_uid ? 'Oui' : 'Non' }}</p>
              <v-alert v-if="!has_anamnese_uid" type="warning" text dense class="mx-3">
                <span class="medium--text secondary--text">Attention les comptes TLC (orthophonistes, etc.) ne doivent pas être migrés</span>
              </v-alert>
              <v-btn v-if="!has_anamnese_uid" text color="primary" :loading="loading_migrate_sso" @click="migrate_user_in_sso()">
                <span class="medium--text text-decoration-underline">Migrer l'utilisateur vers le SSO Anamnèse</span>
              </v-btn>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="2" class="pl-6">
              <p class="mb-1 normal--text">Rôle :</p>
              <span class="semibold--text font-size--16 primary_dark--text">{{ role }}</span>

              <p class="mb-1 mt-4 normal--text">Dernière connexion :</p>
              <span v-if="last_login" class="semibold--text font-size--16 primary_dark--text">{{ last_login }}</span>
              <v-icon v-else small>mdi-minus</v-icon>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="2" class="pl-6">
              <p class="primary_dark--text medium--text">{{ user.is_active ? 'Désactiver' : 'Activer' }} l'utilisateur</p>
              <div>
                <v-switch style="position: absolute" class="mt-0" :color="user.is_active ? 'green' : 'red'" v-model="user.is_active" @change="toggle_user_active_status()" :loading="loading_active_status"></v-switch>
              </div>
            </v-col>

            <v-col cols="12" class="my-5">
              <v-tabs v-model="tabs" show-arrows background-color="white" color="primary_dark" active-class="bg_primary rounded-xl primary--text semibold--text" hide-slider>
                <v-tab class="font-size--16 semibold--text" style="text-transform: none">Présentation</v-tab>
                <v-tab class="font-size--16 semibold--text" style="text-transform: none">Périmètres de prise en charge</v-tab>
                <v-tab class="font-size--16 semibold--text" style="text-transform: none">Notifications</v-tab>
                <v-tab class="font-size--16 semibold--text" style="text-transform: none">Abonnement</v-tab>
              </v-tabs>
            </v-col>
            <v-col cols="12">
              <v-tabs-items v-model="tabs" style="width: 100%" class="white py-5 px-10">
                <v-tab-item>
                  <p class="font-size--18 semibold--text primary--text mb-1">Profil</p>
                  <v-divider class="mb-4"></v-divider>
                  <v-list class="py-0">
                    <v-list-item>
                      <v-list-item-content v-if="civility">
                        <v-list-item-subtitle class="primary_dark--text medium--text">Civilité</v-list-item-subtitle>
                        <div>
                          <span class="font-size--14 normal--text">{{ civility }}</span>
                          <v-btn v-if="civility" color="primary" icon @click="open_edit_dialog('civility', 'la civilité', user.personnephysique.civilite)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </div>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-subtitle class="primary_dark--text medium--text">Prénom</v-list-item-subtitle>
                        <div>
                          <span class="font-size--14 normal--text">{{ first_name }}</span>
                          <v-btn icon color="primary" @click="open_edit_dialog('first_name', 'le prénom', user.first_name)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </div>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-subtitle class="primary_dark--text medium--text">Nom</v-list-item-subtitle>
                        <div>
                          <span class="font-size--14 normal--text">{{ last_name }}</span>
                          <v-btn icon color="primary" @click="open_edit_dialog('last_name', 'le nom', user.last_name)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </div>
                      </v-list-item-content>
                      <v-list-item-content v-if="!civility"></v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle class="primary_dark--text medium--text">Profession</v-list-item-subtitle>
                        <span class="font-size--14 normal--text">{{ profession }}</span>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-subtitle class="primary_dark--text medium--text">Numéro RPPS/ADELI</v-list-item-subtitle>
                        <div>
                          <span v-if="idpp" class="font-size--14 normal--text">{{ idpp }}</span>
                          <v-icon v-else small>mdi-minus</v-icon>
                          <v-btn v-if="has_professionnel_key" color="primary" icon @click="open_edit_dialog('idpp', 'le numéro RPPS/ADELI', user.professionnel.idpp)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </div>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-subtitle class="primary_dark--text medium--text">Mode d'exercice</v-list-item-subtitle>
                        <div>
                          <span v-if="mode_exercice" class="font-size--14 normal--text">{{ mode_exercice }}</span>
                          <v-icon v-else small>mdi-minus</v-icon>
                          <v-btn v-if="has_professionnel_key" color="primary" class="ml-2" icon @click="open_edit_dialog('mode_exercice', 'le mode d\'exercice', user.professionnel.mode_exercice)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle class="primary_dark--text medium--text">Email</v-list-item-subtitle>
                        <div>
                          <span v-if="email" class="font-size--14 normal--text">{{ email }}</span>
                          <v-icon v-else small>mdi-minus</v-icon>
                          <v-icon v-if="user.status_account === 2" class="ml-2" color="green">mdi-check-circle</v-icon>
                          <v-icon v-else-if="user.status_account === 1" class="ml-2" color="orange">mdi-timer-sand</v-icon>
                          <template v-if="user.status_account === 1">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn icon @click="show_resend_email_confirmation_dialog = true" color="primary" :loading="loading_resend_email" v-on="on">
                                  <v-icon>mdi-email-sync</v-icon>
                                </v-btn>
                              </template>
                              <span>Renvoyer l'email de confirmation</span>
                            </v-tooltip>
                          </template>

                          <AnDialogComponent
                            :dialog.sync="show_resend_email_confirmation_dialog"
                            title="Confirmation"
                            width="500"
                            :persistent="false"
                            actions
                          >
                            <template v-slot:card-text>
                              <p class="font-size--16 semibold--text">Êtes-vous sûr de vouloir renvoyer l'email de confirmation ?</p>
                            </template>
                            <template v-slot:card-actions>
                              <v-btn class="px-6" color="primary" elevation="0" text @click="show_resend_email_confirmation_dialog = false">
                                <span class="medium--text text-decoration-underline">Annuler</span>
                              </v-btn>
                              <v-btn class="px-6" color="primary" elevation="0" :loading="loading_resend_email" @click="resend_email_and_close_dialog()">
                                <span class="medium--text">Oui</span>
                              </v-btn>
                            </template>
                          </AnDialogComponent>
                          <v-btn color="primary" class="ml-2" icon @click="open_edit_dialog('email', 'l\'email', user.email)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </div>
                      </v-list-item-content>

                      <v-list-item-content>
                        <v-list-item-subtitle class="primary_dark--text medium--text">Téléphone</v-list-item-subtitle>
                        <div>
                          <span v-if="phone" class="normal--text font-size--14">{{ phone }}</span>
                          <v-icon v-else small>mdi-minus</v-icon>
<!--                          <v-icon v-if="user.status_account" class="ml-2" color="orange">mdi-timer-sand</v-icon>-->
                          <v-btn v-if="phone" icon color="primary" @click="open_edit_dialog('phone', 'le numéro de téléphone', phone)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </div>
                      </v-list-item-content>

                      <v-list-item-content></v-list-item-content>

                    </v-list-item>

                    <v-form ref="form" v-model="valid">
                      <AnDialogComponent
                        :dialog.sync="dialog_edit"
                        :title="dialog_title"
                        width="500"
                        :persistent="false"
                        actions
                      >
                        <template v-slot:card-text>
                          <v-select
                            v-if="edit_field === 'mode_exercice'"
                            v-model="edited_value"
                            :items="mode_exercice_options"
                            label="Mode d'exercice"
                            :rules="[rules.required]"
                            filled
                          ></v-select>
                          <v-text-field
                            v-else-if="edit_field === 'email'"
                            v-model="edited_value"
                            :rules="[rules.required, rules.email]"
                            label="Email"
                            required
                            filled
                          ></v-text-field>
                          <v-select
                            v-else-if="edit_field === 'civility'"
                            v-model="edited_value"
                            :items="civility_options"
                            item-value="value"
                            item-text="text"
                            label="Civilité"
                            filled
                          ></v-select>
                          <v-text-field
                            v-else-if="edit_field === 'phone'"
                            v-model="edited_value"
                            :rules="[rules.required, rules.phone_number]"
                            label="Téléphone"
                            required
                            filled
                          ></v-text-field>
                          <v-text-field
                            v-else
                            v-model="edited_value"
                            :label="dialog_label"
                            :counter="edit_field === 'idpp'"
                          ></v-text-field>
                        </template>
                        <template v-slot:card-actions>
                          <v-btn class="px-6" color="primary" elevation="0" text @click="dialog_edit = false">
                            <span class="medium--text text-decoration-underline">Annuler</span>
                          </v-btn>
                          <v-btn class="px-6" color="primary" elevation="0" :loading="loading_dialog" :disabled="!valid" @click="validate_and_save()">
                            <span class="medium--text">Enregistrer</span>
                          </v-btn>
                        </template>
                      </AnDialogComponent>
                    </v-form>

                    <v-list-item>
                      <v-list-item-content v-for="(item, key) in services_340" :key="`structure_${key}`">
                        <v-list-item-subtitle class="primary_dark--text medium--text">Structure {{ key+1 }}</v-list-item-subtitle>
                        <span class="font-size--14 normal--text">{{ item.health_facility.commercial_name }}</span>
                      </v-list-item-content>
                      <v-list-item-content v-if="services_340.length === 2"></v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <p class="font-size--18 semibold--text primary--text mb-1 mt-10">Adresse(s)</p>
                  <v-divider class="mb-4"></v-divider>
                  <v-list class="py-0">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle class="primary_dark--text medium--text">Adresse 1</v-list-item-subtitle>
                        <span class="font-size--14 normal--text">{{ address }}</span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

<!--                  <p class="font-size&#45;&#45;18 semibold&#45;&#45;text primary&#45;&#45;text mb-1 mt-10">Présentation</p>-->
<!--                  <v-divider class="mb-4"></v-divider>-->

<!--                  <p class="font-size&#45;&#45;18 semibold&#45;&#45;text primary&#45;&#45;text mb-1 mt-10">Domaines d'expertise</p>-->
<!--                  <v-divider class="mb-4"></v-divider>-->
                  <p class="font-size--18 semibold--text primary--text mb-1 mt-10">Justificatifs
                    <v-tooltip v-if="!user_pj.inscription_pj_identity_card || !user_pj.inscription_pj_photo" bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn text @click="show_reminder_pj_confirmation_dialog = true" color="primary" :loading="loading_reminder_pj_email" v-on="on">
                          <span class="medium--text text-decoration-underline">
                            <v-icon class="mr-2">mdi-email</v-icon> Relancer le professionnel
                          </span>
                        </v-btn>
                      </template>
                      <span>Envoyer un email pour l'envoi des pièces justificatives</span>
                    </v-tooltip>
                  </p>

                  <AnDialogComponent
                    :dialog.sync="show_reminder_pj_confirmation_dialog"
                    title="Confirmation"
                    width="500"
                    :persistent="false"
                    actions
                  >
                    <template v-slot:card-text>
                      <p class="font-size--16 semibold--text mt-4">Êtes-vous sûr de vouloir envoyer un email pour l'envoi des pièces justificatifs ?</p>
                    </template>
                    <template v-slot:card-actions>
                      <v-btn class="px-6" color="primary" elevation="0" text @click="show_reminder_pj_confirmation_dialog = false">
                        <span class="medium--text text-decoration-underline">Annuler</span>
                      </v-btn>
                      <v-btn class="px-6" color="primary" elevation="0" :loading="loading_resend_email" @click="send_reminder_pj_and_close_dialog()">
                        <span class="medium--text">Oui</span>
                      </v-btn>
                    </template>
                  </AnDialogComponent>

                  <v-divider class="mb-4"/>

                  <v-expand-transition>
                    <v-progress-linear v-if="loading_pj" indeterminate></v-progress-linear>
                  </v-expand-transition>

                  <UserIdentityUploader 
                    :user_pj="user_pj"
                    :loading_pj="loading_pj"
                    v-on:submit-document="submit_document($event)"
                  />
                  
                  <p class="font-size--18 semibold--text primary--text mb-1 mt-10">Statut</p>
                  <v-divider class="mb-4"></v-divider>
                  <div v-if="user.is_active" class="d-flex">
                    <div class="align-self-center">
                      <v-chip :color="sa_color_chip">
                        <span :class="`${sa_color_txt}--text darken-4--text semibold--text`">{{ status_account }}</span>
                      </v-chip>
                      <p v-if="status_account_subtext" class="font-italic mb-0">{{ status_account_subtext }}</p>
                    </div>
                    <v-btn v-if="user.status_account && user.status_account !== 6" class="ml-10 px-6 align-self-center" color="primary" text @click="dialog_update_status_account=true">
                      <v-icon class="mr-2">mdi-pencil</v-icon>
                      <span class="medium--text text-decoration-underline">Modifier le statut</span>
                    </v-btn>
                  </div>
                  <v-chip v-else color="red lighten-4">
                    <span class="red--text darken-4--text semibold--text">Désactivé</span>
                  </v-chip>
                  <div class="mt-8">
                    <p class="font-size--16 primary_dark--text medium--text">Historique</p>
                    <v-icon v-if="!user.histories_status_account">mdi-minus</v-icon>
                    <ul v-else>
                      <li v-for="(item, key) in user.histories_status_account" :key="key" class="normal--text">
                        {{ item.value|txt_status_account }} le {{ item.creation_date|datetime }} <span v-if="item.author" class="normal--text">par {{ item.author|txt_author }}</span>
                      </li>
                    </ul>
                  </div>

                  <div class="mt-8">
                    <p class="font-size--16 primary_dark--text medium--text">Note support</p>
                    <v-textarea v-model="support_note" :disabled="loading_patch_user" filled row="6" counter maxLength="500" :rules="[rules.max_length_500]"></v-textarea>
                    <v-btn class="px-8 white--text" elevation="0" color="green" :loading="loading_patch_user" @click="patch_user_note()">
                      <span class="medium--text">Enregistrer la note</span>
                    </v-btn>
                  </div>
                </v-tab-item>
                <v-tab-item>
                  <p class="font-size--18 font-italic normal--text mt-4">
                    <v-icon>mdi-account-hard-hat</v-icon>
                    <v-icon class="mr-2">mdi-tools</v-icon>
                    En construction
                    <v-icon class="ml-2">mdi-tools</v-icon>
                    <v-icon>mdi-account-hard-hat</v-icon>
                  </p>
                </v-tab-item>
                <v-tab-item>
                  <p class="font-size--18 semibold--text primary--text mb-1">Appareils liés</p>
                  <v-divider class="mb-2"></v-divider>
                  <v-list>
                    <v-list-item v-for="device in devices" :key="device.id">
                      <v-list-item-avatar>
                        <v-icon size="36">{{ get_device_icon(device.device_type) }}</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-row no-gutters>
                          <v-col cols="2">
                            <v-list-item-title class="primary_dark--text medium--text">{{ device.device_type }}</v-list-item-title>
                            <v-list-item-subtitle class="font-size--14 normal--text">
                              Appareil ajouté le : {{ format_date(device.date_created) }}
                            </v-list-item-subtitle>
                          </v-col>
                          <v-col cols="3" class="d-flex justify-start">
                            <v-list-item-actions>
                              <v-btn text outlined color="primary" @click="open_delete_device_dialog(device.id)">
                                <p class="my-1 font-italic semibold--text text-underline">Supprimer l'appareil</p>
                                <v-icon class="ml-3 mt-1">mdi-cancel</v-icon>
                              </v-btn>
                            </v-list-item-actions>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="devices.length === 0">
                      <v-list-item-content>
                        <v-list-item-title class="primary_dark--text medium--text">Aucun appareil trouvé</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <AnDialogComponent
                    :dialog.sync="show_del_device_confirmation_dialog"
                    title="Confirmation"
                    width="500"
                    :persistent="false"
                    actions
                  >
                    <template v-slot:card-text>
                      <p class="font-size--16 semibold--text mt-4">Êtes-vous sûr de vouloir supprimer cet appareil ?</p>
                    </template>
                    <template v-slot:card-actions>
                      <v-btn class="px-6" color="primary" elevation="0" text @click="show_del_device_confirmation_dialog = false">
                        <span class="medium--text text-decoration-underline">Annuler</span>
                      </v-btn>
                      <v-btn class="px-6" color="primary" elevation="0" :loading="loading_del_device" @click="delete_device()">
                        <span class="medium--text">Oui</span>
                      </v-btn>
                    </template>
                  </AnDialogComponent>

                  <p class="font-size--18 semibold--text primary--text mt-3">Nouvelles demande d'intervention</p>
                  <v-divider class="mb-0"></v-divider>

                  <v-list class="mb-5">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="primary_dark--text medium--text">Notification par email</v-list-item-title>
                        <v-list-item-subtitle class="font-size--14 normal--text mt-1">
                          <v-switch style="position: absolute;"
                            v-model="user.notifications_by_email"
                            :readonly="true"
                            color="primary"
                          ></v-switch>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-tab-item>
                <v-tab-item>
                  <p class="font-size--18 font-italic normal--text mt-4">
                    <v-icon>mdi-account-hard-hat</v-icon>
                    <v-icon class="mr-2">mdi-tools</v-icon>
                    En construction
                    <v-icon class="ml-2">mdi-tools</v-icon>
                    <v-icon>mdi-account-hard-hat</v-icon>
                  </p>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-expand-transition>

    <DialogUpdateStatusAccount :dialog.sync="dialog_update_status_account"
                               :user_id="user_id"
                               :init_status_account="user.status_account"
                               v-on:update_user="user=$event"/>
  </v-container>
</template>

<script>
import axios from "axios";

import DialogUpdateStatusAccount from "./components/DialogUpdateStatusAccount.vue";
import UserIdentityUploader from "./components/UserIdentityUploader.vue"
import AnDialogComponent from "anam-library/src/components/an-ui/an-dialog/AnDialogComponent";
import AnBackButton from "anam-library/src/components/an-ui/button/AnBackButton";

import { capitalize_str, get_txt_civility, get_txt_mode_exercice, MAP_MODE_EXERCICE, MAP_CIVILITIES } from "anam-library/src/js/utils";
import { get_txt_status_account, get_subtxt_status_account, get_chip_color_status_account, get_color_status_account } from "anam-library/src/js/onboarding";
import { get_profession_name } from "anam-library/src/data/professions";
import { get_formated_address } from "anam-library/src/js/address";
import { set_txt_author } from "anam-library/src/js/utils";
import { get_formatted_date } from "anam-library/src/js/dates";
import rules from "anam-library/src/js/rules";

export default {
  name: "ManagerUsersDetailsView",
  components: {DialogUpdateStatusAccount, AnDialogComponent, AnBackButton, UserIdentityUploader},
  data: () => ({
    loading: false,
    loading_pj: false,
    loading_dialog: false,
    loading_resend_email : false,
    loading_reminder_pj_email : false,
    loading_del_device: false,
    show_resend_email_confirmation_dialog: false,
    show_reminder_pj_confirmation_dialog : false,
    show_del_device_confirmation_dialog : false,
    tabs: 0,
    user: {},
    user_pj: {
        inscription_pj_identity_card: null,
        inscription_pj_photo: null
    },
    identity_card: null,
    photo: null,
    dialog_image: false,
    selected_image: null,
    dialog_update_status_account: false,
    dialog_edit: false,
    dialog_title: "",
    dialog_label: "",
    edited_value: "",
    edit_field: "",
    valid: false,
    rules: rules,
    mode_exercice_options: Object.values(MAP_MODE_EXERCICE),
    civility_options: Object.keys(MAP_CIVILITIES).map(key => ({
      value: key,
      text: MAP_CIVILITIES[key].txt
    })),
    support_note: null,
    loading_patch_user: false,
    loading_migrate_sso: false,
    loading_active_status: false,
    devices: [],
    device_to_delete: null,
  }),
  computed: {
    user_id: function() {
      return this.$route.params.user_id.toString();
    },
    is_active: function() {
      return this.user.is_active;
    },
    first_name: function() {
      return capitalize_str(this.user.first_name);
    },
    last_name: function() {
      if(this.user && this.user.last_name) return this.user.last_name.toUpperCase();
      return "";
    },
    profession: function() {
      if(this.has_professionnel_key) return get_profession_name(this.user.professionnel.dmp_type, this.user.professionnel.speciality_code);
      return "";
    },
    old_inzeecare_account: function() {
      return !!this.user.professionnel.id_agency;
    },
    has_anamnese_uid: function() {
      return !!this.user.anamnese_uid;
    },
    status_account: function() {
      if(this.user.status_account) return get_txt_status_account(this.user.status_account);
      else return null;
    },
    status_account_subtext: function() {
      if(this.user.status_account) return get_subtxt_status_account(this.user.status_account);
      return null
    },
    sa_color_chip: function() {
      return get_chip_color_status_account(this.user.status_account);
    },
    sa_color_txt: function() {
      return get_color_status_account(this.user.status_account);
    },
    role: function() {
      if(this.user && this.user.technical_account && this.user.technical_account.health_facilities) return "Coordinateur";
      return "Membre";
    },
    last_login: function() {
      if(this.user) return get_formatted_date(this.user.last_login);
      return null;
    },
    has_professionnel_key: function() {
      return !!(this.user && this.user.professionnel);
    },
    has_pp_key: function() {
      return !!(this.user && this.user.personnephysique);
    },
    idpp: function() {
      if(this.has_professionnel_key) return this.user.professionnel.idpp;
      return null;
    },
    civility: function() {
      if(this.has_pp_key) return get_txt_civility(this.user.personnephysique.civilite, false);
      return null;
    },
    mode_exercice: function() {
      if(this.has_professionnel_key) return get_txt_mode_exercice(this.user.professionnel.mode_exercice);
      return null;
    },
    email: function() {
      return this.user.email;
    },
    phone: function() {
      if(this.has_pp_key) return this.user.personnephysique.personel_phone;
      return null;
    },
    services_340: function() {
      if(this.has_professionnel_key) return this.user.professionnel.services.filter(x => x.of_type === "340");
      return [];
    },
    address: function() {
      if(this.has_pp_key) return get_formated_address(this.user.personnephysique.adresse);
      return null;
    },
  },
  created() {
    this.$store.state.is_mounted_app = true;
    this.$vuetify.goTo(0);
    this.get_infos();
    this.fetch_user_pjs();
    this.fetch_user_devices();
  },
  methods: {
    open_edit_dialog(field, label, value) {
      this.edit_field = field;
      this.dialog_title = `Modifier ${label}`;
      this.dialog_label = label;
      this.edited_value = value;
      this.dialog_edit = true;
    },
    save_edit(field_name, edited_value) {
      this.loading_dialog = true
      this.$store.commit("ResetSnackBar");

      const payload = {};

      if (field_name === "phone") {
        payload["personnephysique"] = { personel_phone: edited_value }
      } else if (field_name === "mode_exercice") {
        payload["professionnel"] = { "mode_exercice": edited_value };
      } else if (field_name === "idpp") {
        payload["professionnel"] = { "idpp": edited_value };
      } else if (field_name === "civility") {
        payload["personnephysique"] = { civilite: edited_value };
      } else {
        payload[field_name] = edited_value;
      }

      this.$http.patch(`/api/coordination/bo/professionals/${this.user_id}`, payload)
        .then(response => {
          this.user = response.data
          this.$store.commit("PostSuccess", "Profil mis à jour");
        }).catch(error => {
          if(error.response && error.response.status === 422) {
            if(Object.keys(error.response.data).includes("email")) this.$store.commit("PostError", "Email déjà utilisé");
          }
        })
        .finally(() => {
          this.loading_dialog = false;
          this.dialog_edit = false;
        });
    },
    submit_document(data) {
      this.loading_pj = true;

      let form_data = new FormData();
      form_data.append(data["document_type"], data["file"]);

      this.$http.patch(`/api/users/${this.user_id}/inscription-pj`, form_data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { 'one_by_one': true }
      })
        .then(response => {
          this.user_pj = response.data
        })
        .finally(() => {
          this.loading_pj = false;
        });
    },
    fetch_user_pjs() {
      this.loading_pj = true;

      axios.get(`/api/users/${this.user_id}/inscription-pj`)
        .then(response => {
          this.user_pj = response.data
        })
        .finally(() => {
          this.loading_pj = false
        });
    },
    open_image(image_url) {
      this.selected_image = image_url;
      this.dialog_image = true;
    },
    resend_email_and_close_dialog() {
      this.resend_email_confirmation()
      this.show_resend_email_confirmation_dialog = false;
    },
    resend_email_confirmation() {
      this.loading_resend_email = true;
      this.$store.commit("ResetSnackBar");

      this.$http.post(`/api/user/${this.user_id}/sso-actions/verify-email`, {}).then(() => {
        this.$store.commit("PostSuccess", "Email envoyé");
      }).finally(() => {
        this.loading_resend_email = false;
      })
    },
    validate_and_save() {
      if (this.$refs.form.validate()) {
        this.save_edit(this.edit_field, this.edited_value);
      }
    },
    send_reminder_pj_and_close_dialog() {
      this.send_reminder_pj()
      this.show_reminder_pj_confirmation_dialog = false      
    },
    send_reminder_pj() {
      this.loading_reminder_pj_email = true;
      this.$store.commit("ResetSnackBar");

      this.$http.post(`/api/coordination/bo/professionals/send_email_reminder_onboarding_pj/${this.user_id}`, {}).then(() => {
        this.$store.commit("PostSuccess", "Email envoyé");
      }).finally(() => {
        this.loading_reminder_pj_email = false
      })
    },
    get_infos() {
      this.loading = true;
      this.$http.get(`/api/coordination/bo/professionals/${this.user_id}`).then(response => {
        this.user = response.data;
        if(response.data.support_note) this.support_note = response.data.support_note;
      }).finally(() => {
        this.loading = false;
      })
    },
    patch_user_note: function() {
      this.loading_patch_user = true;
      this.$store.commit("ResetSnackBar");

      let data = {
        "support_note": this.support_note,
      }
      this.$http.patch(`/api/coordination/bo/professionals/${this.user_id}`, data).then(() => {
        this.$store.commit("PostSuccess", "Note enregistrée");
      }).finally(() => {
        this.loading_patch_user = false;
      })
    },
    toggle_user_active_status() {
      this.loading_active_status = true;
      this.$store.commit("ResetSnackBar");

      const payload = { is_active: this.user.is_active };

      this.$http.patch(`/api/coordination/bo/professionals/${this.user_id}`, payload)
        .then(response => {
          this.user = response.data;
          this.$store.commit("PostSuccess", "Statut actif mis à jour");
        })
        .finally(() => {
          this.loading_active_status = false;
        });
    },
    fetch_user_devices() {
      this.$http.get(`/api/coordination/bo/professionals/fcm-device-by-user/${this.user_id}`)
        .then(response => {
          this.devices = response.data
        })
    },
    get_device_icon(device_type) {
      switch (device_type) {
        case "DESKTOP":
          return "mdi-monitor";
        case "ANDROID":
          return "mdi-android";
        case "IOS":
          return "mdi-apple-ios";
        default:
          return "mdi-devices";
      }
    },
    open_delete_device_dialog(device_id) {
      this.device_to_delete = device_id;
      this.show_del_device_confirmation_dialog = true;
    },
    delete_device() {
      this.loading_del_device = true

      this.$http.delete(`/api/coordination/bo/professionals/fcm-device-by-user/${this.user_id}`, {
        data: { device_id: this.device_to_delete}
      })
      .then(() => {
        this.devices = this.devices.filter(device => device.id !== this.device_to_delete);
        this.device_to_delete = null
        this.show_del_device_confirmation_dialog = false;
        this.$store.commit("PostSuccess", "Appareil supprimé");
      })
      .finally(() => {
        this.loading_del_device = false
      })
    },
    format_date(date) {
      return get_formatted_date(date)
    },
    migrate_user_in_sso: function() {
      this.loading_migrate_sso = true;
      this.$http.post(`/api/user/${this.user_id}/sso-migrate`, {}).then(() => {
        window.location.reload();
      }).catch(() => {
        this.loading_migrate_sso = false;
      })
    }
  },
  filters: {
    txt_status_account: function (val) {
      return get_txt_status_account(val);
    },
    datetime: function (val) {
      if (val) return get_formatted_date(val);
      return val;
    },
    txt_author: function (val) {
      return set_txt_author(val);
    }
  }
}
</script>

<style scoped>

</style>
